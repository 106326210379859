import { useRoundState, useUserSelector } from 'services';

import { useTheme } from '@/common/ui/theme/hooks';
import { ChatModal } from '@/common/modules/Chat/components/ChatModal/ChatModal';
import { useSidebarSelector } from '@/common/modules/Sidebar';
import { ChatLoadingError, GetStreamChat, ChatMessage, useChat } from '@/common/modules/Chat';
import { IPlayTapSound } from '@/common/entities';

import { useTranslation } from '#/translates';
import { withTapSound } from '#/hocs/withTapSound';

import { ChatMessageInput } from './components/ChatMessageInput';

const Chat = ({ playTapSound }: IPlayTapSound) => {
   const theme = useTheme();
   const { t } = useTranslation();
   const sidebarType = useSidebarSelector();
   const { isChatEnabled, handleCloseChat } = useChat();
   const { physicalTableId } = useRoundState() ?? {};
   const { id, username, chatToken } = useUserSelector() ?? {};

   const isChatModalOpen = isChatEnabled && !sidebarType?.length;

   const onCloseChatModal = (): void => {
      playTapSound();
      handleCloseChat();
   };

   return (
      <ChatModal isChatModalOpen={isChatModalOpen} onCloseChatModal={onCloseChatModal}>
         <GetStreamChat
            isDarkMode
            userName={username}
            userId={id}
            userToken={chatToken}
            physicalTableUUID={physicalTableId}
            ChatMessageInput={() => <ChatMessageInput placeholder={t('chat.placeholder')} />}
            ChatLoadingError={() => <ChatLoadingError title={t('chat.errorLoading')} />}
            ChatMessages={() => (
               <ChatMessage
                  userId={id}
                  usernameColor={String(theme.colors?.main)}
                  messageColor={String(theme.colors?.white)}
               />
            )}
            threadPlaceholder={t('chat.placeholder')}
         />
      </ChatModal>
   );
};

export const ChatWithTapSound = withTapSound(Chat);
