import { TNullable } from '@/common/types';
import { ETypeBet } from '@/common/roulette/constants';

interface IConfig {
   id: number;
   numbers: number[];
   value?: string;
}

export interface IConfigWithTypeBet extends IConfig {
   type: ETypeBet;
}

export interface IConfigByCellNumber {
   id: number;
   multiplier?: number;
   numbers?: number[];
   type: ETypeBet;
}

export abstract class BetConfig {
   protected readonly type: ETypeBet;
   protected readonly config: IConfig[] | IConfig[][];
   protected readonly multiplier?: TNullable<number> = null;

   getConfig = (): IConfigWithTypeBet[] => {
      return this.config.map((conf) => ({ ...conf, type: this.type }));
   };

   getArrayOfBetIds = (): number[] => {
      return [...this.getConfig().map(({ id }) => id)];
   };

   findConfigByCellNumber = (cellNumber: number): IConfigByCellNumber[] => {
      return this.getConfig()
         .filter(({ numbers }) => numbers.includes(cellNumber))
         .map(({ id, numbers, type }) => ({
            id,
            type,
            numbers,
            ...(this.multiplier && { multiplier: this.multiplier }),
         }));
   };

   getConfigNumbers = () => this.config;
}
