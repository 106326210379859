// @ts-nocheck
import styled, { css } from 'styled-components/macro';

import { orientation } from '@/common/ui/theme/mixins';
import { Button } from '@/common/ui';

export const AutoplayButton = styled(Button)`
   font-size: var(--autoplay-control-fz);
   display: flex;
   text-align: center;
   padding: 10px 0;
   background: ${({ theme, inProgress }) => (inProgress ? theme.colors.yellow : 'transparent')};
   border-color: ${({ theme, inProgress }) =>
      inProgress ? theme.colors.yellow : theme.colors.grey};

   transition: 0.2s;
   margin: 0 auto;

   &:disabled {
      opacity: 1;
   }

   &:active:not(:disabled) {
      cursor: pointer;
      background-color: transparent;
      border-color: ${({ theme }) => theme.colors.yellow};
   }

   ${({ inProgress }) =>
      inProgress &&
      css`
         &:active:not(:disabled) {
            background-color: ${({ theme }) => theme.colors.yellow};
            border-color: ${({ theme }) => theme.colors.yellow};
         }
      `};

   ${orientation.portrait`
      --autoplay-control-fz: 16px;
   `}

   ${orientation.mobileLandscape`
      --autoplay-control-fz: 16px;
   `}
`;

export const ButtonsWrapper = styled.div`
   display: flex;
   margin-top: 30px;
   margin-bottom: 40px;
   justify-content: center;
`;

export const AutoplayWrapper = styled.div`
   --autoplay-control-gap: 10px;
   --autoplay-column-count: 3;
   --autoplay-label-fz: 18px;
   --autoplay-control-width: calc(
      (100% / var(--autoplay-column-count)) - var(--autoplay-control-gap)
   );
   --autoplay-control-fz: 16px;
   --autoplay-control-padding: 10px 0;

   width: 100%;
   color: ${({ theme }) => theme.colors.white};

   ${orientation.mobileLandscape`
      --autoplay-control-fz: 14px;
      --autoplay-label-fz: 14px;
      --autoplay-count-grid: 4;
      --autoplay-control-padding: 8px 0;
   `}

   ${orientation.portrait`
      --autoplay-control-gap: 5px;
      --autoplay-label-fz: 18px;
      --autoplay-control-fz: 16px;
      --autoplay-control-padding: 8px 0;

      margin: 0;
      padding: 0;
   `};
`;

export const Message = styled.div`
   color: ${({ theme }) => theme.colors.yellow};
   font-weight: 700;
   text-align: center;
`;
