/**
 * Calculates the height of a  card based on its width, maintaining an aspect ratio of approximately 1.38:1.
 * @param cardWidth The width of the blackjack card in centimeters.
 * @returns The calculated height of the blackjack card in centimeters.
 */

import { logger } from '../../../utils';

// card has width 6.5 centimeters and width 9 centimeters
// we calculate aspect ratio by these parameters:
const ASPECT_RATIO_VALUE = 9 / 6.5;

export const getCardHeightByCardWidth = (cardWidth: number | string) => {
   const parsedCardWidth = typeof cardWidth === 'string' ? parseInt(cardWidth) : cardWidth;

   try {
      if (parsedCardWidth <= 0) {
         throw new Error("Card width doesn't should less or equal than 0");
      }

      return parsedCardWidth * ASPECT_RATIO_VALUE;
   } catch (error) {
      logger.log(error);
      return 0;
   }
};
