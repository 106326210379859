import { generateQueryParamsString } from '../generateQueryParamsString';

export const addQueryParamsToUrl = ({
   url,
   queryParams = {},
}: {
   url: string;
   queryParams?: Record<string, number | string>;
}): string => {
   const queryString = generateQueryParamsString(queryParams);

   if (queryString) {
      return url.includes('?') ? `${url}&${queryString}` : `${url}?${queryString}`;
   }

   return url;
};
