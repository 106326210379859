import { EHandResult } from '@/common/blackjack/constants';
import { TDealerSeat, TUnstableServerType } from '@/common/blackjack/types';

import { CardRankAdapter } from '../../adapters/cardRankAdapter';
import { cardSuitAdapter } from '../../adapters/cardSuitAdapter';
import { getHandScore } from '../../utils/getHandScore';
import { DEALER_SEAT_INDEX } from '../../constants';
import { IGameStepAdapter, gameStepAdapter } from '../gameStepAdapter';

export interface IDealerAdapter {
   adapt: ({
      serverDealerSeat,
      currentSeat,
      serverGameStep,
   }: {
      serverDealerSeat: TUnstableServerType;
      serverGameStep: TUnstableServerType;
      currentSeat: number;
   }) => TDealerSeat;
}

class DealerAdapter implements IDealerAdapter {
   private gameStepAdapter: IGameStepAdapter;

   constructor({ gameStepAdapter }: { gameStepAdapter: IGameStepAdapter }) {
      this.gameStepAdapter = gameStepAdapter;
   }

   public adapt({
      serverDealerSeat,
      currentSeat,
      serverGameStep,
   }: {
      serverDealerSeat: TUnstableServerType;
      serverGameStep: TUnstableServerType;
      currentSeat: number;
   }): TDealerSeat {
      return {
         isDealerNeedsMoreCardsOrReveal:
            this.gameStepAdapter.isAwaitCard(serverGameStep) && currentSeat === DEALER_SEAT_INDEX,
         isDealingSeat:
            this.gameStepAdapter.isDealingTime(serverGameStep) && currentSeat === DEALER_SEAT_INDEX,
         hand: {
            isPlaying: currentSeat === DEALER_SEAT_INDEX,
            cards: serverDealerSeat.hand.cards.map(({ suit, value, isHidden }) => ({
               suit: cardSuitAdapter(suit),
               rank: CardRankAdapter.adapt(value),
               isFaceDown: isHidden,
            })),
            isBusted: serverDealerSeat.hand.result === EHandResult.Bust,
            hasBj: serverDealerSeat.hand.result === EHandResult.Bj,
            score: getHandScore(serverDealerSeat.hand.value),
         },
      };
   }
}

export const dealerAdapter = new DealerAdapter({ gameStepAdapter });
