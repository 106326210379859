import { unwrapResponse } from '@/common/services/api';
import { autoplayApi } from '@/common/services/api/autoplayApi';
import { IApiResponse } from '@/common/services/api/utils/unwrapResponse';
import { clientApiSlice } from '@/common/services/clientAPI/clientApiSlice';
import { logger, formattedJson } from '@/common/utils';
import { gameSettings } from '@/common/helpers';
import { ENotificationIcons } from '@/common/modules/Notifications';

import { IAutoplayState } from '#/modules/Autoplay/state';
import { translation } from '#/translates';

import { roundValue } from './utils';

type AutoplayModel = IAutoplayState;
type AutoplayActions = {
   handleSetAutoplayRounds: (rounds: number[]) => void;
   handleSetLossLimit: (lossLimit: number) => void;
   handleSetSingleWinLimit: (singleWinLimit: number) => void;
   handleSetAutoplayId: (id: string) => void;
   handleSetAutoplayStarted: (isAutoplayStarted: boolean) => void;
   handleSetSelectedRound: (payload: {
      selectedRound: IAutoplayState['selectedRound'];
      lossLimit: IAutoplayState['lossLimit'];
   }) => void;
   handleShowNotification: (message) => void;
   handleCancelApi: (endpoint: () => NonNullable<unknown>) => IApiResponse<{ autoplayId: string }>;
   handleSetAutoplayLeftRounds: (rounds: number) => void;
   handleResetAutoplay: () => void;
   handleSetUserLossProgress: (number) => void;
   handleStartApi: (endpoint: () => NonNullable<unknown>) => IApiResponse<{ autoplayId: string }>;
   handleSetIsLoading: (isLoading: boolean) => void;
};

export class Autoplay {
   private readonly autoplayModel: AutoplayModel;
   private readonly autoplayActions: AutoplayActions;

   constructor({ autoplayModel, autoplayActions }) {
      this.autoplayModel = autoplayModel;
      this.autoplayActions = autoplayActions;
   }

   getBets = () => {
      return this.autoplayModel.bets;
   };

   getSingleWinLimit = () => {
      return this.autoplayModel.singleWinLimit;
   };

   getUserLossProgress = () => {
      return this.autoplayModel.userLossProgress;
   };

   getLossLimit = () => {
      return this.autoplayModel.lossLimit;
   };

   getAutoplayId = () => {
      return this.autoplayModel.autoplayId;
   };

   getSelectedRound = () => {
      return this.autoplayModel.selectedRound;
   };

   getRound = () => {
      return this.autoplayModel.roundCounter;
   };

   getAutoplayRounds = () => {
      return this.autoplayModel.autoplayRounds;
   };

   getDefaultLossLimit = () => {
      const { bets, selectedRound } = this.autoplayModel;
      return Number(selectedRound) * bets;
   };

   isAutoplayStarted = () => {
      return this.autoplayModel.isAutoplayStarted;
   };

   isAutoplayDisabled = () => {
      const { bets, lossLimit } = this.autoplayModel;
      const isAutoplayDisabled = bets > 0 && Number(lossLimit) >= bets;
      return !isAutoplayDisabled;
   };

   isMessageShown = () => {
      const { bets } = this.autoplayModel;
      return !bets && !this.isAutoplayStarted();
   };

   isLoading = () => {
      const { isLoading } = this.autoplayModel;
      return isLoading;
   };

   handleSetIsLoading = (isLoading: boolean) => {
      this.autoplayActions.handleSetIsLoading(isLoading);
   };

   handleSetAutoplayLeftRounds = (rounds: number) => {
      this.autoplayActions.handleSetAutoplayLeftRounds(rounds);
   };

   calculateLossLimit = ({ balance }) => {
      const { initialBalance, selectedRound } = this.autoplayModel;
      const betsAmount = Number(initialBalance) - Number(balance);
      return betsAmount * selectedRound;
   };

   cancelAutoplayFromUI = async () => {
      if (!this.isAutoplayStarted()) {
         return;
      }

      this.handleSetIsLoading(true);

      // if error happens due to network issue it will be stopped on backend side
      try {
         await this.autoplayActions.handleCancelApi(() =>
            autoplayApi(clientApiSlice).endpoints.cancelAutoplay.initiate({
               autoplayId: this.autoplayModel.autoplayId,
            }),
         );

         this.handleShowNotification();
         this.autoplayActions.handleResetAutoplay();
      } catch (error) {
         logger.error(formattedJson(error));
      } finally {
         this.handleSetIsLoading(false);
      }
   };

   cancelAutoplayFromServer = () => {
      this.handleShowNotification();
      this.autoplayActions.handleResetAutoplay();
   };

   startAutoplay = async () => {
      const { selectedRound, lossLimit, singleWinLimit, userId } = this.autoplayModel;
      const gameTableId = gameSettings.gameTableId;

      try {
         this.handleStateAutoplay(true); // firstly change autoplay state then make request
         this.handleSetIsLoading(true);
         const autoplayResponse = await this.autoplayActions.handleStartApi(() =>
            autoplayApi(clientApiSlice).endpoints.startAutoplay.initiate({
               roundLength: selectedRound,
               lossLimit,
               singleWinLimit,
               userId,
               gameTableId,
            }),
         );

         const { data, isError, error, isSuccessful } = unwrapResponse(autoplayResponse);

         if (isError) {
            this.handleStateAutoplay(false);
            logger.error(formattedJson(error));
            return;
         }

         if (isSuccessful) {
            this.handleAutoplayId(data.autoplayId);
         }
      } catch (error) {
         this.handleStateAutoplay(false);
         logger.error(formattedJson(error));
      } finally {
         this.handleSetIsLoading(false);
      }
   };

   handleShowNotification = () => {
      const cancelAutoplayNotification = {
         title: translation('notifications.cancelAutoplay.title'),
         action: '',
         content: translation('notifications.cancelAutoplay.content'),
         type: ENotificationIcons.Warning,
      };

      this.autoplayActions.handleShowNotification(cancelAutoplayNotification);
   };

   handleLossLimit = (lossLimit: number) => {
      this.cancelAutoplayFromUI();

      this.autoplayActions.handleSetLossLimit(lossLimit);
   };

   handleSelectedRound = (payload: {
      selectedRound: IAutoplayState['selectedRound'];
      lossLimit: IAutoplayState['lossLimit'];
   }) => {
      this.cancelAutoplayFromUI();

      this.autoplayActions.handleSetSelectedRound(payload);
   };

   handleSingleWinLimit = (singleWinLimit: number) => {
      this.cancelAutoplayFromUI();

      this.autoplayActions.handleSetSingleWinLimit(singleWinLimit);
   };

   handleStateAutoplay = (isAutoplayStarted: boolean) => {
      this.autoplayActions.handleSetAutoplayStarted(isAutoplayStarted);
   };

   handleAutoplayId = (id: string) => {
      this.autoplayActions.handleSetAutoplayId(id);
   };

   handleAutoplayRounds = (rounds: number[]) => {
      this.autoplayActions.handleSetAutoplayRounds(rounds);
   };

   handleSetUserLossProgress = (lossLimit: number, lossLimitLeft: number) => {
      const progressUserLoss = this.calculateCurrentUserLoss(lossLimit, lossLimitLeft);
      const roundProgressValue = roundValue(progressUserLoss);
      this.autoplayActions.handleSetUserLossProgress(roundProgressValue);
   };

   calculateCurrentUserLoss = (lossLimit: number, lossLimitLeft: number) => {
      if (lossLimitLeft > lossLimit) {
         return 0;
      } // remove after backend fix
      return (1 - lossLimitLeft / lossLimit) * 100;
   };
}
