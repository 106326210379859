export interface IBettingMapConfig {
   blackBackgroundColor: string;
   blackBaseSectorBackgroundColor: string;
   blackNumberColor: string;
   bordersColor: string;
   coldShadowColor: string;
   hotShadowColor: string;
   redBackgroundColor: string;
   redBaseSectorBackgroundColor: string;
   redNumberColor: string;
   sideAreaTextColor: string;
   transparency: number;
   zeroBackgroundColor: string;
   zeroNumberColor: string;
}

export const defaultBettingMapColorsConfig: IBettingMapConfig = {
   blackBaseSectorBackgroundColor: '#000000',
   blackBackgroundColor: '#000000',
   redBackgroundColor: '#000000',
   redBaseSectorBackgroundColor: '#ff0000',
   zeroBackgroundColor: '#000000',
   blackNumberColor: '#ffffff',
   sideAreaTextColor: '#ffffff',
   redNumberColor: '#ff0000',
   zeroNumberColor: '#23b04e',
   hotShadowColor: '#FF3F34',
   coldShadowColor: '#2F69FF',
   bordersColor: '#c5c5c5',
   transparency: 1,
};

export const enum ETypeBet {
   STRAIGHT = 'STRAIGHT',
   ODD = 'ODD',
   EVEN = 'EVEN',
   RED = 'RED',
   BLACK = 'BLACK',
   LOW = 'LOW',
   HIGH = 'HIGH',
   LINE = 'LINE',
   DOZEN = 'DOZEN',
   SPLIT = 'SPLIT',
   STREET = 'STREET',
   CORNER = 'CORNER',
   COLUMN = 'COLUMN',
   DOUBLE = 'DOUBLE',
   COMPLETE = 'COMPLETE',
   LUCKY = 'LUCKY',
   FINAL = 'FINAL',
   JEU_ZERO = 'JEU_ZERO',
   TIERS = 'TIERS',
   VOISIN = 'VOISIN',
   ORPHELINS = 'ORPHELINS',
}

export const enum ECellColor {
   Red = 'red',
   Black = 'black',
   Green = 'green',
   SideArea = 'side-area',
}
