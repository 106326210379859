import { useGameState } from '@/common/state/gameTypes/roulette/gameState';

import { LostConnectionOverlay } from './components/LostConnectionOverlay';

export const LayoutOverlay = ({ children }) => {
   const { isConnectionLostMode } = useGameState();

   if (isConnectionLostMode) {
      return <LostConnectionOverlay />;
   }

   return <>{children}</>;
};
