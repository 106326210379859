import { timerSlice } from '@/common/modules/CountDown';
import { gameSettings, timeSync } from '@/common/helpers';
import { TListenerApiDispatchType } from '@/common/types';
import { RTKQueryApi } from '@/common/services/api/types';
import { chipTraySlice, findDefaultChip } from '@/common/modules/ChipTray';
import { clientApiSlice } from '@/common/services/clientAPI/clientApiSlice';
import { rouletteGameConfigSchema } from '@/common/services/api/gameConfigAPI/schemes';
import { TRouletteGameConfig } from '@/common/services/api/gameConfigAPI/types';
import { gameHistorySlice } from '@/common/modules/History';
import { handleGetGameConfig } from '@/common/services/api/gameConfigAPI';

import { TRootState } from '#/state/types';
import { gameConfigApiAdapter } from '#/services/api/gameConfigApiSlice/adapter';
import { createAutoplay } from '#/modules/Autoplay/hooks';
import { tableCapacitySlice } from '#/modules/TableCapacity';
import { gameConfigSlice } from '#/services/api/gameConfigApiSlice/state';

export const getTableConfig = async (
   dispatch: TListenerApiDispatchType,
   getState: () => unknown,
) => {
   const { autoplay: autoplayState, auth: authState, gameConfig } = getState() as TRootState;
   const tableConfigData = (await handleGetGameConfig({
      dispatch,
      apiSlice: clientApiSlice as RTKQueryApi,
      currency: authState.user.currency,
      gameTableId: gameConfig.gameConfig.gameTableId,
      schema: rouletteGameConfigSchema,
   })) as TRouletteGameConfig;

   const transformGameConfigResponseByAdapter = gameConfigApiAdapter(tableConfigData);

   const { autoplayRounds, betTypeLimits, chipTray, tssUrl, timer, gameType } =
      transformGameConfigResponseByAdapter;
   const { autoplay } = createAutoplay(dispatch, autoplayState);

   autoplay.handleAutoplayRounds(autoplayRounds);

   await timeSync.fetchServerTimeDelta(tssUrl);

   dispatch(gameHistorySlice.actions.setGameType(gameType));
   dispatch(gameHistorySlice.actions.setGameTypesList([gameType]));

   dispatch(tableCapacitySlice.actions.setBetLimits(betTypeLimits));
   dispatch(
      gameConfigSlice.actions.setGameConfig({
         ...transformGameConfigResponseByAdapter,
         gameTableId: gameSettings.gameTableId,
         launchToken: gameSettings.launchToken,
      }),
   );
   dispatch(timerSlice.actions.updateDuration(timer.betting_time));
   dispatch(timerSlice.actions.setTimerType(timer.type));

   const defaultChip = findDefaultChip(chipTray);
   if (defaultChip) {
      dispatch(chipTraySlice.actions.selectChip(defaultChip));
   }
};
