import { FC, ReactNode } from 'react';

import { TableStatusOverlay } from '@/common/modules';

import { useTranslation } from '#/translates';
import { useLobbyRedirect } from '#/modules/LayoutOverlay/hooks';

interface ITableOverlayContainerProps {
   children: ReactNode;
}

export const TableOverlayContainer: FC<ITableOverlayContainerProps> = ({ children }) => {
   const { t } = useTranslation();
   const handleLobbyRedirect = useLobbyRedirect();

   return (
      <TableStatusOverlay handleLobbyRedirect={handleLobbyRedirect} translation={t}>
         {children}
      </TableStatusOverlay>
   );
};
