import { ReactEventHandler, useEffect } from 'react';

import { TNullable } from '@/common/types';

import { dolbyController } from '../DolbyController';

interface IUseConnectLiveStreamToVideoProps {
   audioElement: TNullable<HTMLAudioElement>;
   onConnectionError: ReactEventHandler<HTMLVideoElement>;
   streamId: string;
   videoElement: TNullable<HTMLVideoElement>;
}

export const useConnectLiveStreamToVideo = ({
   streamId,
   videoElement,
   audioElement,
   onConnectionError,
}: IUseConnectLiveStreamToVideoProps): void => {
   useEffect(() => {
      if (!videoElement || !streamId || !audioElement) return;

      dolbyController.initializeConnection({
         streamId,
         videoElement,
         audioElement,
         onConnectionError,
         streamAccountId: import.meta.env.VITE_ACCOUNT_ID,
      });

      dolbyController.subscribeToLiveStreamConnectionStateChange(onConnectionError);

      dolbyController.connectToVideo();
      dolbyController.connectToAudio();

      return () => {
         dolbyController.destroyConnection();
      };
   }, [videoElement, streamId]);
};
