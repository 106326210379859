// @ts-nocheck
import styled, { keyframes } from 'styled-components';

export const SeatSVG = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
`;

export const SVGAnimatedPlace = styled.g`
  opacity: 0;

  /* Desktop */
  @media (hover: hover) {
    &:hover {
      opacity: 1;
    }
  }

  /* Mobile */
  @media (hover: none) {
    &:active {
      opacity: 1;
    }
  }
`;

export const SeatSVGElementsContainer = styled.g`
  pointer-events: ${({ isDisabled }) => (isDisabled ? 'none' : 'all')};
  cursor: ${({ isDisabled }) => (isDisabled ? 'normal' : 'pointer')};
`;

export const opacityAnimation = keyframes`
    from {
      opacity: 0.2;
    }

    to {
      opacity: 1;
    }
`;
