import { TUserId } from '@/common/entities';
import { isMobileDevice } from '@/common/utils/checkDevice';

import { TSeat, TUnstableServerType } from '../../types';
import { ESeatStatus } from '../../constants';
import { seatBetsAdapter } from '../seatBetsAdapter';
import { ISeatBetsAdapter, TMapChipValueById } from '../seatBetsAdapter/types';

import { dealerAdapter, IDealerAdapter } from './adapters/dealerAdapter';
import { ISeatsAdapter, seatsAdapter } from './adapters/seatsAdapter';
import { TGameState, TGameStatus } from './types';
import {
   DEALER_SEAT_INDEX,
   EBlackJackGameStep,
   ELastPauseAction,
   EServerGameStep,
   gameStepsRoundEnded,
} from './constants';
import { gameStepAdapter, IGameStepAdapter } from './adapters/gameStepAdapter';
import { dealNowAdapter, IDealNowAdapter } from './adapters/dealNowAdapter';
import { serverByClientPauseActionMap } from './adapters/lastPauseActionAdapter';

// TODO: we can use types from bjGameStateEventSchema
class GameStateAdapter {
   private gameStepAdapter: IGameStepAdapter;
   private seatBetsAdapter: ISeatBetsAdapter;
   private seatsAdapter: ISeatsAdapter;
   private dealerAdapter: IDealerAdapter;
   private dealNowAdapter: IDealNowAdapter;

   constructor({
      gameStepAdapter,
      seatBetsAdapter,
      seatsAdapter,
      dealerAdapter,
      dealNowAdapter,
   }: {
      gameStepAdapter: IGameStepAdapter;
      seatBetsAdapter: ISeatBetsAdapter;
      seatsAdapter: ISeatsAdapter;
      dealerAdapter: IDealerAdapter;
      dealNowAdapter: IDealNowAdapter;
   }) {
      this.dealNowAdapter = dealNowAdapter;
      this.gameStepAdapter = gameStepAdapter;
      this.seatBetsAdapter = seatBetsAdapter;
      this.seatsAdapter = seatsAdapter;
      this.dealerAdapter = dealerAdapter;
   }

   private canMakeDecision = ({
      seats,
      serverGameStep,
   }: {
      seats: TSeat[];
      serverGameStep: EServerGameStep;
   }): boolean => {
      const isPlayerDecisionTime = this.gameStepAdapter.isPlayerDecisionTime(serverGameStep);
      const isCurrentPlayerSeat = (seat: TSeat) => seat.isCurrentPlayerSeat;
      const isSeatStatusIsDecision = (seat: TSeat) => seat.seatStatus === ESeatStatus.Decision;

      return isPlayerDecisionTime && seats.filter(isCurrentPlayerSeat).some(isSeatStatusIsDecision);
   };

   private canMakeInsurance = ({
      seats,
      serverGameStep,
   }: {
      seats: TSeat[];
      serverGameStep: EServerGameStep;
   }): boolean => {
      return seats.some((seat) => {
         const isInsuranceTime = this.gameStepAdapter.isInsuranceTime(serverGameStep);
         const { isCurrentPlayerSeat, insurance, seatBetAmountByBetType } = seat;
         const hasAnteBet = seatBetAmountByBetType.ante > 0;
         const isInsuranceNotChosen = insurance.isInsuranceNotChosen;

         return isCurrentPlayerSeat && hasAnteBet && isInsuranceTime && isInsuranceNotChosen;
      });
   };

   private isGameStepForPlacingBets = (serverGameStep: EServerGameStep) => {
      return [
         EServerGameStep.AWAITING_BETS,
         EServerGameStep.BETTING_TIME,
         EServerGameStep.LAST_BETS,
      ].includes(serverGameStep);
   };

   private isBetBehindPlacingTime = (serverGameStep: EServerGameStep) => {
      return [EServerGameStep.BETTING_TIME, EServerGameStep.LAST_BETS].includes(serverGameStep);
   };

   private canPlaceBets = ({
      isPlayerHasTakenSeat,
      serverGameStep,
   }: {
      isPlayerHasTakenSeat: boolean;
      serverGameStep: EServerGameStep;
   }) => {
      const isGameStepForPlacingBets = this.isGameStepForPlacingBets(serverGameStep);
      if (!isGameStepForPlacingBets) {
         return false;
      }

      return isPlayerHasTakenSeat;
   };

   private canPlaceBetBehindBets = ({
      seats,
      serverGameStep,
   }: {
      seats: TSeat[];
      serverGameStep: EServerGameStep;
   }) => {
      const isGameStepForPlacingBets = this.isBetBehindPlacingTime(serverGameStep);
      if (!isGameStepForPlacingBets) {
         return false;
      }
      // Players and watchers can place bet behind on other players seats
      return seats.some(
         ({ isSeatTaken, isCurrentPlayerSeat }) => isSeatTaken && !isCurrentPlayerSeat,
      );
   };

   // this method used to determine the number of active players at the table
   private calculatePlayersCount = (seats: TSeat[]) =>
      seats
         .filter(({ isSeatTaken }) => isSeatTaken)
         .reduce(
            (acc, seat) => {
               if (seat.playerId && !acc.uniquePlayers[seat.playerId]) {
                  acc.uniquePlayers[seat.playerId] = true;
                  acc.uniquePlayerCount++;
               }
               return acc;
            },
            { uniquePlayers: {}, uniquePlayerCount: 0 },
         ).uniquePlayerCount ?? 0;

   public adaptedServerStateToClientState = ({
      gameState: {
         dealNowUsers,
         dealer: serverDealerSeat,
         status: serverGameStep,
         seats: serverSeats,
         current_seat: currentSeat,
         current_hand: currentHand,
         betting_start_time: bettingStartTime,
         insurance_start_time: insuranceStartTime,
         decision_start_time: decisionStartTime,
         roundId,
         roundNumber,
         shoe,
         prePauseStatus,
         lastPauseAction,
      },
      playerId = '',
      mapChipValueById = {},
      maxSeatsPerUserValue = 0,
      dealNowLimitValue = 0,
   }: {
      gameState: TUnstableServerType;
      playerId?: TUserId;
      mapChipValueById?: TMapChipValueById;
      maxSeatsPerUserValue?: number;
      dealNowLimitValue?: number;
   }): TGameState => {
      const isDealerPlaying = currentSeat === DEALER_SEAT_INDEX;
      const dealer = this.dealerAdapter.adapt({ serverDealerSeat, currentSeat, serverGameStep });
      const seats = this.seatsAdapter.adapt({
         playerId,
         serverSeats,
         currentHand,
         currentSeat,
         serverGameStep,
         mapChipValueById,
         dealer,
         isMobileDevice: isMobileDevice(),
      });

      const isPlayerHasTakenSeat = seats.some((seat) => seat.playerId === playerId);
      const canMakeInsurance = this.canMakeInsurance({ seats, serverGameStep });
      const canMakeDecision = this.canMakeDecision({ seats, serverGameStep });
      const canPlaceBets = this.canPlaceBets({ isPlayerHasTakenSeat, serverGameStep });
      const canPlaceBetBehindBets = this.canPlaceBetBehindBets({ seats, serverGameStep });

      const playerUnconfirmedBetsTotalAmount =
         this.seatBetsAdapter.calculateCurrentPlayerUnconfirmedBetsTotalAmount({
            serverSeats,
            mapChipValueById,
            playerId,
         });

      const playerBetsAmount = this.seatBetsAdapter.calculateCurrentPlayerBetsTotalAmount({
         serverSeats,
         mapChipValueById,
         playerId,
      });

      const playerBetAmountByBetType = this.seatBetsAdapter.getCurrentPlayerBetAmountByBetType({
         serverSeats,
         mapChipValueById,
         playerId,
      });

      const gameStep = this.gameStepAdapter.adaptServerGameStepToClient({
         serverGameStep,
      });

      const prePauseStep = this.gameStepAdapter.serverByClientGameStepMap(prePauseStatus);
      const lastGamePauseAction = serverByClientPauseActionMap(lastPauseAction);
      const takenCurrentPlayerSeatsCount = seats.filter(
         ({ isCurrentPlayerSeat, isSeatTaken }) => isCurrentPlayerSeat && isSeatTaken,
      ).length;
      const uniqueTakenSeatsAmount = this.seatsAdapter.getUniqueTakenSeatsAmount(seats);
      const isPlayerTakenMaxAvailableSeats = takenCurrentPlayerSeatsCount >= maxSeatsPerUserValue;

      const dealNow = this.dealNowAdapter.adapt({
         uniqueTakenSeatsAmount,
         playerBetAmountByBetType,
         playerId,
         dealNowUsers,
         dealNowLimitValue,
      });

      const gameStatus = (gameStep: EBlackJackGameStep): TGameStatus => ({
         isInitialDealingCards: gameStep === EBlackJackGameStep.DealCards,
         isDrawHiddenCard:
            gameStep === EBlackJackGameStep.DealCards &&
            isDealerPlaying &&
            dealer.hand.cards.length === 1,
         isRevealHiddenCard:
            gameStep === EBlackJackGameStep.AwaitCard &&
            isDealerPlaying &&
            dealer.hand.cards[1]?.isFaceDown,
         isDealerExtraCardNeeded:
            gameStep === EBlackJackGameStep.AwaitCard &&
            isDealerPlaying &&
            !dealer.hand.cards[1]?.isFaceDown,
         isAwaitingBets: gameStep === EBlackJackGameStep.AwaitingBets,
         isInsuranceTime: gameStep === EBlackJackGameStep.Insurance,
         isAwaitingCard: gameStep === EBlackJackGameStep.AwaitCard,
         isGamePaused: gameStep === EBlackJackGameStep.GamePaused,
         isLastBets: gameStep === EBlackJackGameStep.LastBets,
         isBettingTime: [EBlackJackGameStep.BettingTime, EBlackJackGameStep.LastBets].includes(
            gameStep,
         ),
         isWaitingForPlayers:
            gameStep === EBlackJackGameStep.AwaitingBets &&
            this.seatsAdapter.allSeatsAreTaken(seats),
         isPlayerDecision: gameStep === EBlackJackGameStep.PlayerDecision,
         isRoundFinished: gameStep === EBlackJackGameStep.RoundFinished,
         isRoundCanceled: gameStep === EBlackJackGameStep.RoundCanceled,
         isChangeShoe: shoe.code === null, // [PMX-3902] shoe.code field is an indicator. If it’s null, show the text, if not don't
         isShowCuttingCard: shoe.isChangeRequired,
         isDealingStep:
            gameStep === EBlackJackGameStep.DealCards || gameStep === EBlackJackGameStep.AwaitCard,
         isGamePrePausedInDealingCards:
            gameStep === EBlackJackGameStep.GamePaused &&
            prePauseStep === EBlackJackGameStep.DealCards &&
            seats.some(({ playerHands }) => playerHands.some((hand) => hand.hasCards)),
         isRoundEnded: gameStepsRoundEnded.includes(gameStep),
      });

      return {
         shouldWaitNextRound: this.gameStepAdapter.shouldWaitNextRound({
            isPlayerHasTakenSeat,
            serverGameStep,
            bettingStartTime,
            seats,
         }),
         dealNow,
         playerUnconfirmedBetsTotalAmount,
         isPlayerTakenMaxAvailableSeats,
         canMakeDecision,
         canMakeInsurance,
         canPlaceBets,
         canPlaceBetBehindBets,
         playerBets: {
            playerBetsAmount,
            playerBetAmountByBetType,
         },
         // TODO: remove this property (we use gameStatus instead it)
         gameStep,
         gameStatus: gameStatus(gameStep),
         prePauseGameStep: gameStatus(prePauseStep),
         prePauseState: prePauseStep,
         isPlayerHasTakenSeat,
         seats,
         dealer,
         currentSeat,
         currentHand,
         bettingStartTime,
         insuranceStartTime,
         decisionStartTime,
         isDealerPlaying,
         roundId,
         roundNumber,
         playersCount: this.calculatePlayersCount(seats),
         gamePaused: lastGamePauseAction === ELastPauseAction.Pause,
         gameResumed: lastGamePauseAction === ELastPauseAction.Resume,
         handCancelled: lastGamePauseAction === ELastPauseAction.CancelHand,
         seatCancelled: lastGamePauseAction === ELastPauseAction.CancelSeat,
      };
   };
}

export const gameStateAdapter = new GameStateAdapter({
   gameStepAdapter,
   seatBetsAdapter,
   seatsAdapter,
   dealerAdapter,
   dealNowAdapter,
});
