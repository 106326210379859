import { useRoundState } from 'services/api/roundStateApiSlice';

import { EGameState, useGameStateActions } from '@/common/state/gameTypes/roulette/gameState';
import { getEndTime } from '@/common/utils';
import { useTimerState } from '@/common/modules/CountDown';

import { betsCreator } from '#/core/betsCreator';
import { createAutoplay } from '#/modules/Autoplay/hooks';
import { store } from '#/state';
import { Store } from '#/state/types';
import { playChipSound } from '#/core/sounds';

import { StartRoundEvent } from './schemes';

type UseRoundStartOutput = (socket: StartRoundEvent) => void;

export const useRoundStartEvent = (): UseRoundStartOutput => {
   const { handleSetEndTime } = useTimerState();
   const { handleChangeGameState } = useGameStateActions();
   const { handleSetRoundInfo } = useRoundState();

   const handlePlaceBetsByAutoplay = (store: Store) => {
      const { dispatch, getState } = store;
      const { autoplay } = createAutoplay(dispatch, getState().autoplay);

      if (autoplay.isAutoplayStarted()) {
         const autoplayHasRounds = autoplay.getRound() > 0;
         if (autoplayHasRounds) {
            const isBalancePositive = betsCreator.rebetAutoplayBets();
            if (isBalancePositive) {
               playChipSound();
            } else {
               autoplay.cancelAutoplayFromUI();
            }
         } else {
            autoplay.cancelAutoplayFromUI();
         }
      }
   };

   const handleSetTimerEndTime = ({
      store,
      startBettingTime,
   }: {
      store: Store;
      startBettingTime: string;
   }) => {
      const { getState } = store;
      const { duration } = getState().timer;

      if (!duration) {
         return;
      }

      const endTime: number = getEndTime({
         startBettingTime,
         bettingTime: duration,
      });

      handleSetEndTime(endTime);
   };

   return ({ uuid, round_number, start_time }) => {
      handleSetRoundInfo({ id: uuid, number: round_number });
      handleChangeGameState(EGameState.MAKE_BET);
      handleSetTimerEndTime({
         store,
         startBettingTime: start_time,
      });
      handlePlaceBetsByAutoplay(store);
   };
};
