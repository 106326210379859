import { FC } from 'react';
import { useMessageContext } from 'stream-chat-react';

import { ReplyIcon, RepliesCountButton } from './components';
import { IncognitoIcon } from './assets';
import * as S from './ChatMessage.styles';

interface IChatMessageProps {
   isIncognitoMessage: boolean;
   messageColor: string;
   usernameColor: string;
}
export const ChatMessage: FC<IChatMessageProps> = ({
   usernameColor,
   messageColor,
   isIncognitoMessage,
}) => {
   const { message } = useMessageContext();

   return (
      <>
         <S.MessageContainer className="chat-message">
            <S.UsernameWrapper>
               {isIncognitoMessage ? (
                  <IncognitoIcon />
               ) : (
                  <S.Username color={usernameColor}>{message?.user?.name}</S.Username>
               )}
               <ReplyIcon />
            </S.UsernameWrapper>

            <S.Message className="chat-message" color={messageColor}>
               {message?.text}
            </S.Message>
         </S.MessageContainer>

         <RepliesCountButton />
      </>
   );
};
