import { ETableStatus } from '@/common/constants';

export const isTableStatus = (status: unknown): status is ETableStatus => {
   return [
      ETableStatus.Error,
      ETableStatus.MaintenanceIsPending,
      ETableStatus.MaintenanceInProgress,
      ETableStatus.MaintenanceIsEnded,
      ETableStatus.WaitForConnection,
      ETableStatus.InactiveTable,
      ETableStatus.SessionClosed,
      ETableStatus.LostConnection,
   ].includes(status as ETableStatus);
};
