import { MouseEvent, FC } from 'react';

import { GeneralControlProps } from '../../types';

import * as S from './BetControl.styles';
import { EBetControl } from './constants';
import { icons } from './icons';

type BetControlProps = GeneralControlProps & {
  title?: string;
  variant: EBetControl;
  withPulseAnimation?: boolean;
  playClickSound: () => void;
};

export const BetControl: FC<BetControlProps> = ({
  title = '',
  color,
  withPulseAnimation = false,
  onClick,
  playClickSound,
  size = '64px',
  variant,
  dataTestId,
  ...otherProps
}) => {
  const handleOnClick = (event: MouseEvent<HTMLButtonElement, MouseEvent>) => {
    playClickSound();
    onClick(event);
  };

  return (
    <S.BetControlWrapper
      size={size}
      title={title}
      onClick={handleOnClick}
      color={color}
      withPulseAnimation={withPulseAnimation}
      data-testid={dataTestId}
      {...otherProps}
    >
      <S.SvgWrapper>
        <S.Svg viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
          <S.Circle cx="32" cy="32" r="30" />
          {icons[variant]}
        </S.Svg>
      </S.SvgWrapper>
      {title && <S.Title>{title}</S.Title>}
    </S.BetControlWrapper>
  );
};
