import { ETypeBet } from '@/common/roulette/constants';

import { useUserCurrencySign } from '#/services/api/authApiSlice/authSlice';

import { useTableCapacity } from '../state';

export const useStraightBetLimits = () => {
   const { betLimitsByType } = useTableCapacity();
   const { min = null, max = null } = betLimitsByType?.[ETypeBet.STRAIGHT] ?? {};
   const currencySign = useUserCurrencySign();

   if (!min && !max) {
      return null;
   }

   return `${currencySign} ${min} - ${max}`;
};
