// TODO: add font-family
const colors = {
  pink: {
    51: '#2D004E',
    50: '#480044',
    41: '#50265C',
    40: '#4B0082',
    31: '#780072',
    30: '#7B3A8E',
    21: '#8755AA',
    20: '#97174B',
    10: '#E82475',
  },
  blackRange: {
    50: '#000',
    40: '#181818',
    30: '#242424',
    20: '#383838',
    10: '#3D3D3D',
    0: '#2D2D2D',
  },
  green: {
    110: '#249830',
    100: '#29D23A',
    71: '#004500',
    70: '#214C1F',
    60: '#6AE276',
    50: '#177233',
    41: '#007300',
    40: '#0E7625',
    32: '#0E8800',
    31: '#2DA500',
    30: '#23B04E',
    22: '#11AA00',
    21: '#00D215',
    20: '#7BE385',
    10: '#DCF1DF',
  },
  blue: {
    100: '#30ACEB',
    92: '#001854',
    91: '#00288C',
    90: '#152F88',
    81: '#0054CE',
    80: '#305E95',
    72: '#0043A5',
    71: '#0047D2',
    70: '#2048D2',
    60: '#5CBEEF',
    50: '#1D8588',
    42: '#1CA0B4',
    41: '#23C8E1',
    40: '#2DCCD2',
    30: '#2FB8FF',
    20: '#A2EAFA',
    10: '#E4F3F6',
  },
  yellow: {
    100: '#FAA200',
    80: '#FFAE1A',
    71: '#844300',
    70: '#7E6407',
    60: '#FFC04D',
    53: '#C88200',
    52: '#DC7000',
    51: '#FF6700',
    50: '#C29A0A',
    30: '#FFBB3D',
    20: '#FDC762',
    10: '#FDC762',
  },
  silverHue: {
    80: '#302D2A',
    71: '#504B46',
    70: '#5B5B5B',
    60: '#8C8C8C',
    50: '#7C7C7C',
    41: '#1A1A1A',
    40: '#A1A1A1',
    30: '#BEBEBE',
    21: '#D2D2D2',
    20: '#DDDDDD',
    10: '#F2F2F2',
  },
  redHue: {
    100: '#E41D1D',
    60: '#EF7676',
    51: '#9E0000',
    50: '#5B2211',
    40: '#8C351A',
    31: '#B00000',
    30: '#94001C',
    21: '#DC0000',
    20: '#E4002B',
    10: '#FF0000',
  },
  white: '#ffffff',
};

export const theme = {
  space: [4, 8, 12, 16, 32, 64, 128],
  fontSizes: [16, 18, 24, 28],
  colors: {
    black: colors.blackRange,
    darkBlack: '#0F0F0F',
    white: '#ffffff',
    pinkHue: colors.pink,
    yellow: '#FFBB3D',
    darkGrey: '#696969',
    smokeGrey: '#d2d2d2',
    grey: '#a2a2a2',
    mediumGrey: '#a1a1a1',
    lightGrey: '#d2d2d2',
    red: '#FF0000',
    main: '#FAA200',
    gray: '#DDDDDD',
    yellowHue: colors.yellow,
    redHue: colors.redHue,
    blueHue: colors.blue,
    greenHue: colors.green,
    silverHue: colors.silverHue,
    lightBlack: 'rgba(0, 0, 0, 0.6)',
  },
  scrollbar: {
    thumbColor: 'rgb(90, 90, 90)',
    trackColor: 'rgb(0, 0, 0)',
  },
  bg: {
    activePrimary:
      'linear-gradient(180deg, rgba(255, 187, 61, 0.55) 0%, rgba(255, 187, 61, 0.7) 100%)',
  },
  zIndex: {
    videoOverlay: 0,
    track: 1,
    gridLayout: 1,
    noPointerEvents: 1,
    // In case of player taking side seats (for example 5, 6 or 0,1)
    // We expect them to not overlap each other
    // So we add z-indexes to display seats that are closer to user view (closer to center)
    // On top of seats that are further (side seats)
    // That helps to avoid overlapping
    // As an examples we have case when perfect pairs bet on seat 5 was partially overlapped by seat 6
    // And side bets payout was overlapped as it displays on the sides of each seat
    seats: [0, 1, 2, 3, 2, 1, 0],
    sideBetPayout: 1,
    // reserved from 10 to 500 for betting map bets
    bettingTimer: 501,
    mobileChipTray: 502,
    mobileChipTraySelected: 503,
    betConfirmedNotification: 504,
    winNumbers: 505,
    winnerList: 506,
    chat: 507,
    limits: 508,
    feedback: 509,
    swipeUp: 510,
    layoutOverlay: 511,
    loadingScreen: 512,
    biggest: 513,
  },
  breakpoints: {
    sm: 768,
    md: 992,
    lg: 1200,
    xl: 1600,
  },
};
