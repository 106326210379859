import { BetConfig } from 'core/config/BetConfig';

import { rangeNumbers } from '@/common/utils';
import { ETypeBet } from '@/common/roulette/constants';

class OddBetConfig extends BetConfig {
   protected readonly type: ETypeBet = ETypeBet.ODD;
   readonly config = [
      { id: 44, value: 'bettingMap.odd', numbers: rangeNumbers({ start: 1, end: 35, step: 2 }) },
   ];
}

export const oddConfig = new OddBetConfig();
