import styled, { css } from 'styled-components/macro';

export const StarsAnimationWrapper = styled.div`
   ${({ isBlinking }) =>
      isBlinking &&
      css`
         animation: pulseStarAnimation 3s ease-out;
      `};

   @keyframes pulseStarAnimation {
      from {
         filter: drop-shadow(0px 1px 2px white);
      }
      to {
         filter: none;
      }
   }
`;
