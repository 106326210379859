// @ts-nocheck
import styled from 'styled-components/macro';

import { applyZIndex, orientation } from '../theme/mixins';

export const TableLimitsViewWrapper = styled.span`
  --table-capacity-fz: 20px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  font-size: var(--table-capacity-fz);
  position: relative;

  ${orientation.mobileLandscape`
     --table-capacity-fz: 12px;

     flex-direction: column;
     align-items: flex-end;
     white-space: nowrap;
   `}

  ${orientation.portrait`
      --table-capacity-fz: 12px;

      flex-direction: column;
      align-items: end;
   `}

   .limit-container {
    ${applyZIndex('limits')};

    overflow: hidden;
    position: absolute;
    right: 0;
    bottom: calc(100% + 35px);
    min-width: 540px;
    padding: 18px 20px;
    border-radius: 3px;
    background-color: rgba(3, 3, 3, 0.8);
    will-change: transform, filter;

    ${orientation.portrait`
        position: fixed;
        right: unset;
        left: 0;
        bottom: 35px;
        width: 100%;
        height: 90vh;
        min-width: unset;
        padding: 40px 15px 15px;
      `};

    ${orientation.mobileLandscape`
        position: fixed;
        bottom: 15px;
        right: 80px;
        height: 100vh;
        max-height: 90%;
        right: 20px;
        left: 20px;
        padding-right: 0;
      `};
  }
`;
