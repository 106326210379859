import { FC, ReactNode } from 'react';

import { TNullable } from '@/common/types';

import { PlayersCount, Time } from './components';
import * as S from './GameInfoView.styles';

interface IGameInfoViewProps {
  dealerName: TNullable<ReactNode>;
  gameId: TNullable<string>;
  getTimeValue: () => string;
  playersCount?: number;
  title: string;
}
export const GameInfoView: FC<IGameInfoViewProps> = ({
  title,
  gameId,
  getTimeValue,
  playersCount = 0,
  dealerName,
}) => (
  <S.GameInfoViewContainer>
    {gameId && (
      <>
        <S.StyledText>{`${title}:`}&nbsp;</S.StyledText>
        <S.StyledText data-testid="game-id">{gameId}</S.StyledText>
      </>
    )}
    <S.VerticalSeparator width="10px" />
    <Time dataTestId="time-value" getTimeValue={getTimeValue} />
    {dealerName && <S.DealerName data-testid="dealer-name">{dealerName}</S.DealerName>}
    {playersCount > 0 && (
      <S.PlayersCountContainer>
        <PlayersCount dataTestId="player-count-value" playersCount={playersCount} />
      </S.PlayersCountContainer>
    )}
  </S.GameInfoViewContainer>
);
