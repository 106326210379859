export const calculateCurrentTick = ({ endTime, delta }): number => {
   const currentTimeInSeconds: number = Math.floor((Date.now() + delta) / 1000);
   const currentTick = endTime - currentTimeInSeconds;

   // we should sure that return only positive tick value
   return Math.max(currentTick, 0);
};

const _handleTick = ({ cb, endTime, interval, duration, delta }): void => {
   const tick: number = calculateCurrentTick({ endTime, delta });

   cb({ tick, isCountDownFinished: false, duration, delta });

   const isCountDownFinished = tick <= 0;

   if (isCountDownFinished) {
      cb({ tick, isCountDownFinished: true, duration, delta });
      clearInterval?.(interval);
   }
};

export const countDownByDate = ({ cb, endTime, delta }) => {
   const TICK_PER_ONE_SECOND = 1000;

   // handle first tick and calculate timer duration
   const duration: number = calculateCurrentTick({ endTime, delta });
   _handleTick({ cb, endTime, interval: null, duration, delta });

   const interval = setInterval(() => {
      _handleTick({ cb, endTime, interval, duration, delta });
   }, TICK_PER_ONE_SECOND);

   return interval;
};
