// @ts-nocheck
import styled from 'styled-components/macro';

export const TopColumnContainer = styled.div`
   position: relative;
   display: flex;
   align-items: center;
   justify-content: space-between;
`;

export const GameInfoContainer = styled.div`
   display: flex;
   align-items: center;
`;
