import { createSlice } from '@reduxjs/toolkit';
import { useSelector, useDispatch } from 'react-redux';

import { TGameState } from '@/common/blackjack/adapters/gameStateAdapter/types';
import { defaultBjGameState } from '@/common/blackjack/constants';
import { getValueFromQueryParams } from '@/common/utils';
import { gameStateAdapter } from '@/common/blackjack/adapters/gameStateAdapter';

// eslint-disable-next-line
import { gameEvents } from '../../../../../../e2e/bj-dealer/gameEvents';

// for e2e flow we need to replace game state with recorded events for screenshot testing
const E2EFlow = getValueFromQueryParams({ key: 'testStep' });

const initialState = {
   gameState: E2EFlow
      ? gameStateAdapter.adaptedServerStateToClientState({
           gameState: gameEvents[E2EFlow],
        })
      : defaultBjGameState,
};

export const blackjackStateSlice = createSlice({
   name: 'blackjackGameState',
   initialState,
   reducers: {
      updateGameState: (state, action): void => {
         // for e2e flow we need to skip updating game state from socket events
         if (!E2EFlow) {
            state.gameState = action.payload;
         }
      },
   },
   selectors: {
      getGameState: (state) => state.gameState,
      getGameStep: (state) => state.gameState.gameStep,
      getGameSeats: (state) => state.gameState.seats,
   },
});

export const useGameStateSelector = () => useSelector(blackjackStateSlice.selectors.getGameState);

export const useGameStateActions = () => {
   const dispatch = useDispatch();
   const { updateGameState } = blackjackStateSlice.actions;

   const handleUpdateGameState = (gameState: TGameState): void => {
      dispatch(updateGameState(gameState));
   };

   return {
      handleUpdateGameState,
   };
};

export const useGameState = () => {
   const gameState = useGameStateSelector();
   const { handleUpdateGameState } = useGameStateActions();

   return {
      gameState,
      handleUpdateGameState,
   };
};
