import { BetConfig } from 'core/config/BetConfig';

import { rangeNumbers } from '@/common/utils';
import { ETypeBet } from '@/common/roulette/constants';

class EvenBetConfig extends BetConfig {
   protected readonly type: ETypeBet = ETypeBet.EVEN;
   readonly config = [
      { id: 41, value: 'bettingMap.even', numbers: rangeNumbers({ start: 2, end: 36, step: 2 }) },
   ];
}

export const evenConfig = new EvenBetConfig();
