import { TSeatBetsConfig } from '@/common/blackjack/types';

import { TGameState } from './adapters/gameStateAdapter/types';
import { TBjColorsConfig } from './types';
import { EBlackJackGameStep } from './adapters/gameStateAdapter/constants';

export const enum EBJBetType {
   Ante = 'ante',
   TopThreeBet = 'top3',
   BetBehind = 'betBehind',
   TwentyOnePlusThreeBet = '21p3',
   PerfectPairsBet = 'perfectPairs',
   Insurance = 'insurance',
   DoubleAll = 'doubleAll',
   Rebet = 'reBet',
   Split = 'split',
}
export const enum EBJGameHistoryBetType {
   Ante = EBJBetType.Ante,
   TopThreeBet = EBJBetType.TopThreeBet,
   BetBehind = EBJBetType.BetBehind,
   TwentyOnePlusThreeBet = EBJBetType.TwentyOnePlusThreeBet,
   PerfectPairsBet = EBJBetType.PerfectPairsBet,
   Insurance = EBJBetType.Insurance,
   DoubleAll = EBJBetType.DoubleAll,
   Rebet = EBJBetType.Rebet,
   Split = EBJBetType.Split,
   BjSplit = 'bj_split',
   Double = 'double',
}

export const enum ESeatStatus {
   Waiting = 'WAITING',
   Decision = 'DECISION',
   Played = 'PLAYED',
}

export const enum EHandResult {
   Bust = 'BUST',
   Lose = 'LOSE',
   Draw = 'DRAW',
   Win = 'WIN',
   Bj = 'BJ',
}

export const enum EDoubleBetTypes {
   DoubleZero = 'double0',
   DoubleOne = 'double1',
}

export const enum EUndoBet {
   Bet = 'Bet',
   AllBets = 'AllBets',
}

export const enum GameBetTypeStatus {
   Accepted = 'ACCEPTED',
   Cancel = 'CANCELED',
}

export const enum EBetResult {
   WIN = 'WIN',
   DRAW = 'DRAW',
   LOSE = 'LOSE',
   BJ = 'BJ',
   BUST = 'BUST',
}

export const enum ESeatBetsConfig {
   bj21Plus3 = 'bj21Plus3',
   bjBetBehind = 'bjBetBehind',
   bjPerfectPairs = 'bjPerfectPairs',
   bjTop3 = 'bjTop3',
   bjMaxSeatsPerUser = 'bjMaxSeatsPerUser',
   bjDealNowLimit = 'bjDealNowLimit',
}

export const defaultBjSeatConfig: TSeatBetsConfig = {
   [ESeatBetsConfig.bjTop3]: true,
   [ESeatBetsConfig.bj21Plus3]: true,
   [ESeatBetsConfig.bjPerfectPairs]: true,
   [ESeatBetsConfig.bjBetBehind]: true,
   [ESeatBetsConfig.bjMaxSeatsPerUser]: 3,
   [ESeatBetsConfig.bjDealNowLimit]: null,
};

export const defaultBjGameState: TGameState = {
   shouldWaitNextRound: false,
   dealNow: {
      canMakeDealNow: false,
      isWaitingOtherPlayers: false,
      dealNowUsers: [],
   },
   playerUnconfirmedBetsTotalAmount: 0,
   isPlayerTakenMaxAvailableSeats: false,
   canPlaceBets: false,
   canPlaceBetBehindBets: false,
   canMakeDecision: false,
   canMakeInsurance: false,
   playerBets: {
      playerBetsAmount: 0,
      playerBetAmountByBetType: {
         ante: 0,
         perfectPairs: 0,
         top3: 0,
         '21p3': 0,
         betBehind: 0,
      },
   },
   bettingStartTime: null,
   decisionStartTime: null,
   insuranceStartTime: null,
   isPlayerHasTakenSeat: false,
   gameStep: EBlackJackGameStep.WaitingNextRound,
   seats: [],
   isDealerPlaying: false,
   gameStatus: {
      isAwaitingCard: false,
      isRoundFinished: false,
      isGamePaused: false,
      isRoundCanceled: false,
      isDealingStep: false,
      isInsuranceTime: false,
      isLastBets: false,
      isBettingTime: false,
      isPlayerDecision: false,
      isShowCuttingCard: false,
      isWaitingForPlayers: false,
      isRoundEnded: false,
      isGamePrePausedInDealingCards: false,
   },
   roundId: null,
   dealer: {
      isDealerNeedsMoreCardsOrReveal: false,
      isDealingSeat: false,
      hand: {
         isPlaying: false,
         cards: [],
         score: '0',
         isBusted: false,
         hasBj: false,
      },
   },
   currentSeat: 0,
   currentHand: 0,
   playersCount: 0,
   gamePaused: false,
   gameResumed: false,
   handCancelled: false,
   seatCancelled: false,
   prePauseState: null,
   prePauseGameStep: null,
};

export const defaultBjColorsConfig: TBjColorsConfig = {
   topBackgroundColor: '#000000',
   bottomBackgroundColor: '#000000',
   freeSeatColor: '#ffffff',
   seatBetSectionColor: '#ffffff',
   seatHighlightColor: '#FAA200',
   otherSeatHighlightColor: '#ffffff',
   seatDisabledBetSectionColor: '#ffffff',
   seatHoverBetSectionColor: '#ffffff',
};
