import { BetConfig } from 'core/config/BetConfig';

import { ETypeBet } from '@/common/roulette/constants';

import { IConfigWithTypeBet } from '../BetConfig';

class StraightBetsConfig extends BetConfig {
   protected readonly type: ETypeBet = ETypeBet.STRAIGHT;
   protected readonly multiplier = 1;
   protected readonly config = Array.from({ length: 37 }, (_, id) => ({
      id,
      numbers: [id],
      value: String(id),
   }));

   readonly mapBetIdToBet: Record<string, IConfigWithTypeBet> = this.config.reduce(
      (config, bet) => {
         config[bet.id] = { ...bet, type: this.type };
         return config;
      },
      {},
   );
}

export const straightConfig = new StraightBetsConfig();
