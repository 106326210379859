import { BetConfig, IConfigWithTypeBet } from 'core/config/BetConfig';

import { rangeNumbers } from '@/common/utils';
import { ETypeBet } from '@/common/roulette/constants';

class DozenBetsConfig extends BetConfig {
   protected readonly type: ETypeBet = ETypeBet.DOZEN;
   protected readonly config = [
      {
         id: 37,
         value: '1st 12',
         numbers: rangeNumbers({ start: 1, end: 12 }),
      },
      {
         id: 38,
         value: '2nd 12',
         numbers: rangeNumbers({ start: 13, end: 24 }),
      },
      {
         id: 39,
         value: '3rd 12',
         numbers: rangeNumbers({ start: 25, end: 36 }),
      },
   ];

   readonly mapDozenNumberToBet: Record<string, IConfigWithTypeBet> = this.config.reduce(
      (config, bet) => {
         const lastNumber = bet.numbers[bet.numbers.length - 1];
         const numbersCount = bet.numbers.length;
         const columnFirstNumber = lastNumber / numbersCount;
         config[columnFirstNumber] = { ...bet, type: this.type };

         return config;
      },
      {},
   );

   readonly mapDozenIdToNumber: Record<string, number> = this.config.reduce((config, bet) => {
      const { numbers } = bet;
      const lastNumber = numbers[numbers.length - 1];
      const numbersCount = numbers.length;
      const dozen = lastNumber / numbersCount;
      config[bet.id] = dozen;

      return config;
   }, {});
}

export const dozenConfig = new DozenBetsConfig();
