// @ts-nocheck
import styled from 'styled-components/macro';

import { orientation } from '../../../theme/mixins';
import { StyledText } from '../../GameInfoView.styles';

export const Time = styled(StyledText)`
  width: 68px;
  text-align: left;

  ${orientation.mobileLandscape`
       width: 48px;
   `};

  ${orientation.portrait`
      width: 48px;
   `};
`;
