import styled, { css } from 'styled-components/macro';

import { applyZIndex, orientation } from '@/common/ui/theme/mixins';
import { hexToRgba } from '@/common/utils';

export const DealerRateButtonContainer = styled.div`
   --text-size: 14px;

   white-space: nowrap;
   font-size: var(--text-size);
   font-weight: 500;
   color: white;
   position: absolute;
   padding: 15px 10px;
   border-radius: 20px;
   background-color: ${({ theme }) => theme.colors.black['0']};
   color: ${({ theme }) => theme.colors.yellow};
   top: 28px;
   left: calc(50% - 2px);
   transform: translateX(-50%);
   ${applyZIndex('feedback')};

   &:hover {
      cursor: pointer;
   }
   &::after {
      content: '';
      position: absolute;
      top: -19px;
      left: calc(50% - 10px);
      border-top: 10px solid transparent;
      border-bottom: ${({ theme }) => `10px solid ${theme.colors.black['0']}`};
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
   }

   ${orientation.portrait`
      --text-size: 10px;

      background-color: ${({ theme }) => hexToRgba(theme.colors.black['0'], 0.8)};
      padding: 10px;
      top: 22px;

      &::after {
         border-bottom: ${({ theme }) => `10px solid ${hexToRgba(theme.colors.black['0'], 0.8)}`};
      }
   `}

   ${orientation.mobileLandscape`
      --text-size: 10px;

      background-color: ${({ theme }) => hexToRgba(theme.colors.black['0'], 0.8)};
      padding: 10px;
      top: 22px;

      &::after {
         border-bottom: ${({ theme }) => `10px solid ${hexToRgba(theme.colors.black['0'], 0.8)}`};
      }
   `}


   ${({ isBlinking }) =>
      isBlinking &&
      css`
         animation: pulseTooltipAnimation 3s normal forwards;
      `};

   @keyframes pulseTooltipAnimation {
      from {
         box-shadow: ${({ theme }) => ` 0px 0px 14px 0px ${theme.colors.yellow}`};
      }
      to {
         box-shadow: none;
      }
   }
`;
