import { contractValidator } from '@/common/services/api';
import { userBetsByRoundIdSchema } from '@/common/services/api/betsAPI/schemes';
import { errors } from '@/common/roulette/errors';

import { store } from '#/state';
import { betsReverseAdapter } from '#/services/api/betsApiSlice/helpers/transformBetByAdapter/reverseAdapters';
import { getTotalBetsAmount } from '#/core/bets';
import { useBalanceActions } from '#/modules/Balance/state';
import { useBetsActions } from '#/state/features/bets/bets';

export const useBetStateEvent = () => {
   const { handleUpdateBalance } = useBalanceActions();
   const { handleAddBetOutside } = useBetsActions();

   return (data) => {
      const { getState } = store;
      const { auth } = getState();

      const { isValid } = contractValidator({
         schema: userBetsByRoundIdSchema,
         data,
         path: 'betsState API',
      });

      if (!isValid) {
         throw new Error(errors.userBets.requestValidation);
      }

      const userBets = betsReverseAdapter(data);

      if (userBets.betsHistory.length) {
         const totalBetsAmount = getTotalBetsAmount(userBets.totalBets);

         handleUpdateBalance(auth.user.totalBalance + totalBetsAmount);
         handleAddBetOutside(userBets);
      }
   };
};
