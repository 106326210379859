import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { useDispatch, useSelector } from 'react-redux';

import { TNullable } from '@/common/types';

interface IDealerRateState {
   dealerId: TNullable<string>;
   dealerName: TNullable<string>;
   dealerRate: number;
   feedbackComment: TNullable<string>;
   feedbackId: TNullable<string>;
   feedbackStars: number;
   isFeedbackEnabledOnCasinoGameTypeLevel: boolean;
   isFeedbackModalShown: boolean;
   isLeavingFeedbackAvailable: boolean;
   isLeavingFeedbackButtonShown: boolean;
   isModalShown: boolean;
}

const initialState: IDealerRateState = {
   dealerId: null,
   dealerName: null,
   dealerRate: 0,
   feedbackId: null,
   feedbackComment: '',
   feedbackStars: 0,
   isLeavingFeedbackAvailable: false,
   isLeavingFeedbackButtonShown: false,
   isFeedbackModalShown: false,
   isFeedbackEnabledOnCasinoGameTypeLevel: false,
   isModalShown: false,
};

export const dealerRateSlice = createSlice({
   name: 'dealerRate',
   initialState,
   reducers: {
      setIsFeedbackEnabledOnCasinoGameTypeLevel: (
         state,
         action: PayloadAction<IDealerRateState['isFeedbackEnabledOnCasinoGameTypeLevel']>,
      ) => {
         state.isFeedbackEnabledOnCasinoGameTypeLevel = action.payload;
      },
      setDealerId: (state, action: PayloadAction<IDealerRateState['dealerId']>) => {
         state.dealerId = action.payload;
      },
      setDealerRate: (state, action: PayloadAction<IDealerRateState['dealerRate']>) => {
         state.dealerRate = action.payload;
      },

      setFeedbackId: (state, action: PayloadAction<IDealerRateState['feedbackId']>) => {
         state.feedbackId = action.payload;
      },

      setFeedbackComment: (state, action: PayloadAction<IDealerRateState['feedbackComment']>) => {
         state.feedbackComment = action.payload;
      },

      setFeedbackStars: (state, action: PayloadAction<IDealerRateState['feedbackStars']>) => {
         state.feedbackStars = action.payload;
      },

      setIsLeavingFeedbackAvailable: (
         state,
         action: PayloadAction<IDealerRateState['isLeavingFeedbackAvailable']>,
      ) => {
         state.isLeavingFeedbackAvailable = action.payload;
      },

      setIsFeedbackModalShown: (
         state,
         action: PayloadAction<IDealerRateState['isFeedbackModalShown']>,
      ) => {
         state.isFeedbackModalShown = action.payload;
      },

      setIsLeavingFeedbackButtonShown: (
         state,
         action: PayloadAction<IDealerRateState['isLeavingFeedbackButtonShown']>,
      ) => {
         state.isLeavingFeedbackButtonShown = action.payload;
      },
      setIsModalShown: (state, action: PayloadAction<IDealerRateState['isModalShown']>) => {
         state.isModalShown = action.payload;
      },
      setDealerName: (state, action: PayloadAction<IDealerRateState['dealerName']>) => {
         state.dealerName = action.payload;
      },
   },
   selectors: {
      getDealerRate: (state) => state.dealerRate,
      getDealerName: (state) => state.dealerName,
      getIsLeavingFeedbackAvailable: (state) => state.isLeavingFeedbackAvailable,
      getFeedbackComment: (state) => state.feedbackComment,
      getFeedbackStars: (state) => state.feedbackStars,
      getIsFeedbackModalShown: (state) => state.isFeedbackModalShown,
      getIsLeavingFeedbackButtonShown: (state) => state.isLeavingFeedbackButtonShown,
      getFeedbackId: (state) => state.feedbackId,
      getIsFeedbackEnabledOnCasinoGameTypeLevel: (state) =>
         state.isFeedbackEnabledOnCasinoGameTypeLevel,
      getIsModalShown: (state) => state.isModalShown,
   },
});

export const useDealerRateStateActions = () => {
   const dispatch = useDispatch();
   const {
      setDealerRate,
      setDealerName,
      setFeedbackComment,
      setFeedbackStars,
      setIsLeavingFeedbackAvailable,
      setIsFeedbackModalShown,
      setIsLeavingFeedbackButtonShown,
      setIsModalShown,
   } = dealerRateSlice.actions;

   const handleSetDealerRate = (dealerState: IDealerRateState['dealerRate']): void => {
      dispatch(setDealerRate(dealerState));
   };
   const handleSetDealerName = (dealerName: IDealerRateState['dealerName']): void => {
      dispatch(setDealerName(dealerName));
   };
   const handleSetIsModalShown = (isModalShown: IDealerRateState['isModalShown']): void => {
      dispatch(setIsModalShown(isModalShown));
   };

   const handleSetFeedbackComment = (
      feedbackComment: IDealerRateState['feedbackComment'],
   ): void => {
      dispatch(setFeedbackComment(feedbackComment));
   };

   const handleSetFeedbackStars = (stars: IDealerRateState['feedbackStars']): void => {
      dispatch(setFeedbackStars(stars));
   };

   const handleSetLeavingFeedbackAvailable = (
      isLeavingFeedbackAvailable: IDealerRateState['isLeavingFeedbackAvailable'],
   ): void => {
      dispatch(setIsLeavingFeedbackAvailable(isLeavingFeedbackAvailable));
   };

   const handleSetLeavingFeedbackButtonShown = (
      isLeavingFeedbackButtonShown: IDealerRateState['isLeavingFeedbackButtonShown'],
   ): void => {
      dispatch(setIsLeavingFeedbackButtonShown(isLeavingFeedbackButtonShown));
   };

   const handleShowFeedbackModal = (
      isFeedbackModalShown: IDealerRateState['isFeedbackModalShown'],
   ): void => {
      dispatch(setIsFeedbackModalShown(isFeedbackModalShown));
   };

   return {
      handleSetDealerRate,
      handleSetDealerName,
      handleSetFeedbackComment,
      handleSetFeedbackStars,
      handleSetLeavingFeedbackAvailable,
      handleShowFeedbackModal,
      handleSetLeavingFeedbackButtonShown,
      handleSetIsModalShown,
   };
};

export const useDealerRateSelector = () => useSelector(dealerRateSlice.selectors.getDealerRate);
export const useDealerNameSelector = () => useSelector(dealerRateSlice.selectors.getDealerName);
export const useFeedbackCommentSelector = () =>
   useSelector(dealerRateSlice.selectors.getFeedbackComment);
export const useFeedbackStarsSelector = () =>
   useSelector(dealerRateSlice.selectors.getFeedbackStars);
export const useIsLeavingFeedbackAvailableSelector = () =>
   useSelector(dealerRateSlice.selectors.getIsLeavingFeedbackAvailable);
export const useIsFeedbackModalShownSelector = () =>
   useSelector(dealerRateSlice.selectors.getIsFeedbackModalShown);
export const useIsLeavingFeedbackButtonShownSelector = () =>
   useSelector(dealerRateSlice.selectors.getIsLeavingFeedbackButtonShown);
export const useFeedbackIdSelector = () => useSelector(dealerRateSlice.selectors.getFeedbackId);
export const useIsFeedbackEnabledOnCasinoGameTypeLevel = () =>
   useSelector(dealerRateSlice.selectors.getIsFeedbackEnabledOnCasinoGameTypeLevel);
export const useIsModalShown = () => useSelector(dealerRateSlice.selectors.getIsModalShown);

export const useDealerRateState = () => {
   const dealerRate = useDealerRateSelector();
   const dealerName = useDealerNameSelector();
   const feedbackComment = useFeedbackCommentSelector();
   const feedbackStars = useFeedbackStarsSelector();
   const isLeavingFeedbackAvailable = useIsLeavingFeedbackAvailableSelector();
   const isFeedbackModalShown = useIsFeedbackModalShownSelector();
   const isLeavingFeedbackButtonShown = useIsLeavingFeedbackButtonShownSelector();
   const feedbackId = useFeedbackIdSelector();
   const isFeedbackEnabledOnCasinoGameTypeLevel = useIsFeedbackEnabledOnCasinoGameTypeLevel();
   const isModalShown = useIsModalShown();

   const {
      handleSetDealerRate,
      handleSetDealerName,
      handleSetFeedbackComment,
      handleSetFeedbackStars,
      handleSetLeavingFeedbackAvailable,
      handleShowFeedbackModal,
      handleSetLeavingFeedbackButtonShown,
      handleSetIsModalShown,
   } = useDealerRateStateActions();

   return {
      dealerRate,
      dealerName,
      feedbackComment,
      feedbackStars,
      feedbackId,
      isLeavingFeedbackAvailable,
      isFeedbackModalShown,
      isLeavingFeedbackButtonShown,
      isFeedbackEnabledOnCasinoGameTypeLevel,
      isModalShown,
      handleSetDealerRate,
      handleSetDealerName,
      handleSetFeedbackComment,
      handleSetFeedbackStars,
      handleSetLeavingFeedbackAvailable,
      handleShowFeedbackModal,
      handleSetLeavingFeedbackButtonShown,
      handleSetIsModalShown,
   };
};
