import { FC } from 'react';

import {
   HorizontalCountDown,
   HorizontalCountDownPosition,
} from '@/common/modules/CountDown/components/HorizontalCountDown';
import {
   useTimerDurationSelector,
   useTimerEndTimeSelector,
} from '@/common/modules/CountDown/state';
import { isPortraitMode, useDeviceOrientation } from '@/common/hooks';
import { useGameState } from '@/common/state/gameTypes/roulette/gameState';

import { useTranslation } from '#/translates';

import { getCountDownMessage } from './utils/getCountDownMessage';

export interface IHorizontalTimerContainerProps {
   tick: number;
}

export const HorizontalCountDownContainer: FC<IHorizontalTimerContainerProps> = ({ tick }) => {
   const { t } = useTranslation();
   const endTime = useTimerEndTimeSelector();
   const duration = useTimerDurationSelector();
   const { isLastBetsState } = useGameState();

   return (
      <HorizontalCountDownPosition isVertical={isPortraitMode(useDeviceOrientation())}>
         <HorizontalCountDown
            endTime={endTime}
            duration={duration}
            tick={tick}
            message={getCountDownMessage({
               isLastBetsState,
               tick,
               messages: {
                  placeBets: t('timer.bettingTime.placeBets'),
                  lastBets: t('timer.bettingTime.lastBets'),
                  noMoreBets: t('timer.bettingTime.noMoreBets'),
               },
            })}
         />
      </HorizontalCountDownPosition>
   );
};
