import { rouletteColorGetter } from '@/common/roulette/colors';
import { ECellColor } from '@/common/roulette/constants';

export const getWinNumberColors = ([current, previousNumber, nextNumber]): string[] => {
   const [currentColor, leftColorsStart, rightColorsStart] = [
      current,
      previousNumber,
      nextNumber,
   ].map((index: number) => getColor(String(index)));

   return [currentColor, leftColorsStart, rightColorsStart];
};

const getColor = rouletteColorGetter({
   [ECellColor.Green]: '#23b04e',
   [ECellColor.Red]: '#f00000',
   [ECellColor.Black]: '#282828',
});
