export const generateQueryParamsString = (
   queryParams: Record<string, number | string> = {},
): string => {
   const searchParams = new URLSearchParams();

   Object.keys(queryParams).forEach((key) => {
      searchParams.append(key, `${queryParams[key]}`);
   });

   const paramsString = searchParams.toString();

   return paramsString;
};
