import { TNullable } from '@/common/types';
import { EGameTypes } from '@/common/constants';

import {
   ROULETTE_CANCEL_ROUND_INCIDENTS,
   ROULETTE_MANUAL_INCIDENT_SUB_TYPES,
   ROULETTE_MISTAKE_TYPES,
   ROULETTE_LOCK_STREAM_DECK_BUTTONS_INCIDENTS,
} from './gameTypes/roulette';
import {
   BLACKJACK_LOCK_STREAM_DECK_BUTTONS_INCIDENTS,
   BLACKJACK_MANUAL_INCIDENT_SUB_TYPES,
   BLACKJACK_MISTAKE_TYPES,
   BLACKJACK_PAUSE_ROUND_INCIDENTS,
} from './gameTypes/blackjack';

export enum INCIDENT_STATUS {
   RESOLVED = 'RESOLVED',
   OPENED = 'OPENED',
   IN_PROGRESS = 'IN_PROGRESS',
   CLOSED = 'CLOSED',
}

export type TIncidentHistory = {
   comment: TNullable<string>;
   created: string;
   createdBy: string;
   status: string;
};

export enum INCIDENT_STATUS_TITLE {
   OPENED = 'NEW',
   IN_PROGRESS = 'IN PROGRESS',
   RESOLVED = 'RESOLVED',
   CLOSED = 'CLOSE',
}

export enum MANUAL_INCIDENT_TYPES {
   CHAT = 'CHAT',
   TECH = 'TECH',
   SOS = 'SOS',
   CHANGE = 'CHANGE',
   MISTAKE = 'MISTAKE',
   WHEEL = 'WHEEL',
   ASSISTANCE = 'ASSISTANCE',
}

export enum MANUAL_INCIDENT_SUB_TYPES {
   TECH_SCREEN = 'TECH_SCREEN',
   TECH_OTHER = 'TECH_OTHER',
   CHAT_RUDE = 'CHAT_RUDE',
   CHAT_NAME = 'CHAT_NAME',
   CHAT_GP = 'CHAT_GP',
   MISTAKE_OTHER = 'MISTAKE_OTHER',
   ASSISTANCE_SM_CALL = 'ASSISTANCE_SM_CALL',
}

export type rouletteManualSubTypes = ROULETTE_MANUAL_INCIDENT_SUB_TYPES & MANUAL_INCIDENT_TYPES;

export type blackjackManualSubTypes = BLACKJACK_MANUAL_INCIDENT_SUB_TYPES & MANUAL_INCIDENT_TYPES;

export const ROULETTE_CANCEL_ROUND_INCIDENTS_AND_GENERAL = [
   MANUAL_INCIDENT_SUB_TYPES.MISTAKE_OTHER,
   MANUAL_INCIDENT_TYPES.SOS,
   ...ROULETTE_CANCEL_ROUND_INCIDENTS,
];

export const ROULETTE_LOCK_STREAM_DECK_BUTTONS_INCIDENTS_AND_GENERAL = [
   MANUAL_INCIDENT_SUB_TYPES.MISTAKE_OTHER,
   MANUAL_INCIDENT_TYPES.SOS,
   ...ROULETTE_LOCK_STREAM_DECK_BUTTONS_INCIDENTS,
];

export const BLACKJACK_PAUSE_ROUND_INCIDENTS_AND_GENERAL = [
   MANUAL_INCIDENT_SUB_TYPES.MISTAKE_OTHER,
   MANUAL_INCIDENT_TYPES.SOS,
   ...BLACKJACK_PAUSE_ROUND_INCIDENTS,
];

export const BLACKJACK_LOCK_STREAM_DECK_BUTTONS_INCIDENTS_AND_GENERAL = [
   MANUAL_INCIDENT_SUB_TYPES.MISTAKE_OTHER,
   MANUAL_INCIDENT_TYPES.SOS,
   ...BLACKJACK_LOCK_STREAM_DECK_BUTTONS_INCIDENTS,
];

export type Incident = {
   type: blackjackManualSubTypes | rouletteManualSubTypes;
   created: string;
   subType: TNullable<blackjackManualSubTypes | rouletteManualSubTypes>;
   incidentId: string;
   incidentType: TNullable<string>;
   physicalTable: {
      id: string;
      title: string;
   };
   status: INCIDENT_STATUS;
   history: TIncidentHistory[];
   roundId: string;
   dealer: {
      name: string;
   };
};

export type IncidentId = Incident['incidentId'];

export type StudioId = string;

export interface IColoredIncident extends Incident {
   color: string;
   statusTitle: INCIDENT_STATUS_TITLE;
}

export const getIncidentsTypes = (gameType) => {
   const mistakesBasedOnGameType = {
      [EGameTypes.BlackJack]: BLACKJACK_MISTAKE_TYPES,
      [EGameTypes.AutoRoulette]: ROULETTE_MISTAKE_TYPES,
      [EGameTypes.SingleZeroRoulette]: ROULETTE_MISTAKE_TYPES,
   };

   return {
      [MANUAL_INCIDENT_TYPES.ASSISTANCE]: [MANUAL_INCIDENT_SUB_TYPES.ASSISTANCE_SM_CALL],
      [MANUAL_INCIDENT_TYPES.SOS]: [],
      [MANUAL_INCIDENT_TYPES.CHANGE]: [],
      [MANUAL_INCIDENT_TYPES.TECH]: [
         MANUAL_INCIDENT_SUB_TYPES.TECH_SCREEN,
         MANUAL_INCIDENT_SUB_TYPES.TECH_OTHER,
      ],
      [MANUAL_INCIDENT_TYPES.CHAT]: [
         MANUAL_INCIDENT_SUB_TYPES.CHAT_RUDE,
         MANUAL_INCIDENT_SUB_TYPES.CHAT_NAME,
         MANUAL_INCIDENT_SUB_TYPES.CHAT_GP,
      ],
      [MANUAL_INCIDENT_TYPES.MISTAKE]: [
         MANUAL_INCIDENT_SUB_TYPES.MISTAKE_OTHER,
         ...mistakesBasedOnGameType[gameType],
      ],
   };
};
