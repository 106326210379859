import { ETypeBet } from '@/common/roulette/constants';

export const splitPocketsByChunk = ({
   pocketsList,
   rouletteMapRows,
   chunkSize,
   startId,
   type,
}: {
   pocketsList: number[];
   rouletteMapRows: number;
   chunkSize: number;
   startId: number;
   type: ETypeBet;
}) => {
   const config: {
      id: number;
      numbers: number[];
      type: ETypeBet;
   }[] = [];

   for (let i = 1; i <= pocketsList.length - 1; i += rouletteMapRows) {
      const numbersChunk = pocketsList.slice(i, i + chunkSize);

      if (numbersChunk.length === chunkSize) {
         config.push({
            id: startId,
            numbers: numbersChunk,
            type,
         });
      }

      // eslint-disable-next-line no-param-reassign
      startId++;
   }

   return config;
};
