import { findLast } from '@/common/utils';
import { Rank } from '@/common/cardGames/ui/Card/types';
import { isMobileDevice } from '@/common/utils/checkDevice';

import { TGameState } from '../../types';
import { EHandResult } from '../../../../constants';
import { EPlayerAction } from '../../../../services/constants';
import { TSeatPosition, TSeat, TUnstableServerType, THand, TDealerSeat } from '../../../../types';
import { EServerGameStep } from '../../constants';
import { IGameStepAdapter, gameStepAdapter } from '../gameStepAdapter';

// TODO: add unit test
export interface IEarlyDecisionAdapter {
   addEarlyDecisionToSeat: (params: {
      serverSeat: TUnstableServerType;
      seat: TSeat;
      clientSeats: TSeat[];
      currentPlayingSeatPosition: TGameState['currentSeat'];
      serverGameStep: EServerGameStep;
      dealer: TDealerSeat;
   }) => TSeat;
   addMakingEarlyDecisionFlagToSeat: (seats: TSeat[], isMobileDevice: boolean) => TSeat[];
   defaultEarlyDecisionConfig: () => TSeat['earlyDecision'];
}

class EarlyDecisionAdapter implements IEarlyDecisionAdapter {
   private gameStepAdapter: IGameStepAdapter;
   constructor({ gameStepAdapter }: { gameStepAdapter: IGameStepAdapter }) {
      this.gameStepAdapter = gameStepAdapter;
   }

   private isDealerFirstCardIsAce = (dealer: TDealerSeat) => {
      const { cards } = dealer.hand;
      const [firstDealerCard] = cards;

      return firstDealerCard?.rank === Rank.ACE;
   };

   private isPlayerHasOneOrLessCard = (hand: THand) => {
      return hand.cards.length === 0 || hand.cards.length === 1;
   };

   private isPlayerHandHasResult = (hand: THand) => {
      return hand.handResult !== null;
   };

   private isPlayerHasTwoHands = (hands: THand[]) => {
      return hands.length === 2;
   };

   private isPlayerHasBlackjack = (hand: THand) => {
      return hand.handResult === EHandResult.Bj;
   };

   private findFirstPlayingSeatRight = ({
      seats,
      seatPosition,
   }: {
      seats: TSeat[];
      seatPosition: TSeatPosition;
   }) => {
      // we get all previous seats. for example: if seatPosition equal 4, previousSeats will includes seats from 3 to 0 position:
      // [{ seatPosition: 3, ...seat }, { seatPosition: 2, ...seat }, { seatPosition: 1, ...seat }, { seatPosition: 0, ...seat }]
      const previousSeats = [...seats].slice(0, seatPosition).reverse();
      // we find the first seat which has bets (previous playing seat to the right from our seat)
      const previousPlayingSeat = previousSeats.find((seat) => seat.seatBets.length > 0);

      return previousPlayingSeat?.isSeatPlaying;
   };

   private isCurrentSeatPlaying = (seat: TSeatPosition, currentSeat: TGameState['currentSeat']) => {
      return seat === currentSeat;
   };

   private canShowEarlyDecision = ({
      seatPosition,
      playerHands,
      currentPlayingSeatPosition,
   }: {
      seatPosition: TSeatPosition;
      playerHands: THand[];
      currentPlayingSeatPosition: TGameState['currentSeat'];
   }): boolean => {
      const [firstHand] = playerHands;

      // if player has two hands - early decision cannot be shown
      if (this.isPlayerHasTwoHands(playerHands)) {
         return false;
      }

      // if player's hand has result - early decision cannot be shown
      if (this.isPlayerHandHasResult(firstHand)) {
         return false;
      }

      // if current seat is playing - early decision cannot be shown
      if (this.isCurrentSeatPlaying(seatPosition, currentPlayingSeatPosition)) {
         return false;
      }
      // if player has only one card - early decision cannot be shown
      if (this.isPlayerHasOneOrLessCard(firstHand)) {
         return false;
      }
      // if player has Blackjack - early decision cannot be shown
      // eslint-disable-next-line sonarjs/prefer-single-boolean-return
      if (this.isPlayerHasBlackjack(firstHand)) {
         return false;
      }

      // in other cases - early decision can be shown
      return true;
   };

   private canChangeEarlyDecisionOnMobile = ({
      seatPosition,
      earlyAction,
      lastAction,
      clientSeats,
      dealer,
      serverGameStep,
   }: {
      clientSeats: TSeat[];
      seatPosition: TSeatPosition;
      earlyAction: TUnstableServerType;
      lastAction: TUnstableServerType;
      serverGameStep: EServerGameStep;
      dealer: TDealerSeat;
   }): boolean => {
      const {
         isCurrentPlayerSeat,
         insurance: { isInsuranceNotChosen },
         playerHands,
      } = clientSeats[seatPosition];
      const isFirstDealerCardIsAce = this.isDealerFirstCardIsAce(dealer);
      const isDealingTime = this.gameStepAdapter.isDealingTime(serverGameStep);
      const isInsuranceTime = this.gameStepAdapter.isInsuranceTime(serverGameStep);
      const isInsuranceDecisionPending = isInsuranceTime && isInsuranceNotChosen;
      const isInsuranceAvailable =
         isFirstDealerCardIsAce && (isDealingTime || isInsuranceDecisionPending);

      // if seat not is current player - early decision cannot be changed
      if (!isCurrentPlayerSeat) {
         return false;
      }

      // if player is insurance available (player didn't made decision) - early decision cannot be changed
      if (isInsuranceAvailable) {
         return false;
      }

      const [firstHand] = playerHands;
      const playerFirstHandHasTwoCards = firstHand.cards.length === 2;
      // if player hasn't two cards - early decision cannot be changed
      if (!playerFirstHandHasTwoCards) {
         return false;
      }

      const playerMadeEarlyDecision = earlyAction !== null;
      // if player made early decision - early decision cannot be changed
      if (playerMadeEarlyDecision) {
         return false;
      }
      const playerMadeDecision = ![EPlayerAction.Insured, null].includes(lastAction);
      // eslint-disable-next-line sonarjs/prefer-single-boolean-return
      if (playerMadeDecision) {
         return false;
      }

      return true;
   };

   private canChangeEarlyDecisionOnDesktop = ({
      seatPosition,
      earlyAction,
      clientSeats,
      dealer,
      serverGameStep,
   }: {
      clientSeats: TSeat[];
      seatPosition: TSeatPosition;
      earlyAction: TUnstableServerType;
      serverGameStep: EServerGameStep;
      dealer: TDealerSeat;
   }): boolean => {
      const {
         insurance: { isInsuranceNotChosen },
      } = clientSeats[seatPosition];
      const isFirstDealerCardIsAce = this.isDealerFirstCardIsAce(dealer);
      const isDealingTime = this.gameStepAdapter.isDealingTime(serverGameStep);
      const isInsuranceTime = this.gameStepAdapter.isInsuranceTime(serverGameStep);
      const isInsuranceDecisionPending = isInsuranceTime && isInsuranceNotChosen;

      const isInsuranceAvailable =
         isFirstDealerCardIsAce && (isDealingTime || isInsuranceDecisionPending);

      if (isInsuranceAvailable) {
         return false;
      }

      const isRightSeatMakingDecision = this.findFirstPlayingSeatRight({
         seats: clientSeats,
         seatPosition,
      });

      // if player on the previous seat is playing but current player didn't made early decision
      // he will see early decision actions (before he made early decision. after that early actions will be hidden)
      if (isRightSeatMakingDecision && !earlyAction) {
         return true;
      }

      return !isRightSeatMakingDecision;
   };

   private canChangeEarlyDecision = ({
      seatPosition,
      earlyAction,
      lastAction,
      clientSeats,
      dealer,
      serverGameStep,
   }: {
      clientSeats: TSeat[];
      seatPosition: TSeatPosition;
      earlyAction: TUnstableServerType;
      lastAction: TUnstableServerType;
      serverGameStep: EServerGameStep;
      dealer: TDealerSeat;
   }): boolean => {
      // IMPORTRANT! Early Decision on mobile and on desktop have different logic
      return isMobileDevice()
         ? this.canChangeEarlyDecisionOnMobile({
              seatPosition,
              earlyAction,
              lastAction,
              clientSeats,
              dealer,
              serverGameStep,
           })
         : this.canChangeEarlyDecisionOnDesktop({
              seatPosition,
              earlyAction,
              clientSeats,
              dealer,
              serverGameStep,
           });
   };

   public defaultEarlyDecisionConfig = (): TSeat['earlyDecision'] => ({
      isMadeEarlyDecision: false,
      isMakingEarlyDecision: false,
      canChangeEarlyDecision: false,
      canShowEarlyDecision: false,
      isChosenDouble: false,
      isChosenHit: false,
      isChosenSplit: false,
      isChosenStand: false,
   });

   private getPreviousSeatFromCurrent = ({
      seats,
      currentSeatPosition,
   }: {
      seats: TSeat[];
      currentSeatPosition: TSeat['seatPosition'];
   }) =>
      findLast(
         seats,
         ({ isCurrentPlayerSeat, seatPosition }) =>
            isCurrentPlayerSeat && seatPosition <= currentSeatPosition,
      );

   public addMakingEarlyDecisionFlagToSeat = (seats: TSeat[], isMobileDevice: boolean): TSeat[] => {
      // We handle and update flag isMakingEarlyDecision to seat.earlyDecision ONLY for mobile devices
      if (!isMobileDevice) {
         return seats;
      }

      const hasCurrentPlayerSeat = seats.some((seat) => seat.isCurrentPlayerSeat);
      if (!hasCurrentPlayerSeat) {
         return seats;
      }

      return seats.map((seat) => {
         const {
            earlyDecision: { canChangeEarlyDecision, isMadeEarlyDecision },
            seatPosition,
            isSeatPlaying,
            seatLastDecision,
            isCurrentPlayerSeat,
         } = seat;
         const playerMadeDecision = ![EPlayerAction.Insured, null].includes(seatLastDecision);

         // if player made decision or is playing at the moment - early decision cannot be changed and we set isMadeEarlyDecision as TRUE (we made final decision and we can't change early decision)
         if (isSeatPlaying || playerMadeDecision) {
            return {
               ...seat,
               earlyDecision: {
                  ...seat.earlyDecision,
                  isMakingEarlyDecision: false,
                  isMadeEarlyDecision: true,
               },
            };
         }

         const previousSeatPosition = seatPosition - 1;
         const previousCurrentPlayerSeat = this.getPreviousSeatFromCurrent({
            seats,
            currentSeatPosition: previousSeatPosition as TSeat['seatPosition'],
         });

         // we can choose early decision if OUR previous seat has already made early decision
         const isPreviousCurrentSeatMadeEarlyDecision = previousCurrentPlayerSeat
            ? previousCurrentPlayerSeat.earlyDecision?.isMadeEarlyDecision
            : true;
         // we can choose early decision if OUR previous seat is not playing
         const previousCurrentPlayerSeatNotPlaying = !previousCurrentPlayerSeat?.isSeatPlaying;
         // we can choose early decision if seat has NOT made early decision (on mobile we can choose early decision only ONCE)
         const isCurrentSeatNotMadeEarlyDecision = canChangeEarlyDecision && !isMadeEarlyDecision;
         const isMakingEarlyDecision =
            isCurrentPlayerSeat &&
            isCurrentSeatNotMadeEarlyDecision &&
            isPreviousCurrentSeatMadeEarlyDecision &&
            previousCurrentPlayerSeatNotPlaying;

         return {
            ...seat,
            earlyDecision: { ...seat.earlyDecision, isMakingEarlyDecision },
         };
      });
   };

   public addEarlyDecisionToSeat = ({
      currentPlayingSeatPosition,
      serverGameStep,
      serverSeat,
      seat,
      clientSeats,
      dealer,
   }: {
      seat: TSeat;
      clientSeats: TSeat[];
      currentPlayingSeatPosition: TGameState['currentSeat'];
      serverGameStep: EServerGameStep;
      serverSeat: TUnstableServerType;
      dealer: TDealerSeat;
   }): TSeat => {
      const { playerHands, seatPosition } = seat;
      const { lastAction, earlyAction } = serverSeat;

      const isChosenDouble = earlyAction === EPlayerAction.Double;
      const isChosenHit = earlyAction === EPlayerAction.Hit;
      const isChosenSplit = earlyAction === EPlayerAction.Split;
      const isChosenStand = earlyAction === EPlayerAction.Stand;

      const playerMadeEarlyDecision = earlyAction !== null;
      const playerMadeDecision = [EPlayerAction.Stand, EPlayerAction.Double].includes(lastAction);
      // if player made early decision or made decision we set flag isMadeEarlyDecision as TRUE
      const isMadeEarlyDecision = playerMadeDecision || playerMadeEarlyDecision;
      const isEarlyDecisionGameStep = this.gameStepAdapter.isEarlyDecisionGameStep(serverGameStep);
      if (!isEarlyDecisionGameStep) {
         return {
            ...seat,
            earlyDecision: {
               ...this.defaultEarlyDecisionConfig(),
               isMadeEarlyDecision,
               isChosenDouble,
               isChosenHit,
               isChosenSplit,
               isChosenStand,
            },
         };
      }

      const canShowEarlyDecision = this.canShowEarlyDecision({
         seatPosition,
         playerHands,
         currentPlayingSeatPosition,
      });

      const canChangeEarlyDecision = canShowEarlyDecision
         ? this.canChangeEarlyDecision({
              clientSeats,
              seatPosition,
              earlyAction,
              lastAction,
              dealer,
              serverGameStep,
           })
         : false;

      const earlyDecision = {
         canShowEarlyDecision,
         canChangeEarlyDecision,
         isMadeEarlyDecision,
         isMakingEarlyDecision: false,
         isChosenDouble,
         isChosenHit,
         isChosenSplit,
         isChosenStand,
      };

      return { ...seat, earlyDecision };
   };
}

export const earlyDecisionAdapter = new EarlyDecisionAdapter({ gameStepAdapter });
