import { rouletteColorGetter } from '@/common/roulette/colors';
import { winNumberDTO } from '@/common/helpers';

import { rouletteColors } from '../constants';

export const getWinNumberColor = (winNumber: string) => {
   const _winNumberDTO = winNumberDTO(winNumber);
   const getColor = rouletteColorGetter(rouletteColors);

   return getColor(_winNumberDTO);
};
