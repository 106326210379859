import { FC } from 'react';

import { CloseIcon } from '../assets';
import { ENotificationActionColors } from '../constants';
import { INotification } from '../state';
import * as S from '../Notifications.styles';

import { NotificationAction } from './NotificationAction';
import { NotificationIcon } from './NotificationIcon';

interface INotificationTemplateProps extends INotification {
   content?: string;
   contentList?: string[];
   handleCloseNotification: () => void;
   title?: string;
}

export const NotificationTemplate: FC<INotificationTemplateProps> = ({
   title,
   action,
   content,
   type,
   contentList = [],
   handleCloseNotification,
}) => (
   <S.NotificationContainer borderColor={ENotificationActionColors[type]}>
      <S.NotificationIconContainer>
         <NotificationIcon type={type} />
      </S.NotificationIconContainer>
      <S.NotificationContentWrapper>
         {title && <S.Title>{title}</S.Title>}
         <S.Content data-testid={`${type}-notification-content`}>{content}</S.Content>
         {contentList.map((content) => (
            <S.Content key={content}>{content}</S.Content>
         ))}
         {action && <NotificationAction type={type} action={action} />}
      </S.NotificationContentWrapper>
      <S.CloseIconWrapper onClick={handleCloseNotification}>
         <CloseIcon />
      </S.CloseIconWrapper>
   </S.NotificationContainer>
);
