import ReactDOM from 'react-dom/client';

import '@/common/assets/fonts/Montserrat/montserrat-fonts.css';

import { Providers } from '#/state/Providers';
import '#/translates';

// polyfill: add drag and drop to touch devices
import './assets/scripts/drag-and-drop-touch';

import { App } from './App';

const renderApp = (): void => {
   const root = ReactDOM.createRoot(document.getElementById('root')!);
   root.render(
      <Providers>
         <App />
      </Providers>,
   );
};

renderApp();
