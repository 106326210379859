import { TUser } from '@/common/entities';
import { TClientAuth } from '@/common/services/api/authAPI/types';
import { TUserSessionSecret, TUserSessionId } from '@/common/entities/Session/UserSession';

export const authDataApiAdapter = (
   data: TClientAuth,
): { user: TUser; session: { id: TUserSessionId; secret: TUserSessionSecret } } => {
   const { UserId, UserName, Age, Token, TotalBalance, Currency, Country } = data.userInfo;
   const { chatToken } = data.chatInfo ?? {};
   const { sessionId, sessionSecret } = data.sessionInfo ?? {};

   return {
      user: {
         age: Number(Age),
         id: UserId,
         token: Token,
         country: Country ?? '',
         username: UserName ?? '',
         currency: Currency ?? '',
         totalBalance: TotalBalance ?? 0,
         chatToken,
      },
      session: {
         id: sessionId,
         secret: sessionSecret,
      },
   };
};
