import { FC } from 'react';

import { Overlay } from '@/common/ui';
import { EModalNotificationType } from '@/common/ui/ModalNotification/constants';

import * as S from './KickInactivePlayerOverlay.styles';

interface IInactiveOverlayProps {
   buttonTitle: string;
   modalTitle: string;
   onClick: () => void;
}

export const KickInactivePlayerOverlay: FC<IInactiveOverlayProps> = ({
   buttonTitle,
   modalTitle,
   onClick,
}) => (
   <>
      <S.OverlayBlur />
      <Overlay
         withBackdrop={false}
         type={EModalNotificationType.Warning}
         title={modalTitle}
         actionButton={{
            onClick,
            title: buttonTitle,
         }}
      />
   </>
);
