import { iterateObject } from 'helpers';

import { TChip } from '@/common/ui/Chip/entities';

import { TCommonBet } from '#/state/features/bets';

import { findShortestCombination } from './core/utils/findShortestCombination';
import { replacingChipValueWithChipId } from './core/utils/replacingChipValueWithChipId';

const _replaceChipValueToChipIdInOneBet = ({
   chips,
   chipValues,
   chipValueWithChipIdMap,
}: {
   chips: Record<number, number>;
   chipValues: number[];
   chipValueWithChipIdMap: Record<number, string>;
}) => {
   const result: string[] = [];

   iterateObject(chips, (chipValue, count) => {
      const combinationOfChipValues = findShortestCombination(chipValues, Number(chipValue)) ?? [];
      const chipId = replacingChipValueWithChipId(combinationOfChipValues, chipValueWithChipIdMap);
      const newItem = Array(count).fill(chipId).flat();

      result.push(...newItem);
   });

   return result;
};

const _getChipValues = (chipConfig: TChip[]) => {
   return chipConfig.map((chip) => chip.value);
};

const getChipValueWithChipIdMap = (chipConfig: TChip[]) => {
   return chipConfig.reduce((total, { value, id }) => ({ ...total, [value]: id }), {});
};

export const replaceChipValueToChipId = ({
   bets,
   chipConfig,
}: {
   bets: TCommonBet[];
   chipConfig: TChip[];
}) => {
   const chipValues = _getChipValues(chipConfig);
   const chipValueWithChipIdMap = getChipValueWithChipIdMap(chipConfig);

   return bets.map((bet) => ({
      ...bet,
      chips: _replaceChipValueToChipIdInOneBet({
         chips: bet.chips,
         chipValues,
         chipValueWithChipIdMap,
      }),
   }));
};
