// @ts-nocheck
import styled from 'styled-components/macro';

import { applyZIndex } from '@/common/ui/theme/mixins';

export const Overlay = styled.div`
  ${applyZIndex('layoutOverlay')};

  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  pointer-events: none;
  background-color: ${({ theme, withBackdrop }) =>
    withBackdrop ? theme.colors.black[40] : 'none'};

  > :first-child {
    pointer-events: all;
  }
`;
