import { getValueFromQueryParams } from '@/common/utils';
import { isValidLanguage, TRouletteClientLanguage } from '@/common/entities/Language';

import { getNestedProperty } from '../getNestedProperty';
import {
   resolveInterpolationOptions,
   TResolveInterpolationOptions,
} from '../resolveInterpolationOptions';

export const translationQuery = (
   key: string,
   translations,
   options?: TResolveInterpolationOptions,
): string => {
   const DEFAULT_LANG: TRouletteClientLanguage = 'en';
   const languageQueryParam = 'language';

   const languageFromUrl = getValueFromQueryParams({
      key: languageQueryParam,
      error: 'Parameter "language" not found in url',
   });

   const gameLanguage = isValidLanguage(languageFromUrl) ? languageFromUrl : DEFAULT_LANG;

   const translation = getNestedProperty(translations[gameLanguage]?.translation, key) || key;

   return options ? resolveInterpolationOptions(translation, options) : translation;
};
