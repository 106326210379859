import { useDealerRateState } from '@/common/modules/DealerRating/state';
import { DealerRatingStars } from '@/common/modules/DealerRating/components';
import { useUserGeneralSettingsState } from '@/common/state/gameTypes/common/userGeneralSettings';

import { useTranslation } from '#/translates';

export const DealerRatingStarsView = () => {
   const { t } = useTranslation();
   const { isFeedbackEnabledOnCasinoGameTypeLevel } = useDealerRateState();
   const { isDealerRatingEnabled } = useUserGeneralSettingsState();

   if (!isFeedbackEnabledOnCasinoGameTypeLevel || !isDealerRatingEnabled) {
      return;
   }

   return <DealerRatingStars buttonTitle={t('dealerRating.buttonTitle')} />;
};
