import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { useDispatch, useSelector } from 'react-redux';

import { TRoundWinners } from '@/common/services/sockets/roulette/useRoundWinners/schemes';

import { TRootState } from '#/state/types';

const initialState: { list: TRoundWinners } = {
   list: [],
};

export const winnerListSlice = createSlice({
   name: 'winnerList',
   initialState,
   reducers: {
      saveWinnerList: (state, action: PayloadAction<TRoundWinners>) => {
         state.list = action.payload;
      },
   },
});

export const useWinnerListActions = () => {
   const dispatch = useDispatch();
   const { saveWinnerList } = winnerListSlice.actions;

   const handleSaveWinnerList = (winnerList: TRoundWinners) => {
      dispatch(saveWinnerList(winnerList));
   };

   return { handleSaveWinnerList };
};

export const useWinnerList = () => {
   return useSelector((state: TRootState) => state.winnerList?.list);
};
