import { memo } from 'react';

import { useSelectedChipSelector } from '@/common/modules/ChipTray';
import { IPlayChipSound } from '@/common/entities';
import { cellsColorConfig } from '@/common/roulette/colors';

import { IConfigWithTypeBet } from '#/core/config/BetConfig';
import { THotAndColdTypeCellObject } from '#/modules/Track/BettingMap/helpers';
import { TBetType } from '#/state/features/bets';
import { withChipSound } from '#/hocs';

import { Bet, Title, WinningNumber } from '..';
import { LAST_SIMPLE_BET_CELL_ID } from '../constants';
import { getCellBgClass } from '../../helpers';

import * as S from './BetGrid.styles';

interface IBetGridProps {
   getBetsAmountByCellId: (id: number) => number;
   getZIndex: (id: number) => number;
   gridConfig: IConfigWithTypeBet[];
   hotAndColdTypeCellObject: THotAndColdTypeCellObject;
   makeCommonBet: (command: TBetType) => void;
   onClick: (event) => void;
   withTitle?: boolean;
}

const _BetGrid = ({
   getBetsAmountByCellId,
   getZIndex,
   gridConfig,
   hotAndColdTypeCellObject,
   makeCommonBet,
   withTitle = false,
   onClick,
   playChipSound,
}: IBetGridProps & IPlayChipSound) => {
   const { value: chipValue } = useSelectedChipSelector();

   const getCellClassName = (id: number): string => {
      const sectorBgClass = getCellBgClass(id);
      return `cell cell_${sectorBgClass} cell_${id}`;
   };

   const getHighlightNumbers = (id: number, numbers: number[]): number[] => {
      const uniqNumbers = new Set(numbers);

      if (id <= LAST_SIMPLE_BET_CELL_ID) {
         uniqNumbers.add(id);
      }

      return Array.from(uniqNumbers);
   };

   return (
      <>
         {gridConfig.map((configItem) => {
            const { id, value, type, numbers } = configItem;
            const whenRenderTitle = withTitle && value;
            const hotAndColdType = hotAndColdTypeCellObject[id];
            const numberColor = cellsColorConfig[id];

            return (
               <S.Container
                  key={id}
                  color={hotAndColdType}
                  className={getCellClassName(id)}
                  data-testid={`betting-map-cell-${id}`}
                  data-highlight-cells={getHighlightNumbers(id, numbers)}
                  onClick={(event: MouseEvent) => {
                     onClick(event);
                     makeCommonBet({
                        id,
                        type,
                        numbers,
                        amount: chipValue,
                        chips: { [chipValue]: 1 },
                     });
                     playChipSound();
                  }}
               >
                  {whenRenderTitle && (
                     <Title numberColor={numberColor} title={value} cellNumber={id} />
                  )}
                  <Bet
                     type={type}
                     id={id}
                     getBetsAmountByCellId={getBetsAmountByCellId}
                     getZIndex={getZIndex}
                     cells={getHighlightNumbers(id, numbers)}
                  />
                  <WinningNumber />
               </S.Container>
            );
         })}
      </>
   );
};

const BetGridWithChipSound = withChipSound(_BetGrid);
export const BetGrid = memo(BetGridWithChipSound);
