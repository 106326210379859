// @ts-nocheck
import styled, { css } from 'styled-components/macro';

import { isHoverSupports } from '@/common/ui/theme/mixins';
import { hexToRgba } from '@/common/utils';

export const StyledButton = styled.div`
  --unmute-control-color: ${({ theme }) => theme.colors?.main};
  --mute-control-color: ${({ theme }) => theme.colors.red};
  --icon-default-color: ${({ theme }) => theme.colors.white};
  will-change: transform;

  --muted-control-active-bg: ${({ theme }) =>
    `linear-gradient(180deg, ${hexToRgba(theme.colors.red, 0.55)} 0%, ${hexToRgba(
      theme.colors.red,
      0.7,
    )} 100%)`};

  --unmuted-control-active-bg: ${({ theme }) =>
    `linear-gradient(180deg, ${hexToRgba(theme.colors.main, 0.55)} 0%, ${hexToRgba(
      theme.colors.main,
      0.7,
    )} 100%)`};

  .unmute_control {
    svg {
      path: {
        stroke: var(--icon-default-color);
      }

      circle {
        stroke: rgba(0, 0, 0, 0.7);
      }

      ${({ isMuted }) =>
        isMuted &&
        css`
          path,
          circle {
            stroke: var(--mute-control-color);
          }
        `}
    }

    ${isHoverSupports`
        &:hover {
           box-shadow: ${({ isMuted }) =>
             `0 0 6px 0 ${isMuted ? 'var(--mute-control-color)' : 'var(--unmute-control-color)'}`};

           svg {
              path,
              circle {
                 stroke: ${({ isMuted }) =>
                   `${isMuted ? 'var(--mute-control-color)' : 'var(--unmute-control-color)'}`};
              }
           }
        }
   `}

    &:active {
      background: ${({ isMuted }) =>
        isMuted ? 'var(--muted-control-active-bg)' : 'var(--unmuted-control-active-bg)'};
    }
  }
`;
