// @ts-nocheck
import styled from 'styled-components/macro';

import { orientation } from '@/common/ui/theme/mixins';

import { SideControls } from '../../LandscapeGrid.styles';

export const RightColumnContainer = styled.div`
   height: 100%;
   display: flex;
   flex-direction: column;
   justify-content: flex-end;
`;
export const CollapseTrackControl = styled.div`
   top: 50px;
   right: -26px;
   position: absolute;
`;
export const VolumeControl = styled.div`
   position: absolute;
   ${orientation.mobileLandscape`
      --volume-control-icon-size: 18px;
      top: 15px;
      right: -24px;
   `}

   ${orientation.portrait`
	   --volume-control-icon-size: 18px;
      right: 12px;
	   top: 10px;
  `}
`;

export const RightControlsContainer = styled(SideControls)`
   margin-left: auto;
   transform: translateX(25px);
`;
