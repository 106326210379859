import { TNullable } from '@/common/types';
import { getValueFromQueryParams, logger } from '@/common/utils';
import { TRouletteClientLanguage, isValidLanguage } from '@/common/entities/Language';

const languageQueryParam = 'language';
const DEFAULT_LANGUAGE = 'en';
// In our games we get game language from url (new URL(window.location.href))
export const handleGameLanguage = ({
   queryParamsKey = languageQueryParam,
   onSuccessCallback,
}: {
   queryParamsKey?: string;
   onSuccessCallback: (lang: TRouletteClientLanguage) => void;
}) => {
   try {
      const gameLanguage: TNullable<string> = getValueFromQueryParams({
         key: queryParamsKey,
         error: 'Parameter "language" not found in url',
      });
      if (isValidLanguage(gameLanguage)) {
         onSuccessCallback(gameLanguage);
      } else {
         onSuccessCallback(DEFAULT_LANGUAGE);
         logger.log('Invalid language', gameLanguage);
      }
   } catch (error) {
      if (error instanceof Error) {
         logger.log(error);
      }
   }
};
