import { ZodObject, ZodRawShape } from 'zod';

import { TListenerApiDispatchType } from '@/common/types';
import { RTKQueryApi } from '@/common/services/api/types';
import { contractValidator } from '@/common/services/api/utils/contractValidator';
import { unwrapResponse } from '@/common/services/api/utils/unwrapResponse';
import { gameConfigAPI } from '@/common/services/api/gameConfigAPI/gameConfigAPI';
import { TGameTableId, TUser } from '@/common/entities';
import { ETableStatus } from '@/common/constants';
import { logger } from '@/common/utils';

export const handleGetGameConfig = async <T extends ZodRawShape>({
   dispatch,
   currency,
   gameTableId,
   apiSlice,
   schema,
}: {
   schema: ZodObject<T>;
   dispatch: TListenerApiDispatchType;
   currency: TUser['currency'];
   gameTableId: TGameTableId;
   apiSlice: RTKQueryApi;
}) => {
   const gameConfigResponse = await dispatch(
      gameConfigAPI(apiSlice).endpoints.getGameTableConfig.initiate({ currency, gameTableId }),
   );

   // @ts-ignore
   const { data, isError, isException } = unwrapResponse(gameConfigResponse);

   const isGameTableInactive = !data?.is_game_table_active;
   const isPhysicalTableUnderMaintenance = data?.is_physical_table_under_maintenance;

   if (isPhysicalTableUnderMaintenance) {
      throw new Error(ETableStatus.MaintenanceInProgress);
   }

   if (isGameTableInactive) {
      throw new Error(ETableStatus.InactiveTable);
   }

   if (isError || isException) {
      throw new Error(ETableStatus.Error);
   }

   const { isValid } = contractValidator({
      schema,
      data,
      path: 'game config API',
      handleError: (error) => logger.log(error),
   });

   if (!isValid) {
      throw new Error(ETableStatus.Error);
   }

   return data;
};
