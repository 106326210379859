import { useTableStatusSelector } from '@/common/modules/TableStatusOverlay/state';
import { TMaintenanceStartedEvent } from '@/common/services/sockets/useMaintenanceStart/types';

type MaintenanceStartEventOutput = (socket: TMaintenanceStartedEvent) => void;

export const useMaintenanceStart = (): MaintenanceStartEventOutput => {
   const { handleSetMaintenanceInProgress } = useTableStatusSelector();

   return () => {
      handleSetMaintenanceInProgress();
   };
};
