// @ts-nocheck
import styled, { css, keyframes } from 'styled-components/macro';

import * as RangeStyles from '@/common/modules/RangeSlider/RangeSlider.styles';

export const SliderWrapper = styled.div`
   --slider-width: 250px;

   position: relative;
   display: flex;
   width: 100%;
`;

export const SoundControlWrapper = styled.div`
   position: relative;
   display: flex;
   align-items: center;
   margin-right: 22px;
   cursor: pointer;

   ${RangeStyles.RangeSliderWrapper} {
      margin: 0 15px;
   }
`;

export const VolumeControlWrapperMobile = styled.div`
   --volume-control-icon-size: 20px;
   width: var(--volume-control-icon-size);
   height: var(--volume-control-icon-size);
   padding: 2px;

   svg {
      width: 100%;
      height: 100%;
   }
`;

export const SliderFadeIn = keyframes`
   0% {
      width: 0;
   }
   100% {
      width: var(--slider-width);
      margin-right: 10px;
   }
`;

export const SliderFadeOut = keyframes`
   0% {
      width: var(--slider-width);
      margin-right: 10px;
   }
   99% {
      opacity: 1;
   }
   100% {
      width: 0;
      margin: 0;
      opacity: 0;
   }
`;

export const SliderFadeWrapper = css`
   position: absolute;
   right: 44px;
   height: 100%;
   display: flex;
   align-items: center;
   overflow: hidden;
   background: black;
   border-radius: 35px;
`;
