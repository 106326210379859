import { CSSProperties, ElementRef, FC, ReactNode, useRef } from 'react';
import { createPortal } from 'react-dom';

import { useGameState } from '@/common/state/gameTypes/roulette/gameState';
import { TNullable, TSetTimeout } from '@/common/types';

import { SHOW_TOOLTIP_DURATION } from '#/constants/animations';
import { useTranslation } from '#/translates';

import * as S from './CursorTooltip.styles';

type CursorPosition = { clientX: number; clientY: number };

interface ICursorTooltipProps {
   children: ({
      onClick,
      className,
   }: {
      onClick: (position: CursorPosition) => void;
      className: string;
   }) => ReactNode;
   style?: CSSProperties;
}

export const CursorTooltip: FC<ICursorTooltipProps> = ({ children, style }) => {
   const { t } = useTranslation();
   const intervalRef = useRef<TNullable<TSetTimeout>>(null);
   const toolTipRef = useRef<ElementRef<'div'>>(null);
   const { isNoMoreBetsState, isWaitForTheNextRoundState, isRoundCancelledState } = useGameState();
   const isTooltipShown = isWaitForTheNextRoundState || isNoMoreBetsState || isRoundCancelledState;

   const handleOnClick = ({ clientX, clientY }: CursorPosition): void => {
      if (intervalRef.current) {
         clearTimeout(intervalRef.current);
      }

      if (toolTipRef.current?.style) {
         toolTipRef.current.style.opacity = '0';
         toolTipRef.current.style.display = 'block';

         // checks if the tooltip doesn't clip over the right edge
         const { width: tooltipWidth } = toolTipRef.current.getBoundingClientRect();
         const whenTooltipClimbsOverRightEdge = clientX + tooltipWidth >= window.innerWidth;

         if (whenTooltipClimbsOverRightEdge) {
            toolTipRef.current.style.transform = `translate(${
               window.innerWidth - tooltipWidth
            }px, ${clientY}px)`;
         } else {
            toolTipRef.current.style.transform = `translate(${clientX}px, ${clientY}px)`;
         }

         toolTipRef.current.style.opacity = '1';
      }

      intervalRef.current = setTimeout(() => {
         if (toolTipRef.current?.style) {
            toolTipRef.current.style.display = 'none';
         }
      }, SHOW_TOOLTIP_DURATION);
   };

   const toolTip = createPortal(
      <S.Tooltip ref={toolTipRef} id="cursor-tool-tip">
         <div>{t('waitForNextRound')}</div>
      </S.Tooltip>,
      document.getElementById('root') as HTMLDivElement,
   );

   return (
      <S.TooltipWrapper id="cursor-tool-tip-wrapper" style={style}>
         {isTooltipShown ? toolTip : null}
         {children({ onClick: handleOnClick, className: 'cursor-tooltip' })}
      </S.TooltipWrapper>
   );
};
