import { DeviceOrientation, isPortraitMode, useDeviceOrientation } from '@/common/hooks';
import { useGameState } from '@/common/state/gameTypes/roulette/gameState';
import { TNullable } from '@/common/types';

import { useTranslation } from '#/translates';

import * as S from './NextRoundNotification.styles';

export const NextRoundNotification = (): TNullable<JSX.Element> => {
   const { t } = useTranslation();
   const { isWaitForTheNextRoundState } = useGameState();

   const deviceOrientation: DeviceOrientation = useDeviceOrientation();
   const isPortrait: boolean = isPortraitMode(deviceOrientation);

   if (!isWaitForTheNextRoundState) {
      return null;
   }

   return isPortrait ? (
      <S.WrapperPortrait>{t('waitForNextRound')}</S.WrapperPortrait>
   ) : (
      <S.Wrapper>{t('waitForNextRound')}</S.Wrapper>
   );
};
