import { useEffect, useRef } from 'react';

import { TSetTimeout, TNullable } from '@/common/types';
import {
   useLimitTooltipActions,
   LIMIT_TOOLTIP_CLASSNAME,
   SHOW_LIMIT_TOOLTIP_IN_SEC,
   positioningTooltip,
} from '@/common/modules';

export const useShowLimitTooltip = (): void => {
   const timeoutRef = useRef<TNullable<TSetTimeout>>(null);
   const { handleHideTooltip } = useLimitTooltipActions();

   useEffect(() => {
      const handleClick = (event: MouseEvent) => {
         if (event.target) {
            positioningTooltip({
               target: event.target as HTMLElement,
               selector: `.${LIMIT_TOOLTIP_CLASSNAME}`,
            });
         }

         if (timeoutRef.current) {
            clearTimeout(timeoutRef.current);
         }

         timeoutRef.current = setTimeout(handleHideTooltip, SHOW_LIMIT_TOOLTIP_IN_SEC);
      };
      const elements = [...document.querySelectorAll('g[class^="num"]')];

      elements.forEach((element) => element.addEventListener('click', handleClick));

      return () => {
         elements.forEach((element) => element.removeEventListener('click', handleClick));
      };
   }, []);
};
