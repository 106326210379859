import { useEffect, useState } from 'react';

import { useGameState } from '@/common/state/gameTypes/roulette/gameState';
import { useTableStatusSelector } from '@/common/modules/TableStatusOverlay/state';

export const useLoading = () => {
   const [isLoading, setIsLoading] = useState(false);
   const { isSessionClosedDueToInactivity } = useTableStatusSelector();
   const { isSuccessGameStatus, isWaitingForConnectionGameStatus } = useGameState();

   useEffect(() => {
      if (isWaitingForConnectionGameStatus || isSessionClosedDueToInactivity) {
         return;
      }
      setIsLoading(isSuccessGameStatus);
   }, [isSuccessGameStatus]);

   return isLoading;
};
