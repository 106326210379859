import { ECellColor } from '@/common/roulette/constants';

export const rouletteZeroNumbers: number[] = [0];
export const rouletteRedColorNumbers: number[] = [
   1, 3, 5, 7, 9, 12, 14, 16, 18, 19, 21, 23, 25, 27, 30, 32, 34, 36,
];

export const rouletteBlackColorNumbers: number[] = [
   2, 4, 6, 8, 10, 11, 13, 15, 17, 20, 22, 24, 26, 28, 29, 31, 33, 35,
];
export const rouletteSideAreaNumbers: number[] = [37, 38, 39, 40, 41, 44, 45, 46, 47, 48];

export const rouletteColorNumbers: Record<ECellColor, number[]> = {
   [ECellColor.Green]: rouletteZeroNumbers,
   [ECellColor.Red]: rouletteRedColorNumbers,
   [ECellColor.Black]: rouletteBlackColorNumbers,
   [ECellColor.SideArea]: rouletteSideAreaNumbers,
};

export const generateColorsConfig = (): Record<string, ECellColor> => {
   return Object.entries(rouletteColorNumbers).reduce((config, [color, numbers]) => {
      numbers.forEach((number) => (config[number] = color));
      return config;
   }, {});
};

export const cellsColorConfig: Record<string, ECellColor> = generateColorsConfig();

export const rouletteColorGetter = (colors: {
   [ECellColor.Red]: string;
   [ECellColor.Green]: string;
   [ECellColor.Black]: string;
}) => {
   return (cellId: string): string => {
      const color = cellsColorConfig[cellId];
      return colors?.[color] ?? '';
   };
};
