import { ElementRef, useEffect, useRef } from 'react';

import { timeSync } from '@/common/helpers';
import { calculateCurrentTick } from '@/common/modules/CountDown/hooks/useCountDown/helpers/countDownByDate';

import { calculateAnimationProgress } from '../../../utils';
import { IIHorizontalCountDownProps } from '../HorizontalCountDown';

const _createAnimationKeyframes = ({ progressBarPosition }: { progressBarPosition: number }) => {
   return [
      { transform: `translate3d(${progressBarPosition - 100}%, 0, 0)` },
      {
         transform:
            'translate3d(calc(-100% + var(--timer-value-size) - var(--timer-border-width)), 0, 0)',
      },
   ];
};

export const useHorizontalCountDownAnimation = ({
   endTime,
   duration,
}: Pick<IIHorizontalCountDownProps, 'duration' | 'endTime'>) => {
   const timerProgressBarRef = useRef<ElementRef<'div'>>(null);

   useEffect(() => {
      const calculateAnimation = async () => {
         if (!timerProgressBarRef.current || !endTime || !duration) {
            return;
         }
         const timeSyncDelta = await timeSync.getServerTimeDelta();
         const currentTimerTickInSeconds = calculateCurrentTick({ endTime, delta: timeSyncDelta });
         const currentTimerTickInMs = currentTimerTickInSeconds * 1000;
         const progressBarPosition = calculateAnimationProgress({
            currentTimerTickInSeconds,
            duration,
         });
         const isShowAnimation = currentTimerTickInMs >= 0;

         if (isShowAnimation) {
            timerProgressBarRef.current?.animate(
               _createAnimationKeyframes({ progressBarPosition }),
               {
                  duration: currentTimerTickInMs,
                  iterations: 1,
                  easing: 'linear',
                  fill: 'both',
               },
            );
         }
      };

      calculateAnimation();
   }, [endTime, duration]);

   return {
      timerProgressBarRef,
   };
};
