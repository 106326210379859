import { clientAuthSchema } from '@/common/services/api/authAPI/schemes';
import { authAPI } from '@/common/services/api/authAPI/authAPI';
import { contractValidator } from '@/common/services/api/utils/contractValidator';
import { unwrapResponse } from '@/common/services/api/utils/unwrapResponse';
import { RTKQueryApi } from '@/common/services/api/types';
import { TGameTableId, TLaunchToken } from '@/common/entities';
import { TListenerApiDispatchType } from '@/common/types';
import { ETableStatus } from '@/common/constants';

import { authDataApiAdapter } from '../adapter';

export const handleGetAuthData = async ({
   launchToken,
   gameTableId,
   apiSlice,
   dispatch,
}: {
   dispatch: TListenerApiDispatchType;
   apiSlice: RTKQueryApi;
   launchToken: TLaunchToken;
   gameTableId: TGameTableId;
}) => {
   const bodyParams = {
      launchToken,
      gameTableId,
      requestScope: 'country, age, sex',
   };

   const authDataResponse = await dispatch(
      authAPI(apiSlice).endpoints.clientAuth.initiate(bodyParams),
   );

   const { data, isError, isException } = unwrapResponse(authDataResponse);

   if (isError || isException) {
      throw new Error(ETableStatus.Error);
   }

   const { isValid } = contractValidator({
      schema: clientAuthSchema,
      data,
      path: 'auth API',
   });

   if (!isValid) {
      throw new Error(ETableStatus.Error);
   }

   return authDataApiAdapter(data);
};
