import { TRoundWinners } from '@/common/services/sockets/roulette/useRoundWinners/schemes';
import { EGameState, useGameStateActions } from '@/common/state/gameTypes/roulette/gameState';

import { useWinnerListActions } from '#/modules/PlayersList/state';

export const useRoundWinnersEvent = (): ((winnerList: TRoundWinners) => void) => {
   const { handleSaveWinnerList } = useWinnerListActions();
   const { handleChangeGameState } = useGameStateActions();

   return (winnerList) => {
      handleChangeGameState(EGameState.ROUND_WINNERS);
      handleSaveWinnerList(winnerList);
   };
};
