// @ts-nocheck
import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  width: 100vw;
  height: 100vh;
  overflow: hidden;
`;

export const Element = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(var(--scale));

  @media (orientation: landscape) {
    aspect-ratio: 16 / 9;
  }

  ${({ standardSize }) => css`
    width: ${standardSize.width};
    height: ${standardSize.height};
  `}
`;
