import { ComponentProps, FC, forwardRef } from 'react';

import * as S from './Button.styles';

export const Button: FC<ComponentProps<'button'>> = forwardRef(
  ({ children, ...otherProps }, ref) => {
    return (
      <S.Button ref={ref} {...otherProps}>
        {children}
      </S.Button>
    );
  },
);

Button.displayName = 'CustomButton';
