import { LoadingScreen } from '@/common/modules/LoadingScreen';
import { EGameTypesName } from '@/common/constants';

import { useGameType } from '#/services/api/gameConfigApiSlice/state/gameConfig';

import * as S from './RouletteLoadingScreen.styles';
import { useLoading, useFakePercentLoader } from './hooks';

export const RouletteLoadingScreen = () => {
   const isScreenLoaded = useLoading();
   const percents = useFakePercentLoader();
   const { isAutoRoulette } = useGameType();
   const rouletteGameType = isAutoRoulette
      ? EGameTypesName.AutoRoulette
      : EGameTypesName.SingleZeroRoulette;

   return !isScreenLoaded ? (
      <S.RouletteLoadingScreen>
         <LoadingScreen percents={percents} type={rouletteGameType} />
      </S.RouletteLoadingScreen>
   ) : null;
};
