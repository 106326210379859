import { ETypeBet } from '@/common/roulette/constants';

import {
   CallBetCommand,
   Command,
   DoubleCommand,
   type ICallBetCommand,
   RangeStraightCommand,
} from '#/modules/Track/BettingMap/commands';
import { RangeCommandType } from '#/modules/Track/BettingMap/commands/RangeStraightCommand';
import { SpecialCommand } from '#/modules/Track/BettingMap/commands/special/SpecialCommand';
import { SpecialCommandOptions } from '#/modules/Track/BettingMap/commands/special/types';
import {
   TCallBet,
   TBetType,
   TCommonBet,
   TDoubleBet,
   TRangeStraightBet,
} from '#/state/features/bets';

export abstract class AbstractBetsCreator {
   // protected bets: Bets;

   protected calculateTotalAmountBets = (bets: TBetType[]): number => {
      return bets.reduce((total, bet) => total + (bet?.amount ?? 0), 0);
   };

   protected createCommonBet = (bet: Omit<TCommonBet, 'chips'>): TCommonBet => {
      const command = new Command(bet);

      return command.execute();
   };

   protected createSpecialBet = (
      specialCommand: SpecialCommandOptions,
   ): {
      type: ETypeBet.COMPLETE | ETypeBet.FINAL | ETypeBet.LUCKY;
      numbers: number[];
      extractedCommand: TCommonBet[];
      amount: number;
   } => {
      const command = new SpecialCommand().createCommand(specialCommand);

      return command.execute();
   };

   protected createCallBet = (callBetCommand: ICallBetCommand): TCallBet => {
      const command = new CallBetCommand(callBetCommand);

      return command.execute();
   };

   protected createdDoubleBet = (): TDoubleBet => {
      const command = new DoubleCommand();

      return command.execute();
   };

   protected createRangeStraightBet = ({
      numbers,
      amount,
      title,
   }: RangeCommandType): TRangeStraightBet => {
      const command = new RangeStraightCommand({ numbers, amount, title });

      return command.execute();
   };
}
