import { autoKeys } from '@/common/utils';

import { WinnerListTitle } from '../WinnerListTitle/WinnerListTitle';
import { IWinnerList } from '../types';
import { formatAmount } from '../../utils/formatAmount';

import * as S from './HorizontalWinnerList.styles';

export const HorizontalWinnerList = ({
   winnerList,
   currencySign,
   animationTriggers,
}: IWinnerList) => {
   if (!winnerList.length) {
      return null;
   }

   return (
      <S.HorizontalWinnerListContainer {...animationTriggers}>
         <WinnerListTitle winnerList={winnerList} />
         <S.HorizontalWinnerListInner>
            {autoKeys(
               winnerList.map(({ amount, username }) => {
                  return (
                     <S.WinnerListItem>
                        <S.Amount>{formatAmount({ amount, currencySign })}</S.Amount>
                        <S.Username>{username}</S.Username>
                     </S.WinnerListItem>
                  );
               }),
            )}
         </S.HorizontalWinnerListInner>
      </S.HorizontalWinnerListContainer>
   );
};
