import { cellsColorConfig } from '@/common/roulette/colors';
import { ECellColor } from '@/common/roulette/constants';

const POCKET_NUMBER: Record<string, number> = {
   ROTATE_TEXT: 45,
   LAST_INNER_CELL_ID: 36,
};

const colorsConfig: Record<ECellColor, string> = {
   [ECellColor.Red]: 'rgba(255, 0, 0, 1)',
   [ECellColor.Black]: 'rgba(255, 255, 255, 1)',
   [ECellColor.Green]: 'rgba(35, 176, 78, 1)',
};

export const getCellColor = (number: number | string): string => {
   const cellColor = cellsColorConfig[number];

   return colorsConfig[cellColor];
};

export const rotatedText = (cellId: number) => cellId > POCKET_NUMBER.ROTATE_TEXT;
export const smallText = (cellId: number) => cellId > POCKET_NUMBER.LAST_INNER_CELL_ID;
