import { FC, SyntheticEvent } from 'react';

import TEST_VIDEO from './assets/test-video.mp4';
import * as S from './TestVideo.styles';

export const TestVideo: FC<{
   onSuccessPlay: () => void;
   onErrorPlay: () => void;
}> = ({ onSuccessPlay, onErrorPlay }) => (
   <S.TestVideo
      autoPlay
      muted
      playsInline
      src={TEST_VIDEO}
      onPlay={onSuccessPlay}
      onSuspend={(event: SyntheticEvent<HTMLVideoElement, Event>) => {
         event.currentTarget.play().then(onSuccessPlay).catch(onErrorPlay);
      }}
   />
);
