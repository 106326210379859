import { ETypeBet } from '@/common/roulette/constants';

export const getCallBetIdByType = (type: ETypeBet) => {
   const idx = {
      [ETypeBet.ORPHELINS]: 157,
      [ETypeBet.JEU_ZERO]: 158,
      [ETypeBet.VOISIN]: 159,
      [ETypeBet.TIERS]: 160,
   };

   return idx[type];
};

export const getAllCallBets = (): ETypeBet[] => [
   ETypeBet.ORPHELINS,
   ETypeBet.JEU_ZERO,
   ETypeBet.VOISIN,
   ETypeBet.TIERS,
];
