import { FC } from 'react';

import { INotification, useNotifications } from '../state';
import { useDelayCallback } from '../../../hooks';

import { NotificationTemplate } from './NotificationTemplate';

interface INotificationProps extends INotification {
   handleCloseNotification: () => void;
}
export const Notification: FC<INotificationProps> = (props) => {
   const { handleRemoveNotification } = useNotifications();

   const NOTIFICATION_DURATION = 3_000;
   useDelayCallback({
      callback: handleRemoveNotification,
      delay: NOTIFICATION_DURATION,
   });

   return <NotificationTemplate {...props} handleCloseNotification={handleRemoveNotification} />;
};
