import { BetConfig } from 'core/config/BetConfig';
import { roulettePocketsList } from 'core/roulette';

import { ETypeBet } from '@/common/roulette/constants';

class CornerBetsConfig extends BetConfig {
   protected readonly type: ETypeBet = ETypeBet.CORNER;
   protected readonly multiplier = 4;
   readonly config = this.generateConfig({
      pocketsList: roulettePocketsList,
      startId: 50,
   });

   public getConfig = () => {
      return this.config.map((configItem) => ({ ...configItem, type: this.type }));
   };

   public getIds = () => {
      return this.config.map(({ id }) => id);
   };

   generateConfig({ pocketsList, startId }: { pocketsList: number[]; startId: number }) {
      const config = [{ id: 49, numbers: [0, 1, 2, 3], type: this.type }];
      const whenLastCellId = (i: number) => i > 33;
      const whenSkipCellId = (i: number) => i % 3 === 0;

      for (let i = 0; i <= pocketsList?.length - 1; i++) {
         if (whenSkipCellId(i) || whenLastCellId(i)) {
            continue;
         }

         const { [i]: first, [i + 1]: second, [i + 3]: third, [i + 4]: fourth } = pocketsList;

         const numbersChunk = [first, second, third, fourth];

         config.push({
            id: startId,
            numbers: numbersChunk,
            type: this.type,
         });

         // TODO: fix mutation
         // eslint-disable-next-line no-param-reassign
         startId++;
      }

      return config;
   }
}

export const cornerConfig = new CornerBetsConfig();
