import { useEffect, useState } from 'react';

import { TNullable, TSetTimeout } from '@/common/types';
import { useGameState } from '@/common/state/gameTypes/roulette/gameState';

import { ANIMATION_DURATION } from '../../constants';

export const useWinNumberAnimation = () => {
   const [isStartWinNumberAnimation, setIsStartWinNumberAnimation] = useState(false);
   const { isWinNumberState } = useGameState();

   const handleStartAnimation = () => setIsStartWinNumberAnimation(true);
   const handleEndAnimation = () => setIsStartWinNumberAnimation(false);

   useEffect(() => {
      let animationTimeout: TNullable<TSetTimeout> = null;

      if (animationTimeout) {
         clearTimeout(animationTimeout);
      }

      if (isWinNumberState) {
         handleStartAnimation();
         animationTimeout = setTimeout(handleEndAnimation, ANIMATION_DURATION);
      }
   }, [isWinNumberState]);

   return { isStartWinNumberAnimation };
};
