import { EBlackJackGameStep } from '@/common/blackjack/adapters/gameStateAdapter/constants';
import { humanizeIndex } from '@/common/utils/humanizeIndex';
import { TGameState, TGameStatus } from '@/common/blackjack/adapters/gameStateAdapter/types';

const gameStepMap = {
   [EBlackJackGameStep.AwaitCard]: 'DEALING FOR: ',
   [EBlackJackGameStep.PlayerDecision]: 'DECISION TIME: ',
   [EBlackJackGameStep.DealCards]: 'INITIAL CARD DEALING: DRAW CARD FOR ',
};

export const getPersonalGameStep = ({
   gameState,
   gameStep,
   gameStatus,
}: {
   gameState: TGameState;
   gameStep: EBlackJackGameStep;
   gameStatus: TGameStatus;
}) => {
   const splitView = `SEAT ${humanizeIndex(gameState.currentSeat)} | Hand ${humanizeIndex(gameState.currentHand)}`;
   const oneHandView = `SEAT ${humanizeIndex(gameState.currentSeat)}`;
   const lastDecisionView = ` | ${gameState.seats[gameState.currentSeat]?.seatLastDecision}`;

   const seat = gameState.seats[gameState.currentSeat]?.isSplit ? splitView : oneHandView;

   const seatLastDecision =
      gameState.seats[gameState.currentSeat]?.seatLastDecision && gameStatus.isAwaitingCard // we need to show last decision only if we are awaiting card so dealer can see what he/she should do
         ? lastDecisionView
         : '';

   const personalGameStep = gameStepMap[gameStep];

   if (!personalGameStep) {
      return null;
   }

   if (gameState.isDealerPlaying && personalGameStep) {
      return `${personalGameStep}DEALER`;
   }

   return `${personalGameStep}${seat}${seatLastDecision}`;
};
