import { getNearElements } from '@/common/helpers';

interface IActiveElementsIndexes {
   classList: DOMTokenList;
   rangeLevel: number;
}
export const getActiveElementsIndexes = ({
   classList,
   rangeLevel,
}: IActiveElementsIndexes): number[] => {
   const elementClassName = classList[0];
   const currentNumber = parseInt(elementClassName.replace(/\D/g, ''), 10);

   return getNearElements({ currentNumber, rangeLevel });
};
