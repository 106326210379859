import { PayloadAction, createSelector, createSlice } from '@reduxjs/toolkit';

import { gameSettings } from '@/common/helpers';
import { ETimerType } from '@/common/modules/CountDown';
import {
   defaultBjSeatConfig,
   EBJBetType,
   defaultBjColorsConfig,
   ESeatBetsConfig,
} from '@/common/blackjack/constants';

import { useAppDispatch, useAppSelector } from '#/state/hooks';

import { IGameConfig } from './types';

const initialState: IGameConfig = {
   mapChipValueById: {},
   gameLanguage: 'en',
   launchToken: gameSettings.launchToken,
   gameTableId: gameSettings.gameTableId,
   physicalTableId: '',
   colors: {
      topBackgroundColor: defaultBjColorsConfig.topBackgroundColor,
      bottomBackgroundColor: defaultBjColorsConfig.bottomBackgroundColor,
      freeSeatColor: defaultBjColorsConfig.freeSeatColor,
      seatBetSectionColor: defaultBjColorsConfig.seatBetSectionColor,
      seatHighlightColor: defaultBjColorsConfig.seatHighlightColor,
      otherSeatHighlightColor: defaultBjColorsConfig.otherSeatHighlightColor,
      seatHoverBetSectionColor: defaultBjColorsConfig.seatHoverBetSectionColor,
      seatDisabledBetSectionColor: defaultBjColorsConfig.seatDisabledBetSectionColor,
   },
   gameConfig: {
      socketUrl: '',
      lobbyRedirectUrl: gameSettings.lobbyRedirectUrl,
      title: '',
      streamId: null,
      chipTray: [],
      gameType: {
         id: '',
         name: '',
         title: '',
      },
      currency: {
         name: '',
         symbol: '',
      },
      timer: {
         type: ETimerType.HORIZONTAL,
         bettingTime: null,
         decisionTime: null,
         insuranceTime: null,
      },
      seatBetsConfig: {
         [ESeatBetsConfig.bj21Plus3]: false,
         [ESeatBetsConfig.bjBetBehind]: false,
         [ESeatBetsConfig.bjPerfectPairs]: false,
         [ESeatBetsConfig.bjTop3]: false,
         [ESeatBetsConfig.bjMaxSeatsPerUser]:
            defaultBjSeatConfig[ESeatBetsConfig.bjMaxSeatsPerUser],
         [ESeatBetsConfig.bjDealNowLimit]: defaultBjSeatConfig[ESeatBetsConfig.bjDealNowLimit],
      },
      channelId: '',
      isGameTableActive: false,
      isPhysicalTableUnderMaintenance: false,
      betTypeLimits: {
         [EBJBetType.Ante]: { min: 0, max: 0 },
         [EBJBetType.BetBehind]: { min: 0, max: 0 },
         [EBJBetType.PerfectPairsBet]: { min: 0, max: 0 },
         [EBJBetType.TwentyOnePlusThreeBet]: { min: 0, max: 0 },
      },
   },
};

export const gameConfigSlice = createSlice({
   name: 'gameConfig',
   initialState,
   reducers: {
      setGameConfig: (state, action: PayloadAction<IGameConfig['gameConfig']>) => {
         state.gameConfig = action.payload;
      },
      setColorsConfig: (state, action: PayloadAction<Partial<IGameConfig['colors']>>) => {
         state.colors = { ...state.colors, ...action.payload };
      },
      setPhysicalTableId: (state, action: PayloadAction<IGameConfig['physicalTableId']>) => {
         state.physicalTableId = action.payload;
      },
      setGameLanguage: (state, action: PayloadAction<IGameConfig['gameLanguage']>) => {
         state.gameLanguage = action.payload;
      },

      setMapChipValueById: (state, action: PayloadAction<IGameConfig['mapChipValueById']>) => {
         state.mapChipValueById = action.payload;
      },
   },
   selectors: {
      getGameLanguage: (state) => state.gameLanguage,
      getGameConfig: (state) => state.gameConfig,
      getColorsConfig: (state) => state.colors,
      getBetTypeLimits: (state) => state.gameConfig.betTypeLimits,
      getGameTableId: (state) => state.gameTableId,
      getTimersConfig: (state) => state.gameConfig.timer,
      getPhysicalTableId: (state) => state.physicalTableId,
   },
});

export const useGameConfigActions = () => {
   const dispatch = useAppDispatch();
   const { setGameLanguage, setGameConfig, setMapChipValueById } = gameConfigSlice.actions;

   const handleSetMapChipValueById = (mapChipValueById: IGameConfig['mapChipValueById']): void => {
      dispatch(setMapChipValueById(mapChipValueById));
   };

   const handleSetGameConfig = (gameConfig: IGameConfig['gameConfig']): void => {
      dispatch(setGameConfig(gameConfig));
   };

   const handleSetGameLanguage = (gameLanguage: IGameConfig['gameLanguage']): void => {
      dispatch(setGameLanguage(gameLanguage));
   };

   return {
      handleSetMapChipValueById,
      handleSetGameLanguage,
      handleSetGameConfig,
   };
};

export const useGameLanguageSelector = () =>
   useAppSelector(gameConfigSlice.selectors.getGameLanguage);

export const useColorsSelector = () => useAppSelector(gameConfigSlice.selectors.getColorsConfig);

export const usePhysicalTableIdSelector = () =>
   useAppSelector(gameConfigSlice.selectors.getPhysicalTableId);
export const useGameConfigSelector = () => useAppSelector(gameConfigSlice.selectors.getGameConfig);
export const useSeatBetsConfigSelector = () =>
   useAppSelector(
      createSelector(
         gameConfigSlice.selectors.getGameConfig,
         (gameConfig) => gameConfig.seatBetsConfig,
      ),
   );
export const useTimersConfigSelector = () =>
   useAppSelector(
      createSelector(gameConfigSlice.selectors.getTimersConfig, (timersConfig) => timersConfig),
   );

export const useBetTypeLimitsSelector = () =>
   useAppSelector(gameConfigSlice.selectors.getBetTypeLimits);
export const useGameTableIdSelector = () =>
   useAppSelector(gameConfigSlice.selectors.getGameTableId);
