// @ts-nocheck
import styled from 'styled-components/macro';

export const UnClickedElement = styled.div`
   pointer-events: none;
   filter: grayscale(1);

   > * {
      pointer-events: none !important;
   }
`;
