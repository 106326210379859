import { TUnstableServerType } from '@/common/blackjack/types';
import { Suit } from '@/common/cardGames/ui/Card/types';

// TODO: transform this into a class
export const cardSuitAdapter = (cardSuit: TUnstableServerType): Suit => {
   const map = {
      hearts: Suit.HEARTS,
      clubs: Suit.CLUBS,
      spades: Suit.SPADES,
      diamonds: Suit.DIAMONDS,
   };

   return map[cardSuit];
};
