import { FC } from 'react';

import { Chip } from '@/common/ui/Chip';
import { TChip } from '@/common/ui/Chip/entities';
import { autoKeys } from '@/common/utils/react/autoKeys';

import { Slider, Slide } from '../../../ui/Slider';
import { IChipTrayProps } from '../types';

import {
   useSwiperControls,
   useSlideToSelectedChip,
   useSortChipsByChipTrayDirection,
} from './hooks';
import * as S from './ChipTray.styles';

export const ChipTray: FC<IChipTrayProps> = ({
   chips,
   selectedChip,
   chipSize = 65,
   handleSelectChip,
   slidesPerView = 7,
   direction = 'horizontal',
   spaceBetween = 5,
}) => {
   const isVerticalSlider = direction === 'vertical';
   const { swiper, setSwiper, handleNextSlide, handlePreviousSlide } = useSwiperControls();
   const { chipsList, selectedChipIndex } = useSortChipsByChipTrayDirection({
      isVerticalSlider,
      chips,
      selectedChip,
   });
   useSlideToSelectedChip({
      swiper,
      selectedChipIndex,
   });

   return (
      <S.ChipTrayWrapper data-testid="chipTray-container" isVertical={isVerticalSlider}>
         <S.PreviousSlideControl
            data-testid="chiptray-previous-control"
            className="chiptray-previous-control"
            role="chiptray-control"
            onClick={handlePreviousSlide}
         />
         <Slider
            direction={direction}
            spaceBetween={spaceBetween}
            slidesPerView={slidesPerView}
            onSwiper={setSwiper}
         >
            {autoKeys(
               chipsList.map(({ id, value, primaryColor }: TChip) => {
                  const isSelectedChip = selectedChip.value === value;

                  return (
                     <Slide
                        onClick={() => {
                           handleSelectChip({ id, value, primaryColor });
                        }}
                     >
                        <S.ChipWrapper
                           data-testid={`chip-tray-item-${id}`}
                           isSelectedChip={isSelectedChip}
                           className={isSelectedChip ? 'chiptray__selected-chip' : ''}
                        >
                           <Chip
                              chips={chips}
                              chipNumber={value}
                              width={chipSize}
                              height={chipSize}
                           />
                        </S.ChipWrapper>
                     </Slide>
                  );
               }),
            )}
         </Slider>
         <S.NextSlideControl
            data-testid="chiptray-next-control"
            className="chiptray-next-control"
            role="chiptray-control"
            onClick={handleNextSlide}
         />
      </S.ChipTrayWrapper>
   );
};
