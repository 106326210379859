import { useCurrency } from 'hooks/useCurrency';

import { WinAmountView, FadeBox } from '@/common/ui';
import { TNullable } from '@/common/types';
import { isPortraitMode, useDeviceOrientation } from '@/common/hooks';

import { useTranslation } from '#/translates';
import { FADE_DURATION } from '#/constants';

import { useWin } from './state';
import { ANIMATION_DURATION } from './constants';
import { useWinSumAnimation } from './hooks/useWinSumAnimation';
import * as S from './WinSum.styles';

export const WinSum = (): TNullable<JSX.Element> => {
   const { t } = useTranslation();
   const formatCurrency = useCurrency();
   const { winSum } = useWin();
   const { isStartWinSumAnimation } = useWinSumAnimation();
   const isPortrait = isPortraitMode(useDeviceOrientation());

   return (
      <FadeBox isVisible={isStartWinSumAnimation} duration={FADE_DURATION}>
         <S.WinAmountViewContainer>
            <WinAmountView
               animationDuration={ANIMATION_DURATION}
               content={t('youWin')}
               count={formatCurrency(winSum)}
               isPortrait={isPortrait}
            />
         </S.WinAmountViewContainer>
      </FadeBox>
   );
};
