/// <reference types="vite-plugin-svgr/client" />
import { EControl } from '../../constants';

import Autoplay from './autoplay.svg?react';
import BettingMap from './betting-map.svg?react';
import Chat from './chat.svg?react';
import Menu from './menu.svg?react';
import Mute from './mute-volume.svg?react';
import Favourite from './favourite.svg?react';
import RaceTrack from './racetrack.svg?react';
import Remove from './remove.svg?react';
import Statistics from './statistics.svg?react';
import Unmute from './unmute-volume.svg?react';
import Video from './video.svg?react';
import CollapseExpand from './collapse-expand.svg?react';
import Incognito from './incognito.svg?react';
import Collapse from './collapse.svg?react';

export const icons = {
  [EControl.Menu]: Menu,
  [EControl.Chat]: Chat,
  [EControl.Remove]: Remove,
  [EControl.Play]: Autoplay,
  [EControl.Favourite]: Favourite,
  [EControl.Statistics]: Statistics,
  [EControl.MuteVolume]: Mute,
  [EControl.ToggleVideo]: Video,
  [EControl.Racetrack]: RaceTrack,
  [EControl.UnmuteVolume]: Unmute,
  [EControl.BettingMap]: BettingMap,
  [EControl.Collapse]: Collapse,
  [EControl.CollapseExpand]: CollapseExpand,
  [EControl.Incognito]: Incognito,
};
