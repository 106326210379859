import { FC, ReactElement, ReactNode } from 'react';

import { useGameState } from '@/common/state/gameTypes/roulette/gameState';
import { TooltipPortal } from '@/common/modules';

import { useTranslation } from '#/translates';

interface IWaitForTheNextStateTooltip {
   children: ReactElement<ReactNode>;
}
export const WaitForTheNextStateTooltip: FC<IWaitForTheNextStateTooltip> = ({ children }) => {
   const { t } = useTranslation();
   const { isBettingTimeState, isLastBetsState } = useGameState();
   const isSuppressTooltip = isBettingTimeState || isLastBetsState;

   return (
      <TooltipPortal text={t('waitForNextRound')} suppressTooltip={isSuppressTooltip}>
         {children}
      </TooltipPortal>
   );
};
