import { logger } from '@/common/utils';
import { betsAPI } from '@/common/services/api';
import { notificationsSlice } from '@/common/modules/Notifications';
import { clientApiSlice } from '@/common/services/clientAPI/clientApiSlice';
import { ENotificationIcons } from '@/common/modules/Notifications/constants';

import { createAutoplay } from '#/modules/Autoplay/hooks';
import { balanceSlice } from '#/modules/Balance';
import { betConfirmedSlice } from '#/modules/BetConfirmedNotification';
import { sendBetsApiAdapter } from '#/services/api/betsApiSlice/sendBetsApiAdapter';
import { betsSlice } from '#/state/features/bets';
import { TRootState, Store } from '#/state/types';
import { translation } from '#/translates';

const sendBetsRequest = async (options: {
   request: (...args: unknown[]) => Promise<{ data: unknown } | { error: unknown }>;
   onSuccess: (data: unknown) => void;
   onError: (error: unknown) => void;
}) => {
   try {
      const response = await options.request();
      const responseHasError = 'error' in response;
      responseHasError ? options.onError(response.error) : options.onSuccess(response.data);
   } catch (error) {
      logger.error(error);
   }
};

const showErrorNotification = (state: TRootState, dispatch: Store['dispatch']) => {
   const { autoplay } = createAutoplay(dispatch, state.autoplay);

   const currentBalance = Number(state.balance.balance);
   const betsAmount = Object.values(state.bets.totalBets).reduce((total, bet) => total + bet, 0);
   const updatedBalance = currentBalance + betsAmount;

   dispatch(betsSlice.actions.resetBets());
   dispatch(balanceSlice.actions.updateBalanceFromServer(updatedBalance));
   dispatch(
      notificationsSlice.actions.addNotification({
         title: translation('notifications.betConfirmed.error.title'),
         action: '',
         content: translation('notifications.betConfirmed.error.content'),
         type: ENotificationIcons.Error,
      }),
   );
   autoplay.cancelAutoplayFromUI();
};

const onBetConfirmation = (dispatch: Store['dispatch']) => {
   dispatch(betConfirmedSlice.actions.showNotification());
};

export const handleSendBets = (_, listenerApi): void => {
   const { dispatch } = listenerApi;
   const betsQuery = betsAPI(clientApiSlice).endpoints.bets.initiate;
   const state = listenerApi.getOriginalState() as TRootState;
   const dataWithBets = sendBetsApiAdapter(state, dispatch);
   const hasDataForSend = dataWithBets && dataWithBets?.bets?.length > 0;

   if (hasDataForSend) {
      sendBetsRequest({
         request: () => dispatch(betsQuery({ bets: dataWithBets })),
         onSuccess: () => onBetConfirmation(dispatch),
         onError: () => showErrorNotification(state, dispatch),
      });
   }
};
