import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { useDispatch, useSelector } from 'react-redux';

import { TNullable } from '@/common/types';
import { ETableStatus, ESessionClosedReason } from '@/common/constants';

interface ITableStatusState {
   isNotifyInactivePlayer: boolean;
   tableStatus: TNullable<ETableStatus>;
}

const initialState: ITableStatusState = {
   tableStatus: null,
   isNotifyInactivePlayer: false,
};

export const tableStatusSlice = createSlice({
   name: 'tableStatus',
   initialState,
   reducers: {
      setNotifyInactivePlayer: (state) => {
         state.isNotifyInactivePlayer = true;
      },
      unsetNotifyInactivePlayer: (state) => {
         state.isNotifyInactivePlayer = false;
      },

      setErrorTable: (state) => {
         state.tableStatus = ETableStatus.Error;
      },

      setMaintenanceIsPending: (state) => {
         state.tableStatus = ETableStatus.MaintenanceIsPending;
      },

      setMaintenanceInProgress: (state) => {
         state.tableStatus = ETableStatus.MaintenanceInProgress;
      },

      setMaintenanceEnded: (state) => {
         state.tableStatus = ETableStatus.MaintenanceIsEnded;
      },

      setInactiveTable: (state) => {
         state.tableStatus = ETableStatus.InactiveTable;
      },

      setSessionClosedByReason: (state, action: PayloadAction<ESessionClosedReason>) => {
         const tableStatusBySessionClosedReason = {
            [ESessionClosedReason.KickInactiveGameTablePlayer]: ETableStatus.KickInactivePlayer,
            [ESessionClosedReason.LoginFromAnotherPlace]: ETableStatus.LoginFromAnotherPlace,
         };

         state.tableStatus = tableStatusBySessionClosedReason[action.payload];
      },
      resetTableStatus: (state) => {
         state.tableStatus = null;
      },
   },
   selectors: {
      getTableStatus: (state) => state.tableStatus,
      getIsNotifyInactivePlayer: (state) => state.isNotifyInactivePlayer,
   },
});

export const useTableStatusActions = () => {
   const {
      setInactiveTable,
      setMaintenanceEnded,
      setMaintenanceInProgress,
      setMaintenanceIsPending,
      unsetNotifyInactivePlayer,
      setNotifyInactivePlayer,
      setSessionClosedByReason,
      resetTableStatus,
   } = tableStatusSlice.actions;
   const dispatch = useDispatch();

   const handleSetInactiveTable = (): void => {
      dispatch(setInactiveTable());
   };
   const handleSetNotifyInactivePlayer = () => {
      dispatch(setNotifyInactivePlayer());
   };
   const handleUnsetNotifyInactivePlayer = () => {
      dispatch(unsetNotifyInactivePlayer());
   };

   const handleSetSessionClosed = (reason: ESessionClosedReason) => {
      dispatch(setSessionClosedByReason(reason));
   };

   const handleSetMaintenanceIsPending = (): void => {
      dispatch(setMaintenanceIsPending());
   };
   const handleSetMaintenanceEnded = (): void => {
      dispatch(setMaintenanceEnded());
   };
   const handleSetMaintenanceInProgress = (): void => {
      dispatch(setMaintenanceInProgress());
   };
   const handleResetTableStatus = (): void => {
      dispatch(resetTableStatus());
   };

   return {
      handleSetInactiveTable,
      handleSetMaintenanceIsPending,
      handleSetMaintenanceEnded,
      handleSetMaintenanceInProgress,
      handleSetNotifyInactivePlayer,
      handleUnsetNotifyInactivePlayer,
      handleSetSessionClosed,
      handleResetTableStatus,
   };
};

export const useTableStatusSelector = () => {
   const tableStatus = useSelector(tableStatusSlice.selectors.getTableStatus);
   const isNotifyInactivePlayer = useSelector(tableStatusSlice.selectors.getIsNotifyInactivePlayer);

   return {
      tableStatus,
      isError: tableStatus === ETableStatus.Error,
      isMaintenanceStarted:
         tableStatus === ETableStatus.MaintenanceIsPending ||
         tableStatus === ETableStatus.MaintenanceInProgress,
      isMaintenancePending: tableStatus === ETableStatus.MaintenanceIsPending,
      isMaintenanceInProgress: tableStatus === ETableStatus.MaintenanceInProgress,
      isMaintenanceEnded: tableStatus === ETableStatus.MaintenanceIsEnded,
      isInactiveTable: tableStatus === ETableStatus.InactiveTable,
      isLoginFromAnotherPlace: tableStatus === ETableStatus.LoginFromAnotherPlace,
      isSessionClosedDueToInactivity: tableStatus === ETableStatus.KickInactivePlayer,
      isNotifyInactivePlayer,
      ...useTableStatusActions(),
   };
};

export const useNotifyInactivePlayer = () =>
   useSelector(tableStatusSlice.selectors.getIsNotifyInactivePlayer);
