// @ts-nocheck
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { useDispatch, useSelector } from 'react-redux';

import { TNullable } from '../../../types';

interface ITooltipState {
   openTooltipId: TNullable<string>;
}

const initialState: ITooltipState = {
   openTooltipId: null,
};

export const tooltipPortalSlice = createSlice({
   name: 'tooltip-portal',
   initialState,
   reducers: {
      updateTooltip: (state, action: PayloadAction<TNullable<string>>): void => {
         state.openTooltipId = action.payload;
      },
   },
   selectors: {
      getOpenTooltipId: (state) => state.openTooltipId,
   },
});

export const useTooltipPortalActions = () => {
   const dispatch = useDispatch();
   const { updateTooltip } = tooltipPortalSlice.actions;

   const handleUpdateTooltip = (openTooltipId: TNullable<string>): void => {
      dispatch(updateTooltip(openTooltipId));
   };

   return { handleUpdateTooltip };
};

export const useTooltipPortalSelector = () =>
   useSelector((state) => state?.tooltipPortal?.openTooltipId);
