import { BetConfig } from 'core/config/BetConfig';

import { rouletteRedColorNumbers } from '@/common/roulette/colors';
import { ETypeBet } from '@/common/roulette/constants';

class RedBetConfig extends BetConfig {
   protected readonly type: ETypeBet = ETypeBet.RED;
   readonly config = [
      {
         id: 42,
         value: 'bettingMap.red',
         numbers: rouletteRedColorNumbers,
      },
   ];
}

export const redConfig = new RedBetConfig();
