import { FC } from 'react';

import { Overlay } from '@/common/ui';
import { EModalNotificationType } from '@/common/ui/ModalNotification/constants';

interface ILoginFromAnotherPlaceOverlayProps {
   buttonTitle: string;
   modalTitle: string;
   onClick: () => void;
}

export const LoginFromAnotherPlaceOverlay: FC<ILoginFromAnotherPlaceOverlayProps> = ({
   buttonTitle,
   modalTitle,
   onClick,
}) => (
   <Overlay
      type={EModalNotificationType.Warning}
      title={modalTitle}
      actionButton={{
         title: buttonTitle,
         onClick,
      }}
   />
);
