import { BetConfig } from 'core/config/BetConfig';
import { roulettePocketsList } from 'core/roulette';

import { ETypeBet } from '@/common/roulette/constants';

import { splitPocketsByChunk } from '#/core/config/utils/splitPocketsByChunk';

class LineBetsConfig extends BetConfig {
   protected readonly multiplier: number = 6;
   protected readonly type: ETypeBet = ETypeBet.LINE;
   readonly config = this.generateConfig({
      pocketsList: roulettePocketsList,
      chunkSize: this.multiplier,
      startId: 86,
   });

   generateConfig({
      pocketsList,
      chunkSize,
      startId,
   }: {
      pocketsList: number[];
      chunkSize: number;
      startId: number;
   }) {
      return splitPocketsByChunk({
         pocketsList,
         chunkSize,
         startId,
         type: this.type,
         rouletteMapRows: 3,
      });
   }
}

export const lineConfig = new LineBetsConfig();
