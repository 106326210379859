import { TListenerApiDispatchType } from '@/common/types';
import { clientApiSlice } from '@/common/services/clientAPI/clientApiSlice';
import { addSentryTags } from '@/common/tracing/setupSentry';
import { handleGetAuthData } from '@/common/services/api/authAPI';
import { RTKQueryApi } from '@/common/services/api/types';

import { authSlice } from '#/services/api/authApiSlice/authSlice';
import { balanceSlice } from '#/modules/Balance';
import { TRootState } from '#/state/types';

export const getAuthData = async (dispatch: TListenerApiDispatchType, getState: () => unknown) => {
   const { gameConfig } = getState() as TRootState;
   const { gameTableId, launchToken } = gameConfig.gameConfig;

   const authData = await handleGetAuthData({
      dispatch,
      gameTableId,
      launchToken,
      apiSlice: clientApiSlice as RTKQueryApi,
   });

   if (authData) {
      const { user, session } = authData;
      const { id: userId, username: userName, totalBalance } = user;

      addSentryTags({ userId, userName });

      dispatch(authSlice.actions.updateUserAndSession({ user, session }));
      dispatch(balanceSlice.actions.updateInitialBalance(totalBalance));
      dispatch(balanceSlice.actions.updateBalanceFromServer(totalBalance));
   }
};
