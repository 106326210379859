import { useGameState } from '@/common/state/gameTypes/roulette/gameState';

import { Loader } from '#/modules/Loader';
import { useTranslation } from '#/translates';

import * as S from './ConnectionLoadingScreen.styles';

export const ConnectionLoadingScreen = () => {
   const { t } = useTranslation();

   const { isWaitingForConnectionGameStatus } = useGameState();
   if (!isWaitingForConnectionGameStatus) {
      return null;
   }

   return (
      <>
         <S.Mask data-testid="connection" />
         <Loader title={t('connection.waitForConnection')} />
      </>
   );
};
