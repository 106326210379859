import { EBetControl } from '@/common/ui';
import { useLongPress } from '@/common/hooks';
import { IPlayChipSound } from '@/common/entities';

import { betsCreator } from '#/core/betsCreator';
import { useAutoplay } from '#/modules/Autoplay/hooks';
import { withChipSound } from '#/hocs';

import { BetControl } from '../BetControl';

export const UndoControl = ({ playChipSound }: IPlayChipSound) => {
   const { autoplay } = useAutoplay();

   const handleOnClick = (callback: () => void): void => {
      callback();
      autoplay.cancelAutoplayFromUI();
   };

   const onLongPress = useLongPress({
      callback: () => handleOnClick(betsCreator?.resetBets),
      delay: 500,
   });

   return (
      <BetControl
         dataTestId="undo-control"
         color="#E41D1D"
         variant={EBetControl.Undo}
         onClick={() => handleOnClick(betsCreator.removeLastBet)}
         disabled={!betsCreator?.hasPlacedBets?.()}
         playClickSound={playChipSound}
         {...onLongPress}
      />
   );
};

export const UndoControlWithChipSound = withChipSound(UndoControl);
