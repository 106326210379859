import { ETypeBet } from '@/common/roulette/constants';

import { TCommonBet } from '#/state/features/bets';

import { IBetAdapter } from '../../types';

import {
   columnAdapter,
   cornerAdapter,
   dozenAdapter,
   evenOrOddAdapter,
   highOrLowAdapter,
   lineAdapter,
   redOrBlackAdapter,
   splitAdapter,
   straightAdapter,
   streetAdapter,
} from './adapters';

type SendBetType = Omit<TCommonBet, 'chips'> & { chips: string[] };

export const transformBetByAdapter = (bets: SendBetType[]): IBetAdapter[] => {
   const mapAdapters = {
      [ETypeBet.STRAIGHT]: straightAdapter,
      [ETypeBet.RED]: redOrBlackAdapter,
      [ETypeBet.BLACK]: redOrBlackAdapter,
      [ETypeBet.EVEN]: evenOrOddAdapter,
      [ETypeBet.ODD]: evenOrOddAdapter,
      [ETypeBet.DOZEN]: dozenAdapter,
      [ETypeBet.COLUMN]: columnAdapter,
      [ETypeBet.LOW]: highOrLowAdapter,
      [ETypeBet.HIGH]: highOrLowAdapter,
      [ETypeBet.CORNER]: cornerAdapter,
      [ETypeBet.LINE]: lineAdapter,
      [ETypeBet.STREET]: streetAdapter,
      [ETypeBet.SPLIT]: splitAdapter,
   };

   const transformBet = (bet: SendBetType) => {
      const adapterFunction = mapAdapters?.[bet.type];
      return adapterFunction(bet);
   };

   return bets.map(transformBet);
};
