import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { useDispatch, useSelector } from 'react-redux';

export interface IUserGeneralSettingState {
   isDealerFeedbackEnabled: boolean;
}

const initialState: IUserGeneralSettingState = {
   isDealerFeedbackEnabled: false,
};

export const userGeneralSettingsSlice = createSlice({
   name: 'userGeneralSettings',
   initialState,
   reducers: {
      setIsDealerRatingEnabled: (
         state,
         action: PayloadAction<IUserGeneralSettingState['isDealerFeedbackEnabled']>,
      ) => {
         state.isDealerFeedbackEnabled = action.payload;
      },
   },
   selectors: {
      getDealerRatingEnabled: (state) => state.isDealerFeedbackEnabled,
   },
});

export const useUserGeneralSettingsState = () => {
   const isDealerRatingEnabled = useSelector(
      userGeneralSettingsSlice.selectors.getDealerRatingEnabled,
   );

   return {
      isDealerRatingEnabled,
   };
};

export const useUserGeneralSettingsActions = () => {
   const dispatch = useDispatch();
   const { setIsDealerRatingEnabled } = userGeneralSettingsSlice.actions;

   const handleSetIsDealerRatingEnabled = (isRatingEnabled: boolean) => {
      dispatch(setIsDealerRatingEnabled(isRatingEnabled));
   };

   return {
      handleSetIsDealerRatingEnabled,
   };
};

export const useUserGeneralSettings = () => ({
   ...useUserGeneralSettingsState(),
   ...useUserGeneralSettingsActions(),
});
