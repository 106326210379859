export const enum EBlackJackActions {
   takeSeat = 'takeSeat',
   leaveSeat = 'leaveSeat',
   placeBet = 'placeBet',
   playerAction = 'playerAction',
   earlyAction = 'earlyAction',
   insuranceDecision = 'insuranceDecision',
   doubleAllBets = 'doubleAllBets',
   undoAllBets = 'undoAllBets',
   undoBet = 'undoBet',
   reBet = 'reBet',
   dealerFeedbackLeft = 'dealerFeedbackLeft',
   flagDealNow = 'flagDealNow',
   unFlagDealNow = 'unflagDealNow',
   userGameTypeSettingsUpdated = 'userGameTypeSettingsUpdated',
}

export const enum EPlayerAction {
   Hit = 'HIT',
   Stand = 'STAND',
   Split = 'SPLIT',
   Double = 'DOUBLE',
   Insured = 'INSURANCE',
   Insure = 'INSURE',
   NotInsure = 'NOT_INSURE',
}
