import { FC } from 'react';

import { Portal } from '../../../Portal';

import { WarningIcon } from './assets';
import * as S from './WarningTooltip.styles';

interface IWarningTooltipProps {
  message: string;
  selector: string;
  shouldShowNotification: boolean;
}

export const WarningTooltip: FC<IWarningTooltipProps> = ({
  shouldShowNotification,
  message,
  selector,
}) => (
  <Portal>
    <S.WarningNotificationWrapper
      className={selector}
      data-test-visibility={shouldShowNotification ? 'visible' : 'hidden'}
      shouldShowNotification={shouldShowNotification}
    >
      <S.Icon>
        <WarningIcon />
      </S.Icon>
      <S.WarningNotificationContent data-testid={selector}>{message}</S.WarningNotificationContent>
    </S.WarningNotificationWrapper>
  </Portal>
);
