export const enum EServerGameStep {
   NO_ROUND = 'NO_ROUND',
   LAST_BETS = 'LAST_BETS',
   AWAITING_BETS = 'AWAITING_BETS',
   BETTING_TIME = 'BETTING_TIME',
   INIT = 'INIT',
   PLAYER_INPUT = 'PLAYER_INPUT',
   FINISHED = 'FINISHED',
   INSURANCE = 'INSURANCE',
   AWAIT_CARD = 'AWAIT_CARD',
   CANCELED = 'CANCELED',
   PAUSED = 'PAUSED',
   FAILED_TO_START = 'FAILED_TO_START',
   BET_PROCESSING = 'BET_PROCESSING',
}

export const enum EBlackJackGameStep {
   WaitingNextRound = 'WaitingNextRound',
   AwaitingBets = 'AwaitingBets',
   BettingTime = 'BettingTime',
   DealCards = 'DealCards',
   PlayerDecision = 'PlayerDecision',
   RoundFinished = 'RoundFinished',
   LastBets = 'LastBets',
   Insurance = 'Insurance',
   AwaitCard = 'AwaitCard',
   RoundCanceled = 'RoundCanceled',
   GamePaused = 'GamePaused',
   BetProcessing = 'BetProcessing',
}

export const enum EServerLastPauseAction {
   PAUSE = 'PAUSE',
   RESUME = 'RESUME',
   CANCEL_HAND = 'CANCEL_HAND',
   CANCEL_SEAT = 'CANCEL_SEAT',
}

export const enum ELastPauseAction {
   Pause = 'Pause',
   Resume = 'Resume',
   CancelHand = 'CancelHand',
   CancelSeat = 'CancelSeat',
}

export const DEALER_SEAT_INDEX = 7;

export const gameStepsRoundEnded = [
   EBlackJackGameStep.RoundCanceled,
   EBlackJackGameStep.RoundFinished,
];
