import { limitTooltipActions } from '@/common/modules';

import { balanceState, balanceActions } from '#/modules/Balance';
import { betGeneratorActions } from '#/modules/Favourite/components/BetConstructor/state/actions';
import { betGeneratorState } from '#/modules/Favourite/components/BetConstructor/state/selectors';
import { rebetState, rebetActions } from '#/modules/Rebet';
import { betsActions } from '#/state/features/bets/actions';
import { EPlaceTrack } from '#/state/features/bets/constants';
import { betsState } from '#/state/features/bets/selectors';
import { Store } from '#/state/types';

import { Rebet } from '../rebet';
import { Bets } from '../bets';
import { BetLimits } from '../betLimits';
import { Balance } from '../balance';

import { BetsGeneratorCreator } from './BetsGeneratorCreator';
import { BetsCreator } from './BetsCreator';

export const betsGeneratorCreator = new BetsGeneratorCreator({
   initBets: (store: Store) =>
      new Bets({
         betsModel: betGeneratorState(store),
         betsActions: betGeneratorActions(store),
      }),
});

export const betsCreator = new BetsCreator({
   initBets: (store: Store) =>
      new Bets({
         betsModel: betsState(store),
         betsActions: betsActions(store),
      }),
   initBalance: (store: Store) =>
      new Balance({
         balanceModel: balanceState(store),
         balanceActions: balanceActions(store),
      }),
   initBetLimits: (store: Store) =>
      new BetLimits({
         limits: store.getState().tableCapacity.betLimitsByType,
         limitsActions: limitTooltipActions(store),
      }),
   initRebet: (store: Store) =>
      new Rebet({
         rebetModel: rebetState(store),
         rebetActions: rebetActions(store),
      }),
});

export const betsCreatorByPlaceTrack = (placeTrack: EPlaceTrack) => {
   const trackMapper = {
      [EPlaceTrack.BetGeneratorTrack]: betsGeneratorCreator,
      [EPlaceTrack.MainTrack]: betsCreator,
   };

   return trackMapper?.[placeTrack] ?? betsCreator;
};
