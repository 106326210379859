import { EGameState } from '@/common/state/gameTypes/roulette/gameState';

import { autoplaySlice } from '#/modules/Autoplay/state';
import { TRootState } from '#/state/types';

export const updateLossLimit = (action, listenerApi): void => {
   const { dispatch } = listenerApi;
   const balance = action.payload;
   const state = listenerApi.getOriginalState() as TRootState;
   if (state.gameState.gameState === EGameState.ROUND_CANCELLED) {
      return;
   }

   const { setLossLimit } = autoplaySlice.actions;
   const initialBalance = state.balance.initialBalance;
   const selectedRounds = state.autoplay.selectedRound;
   const betsAmount = Number(initialBalance) - Number(balance);
   dispatch(setLossLimit(betsAmount * selectedRounds));
};
