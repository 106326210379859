import { useMemo } from 'react';

import { ETypeBet } from '@/common/roulette/constants';
import { formatCurrency } from '@/common/helpers';

import { useTableCapacity } from '#/modules/TableCapacity/state';
import { useTranslation } from '#/translates';

export const useLimitsTableRows = () => {
   const { betLimitsByType = {} } = useTableCapacity();
   const { t } = useTranslation();

   const limitTableRows = [
      {
         title: t('rouletteBetLimits.straight'),
         pays: '35:1',
         bets: [ETypeBet.STRAIGHT],
      },
      {
         title: t('rouletteBetLimits.split'),
         pays: '17:1',
         bets: [ETypeBet.SPLIT],
      },
      {
         title: t('rouletteBetLimits.street'),
         pays: '11:1',
         bets: [ETypeBet.STREET],
      },
      {
         title: t('rouletteBetLimits.corner'),
         pays: '8:1',
         bets: [ETypeBet.CORNER],
      },
      {
         title: t('rouletteBetLimits.line'),
         pays: '5:1',
         bets: [ETypeBet.LINE],
      },
      {
         title: t('rouletteBetLimits.dozen'),
         pays: '2:1',
         bets: [ETypeBet.COLUMN, ETypeBet.DOZEN],
      },
      {
         title: t('rouletteBetLimits.color'),
         pays: '1:1',
         bets: [ETypeBet.RED, ETypeBet.BLACK],
      },
      {
         title: t('rouletteBetLimits.evenOdd'),
         pays: '1:1',
         bets: [ETypeBet.EVEN, ETypeBet.ODD],
      },
      {
         title: t('rouletteBetLimits.lowHigh'),
         pays: '1:1',
         bets: [ETypeBet.LOW, ETypeBet.HIGH],
      },
   ];

   return useMemo(() => {
      const limitTableRowsWithMinAndMax = limitTableRows.map(({ title, pays, bets }) => {
         const [betType] = bets;
         const { min, max } = betLimitsByType[betType];

         return [
            title,
            pays,
            formatCurrency(min, { isCompact: true }),
            formatCurrency(max, { isCompact: true }),
         ];
      });

      return limitTableRowsWithMinAndMax;
   }, [betLimitsByType]);
};
