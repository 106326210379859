import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { useDispatch, useSelector } from 'react-redux';

import { EStreamingUpdateMode } from '@/common/modules/LiveStreamPlayer/constants';

import { TRootState } from '#/state/types';

export interface IVideoState {
   isPlaying: boolean;
   mode: EStreamingUpdateMode;
}

const initialState: IVideoState = {
   mode: EStreamingUpdateMode.Auto,
   isPlaying: false,
};

export const videoSlice = createSlice({
   name: 'video',
   initialState,
   reducers: {
      updateMode: (state, action: PayloadAction<EStreamingUpdateMode>): void => {
         state.mode = action.payload;
      },
      setPlaybackState: (state, action: PayloadAction<boolean>): void => {
         state.isPlaying = action.payload;
      },
   },
});

export const useVideoActions = () => {
   const dispatch = useDispatch();
   const { updateMode, setPlaybackState } = videoSlice.actions;

   const handleUpdateMode = (mode: EStreamingUpdateMode): void => {
      dispatch(updateMode(mode));
   };

   const handleSetPlaybackState = (state: boolean): void => {
      dispatch(setPlaybackState(state));
   };

   return {
      handleUpdateMode,
      handleSetPlaybackState,
   };
};

export const useVideoSelector = () => useSelector((state: TRootState) => state.video);

export const useVideo = () => {
   const { mode, isPlaying } = useVideoSelector() ?? {};
   const { handleUpdateMode, handleSetPlaybackState } = useVideoActions();

   return {
      mode,
      isPlaying,
      handleUpdateMode,
      handleSetPlaybackState,
   };
};
