import { dozenConfig } from 'core/config';
import { getCallBetIdByType } from 'core/config/call-bets/getCallBetIdByType';

import { ETypeBet } from '@/common/roulette/constants';

import { StatisticByRange } from '#/modules/Statistics/state/types';
import { translation } from '#/translates';

const dozenConfigTitles = dozenConfig.getConfig().reduce((titles, { value, id }) => {
   titles[id] = value;
   return titles;
}, {});

const _getColorStatistics = (statistics?: StatisticByRange[number]) => {
   const { reds = 0, blacks = 0, zeros = 0 } = statistics?.color ?? {};

   const betIds = {
      red: 42,
      black: 43,
      green: 0,
   };

   return {
      red: {
         betId: betIds.red,
         percentage: reds,
      },
      black: {
         betId: betIds.black,
         percentage: blacks,
      },
      green: {
         betId: betIds.green,
         percentage: zeros,
      },
   };
};

const _getDoubleStatistics = (statistics?: StatisticByRange[number]) => {
   const { even = 0, odd = 0 } = statistics?.pair ?? {};
   const { first: low = 0, second: high = 0 } = statistics?.lowHigh ?? {};

   const betIds = {
      even: 41,
      odd: 44,
      low: 40,
      high: 45,
   };
   return {
      even: {
         betId: betIds.even,
         label: translation('bettingMap.even'),
         percentage: even,
         dataTestId: 'statistic-even',
      },
      odd: {
         betId: betIds.odd,
         label: translation('bettingMap.odd'),
         percentage: odd,
         dataTestId: 'statistic-odd',
      },
      low: {
         betId: betIds.low,
         label: '1-18',
         percentage: low,
         dataTestId: 'statistic-low',
      },
      high: {
         betId: 45,
         label: '19-36',
         percentage: high,
         dataTestId: 'statistic-high',
      },
   };
};

const _getCallBetsStatistics = (statistics?: StatisticByRange[number]) => {
   const { tiers = 0, orphelins = 0, voisins = 0, zeros = 0 } = statistics?.callBets ?? {};

   return [
      {
         value: tiers,
         betId: getCallBetIdByType(ETypeBet.TIERS),
         type: ETypeBet.TIERS,
         label: 'Tier',
      },
      {
         value: orphelins,
         betId: getCallBetIdByType(ETypeBet.ORPHELINS),
         type: ETypeBet.ORPHELINS,
         label: 'Orphelins',
      },
      {
         value: voisins,
         betId: getCallBetIdByType(ETypeBet.VOISIN),
         type: ETypeBet.VOISIN,
         label: 'Voisins',
      },
      {
         value: zeros,
         betId: getCallBetIdByType(ETypeBet.JEU_ZERO),
         type: ETypeBet.JEU_ZERO,
         label: 'Zero',
      },
   ];
};

const _getTripleStatistics = (statistics?: StatisticByRange[number]) => {
   const {
      first: firstDozen = 0,
      second: secondDozen = 0,
      third: thirdDozen = 0,
   } = statistics?.dozen ?? {};
   const {
      first: firstColumn = 0,
      second: secondColumn = 0,
      third: thirdColumn = 0,
   } = statistics?.column ?? {};

   const betIds = {
      firstDozen: 37,
      secondDozen: 38,
      thirdDozen: 39,
      firstColumn: 46,
      secondColumn: 47,
      thirdColumn: 48,
   };

   return {
      firstDozen: {
         betId: betIds.firstDozen,
         label: dozenConfigTitles[betIds.firstDozen],
         percentage: firstDozen,
         dataTestId: 'statistic-dozen-1',
      },
      secondDozen: {
         betId: betIds.secondDozen,
         label: dozenConfigTitles[betIds.secondDozen],
         percentage: secondDozen,
         dataTestId: 'statistic-dozen-2',
      },
      thirdDozen: {
         betId: betIds.thirdDozen,
         label: dozenConfigTitles[betIds.thirdDozen],
         percentage: thirdDozen,
         dataTestId: 'statistic-dozen-3',
      },

      firstColumn: {
         betId: betIds.firstColumn,
         label: '1st COL',
         percentage: firstColumn,
         dataTestId: 'statistic-column-1',
      },
      secondColumn: {
         betId: betIds.secondColumn,
         label: '2nd COL',
         percentage: secondColumn,
         dataTestId: 'statistic-column-2',
      },
      thirdColumn: {
         betId: betIds.thirdColumn,
         label: '3rd COL',
         percentage: thirdColumn,
         dataTestId: 'statistic-column-3',
      },
   };
};

export const getStatisticsBySectionConfig = (statistics?: StatisticByRange[number]) => {
   const colorStatistics = _getColorStatistics(statistics);
   const doubleStatistics = _getDoubleStatistics(statistics);
   const callBetsStatistics = _getCallBetsStatistics(statistics);
   const tripleStatistics = _getTripleStatistics(statistics);

   return {
      colorStatistics,
      doubleStatistics,
      callBetsStatistics,
      tripleStatistics,
   };
};
