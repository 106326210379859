export const getTranslations = (t: (val: string) => string) => {
   return {
      gameInfoGameId: t('gameHistory.gameInfo.gameId'),
      gameInfoTotalBet: t('gameHistory.gameInfo.totalBet'),
      gameInfoNetCash: t('gameHistory.gameInfo.netCash'),
      gameInfoDealer: t('gameHistory.gameInfo.dealer'),
      gameInfoSeat: t('gameHistory.gameInfo.seat'),
      gameInfoDealerCards: t('gameHistory.gameInfo.dealerCards'),

      bjRoundBetsTableSeat: t('gameHistory.bjRoundBetsTable.seat'),
      bjRoundBetsTableBetType: t('gameHistory.bjRoundBetsTable.betType'),
      bjRoundBetsTableCards: t('gameHistory.bjRoundBetsTable.cards'),
      bjRoundBetsTableScore: t('gameHistory.bjRoundBetsTable.score'),
      bjRoundBetsTableBet: t('gameHistory.bjRoundBetsTable.bet'),
      bjRoundBetsTableNetCash: t('gameHistory.bjRoundBetsTable.netCash'),

      bjGameBetTypeCancelled: t('gameHistory.bjGameBetType.cancelled'),
      bjGameBetTypeHand: t('gameHistory.bjGameBetType.hand'),
      bjGameBetTypeMainBet: t('gameHistory.bjGameBetType.mainBet'),
      bjGameBetTypeTop3: t('gameHistory.bjGameBetType.top3'),
      bjGameBetTypeBetBehind: t('gameHistory.bjGameBetType.betBehind'),
      bjGameBetTypePerfectPair: t('gameHistory.bjGameBetType.perfectPair'),
      bjGameBetTypeSplit: t('gameHistory.bjGameBetType.split'),
      bjGameBetTypeInsurance: t('gameHistory.bjGameBetType.insurance'),
      bjGameBetTypeTwentyOnePlusThreeBet: t('gameHistory.bjGameBetType.twentyOnePlusThreeBet'),

      emptyGameHistoryTitle: t('gameHistory.emptyGameHistoryList'),

      navigationPreviousTitle: t('gameHistory.navigation.previous'),
      navigationNextTitle: t('gameHistory.navigation.next'),

      defaultDatePickerInputText: t('gameHistory.chooseDate'),
      datePickerCancelTitle: t('gameHistory.datePicker.cancel'),
      datePickerSaveTitle: t('gameHistory.datePicker.save'),

      roundBetsTableBetTypeTitle: t('sidebars.history.roundBetsTable.betType'),
      roundBetsTableBetTitle: t('sidebars.history.roundBetsTable.bet'),
      roundBetsTableNetCash: t('sidebars.history.roundBetsTable.netCash'),
   };
};
