import { useDispatch } from 'react-redux';

import { useGameStateActions } from '@/common/state/gameTypes/roulette/gameState';

import { reconnectionEventAction } from '#/state/middlewares/reconnectMiddleware';

export const useReconnect = () => {
   const { handleSetWaitingForConnection, handleSetLostConnectionMode } = useGameStateActions();
   const dispatch = useDispatch();

   const handleConnectError = () => {
      handleSetWaitingForConnection();
   };

   const handleReachingReconnectAttempts = () => {
      handleSetLostConnectionMode();
   };

   const handleReconnect = async () => {
      dispatch(reconnectionEventAction());
   };

   return {
      handleReconnect,
      handleConnectError,
      handleReachingReconnectAttempts,
   };
};
