// @ts-nocheck
import styled from 'styled-components/macro';

import { applyZIndex, orientation } from '@/common/ui/theme/mixins';

export const NotificationContainer = styled.div`
   --container-padding: 16px 12px 16px 24px;
   --container-gap: 24px;
   position: relative;
   padding: var(--container-padding);
   display: flex;
   align-items: flex-start;
   gap: var(--container-gap);
   max-width: 437px;
   box-shadow:
      0 4px 8px rgba(0, 0, 0, 0.3),
      0 12px 24px rgba(0, 0, 0, 0.2);
   border-radius: 4px;
   border-left: ${({ borderColor }) => `8px solid ${borderColor}`};
   background: rgba(3, 3, 3, 0.8);
   color: ${({ theme }) => theme.colors?.white};
   padding: var(--container-padding);
   gap: var(--container-gap);
   display: flex;
   align-items: flex-start;
   border-left: ${({ borderColor }) => `8px solid ${borderColor}`};
   position: relative;
   margin-bottom: 8px;

   ${orientation.portrait`
      --container-padding: 16px 12px;
      --container-gap: 12px;
      max-width: 95vw;
   `}

   ${orientation.mobileLandscape`
      --container-padding: 10px 12px;
      --container-gap: 12px;
      font-size: 14px;
   `}
`;

export const NotificationContentWrapper = styled.div`
   display: flex;
   gap: 4px;
   flex-direction: column;
`;

export const NotificationsContainer = styled.div`
   ${applyZIndex('biggest')}

   position: absolute;
   right: 45px;
   top: 88px;
   width: max-content;

   ${orientation.portrait`
        right: 8px;
        top: 45px;
   `}

   ${orientation.mobileLandscape`
   	  top: 4px;
        right: 5px;
   `}
`;

export const CloseIconWrapper = styled.div`
   --close-icon-size: 30px;
   display: flex;
   flex-shrink: 0;
   width: var(--close-icon-size);
   height: var(--close-icon-size);

   svg {
      width: 100%;
      height: 100%;
   }

   &:hover {
      cursor: pointer;
   }
`;

export const NotificationIconContainer = styled.div`
   --notification-icon-size: 24px;

   display: flex;
   flex-shrink: 0;
   width: var(--notification-icon-size);
   height: var(--notification-icon-size);

   svg {
      width: 100%;
      height: 100%;
   }
`;

export const Title = styled.div`
   font-family: Montserrat, sans-serif;
   color: #fff;
   font-family: Montserrat;
   font-size: 14px;
   font-weight: 600;
   line-height: 140%;
`;

export const Content = styled.div`
   font-family: Montserrat, sans-serif;
   font-weight: 400;
   font-size: 14px;
   padding-right: 5px;

   ${orientation.portrait`
      word-break: break-word;
   `}
`;

export const Action = styled.div`
   color: #31c440;
   cursor: pointer;
`;
