import { useSidebarSelector } from '@/common/modules/Sidebar';
import { positioningTooltip } from '@/common/modules';
import { THandleShowLimitTooltip } from '@/common/modules/LimitTooltip/types';

import { useTooltip } from '../../hooks';

export const LossLimitTooltipWrapper = ({ children, inputValue }) => {
   const sidebarType = useSidebarSelector();
   const tooltip = useTooltip(inputValue, sidebarType);

   const handleShowTooltip: THandleShowLimitTooltip = (element) => {
      positioningTooltip(element.target as HTMLElement, '.loss-limit-notification');
   };

   return children({ handleShowTooltip, tooltip });
};
