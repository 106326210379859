import { EHandResult, ESeatStatus } from '@/common/blackjack/constants';
import { TDealerSeat, THand, TSeat, TUnstableServerType } from '@/common/blackjack/types';
import { seatBetsAdapter } from '@/common/blackjack/adapters/seatBetsAdapter';
import {
   ISeatBetsAdapter,
   TMapChipValueById,
} from '@/common//blackjack/adapters/seatBetsAdapter/types';
import { pipe } from '@/common/utils';

import { earlyDecisionAdapter, IEarlyDecisionAdapter } from '../earlyDecisionAdapter';
import { EServerGameStep } from '../../constants';
import { getHandScore } from '../../utils/getHandScore';
import { cardSuitAdapter } from '../cardSuitAdapter';
import { CardRankAdapter } from '../cardRankAdapter';
import { insuranceAdapter } from '../insuranceAdapter';
import { gameStepAdapter, IGameStepAdapter } from '../gameStepAdapter';

export interface ISeatsAdapter {
   adapt: ({
      serverSeats,
      playerId,
      currentSeat,
      currentHand,
      serverGameStep,
      mapChipValueById,
      isMobileDevice,
      dealer,
   }: {
      serverSeats: TUnstableServerType;
      playerId: TUnstableServerType;
      currentSeat: TUnstableServerType;
      currentHand: TUnstableServerType;
      serverGameStep: TUnstableServerType;
      mapChipValueById: TMapChipValueById;
      isMobileDevice: boolean;
      dealer: TDealerSeat;
   }) => TSeat[];
   allSeatsAreTaken: (seats: TSeat[]) => boolean;
   getCurrentPlayerSeatsCount: (seats: TSeat[]) => number;
   getUniqueTakenSeatsAmount: (seats: TSeat[]) => number;
}

class SeatsAdapter {
   private seatBetsAdapter: ISeatBetsAdapter;
   private earlyDecisionAdapter: IEarlyDecisionAdapter;
   private gameStepAdapter: IGameStepAdapter;

   constructor({
      seatBetsAdapter,
      earlyDecisionAdapter,
      gameStepAdapter,
   }: {
      seatBetsAdapter: ISeatBetsAdapter;
      earlyDecisionAdapter: IEarlyDecisionAdapter;
      gameStepAdapter: IGameStepAdapter;
   }) {
      this.seatBetsAdapter = seatBetsAdapter;
      this.earlyDecisionAdapter = earlyDecisionAdapter;
      this.gameStepAdapter = gameStepAdapter;
   }

   private isCurrentPlayerAbleToPlaceBet = (
      isCurrentPlayerSeat: boolean,
      serverGameStep: EServerGameStep,
   ): boolean => {
      const isBettingTime = this.gameStepAdapter.isBettingTime(serverGameStep);
      const isAwaitingBets = this.gameStepAdapter.isAwaitingBets(serverGameStep);

      return isCurrentPlayerSeat && (isBettingTime || isAwaitingBets);
   };

   private isCurrentPlayerMakingInsuranceDecision = ({
      isCurrentPlayerSeat,
      insurance,
      serverGameStep,
   }: {
      isCurrentPlayerSeat: boolean;
      insurance: TSeat['insurance'];
      serverGameStep: EServerGameStep;
   }): boolean => {
      const isInsuranceTime = this.gameStepAdapter.isInsuranceTime(serverGameStep);
      const isInsuranceNotChosen = insurance.isInsuranceNotChosen;
      if (!isInsuranceTime || !isInsuranceNotChosen) {
         return false;
      }

      return isCurrentPlayerSeat;
   };

   private isPlayerMakingDecision = (
      isSeatPlaying: TSeat['isSeatPlaying'],
      serverGameStep: EServerGameStep,
   ): boolean => {
      const isAwaitCard = this.gameStepAdapter.isAwaitCard(serverGameStep);
      const isPlayerDecisionTime = this.gameStepAdapter.isPlayerDecisionTime(serverGameStep);

      return (isPlayerDecisionTime || isAwaitCard) && isSeatPlaying;
   };

   private shouldHighlightSeat = ({
      seat,
      serverGameStep,
      isMobileDevice,
   }: {
      seat: TSeat;
      serverGameStep: EServerGameStep;
      isMobileDevice: boolean;
   }): boolean => {
      const {
         isSeatPlaying,
         isCurrentPlayerSeat,
         earlyDecision: { isMakingEarlyDecision, isMadeEarlyDecision },
         insurance,
         seatBets,
      } = seat;
      const playerHasBets = seatBets.length > 0;

      if (!playerHasBets) {
         return false;
      }

      const isRoundFinished = this.gameStepAdapter.isRoundFinished(serverGameStep);

      const isCurrentPlayerAbleToPlaceBet = this.isCurrentPlayerAbleToPlaceBet(
         isCurrentPlayerSeat,
         serverGameStep,
      );
      const isCurrentPlayerMakingInsuranceDecision = this.isCurrentPlayerMakingInsuranceDecision({
         isCurrentPlayerSeat,
         insurance,
         serverGameStep,
      });
      const isPlayerMakingDecision = this.isPlayerMakingDecision(isSeatPlaying, serverGameStep);
      const playerRoundHasFinished = isCurrentPlayerSeat && isRoundFinished;
      const shouldHighlightSeatDuringEarlyDecision = isMakingEarlyDecision && !isMadeEarlyDecision;

      const shouldHighlightSeatSeat =
         isCurrentPlayerAbleToPlaceBet ||
         playerRoundHasFinished ||
         isCurrentPlayerMakingInsuranceDecision ||
         isPlayerMakingDecision;

      return isMobileDevice
         ? shouldHighlightSeatSeat || shouldHighlightSeatDuringEarlyDecision
         : shouldHighlightSeatSeat;
   };

   private disableSeatAdapter = ({
      playerId,
      serverGameStep,
      seatPlayerId,
      isMobileDevice,
   }: {
      serverGameStep: TUnstableServerType;
      playerId: TUnstableServerType;
      seatPlayerId: TUnstableServerType;
      isMobileDevice: boolean;
   }) => {
      const isCurrentPlayerSeat = seatPlayerId === playerId;
      const isSeatTaken = seatPlayerId !== null;
      const isSeatOfAnotherPlayer = isSeatTaken && !isCurrentPlayerSeat;
      const isGameStepForNotPlaceBets =
         isCurrentPlayerSeat &&
         ![
            EServerGameStep.AWAITING_BETS,
            EServerGameStep.BETTING_TIME,
            EServerGameStep.LAST_BETS,
         ].includes(serverGameStep);

      const isDisableTakenSeatForMobile = isSeatTaken && isMobileDevice;

      return isSeatOfAnotherPlayer || isGameStepForNotPlaceBets || isDisableTakenSeatForMobile;
   };

   public getUniqueTakenSeatsAmount = (seats: TSeat[]) => {
      const playersTakenSeats = seats
         .filter(({ isSeatTaken }) => isSeatTaken)
         .map(({ playerId }) => playerId);
      const uniquePlayersTakenSeats = new Set(playersTakenSeats);

      return uniquePlayersTakenSeats.size;
   };

   public allSeatsAreTaken = (seats: TSeat[]) => {
      return seats.every((seat) => !seat.isSeatTaken);
   };

   public getCurrentPlayerSeatsCount = (seats: TSeat[]) => {
      return seats.filter((seat) => seat.isCurrentPlayerSeat).length;
   };

   public handAdapt = ({
      currentSeat,
      seatPosition,
      cards,
      currentHand,
      handIndex,
      value,
      result,
      isCanceled,
   }): THand => ({
      isPlaying: currentSeat === seatPosition && cards.length > 0 && currentHand === handIndex, // When a player has a card in hand and it is his turn to play, we mark him with this flag
      cards: cards.map(({ suit, value }) => ({
         suit: cardSuitAdapter(suit),
         rank: CardRankAdapter.adapt(value),
      })),
      hasCards: cards.length > 0,
      handScore: getHandScore(value),
      handResult: result,
      hasBJ: result === EHandResult.Bj,
      isHandBusted: result === EHandResult.Bust,
      isHandLose: result === EHandResult.Lose,
      isCancelled: isCanceled,
      isSoftHand: value.length === 2,
   });

   public adapt = ({
      serverSeats,
      playerId,
      currentSeat,
      currentHand,
      serverGameStep,
      mapChipValueById,
      isMobileDevice,
      dealer,
   }: {
      serverSeats: TUnstableServerType;
      playerId: TUnstableServerType;
      currentSeat: TUnstableServerType;
      currentHand: TUnstableServerType;
      serverGameStep: TUnstableServerType;
      mapChipValueById: TMapChipValueById;
      isMobileDevice: boolean;
      dealer: TDealerSeat;
   }): TSeat[] => {
      const clientSeats: TSeat[] = serverSeats.map(
         (
            {
               userId: seatPlayerId,
               userName,
               status,
               bets: serverSeatBets,
               hands,
               hasInsurance,
               lastAction,
               isCanceled,
               winningStreak,
               policies,
            },
            seatPosition: number,
         ) => {
            const isCurrentPlayerSeat = seatPlayerId === playerId;
            const isSeatTaken = seatPlayerId !== null;
            const isSeatDisabled = this.disableSeatAdapter({
               seatPlayerId,
               serverGameStep,
               playerId,
               isMobileDevice,
            });

            const seatBets = seatBetsAdapter.adaptSeatBets({
               serverSeatBets,
               playerId,
               mapChipValueById,
            });

            const seatBetAmountByBetType =
               this.seatBetsAdapter.calculateBetAmountByBetType(seatBets);

            const playerHands = hands.map(
               ({ cards, value, result, isCanceled }, handIndex: number) =>
                  this.handAdapt({
                     currentSeat,
                     seatPosition,
                     cards,
                     currentHand,
                     handIndex,
                     value,
                     result,
                     isCanceled,
                  }),
            );

            const isDealingSeat =
               this.gameStepAdapter.isDealingTime(serverGameStep) && currentSeat === seatPosition;
            const isSeatPlaying = seatPosition === currentSeat;
            const hasSeatBets = serverSeatBets.length > 0;

            return {
               isSeatPlaying,
               seatPosition,
               isSeatDisabled,
               seatBetAmountByBetType,
               playerId: seatPlayerId,
               playerName: userName,
               seatStatus: status,
               seatBets,
               policies,
               isSplit: hands.length > 1,
               isSeatPlayed: status === ESeatStatus.Played,
               isSeatTaken,
               isCurrentPlayerSeat,
               playerHands,
               reversedPlayerHandsForUI: [...playerHands].reverse(),
               insurance: insuranceAdapter(hasInsurance),
               seatLastDecision: lastAction,
               isDealingSeat,
               isCanceled,
               winningStreak,
               canLeaveSeat: !hasSeatBets && isCurrentPlayerSeat,
               earlyDecision: this.earlyDecisionAdapter.defaultEarlyDecisionConfig(),
            };
         },
      );

      const addEarlyDecision = (seats: TSeat[]) =>
         seats.map((seat, seatPosition) =>
            this.earlyDecisionAdapter.addEarlyDecisionToSeat({
               serverSeat: serverSeats[seatPosition],
               seat,
               clientSeats,
               serverGameStep,
               currentPlayingSeatPosition: currentSeat,
               dealer,
            }),
         );
      const addMakingEarlyDecisionFlagToSeat = (seats: TSeat[]) =>
         this.earlyDecisionAdapter.addMakingEarlyDecisionFlagToSeat(seats, isMobileDevice);

      const addHighlightFlagToSeat = (seats: TSeat[]) =>
         seats.map((seat) => ({
            ...seat,
            shouldHighlightSeat: this.shouldHighlightSeat({
               seat,
               serverGameStep,
               isMobileDevice,
            }),
         }));

      const adaptSeats = pipe(
         addEarlyDecision,
         addMakingEarlyDecisionFlagToSeat,
         addHighlightFlagToSeat,
      );

      const adaptedSeats: TSeat[] = adaptSeats(clientSeats);
      return adaptedSeats;
   };
}

export const seatsAdapter = new SeatsAdapter({
   seatBetsAdapter,
   earlyDecisionAdapter,
   gameStepAdapter,
});
