import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { useDispatch, useSelector } from 'react-redux';

import { ENotificationIcons } from '../constants';

export interface INotification {
   action: string;
   content?: string;
   contentList?: string[];
   title?: string;
   type: ENotificationIcons;
}

interface INotificationsState {
   notifications: INotification[];
}

const initialState: INotificationsState = {
   notifications: [],
};

export const notificationsSlice = createSlice({
   name: 'notifications',
   initialState,
   reducers: {
      addNotification: (state, action: PayloadAction<INotification>): void => {
         state.notifications = [...state.notifications, action.payload];
      },
      removeNotification: (state): void => {
         state.notifications.pop();
         state.notifications = [...state.notifications];
      },
   },
   selectors: {
      getNotifications: (state) => state.notifications,
   },
});

export const useNotificationsActions = () => {
   const dispatch = useDispatch();
   const { addNotification, removeNotification } = notificationsSlice.actions;

   const handleAddNotification = (notification: INotification): void => {
      dispatch(addNotification(notification));
   };

   const handleRemoveNotification = (): void => {
      dispatch(removeNotification());
   };

   return { handleAddNotification, handleRemoveNotification };
};

const useNotificationsSelector = () => useSelector(notificationsSlice.selectors.getNotifications);

export const useNotifications = () => {
   const notifications = useNotificationsSelector() ?? {};
   const { handleAddNotification, handleRemoveNotification } = useNotificationsActions();

   return { notifications, handleAddNotification, handleRemoveNotification };
};
