import { forwardRef, VideoHTMLAttributes, SyntheticEvent, ReactEventHandler } from 'react';

import { LIVE_STREAM_CLIENT_ID } from './constants';

interface ILiveStreamElementProps extends VideoHTMLAttributes<HTMLVideoElement> {
   onPause: ReactEventHandler<HTMLVideoElement>;
   onPlaying: ReactEventHandler<HTMLVideoElement>;
   onSuspend?: (event: SyntheticEvent<HTMLVideoElement, Event>) => void;
   videoContainerId?: string;
}

export const LiveStreamElement = forwardRef<HTMLVideoElement, ILiveStreamElementProps>(
   ({ onPause, onPlaying, videoContainerId = LIVE_STREAM_CLIENT_ID }, ref) => (
      <video
         muted // we always mute video element and handle audio via audio element
         autoPlay
         id={videoContainerId}
         playsInline
         ref={ref}
         onAbort={onPause}
         onSuspend={onPause}
         onPlaying={onPlaying}
      />
   ),
);
