import { createSelector, createSlice } from '@reduxjs/toolkit';
import { useDispatch, useSelector } from 'react-redux';

import { TNullable } from '../../types';

import { ELimitTooltip } from './constants';

export interface ILimitTooltipActions {
   handleHideTooltip: () => void;
   handleSetMaxLimitTooltip: () => void;
   handleSetMinLimitTooltip: () => void;
   handleSetNotEnoughBalanceTooltip: () => void;
   handleShowNotEnoughBalanceTooltip: () => void;
   handleShowTooltip: () => void;
}

interface ILimitTooltipState {
   activeTooltip: TNullable<ELimitTooltip>;
   isShow: boolean;
}

const initialState: ILimitTooltipState = {
   activeTooltip: null,
   isShow: false,
};

export const limitTooltipSlice = createSlice({
   name: 'limitTooltip',
   initialState,
   reducers: {
      setMaxTooltip: (state) => {
         state.activeTooltip = ELimitTooltip.Max;
      },

      setMinTooltip: (state) => {
         state.activeTooltip = ELimitTooltip.Min;
      },

      setNotEnoughBalanceTooltip: (state) => {
         state.activeTooltip = ELimitTooltip.NotEnoughBalance;
      },

      showTooltip: (state) => {
         state.isShow = true;
      },

      showNotEnoughBalanceTooltip: (state) => {
         state.activeTooltip = ELimitTooltip.NotEnoughBalance;
         state.isShow = true;
      },

      hideTooltip: (state) => {
         state.isShow = false;
         state.activeTooltip = null;
      },
   },
   selectors: {
      getActiveTooltip: (state) => state.activeTooltip,
      getIsShowTooltip: (state) => state.isShow,
   },
});

const createActions = (dispatch) => {
   const {
      hideTooltip,
      setMaxTooltip,
      setMinTooltip,
      setNotEnoughBalanceTooltip,
      showTooltip,
      showNotEnoughBalanceTooltip,
   } = limitTooltipSlice.actions;

   const handleShowNotEnoughBalanceTooltip = () => {
      dispatch(showNotEnoughBalanceTooltip());
   };

   const handleShowTooltip = () => {
      dispatch(showTooltip());
   };

   const handleSetMaxLimitTooltip = (): void => {
      dispatch(setMaxTooltip());
   };

   const handleSetNotEnoughBalanceTooltip = (): void => {
      dispatch(setNotEnoughBalanceTooltip());
   };

   const handleSetMinLimitTooltip = (): void => {
      dispatch(setMinTooltip());
   };

   const handleHideTooltip = (): void => {
      dispatch(hideTooltip());
   };

   return {
      handleShowTooltip,
      handleSetMaxLimitTooltip,
      handleSetMinLimitTooltip,
      handleHideTooltip,
      handleSetNotEnoughBalanceTooltip,
      handleShowNotEnoughBalanceTooltip,
   };
};

export const useLimitTooltipActions = (): ILimitTooltipActions => {
   return createActions(useDispatch());
};
export const limitTooltipActions = (store): ILimitTooltipActions => {
   return createActions(store.dispatch);
};

export const useLimitTooltipSelector = () => {
   return useSelector(
      createSelector([limitTooltipSlice.selectors.getActiveTooltip], (activeTooltip) => {
         return {
            isMinLimitTooltip: activeTooltip === ELimitTooltip.Min,
            isMaxLimitTooltip: activeTooltip === ELimitTooltip.Max,
            isNotEnoughBalance: activeTooltip === ELimitTooltip.NotEnoughBalance,
         };
      }),
   );
};

export const useShowLimitTooltipSelector = () => {
   return useSelector(limitTooltipSlice.selectors.getIsShowTooltip);
};
