import { RefObject, useEffect } from 'react';

import { useGameState } from '@/common/state/gameTypes/roulette/gameState';
import { useTheme } from '@/common/ui/theme/hooks';
import { winNumberDTO } from '@/common/helpers';

import { useWinNumber } from '#/modules/WinNumbers';

import { TrackContainerId } from '../../../types';
import { SVG_BLUR_FILTER_ID } from '../../components/RaceTrackSvg/constants';
import { getNodeCellByCellId } from '../../helpers';

import { animateWinningNumber } from './utils';

export const useWinningNumber = ({
   raceTrackContainerRef,
   animationDurationOnMs,
   trackContainerId,
}: {
   trackContainerId: TrackContainerId;
   raceTrackContainerRef: RefObject<SVGSVGElement>;
   animationDurationOnMs: number;
}) => {
   const theme = useTheme();
   const { winNumber } = useWinNumber();
   const transformedWinNumber = winNumberDTO(winNumber ?? '');
   const { isWinNumberState } = useGameState();

   useEffect(() => {
      if (!raceTrackContainerRef.current || !isWinNumberState) {
         return;
      }

      const node = getNodeCellByCellId({
         cellId: transformedWinNumber,
         container: raceTrackContainerRef.current,
      });

      const handleHighlightingWinNumber = (): void => {
         if (node) {
            const { animatedElement, handleStartAnimation } = animateWinningNumber({
               winningNumberCell: node.firstChild as HTMLElement,
               strokeColor: String(theme.colors.yellow),
               animationDurationOnMs,
               trackContainerId,
            });
            animatedElement.style.filter = `url(#${trackContainerId}_${SVG_BLUR_FILTER_ID})`;
            raceTrackContainerRef.current?.append(animatedElement);
            handleStartAnimation();
         }
      };

      handleHighlightingWinNumber();
   }, [transformedWinNumber, animationDurationOnMs, raceTrackContainerRef, isWinNumberState]);
};
