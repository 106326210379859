import { memo } from 'react';

import { autoKeys } from '../../utils';
import { FadeBox } from '../../ui/FadeBox';

import { Notification } from './components';
import { INotification, useNotifications } from './state';
import * as S from './Notifications.styles';

export const Notifications = memo(() => {
   const { notifications, handleRemoveNotification } = useNotifications();

   return notifications?.length > 0 ? (
      <S.NotificationsContainer>
         {notifications.map((notification: INotification) =>
            autoKeys(
               <FadeBox
                  isVisible={Boolean(notification.content)}
                  style={{
                     width: 'fit-content',
                     marginLeft: 'auto',
                  }}
               >
                  <Notification
                     {...notification}
                     handleCloseNotification={handleRemoveNotification}
                  />
               </FadeBox>,
            ),
         )}
      </S.NotificationsContainer>
   ) : null;
});

Notifications.displayName = 'Notifications';
