import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { useDispatch, useSelector } from 'react-redux';

import { EBlackjackSidebar, ERouletteSidebar } from '@/common/entities/Sidebar';
import { TNullable } from '@/common/types';

export type SidebarType = TNullable<EBlackjackSidebar | ERouletteSidebar>;

const initialState: { sidebarType: TNullable<SidebarType> } = {
   sidebarType: null,
};

export const sidebarSlice = createSlice({
   name: 'sidebar',
   initialState,
   reducers: {
      setSidebarType: (state, action: PayloadAction<SidebarType>) => {
         state.sidebarType = action.payload;
      },
   },
   selectors: {
      getOpenedSidebar: (state) => state.sidebarType,
   },
});

const useSidebarActions = () => {
   const dispatch = useDispatch();
   const sidebarType = useSidebarSelector();
   const { setSidebarType } = sidebarSlice.actions;

   const handleSetSidebarType = (type: EBlackjackSidebar | ERouletteSidebar): void => {
      const shouldToggleSideBar = sidebarType === type ? null : type;

      dispatch(setSidebarType(shouldToggleSideBar));
   };

   const handleCloseSidebar = (): void => {
      dispatch(setSidebarType(null));
   };

   return { handleSetSidebarType, handleCloseSidebar };
};

export const useSidebarSelector = () => useSelector(sidebarSlice.selectors.getOpenedSidebar);

export const useSidebar = () => {
   const sidebarType = useSidebarSelector();
   const { handleSetSidebarType, handleCloseSidebar } = useSidebarActions();

   return {
      handleSetSidebarType,
      handleCloseSidebar,
      sidebarType,
   };
};
