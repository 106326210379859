import { useEffect } from 'react';

import { TSetTimeout } from '@/common/types';
import { useGameState } from '@/common/state/gameTypes/roulette/gameState';
import { winNumberDTO } from '@/common/helpers';

import { BettingMapRefType } from '#/modules/Track/BettingMap/hooks/useBettingMapRef/types';
import { useWinNumber } from '#/modules/WinNumbers';

import {
   ACTIVE_CLASSNAME_MODIFIER,
   ANIMATION_DURATION_SEC,
   HIDE_ANIMATION_DURATION_SEC,
} from '../../constants';

export const useWinningNumber = (node: BettingMapRefType) => {
   const { winNumber } = useWinNumber();
   const { isWinNumberState } = useGameState();

   useEffect(() => {
      if (winNumber && node && isWinNumberState) {
         const cell = node.querySelector(`.cell_${winNumberDTO(winNumber)} > .active__cell`);

         cell?.classList.add(ACTIVE_CLASSNAME_MODIFIER);

         const whenRemoveActiveClassName =
            (ANIMATION_DURATION_SEC + HIDE_ANIMATION_DURATION_SEC) * 1000;

         const timeout: TSetTimeout = setTimeout(() => {
            cell?.classList.remove(ACTIVE_CLASSNAME_MODIFIER);
         }, whenRemoveActiveClassName);

         return () => {
            if (timeout) {
               clearTimeout(timeout);
            }
         };
      }
   }, [node, winNumber, isWinNumberState]);
};
