import { Suspense, lazy, FC } from 'react';

import { TUserCurrency } from '@/common/entities/User';
import { IPlayTapSound } from '@/common/entities';
import { TTranslation } from '@/common/modules/History/types';
import { TRouletteClientLanguage } from '@/common/entities/Language';

import * as S from './History.styles';

const HistoryContainer = lazy(() => import('./components/HistoryContainer'));

interface IHistoryProps {
   currency: TUserCurrency;
   locale?: TRouletteClientLanguage;
   translation: TTranslation;
   userId: string;
}

export const History: FC<IHistoryProps & IPlayTapSound> = ({
   currency,
   translation,
   userId,
   playTapSound,
   locale = 'en',
}) => (
   <S.HistoryWrapper>
      <Suspense fallback={null}>
         <HistoryContainer
            playTapSound={playTapSound}
            userId={userId}
            currency={currency}
            translation={translation}
            locale={locale}
         />
      </Suspense>
   </S.HistoryWrapper>
);
