import { useMemo } from 'react';

import { ModalNotificationView } from '@/common/ui/ModalNotification/ModalNotificationView';
import { LIVE_STREAM_CLIENT_ID } from '@/common/modules/LiveStreamPlayer/components/constants';
import { EModalNotificationType } from '@/common/ui/ModalNotification/constants';

import * as S from './PowerSavingModeModal.styles';

export const PowerSavingModeModal = ({ isShow, onClose }) => {
   if (!isShow) {
      return null;
   }

   const liveStreamDomElement = useMemo(
      () => document.getElementById(LIVE_STREAM_CLIENT_ID) as HTMLVideoElement,
      [LIVE_STREAM_CLIENT_ID],
   );

   const handleCloseModal = () => {
      if (liveStreamDomElement) {
         liveStreamDomElement.style.opacity = '0';
      }
      onClose();
   };

   const handleActionModalClick = () => {
      if (liveStreamDomElement) {
         liveStreamDomElement.play();
      }

      onClose();
   };

   return (
      <S.LowPowerModeOverlay>
         <ModalNotificationView
            message=""
            type={EModalNotificationType.Warning}
            onClose={handleCloseModal}
            title="Video is disabled due to battery-saving mode. Please confirm to enable video streaming."
            actionButton={{
               title: 'Confirm',
               onClick: handleActionModalClick,
            }}
         />
      </S.LowPowerModeOverlay>
   );
};
