export const gameEvents = {
  INIT2: {
    roundId: "7e2884b0-4784-4ceb-90ef-ddb5c44253d8",
    roundNumber: "644",
    status: "PLAYER_INPUT",
    prePauseStatus: "PLAYER_INPUT",
    lastPauseAction: "RESUME",
    dealNowUsers: [],
    seats: [
      {
        userId: null,
        userName: null,
        hasInsurance: null,
        isCanceled: false,
        status: "PLAYED",
        policies: null,
        hands: [
          {
            cards: [],
            value: [0],
            result: null,
            isCanceled: false,
          },
        ],
        bets: [],
        earlyAction: null,
        lastAction: null,
        winningStreak: 0,
      },
      {
        userId: null,
        userName: null,
        hasInsurance: null,
        isCanceled: false,
        status: "PLAYED",
        policies: null,
        hands: [
          {
            cards: [],
            value: [0],
            result: null,
            isCanceled: false,
          },
        ],
        bets: [],
        earlyAction: null,
        lastAction: null,
        winningStreak: 0,
      },
      {
        userId: "5003924",
        userName: "MavibetTestUser5",
        hasInsurance: null,
        isCanceled: false,
        status: "DECISION",
        policies: {
          isBetsHidden: true,
          isNicknameHidden: true,
        },
        hands: [
          {
            cards: [
              {
                suit: "clubs",
                value: "2",
                isHidden: false,
                code: "1021",
              },
              {
                suit: "clubs",
                value: "3",
                isHidden: false,
                code: "1031",
              },
            ],
            value: [5],
            result: null,
            isCanceled: false,
          },
        ],
        bets: [
          {
            type: "ante",
            amount: 20,
            uuid: "d3764f4c-6eb5-11ef-9574-0a8fa6966535",
            isCanceled: false,
            chips: [
              "385f0654-d08c-4633-afed-6b8bbb5a84bf",
              "385f0654-d08c-4633-afed-6b8bbb5a84bf",
            ],
            amountPayed: null,
          },
          {
            type: "21p3",
            amount: 10,
            uuid: "d3a481d2-6eb5-11ef-9574-0a8fa6966535",
            isCanceled: false,
            chips: ["385f0654-d08c-4633-afed-6b8bbb5a84bf"],
            amountPayed: null,
          },
        ],
        earlyAction: null,
        lastAction: null,
        winningStreak: 0,
      },
      {
        userId: "5003924",
        userName: "MavibetTestUser5",
        hasInsurance: null,
        isCanceled: false,
        status: "WAITING",
        policies: {
          isBetsHidden: true,
          isNicknameHidden: true,
        },
        hands: [
          {
            cards: [
              {
                suit: "hearts",
                value: "2",
                isHidden: false,
                code: "1022",
              },
              {
                suit: "spades",
                value: "3",
                isHidden: false,
                code: "1033",
              },
            ],
            value: [5],
            result: null,
            isCanceled: false,
          },
        ],
        bets: [
          {
            type: "ante",
            amount: 30,
            uuid: "d3c4e094-6eb5-11ef-9574-0a8fa6966535",
            isCanceled: false,
            chips: [
              "385f0654-d08c-4633-afed-6b8bbb5a84bf",
              "385f0654-d08c-4633-afed-6b8bbb5a84bf",
              "385f0654-d08c-4633-afed-6b8bbb5a84bf",
            ],
            amountPayed: null,
          },
          {
            type: "perfectPairs",
            amount: 10,
            uuid: "d3e98eee-6eb5-11ef-9574-0a8fa6966535",
            isCanceled: false,
            chips: ["385f0654-d08c-4633-afed-6b8bbb5a84bf"],
            amountPayed: 0,
          },
          {
            type: "21p3",
            amount: 10,
            uuid: "d40cd958-6eb5-11ef-9574-0a8fa6966535",
            isCanceled: false,
            chips: ["385f0654-d08c-4633-afed-6b8bbb5a84bf"],
            amountPayed: null,
          },
        ],
        earlyAction: null,
        lastAction: null,
        winningStreak: 0,
      },
      {
        userId: "5003924",
        userName: "MavibetTestUser5",
        hasInsurance: null,
        isCanceled: false,
        status: "WAITING",
        policies: {
          isBetsHidden: true,
          isNicknameHidden: true,
        },
        hands: [
          {
            cards: [
              {
                suit: "spades",
                value: "2",
                isHidden: false,
                code: "1023",
              },
              {
                suit: "diamonds",
                value: "3",
                isHidden: false,
                code: "1034",
              },
            ],
            value: [5],
            result: null,
            isCanceled: false,
          },
        ],
        bets: [
          {
            type: "ante",
            amount: 10,
            uuid: "d4340a14-6eb5-11ef-9574-0a8fa6966535",
            isCanceled: false,
            chips: ["385f0654-d08c-4633-afed-6b8bbb5a84bf"],
            amountPayed: null,
          },
        ],
        earlyAction: null,
        lastAction: null,
        winningStreak: 0,
      },
      {
        userId: null,
        userName: null,
        hasInsurance: null,
        isCanceled: false,
        status: "WAITING",
        policies: null,
        hands: [
          {
            cards: [],
            value: [0],
            result: null,
            isCanceled: false,
          },
        ],
        bets: [],
        earlyAction: null,
        lastAction: null,
        winningStreak: 0,
      },
      {
        userId: null,
        userName: null,
        hasInsurance: null,
        isCanceled: false,
        status: "WAITING",
        policies: null,
        hands: [
          {
            cards: [],
            value: [0],
            result: null,
            isCanceled: false,
          },
        ],
        bets: [],
        earlyAction: null,
        lastAction: null,
        winningStreak: 0,
      },
    ],
    dealer: {
      hand: {
        cards: [
          {
            suit: "diamonds",
            value: "2",
            isHidden: false,
            code: "1024",
          },
          {
            suit: null,
            value: null,
            code: null,
            isHidden: true,
          },
        ],
        value: [2],
        result: null,
        isCanceled: false,
      },
      status: "WAITING",
    },
    shoe: {
      isChangeRequired: false,
      code: "0001010007",
      previousCode: "0001010006",
    },
    current_seat: 2,
    current_hand: 0,
    betting_start_time: "2024-09-09T14:14:08.668Z",
    insurance_start_time: null,
    decision_start_time: "2024-09-09T14:23:09.840Z",
  },
  PAUSED7: {
    roundId: "b8b03c91-5bc0-49e7-ac26-21b8d18fa255",
    roundNumber: "652",
    status: "PAUSED",
    prePauseStatus: "AWAIT_CARD",
    lastPauseAction: "PAUSE",
    dealNowUsers: [],
    seats: [
      {
        userId: null,
        userName: null,
        hasInsurance: null,
        isCanceled: false,
        status: "PLAYED",
        policies: null,
        hands: [
          {
            cards: [],
            value: [0],
            result: null,
            isCanceled: false,
          },
        ],
        bets: [],
        earlyAction: null,
        lastAction: null,
        winningStreak: 0,
      },
      {
        userId: null,
        userName: null,
        hasInsurance: null,
        isCanceled: false,
        status: "PLAYED",
        policies: null,
        hands: [
          {
            cards: [],
            value: [0],
            result: null,
            isCanceled: false,
          },
        ],
        bets: [],
        earlyAction: null,
        lastAction: null,
        winningStreak: 0,
      },
      {
        userId: "5003924",
        userName: "MavibetTestUser5",
        hasInsurance: null,
        isCanceled: false,
        status: "PLAYED",
        policies: {
          isBetsHidden: true,
          isNicknameHidden: true,
        },
        hands: [
          {
            cards: [],
            value: [0],
            result: null,
            isCanceled: false,
          },
        ],
        bets: [],
        earlyAction: null,
        lastAction: null,
        winningStreak: 0,
      },
      {
        userId: "5003924",
        userName: "MavibetTestUser5",
        hasInsurance: null,
        isCanceled: false,
        status: "PLAYED",
        policies: {
          isBetsHidden: true,
          isNicknameHidden: true,
        },
        hands: [
          {
            cards: [
              {
                suit: "diamonds",
                value: "4",
                isHidden: false,
                code: "1044",
              },
              {
                suit: "spades",
                value: "5",
                isHidden: false,
                code: "1053",
              },
            ],
            value: [9],
            result: null,
            isCanceled: false,
          },
        ],
        bets: [
          {
            type: "ante",
            amount: 10,
            uuid: "0ff9bac8-74e0-11ef-8a76-16d1b171df00",
            isCanceled: false,
            chips: ["385f0654-d08c-4633-afed-6b8bbb5a84bf"],
            amountPayed: null,
          },
        ],
        earlyAction: null,
        lastAction: "STAND",
        winningStreak: 0,
      },
      {
        userId: null,
        userName: null,
        hasInsurance: null,
        isCanceled: false,
        status: "PLAYED",
        policies: null,
        hands: [
          {
            cards: [],
            value: [0],
            result: null,
            isCanceled: false,
          },
        ],
        bets: [],
        earlyAction: null,
        lastAction: null,
        winningStreak: 0,
      },
      {
        userId: null,
        userName: null,
        hasInsurance: null,
        isCanceled: false,
        status: "PLAYED",
        policies: null,
        hands: [
          {
            cards: [],
            value: [0],
            result: null,
            isCanceled: false,
          },
        ],
        bets: [],
        earlyAction: null,
        lastAction: null,
        winningStreak: 0,
      },
      {
        userId: null,
        userName: null,
        hasInsurance: null,
        isCanceled: false,
        status: "PLAYED",
        policies: null,
        hands: [
          {
            cards: [],
            value: [0],
            result: null,
            isCanceled: false,
          },
        ],
        bets: [],
        earlyAction: null,
        lastAction: null,
        winningStreak: 0,
      },
    ],
    dealer: {
      hand: {
        cards: [
          {
            suit: "clubs",
            value: "5",
            isHidden: false,
            code: "1051",
          },
          {
            suit: null,
            value: null,
            code: null,
            isHidden: true,
          },
        ],
        value: [5],
        result: null,
        isCanceled: false,
      },
      status: "DECISION",
    },
    shoe: {
      isChangeRequired: false,
      code: "0001010007",
      previousCode: "0001010006",
    },
    current_seat: 7,
    current_hand: 0,
    betting_start_time: "2024-09-17T10:31:35.781Z",
    insurance_start_time: null,
    decision_start_time: "2024-09-17T10:32:09.091Z",
  },
  AWAIT_CARD7: {
    roundId: "b8b03c91-5bc0-49e7-ac26-21b8d18fa255",
    roundNumber: "652",
    status: "AWAIT_CARD",
    prePauseStatus: "AWAIT_CARD",
    lastPauseAction: "RESUME",
    dealNowUsers: [],
    seats: [
      {
        userId: null,
        userName: null,
        hasInsurance: null,
        isCanceled: false,
        status: "PLAYED",
        policies: null,
        hands: [
          {
            cards: [],
            value: [0],
            result: null,
            isCanceled: false,
          },
        ],
        bets: [],
        earlyAction: null,
        lastAction: null,
        winningStreak: 0,
      },
      {
        userId: null,
        userName: null,
        hasInsurance: null,
        isCanceled: false,
        status: "PLAYED",
        policies: null,
        hands: [
          {
            cards: [],
            value: [0],
            result: null,
            isCanceled: false,
          },
        ],
        bets: [],
        earlyAction: null,
        lastAction: null,
        winningStreak: 0,
      },
      {
        userId: "5003924",
        userName: "MavibetTestUser5",
        hasInsurance: null,
        isCanceled: false,
        status: "PLAYED",
        policies: {
          isBetsHidden: true,
          isNicknameHidden: true,
        },
        hands: [
          {
            cards: [],
            value: [0],
            result: null,
            isCanceled: false,
          },
        ],
        bets: [],
        earlyAction: null,
        lastAction: null,
        winningStreak: 0,
      },
      {
        userId: "5003924",
        userName: "MavibetTestUser5",
        hasInsurance: null,
        isCanceled: false,
        status: "PLAYED",
        policies: {
          isBetsHidden: true,
          isNicknameHidden: true,
        },
        hands: [
          {
            cards: [
              {
                suit: "diamonds",
                value: "4",
                isHidden: false,
                code: "1044",
              },
              {
                suit: "spades",
                value: "5",
                isHidden: false,
                code: "1053",
              },
            ],
            value: [9],
            result: null,
            isCanceled: false,
          },
        ],
        bets: [
          {
            type: "ante",
            amount: 10,
            uuid: "0ff9bac8-74e0-11ef-8a76-16d1b171df00",
            isCanceled: false,
            chips: ["385f0654-d08c-4633-afed-6b8bbb5a84bf"],
            amountPayed: null,
          },
        ],
        earlyAction: null,
        lastAction: "STAND",
        winningStreak: 0,
      },
      {
        userId: null,
        userName: null,
        hasInsurance: null,
        isCanceled: false,
        status: "PLAYED",
        policies: null,
        hands: [
          {
            cards: [],
            value: [0],
            result: null,
            isCanceled: false,
          },
        ],
        bets: [],
        earlyAction: null,
        lastAction: null,
        winningStreak: 0,
      },
      {
        userId: null,
        userName: null,
        hasInsurance: null,
        isCanceled: false,
        status: "PLAYED",
        policies: null,
        hands: [
          {
            cards: [],
            value: [0],
            result: null,
            isCanceled: false,
          },
        ],
        bets: [],
        earlyAction: null,
        lastAction: null,
        winningStreak: 0,
      },
      {
        userId: null,
        userName: null,
        hasInsurance: null,
        isCanceled: false,
        status: "PLAYED",
        policies: null,
        hands: [
          {
            cards: [],
            value: [0],
            result: null,
            isCanceled: false,
          },
        ],
        bets: [],
        earlyAction: null,
        lastAction: null,
        winningStreak: 0,
      },
    ],
    dealer: {
      hand: {
        cards: [
          {
            suit: "clubs",
            value: "5",
            isHidden: false,
            code: "1051",
          },
          {
            suit: null,
            value: null,
            code: null,
            isHidden: true,
          },
        ],
        value: [5],
        result: null,
        isCanceled: false,
      },
      status: "DECISION",
    },
    shoe: {
      isChangeRequired: false,
      code: "0001010007",
      previousCode: "0001010006",
    },
    current_seat: 7,
    current_hand: 0,
    betting_start_time: "2024-09-17T10:31:35.781Z",
    insurance_start_time: null,
    decision_start_time: "2024-09-17T10:32:09.091Z",
  },
  CANCELED7: {
    roundId: "b8b03c91-5bc0-49e7-ac26-21b8d18fa255",
    roundNumber: "652",
    status: "CANCELED",
    prePauseStatus: "AWAIT_CARD",
    lastPauseAction: "PAUSE",
    dealNowUsers: [],
    seats: [
      {
        userId: null,
        userName: null,
        hasInsurance: null,
        isCanceled: false,
        status: "PLAYED",
        policies: null,
        hands: [
          {
            cards: [],
            value: [0],
            result: null,
            isCanceled: false,
          },
        ],
        bets: [],
        earlyAction: null,
        lastAction: null,
        winningStreak: 0,
      },
      {
        userId: null,
        userName: null,
        hasInsurance: null,
        isCanceled: false,
        status: "PLAYED",
        policies: null,
        hands: [
          {
            cards: [],
            value: [0],
            result: null,
            isCanceled: false,
          },
        ],
        bets: [],
        earlyAction: null,
        lastAction: null,
        winningStreak: 0,
      },
      {
        userId: "5003924",
        userName: "MavibetTestUser5",
        hasInsurance: null,
        isCanceled: false,
        status: "PLAYED",
        policies: {
          isBetsHidden: true,
          isNicknameHidden: true,
        },
        hands: [
          {
            cards: [],
            value: [0],
            result: null,
            isCanceled: false,
          },
        ],
        bets: [],
        earlyAction: null,
        lastAction: null,
        winningStreak: 0,
      },
      {
        userId: "5003924",
        userName: "MavibetTestUser5",
        hasInsurance: null,
        isCanceled: false,
        status: "PLAYED",
        policies: {
          isBetsHidden: true,
          isNicknameHidden: true,
        },
        hands: [
          {
            cards: [
              {
                suit: "diamonds",
                value: "4",
                isHidden: false,
                code: "1044",
              },
              {
                suit: "spades",
                value: "5",
                isHidden: false,
                code: "1053",
              },
            ],
            value: [9],
            result: null,
            isCanceled: false,
          },
        ],
        bets: [],
        earlyAction: null,
        lastAction: "STAND",
        winningStreak: 0,
      },
      {
        userId: null,
        userName: null,
        hasInsurance: null,
        isCanceled: false,
        status: "PLAYED",
        policies: null,
        hands: [
          {
            cards: [],
            value: [0],
            result: null,
            isCanceled: false,
          },
        ],
        bets: [],
        earlyAction: null,
        lastAction: null,
        winningStreak: 0,
      },
      {
        userId: null,
        userName: null,
        hasInsurance: null,
        isCanceled: false,
        status: "PLAYED",
        policies: null,
        hands: [
          {
            cards: [],
            value: [0],
            result: null,
            isCanceled: false,
          },
        ],
        bets: [],
        earlyAction: null,
        lastAction: null,
        winningStreak: 0,
      },
      {
        userId: null,
        userName: null,
        hasInsurance: null,
        isCanceled: false,
        status: "PLAYED",
        policies: null,
        hands: [
          {
            cards: [],
            value: [0],
            result: null,
            isCanceled: false,
          },
        ],
        bets: [],
        earlyAction: null,
        lastAction: null,
        winningStreak: 0,
      },
    ],
    dealer: {
      hand: {
        cards: [
          {
            suit: "clubs",
            value: "5",
            isHidden: false,
            code: "1051",
          },
          {
            suit: null,
            value: null,
            code: null,
            isHidden: true,
          },
        ],
        value: [5],
        result: null,
        isCanceled: false,
      },
      status: "DECISION",
    },
    shoe: {
      isChangeRequired: false,
      code: "0001010007",
      previousCode: "0001010006",
    },
    current_seat: 7,
    current_hand: 0,
    betting_start_time: "2024-09-17T10:31:35.781Z",
    insurance_start_time: null,
    decision_start_time: "2024-09-17T10:32:09.091Z",
  },
  AWAITING_BETSnull: {
    roundId: "5a7a33da-2687-4264-80de-a0da976e602e",
    roundNumber: "653",
    status: "AWAITING_BETS",
    prePauseStatus: null,
    lastPauseAction: null,
    dealNowUsers: [],
    seats: [
      {
        userId: null,
        userName: null,
        hasInsurance: null,
        isCanceled: false,
        status: "WAITING",
        policies: null,
        hands: [
          {
            cards: [],
            value: [0],
            result: null,
            isCanceled: false,
          },
        ],
        bets: [],
        earlyAction: null,
        lastAction: null,
        winningStreak: 0,
      },
      {
        userId: null,
        userName: null,
        hasInsurance: null,
        isCanceled: false,
        status: "WAITING",
        policies: null,
        hands: [
          {
            cards: [],
            value: [0],
            result: null,
            isCanceled: false,
          },
        ],
        bets: [],
        earlyAction: null,
        lastAction: null,
        winningStreak: 0,
      },
      {
        userId: null,
        userName: null,
        hasInsurance: null,
        isCanceled: false,
        status: "WAITING",
        policies: null,
        hands: [
          {
            cards: [],
            value: [0],
            result: null,
            isCanceled: false,
          },
        ],
        bets: [],
        earlyAction: null,
        lastAction: null,
        winningStreak: 0,
      },
      {
        userId: null,
        userName: null,
        hasInsurance: null,
        isCanceled: false,
        status: "WAITING",
        policies: null,
        hands: [
          {
            cards: [],
            value: [0],
            result: null,
            isCanceled: false,
          },
        ],
        bets: [],
        earlyAction: null,
        lastAction: null,
        winningStreak: 0,
      },
      {
        userId: null,
        userName: null,
        hasInsurance: null,
        isCanceled: false,
        status: "WAITING",
        policies: null,
        hands: [
          {
            cards: [],
            value: [0],
            result: null,
            isCanceled: false,
          },
        ],
        bets: [],
        earlyAction: null,
        lastAction: null,
        winningStreak: 0,
      },
      {
        userId: null,
        userName: null,
        hasInsurance: null,
        isCanceled: false,
        status: "WAITING",
        policies: null,
        hands: [
          {
            cards: [],
            value: [0],
            result: null,
            isCanceled: false,
          },
        ],
        bets: [],
        earlyAction: null,
        lastAction: null,
        winningStreak: 0,
      },
      {
        userId: null,
        userName: null,
        hasInsurance: null,
        isCanceled: false,
        status: "WAITING",
        policies: null,
        hands: [
          {
            cards: [],
            value: [0],
            result: null,
            isCanceled: false,
          },
        ],
        bets: [],
        earlyAction: null,
        lastAction: null,
        winningStreak: 0,
      },
    ],
    dealer: {
      hand: {
        cards: [],
        value: [0],
        result: null,
        isCanceled: false,
      },
      status: "WAITING",
    },
    shoe: {
      isChangeRequired: false,
      code: "0001010007",
      previousCode: "0001010006",
    },
    current_seat: null,
    current_hand: null,
    betting_start_time: null,
    insurance_start_time: null,
    decision_start_time: null,
  },
  INIT3: {
    roundId: "5a7a33da-2687-4264-80de-a0da976e602e",
    roundNumber: "653",
    status: "INIT",
    prePauseStatus: null,
    lastPauseAction: null,
    dealNowUsers: [],
    seats: [
      {
        userId: null,
        userName: null,
        hasInsurance: null,
        isCanceled: false,
        status: "PLAYED",
        policies: null,
        hands: [
          {
            cards: [],
            value: [0],
            result: null,
            isCanceled: false,
          },
        ],
        bets: [],
        earlyAction: null,
        lastAction: null,
        winningStreak: 0,
      },
      {
        userId: null,
        userName: null,
        hasInsurance: null,
        isCanceled: false,
        status: "PLAYED",
        policies: null,
        hands: [
          {
            cards: [],
            value: [0],
            result: null,
            isCanceled: false,
          },
        ],
        bets: [],
        earlyAction: null,
        lastAction: null,
        winningStreak: 0,
      },
      {
        userId: null,
        userName: null,
        hasInsurance: null,
        isCanceled: false,
        status: "PLAYED",
        policies: null,
        hands: [
          {
            cards: [],
            value: [0],
            result: null,
            isCanceled: false,
          },
        ],
        bets: [],
        earlyAction: null,
        lastAction: null,
        winningStreak: 0,
      },
      {
        userId: "5003924",
        userName: "MavibetTestUser5",
        hasInsurance: null,
        isCanceled: false,
        status: "DECISION",
        policies: {
          isBetsHidden: true,
          isNicknameHidden: true,
        },
        hands: [
          {
            cards: [],
            value: [0],
            result: null,
            isCanceled: false,
          },
        ],
        bets: [
          {
            type: "ante",
            amount: 10,
            uuid: "88b7dd20-74f2-11ef-855a-16d1b171df00",
            isCanceled: false,
            chips: ["385f0654-d08c-4633-afed-6b8bbb5a84bf"],
            amountPayed: null,
          },
          {
            type: "21p3",
            amount: 10,
            uuid: "890b6422-74f2-11ef-855a-16d1b171df00",
            isCanceled: false,
            chips: ["385f0654-d08c-4633-afed-6b8bbb5a84bf"],
            amountPayed: null,
          },
          {
            type: "perfectPairs",
            amount: 10,
            uuid: "893fad9a-74f2-11ef-855a-16d1b171df00",
            isCanceled: false,
            chips: ["385f0654-d08c-4633-afed-6b8bbb5a84bf"],
            amountPayed: null,
          },
        ],
        earlyAction: null,
        lastAction: null,
        winningStreak: 0,
      },
      {
        userId: null,
        userName: null,
        hasInsurance: null,
        isCanceled: false,
        status: "WAITING",
        policies: null,
        hands: [
          {
            cards: [],
            value: [0],
            result: null,
            isCanceled: false,
          },
        ],
        bets: [],
        earlyAction: null,
        lastAction: null,
        winningStreak: 0,
      },
      {
        userId: null,
        userName: null,
        hasInsurance: null,
        isCanceled: false,
        status: "WAITING",
        policies: null,
        hands: [
          {
            cards: [],
            value: [0],
            result: null,
            isCanceled: false,
          },
        ],
        bets: [],
        earlyAction: null,
        lastAction: null,
        winningStreak: 0,
      },
      {
        userId: null,
        userName: null,
        hasInsurance: null,
        isCanceled: false,
        status: "WAITING",
        policies: null,
        hands: [
          {
            cards: [],
            value: [0],
            result: null,
            isCanceled: false,
          },
        ],
        bets: [],
        earlyAction: null,
        lastAction: null,
        winningStreak: 0,
      },
    ],
    dealer: {
      hand: {
        cards: [],
        value: [0],
        result: null,
        isCanceled: false,
      },
      status: "WAITING",
    },
    shoe: {
      isChangeRequired: false,
      code: "0001010007",
      previousCode: "0001010006",
    },
    current_seat: 3,
    current_hand: 0,
    betting_start_time: "2024-09-17T12:43:49.075Z",
    insurance_start_time: null,
    decision_start_time: null,
  },
};
