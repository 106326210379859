import { EGameState, useGameStateActions } from '@/common/state/gameTypes/roulette/gameState';
import { TMaintenanceEndEvent } from '@/common/services/sockets/useMaintenanceEnd/types';

type MaintenanceEndEventOutput = (socket: TMaintenanceEndEvent) => void;

export const useMaintenanceEnd = (): MaintenanceEndEventOutput => {
   const { handleChangeGameState, handleSetGameStatusAsSuccess } = useGameStateActions();

   return () => {
      handleSetGameStatusAsSuccess();
      handleChangeGameState(EGameState.WAIT_NEXT_ROUND);
   };
};
