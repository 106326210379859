import { mapConfigCommonBets } from 'core';

import { rouletteColorGetter } from '@/common/roulette/colors';
import { ECellColor } from '@/common/roulette/constants';
import { checkIsCancelRound, autoKeys } from '@/common/utils';
import { useSelectedChipSelector } from '@/common/modules/ChipTray';
import { winNumberDTO } from '@/common/helpers';
import { LimitTooltipWrapper } from '@/common/modules';
import { IPlayChipSound } from '@/common/entities';

import { betsCreator } from '#/core/betsCreator';
import { useHoverSpecialBet } from '#/modules/Favourite/components/SpecialBets/hooks/useHoverSpecialBet';
import { WaitForTheNextStateTooltip } from '#/modules/Tooltips';
import { useWinHistory } from '#/modules/WinHistory';
import { withChipSound } from '#/hocs';

import * as S from './LastResults.styles';

const lastResultsColors = {
   [ECellColor.Black]: '#fff',
   [ECellColor.Red]: '#f00000',
   [ECellColor.Green]: '#23b04e',
} as const;

const getColor = rouletteColorGetter(lastResultsColors);

const LastResults = ({ playChipSound }: IPlayChipSound) => {
   const { winHistory } = useWinHistory();
   const wrapperRef = useHoverSpecialBet();
   const { value: chipValue } = useSelectedChipSelector();

   const handleSetBetClick = (lastResultNumber: string) => {
      const { id, type, numbers } = mapConfigCommonBets[lastResultNumber];

      betsCreator.makeCommonBet({
         id,
         type,
         numbers,
         amount: chipValue,
      });
      playChipSound();
   };

   return (
      <S.LastResultsWrapper ref={wrapperRef}>
         {autoKeys(
            winHistory.map((lastResultNumber: string) => {
               const numericLastResultNumber = winNumberDTO(lastResultNumber);

               return (
                  <LimitTooltipWrapper>
                     {({ handleShowLimitTooltip }) => (
                        <WaitForTheNextStateTooltip>
                           {checkIsCancelRound(numericLastResultNumber) ? (
                              <S.ResultNumberCancelRound
                                 color={lastResultsColors[ECellColor.Black]}
                              />
                           ) : (
                              <S.ResultNumber
                                 data-highlight-cells={numericLastResultNumber}
                                 color={getColor(numericLastResultNumber)}
                                 id={`last-result-cell-${numericLastResultNumber}`}
                                 handleAction={(e) => {
                                    handleSetBetClick(numericLastResultNumber);
                                    handleShowLimitTooltip(e[0]);
                                 }}
                              >
                                 {numericLastResultNumber}
                              </S.ResultNumber>
                           )}
                        </WaitForTheNextStateTooltip>
                     )}
                  </LimitTooltipWrapper>
               );
            }),
         )}
      </S.LastResultsWrapper>
   );
};

export const LastResultsWithChipSound = withChipSound(LastResults);
