import { LimitTooltipView } from '@/common/modules';

import { useTranslation } from '#/translates';

export const LimitTooltipContainer = () => {
   const { t } = useTranslation();

   return (
      <LimitTooltipView
         maxLimitMessage={t('tooltip.limit.maxLimit')}
         minLimitMessage={t('tooltip.limit.minLimit')}
      />
   );
};
