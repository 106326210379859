/* eslint-disable */
export const en = {
   translation: {
      track: {
         raceTrack: 'Parkur',
         bettingMap: 'Betting map',
      },
      menu: {
         lobby: 'Lobby',
         history: 'History',
         help: 'Help',
         settings: 'Settings',
      },
      gameId: 'ID',
      balance: 'Balance',
      bet: 'Bet',
      sidebars: {
         menu: {
            title: 'Menu',
         },
         settings: {
            title: 'Settings',
            volume: 'Volume',
            gameSounds: 'Game Sounds ',
            dealerSounds: 'Studio Sounds',
            dealerRate: 'Enable Rate Game Presenter',
            videoQuality: 'Video Quality',
            currentQuality: 'Current quality',
            lowQuality: 'Low',
            mediumQuality: 'Medium',
            highQuality: 'High',
            autoQuality: 'Auto',
            announcementsSounds: 'Auto Roulette Voice Announcements',
         },
         help: {
            title: 'Help',
            search: {
               placeholder: 'Enter a text',
            },
            singleZeroRoulette: {
               gameOverview: {
                  title: 'Game Overview',
                  content: [
                     {
                        text: 'Single Zero Roulette is an exciting virtual adaptation of the classic casino game that offers an immersive and interactive experience for players. Hosted by professional dealers in real-time, this game allows players to enjoy the thrill of roulette from the comfort of their own homes. The game follows the standard rules of single zero roulette, enhancing the authenticity and providing an engaging gameplay experience.',
                     },
                  ],
               },
               objective: {
                  title: 'Objective',
                  content: [
                     {
                        text: 'The objective of Single Zero Roulette is to correctly predict the outcome of a spin of the roulette wheel. Players place bets on various betting options available, including individual numbers, groups of numbers, colours, or odd/even, and if their bet matches the outcome, they win.',
                     },
                  ],
               },
               gamePlayFeatures: {
                  title: 'Gameplay Features',
                  content: [
                     {
                        text: 'Live Dealers: Experienced and friendly dealers host the game, creating a realistic and interactive atmosphere. They spin the wheel and announce the results, providing a captivating gaming experience.',
                     },
                     {
                        text: 'Real-Time Streaming: The game is streamed in real-time using high-quality video and audio, ensuring players can observe every aspect of the gameplay with clarity. Players can interact with the dealer and other participants through a live chat feature.',
                     },
                     {
                        text: 'Single Zero Wheel: Single Zero Roulette uses a European-style roulette wheel, which contains numbers 0 to 36. This wheel has a single green zero pocket, giving players better odds compared to double zero roulette.',
                     },
                     {
                        text: 'Bet Options: Players have a variety of betting options available, including inside bets (betting on individual numbers or small groups) and outside bets (betting on larger groups, such as colours or odd/even). The game interface provides an intuitive platform for placing bets.',
                     },
                     {
                        text: 'Bet Limits: Each table in Single Zero Roulette has predefined minimum and maximum bet limits, allowing players to choose a table that suits their preferred betting range.',
                     },
                     {
                        text: 'Statistics and History: The game provides detailed statistics and a comprehensive history of previous spins, enabling players to make informed betting decisions based on past results.',
                     },
                  ],
               },
               howToPlay: {
                  title: 'How to Play',
                  content: [
                     {
                        text: 'Once at the table, players use the interface to place their bets by selecting their desired chip value and placing it on the corresponding betting areas on the virtual roulette table.',
                     },
                     {
                        text: 'When the betting time expires, the dealer spins the wheel and launches the ball. Players can watch the spinning wheel through the live video stream.',
                     },
                     {
                        text: 'After the ball comes to a stop in one of the numbered pockets, the dealer announces the winning number.',
                     },
                     {
                        text: "Winning bets are automatically settled, and the corresponding payouts are credited to the players' accounts. Players can then choose to continue placing bets for the next round.",
                     },
                  ],
               },
               tipsAndStrategies: {
                  title: 'Tips and Strategies',
                  content: [
                     {
                        text: 'Familiarize yourself with the different types of bets available and their corresponding odds to make informed betting decisions.',
                     },
                     {
                        text: 'Manage your bankroll wisely and set limits for your bets to ensure responsible gambling.',
                     },
                     {
                        text: "Utilize the game's statistics and history to spot any patterns or trends that might help inform your betting strategy.",
                     },
                     {
                        text: 'Interact with the live dealer and fellow players to enhance your overall gaming experience.',
                     },
                  ],
               },
               gameRound: {
                  title: 'Game Round',
                  content: [
                     {
                        title: 'Betting Time',
                        text: 'Bets should be placed during the betting time on the table. To place a bet, chip value must be selected and placed on one or more bet spots on the betting grid or racetrack. Any placed bets that are below the minimum bet limit for the table are not valid and will not be accepted. Any placed bets that are above the maximum bet limit for the table are automatically adjusted to match the maximum limit. If you have joined the game round in progress, please wait for the next round to place bets.',
                     },
                     {
                        title: 'Spinning',
                        text: 'After betting time has ended, Game Presenter will release the Roulette ball into the ball track of the spinning wheel. Ball is spun within the Roulette wheel. After the status "NO MORE BETS" appear bets are not accepted any more. Placed bets can not be modified. ',
                     },
                     {
                        title: 'Game Outcome',
                        text: ' The ball will eventually land in one of the numbered pockets within the wheel and winning number will be announced by Game Presenter and appear on UI.  The player will win if they have placed a bet that covers that particular number. A winning number is valid only when the spin was valid.   Valid Spin is when:   The Roulette ball is spun by the Game Presenter in the direction opposite to the rotation of the wheel and completes at least three revolutions around the track of the wheel before dropping to constitute valid spin. ',
                     },
                  ],
               },
               rules: {
                  title: 'Rules',
                  content: [
                     {
                        title: 'Limits',
                        text: 'The Bet Limits should be always available during the game play and show minimum and maximum allowed bet limits at the table. The Min and Max sign on the table indicate the limits for Strait-Up bets. All the limits are displayed on the Limits and Payouts panel. In Roulette games the following bets have their own limits: Straight Up, Split, Street, Corner, Basket, Line, Column, Dozen, Red, Black, Even, Odd, Low 1-18, High 19-36. Min and Max limits are configurable in Backoffice and sent from server.',
                     },
                     {
                        title: 'Payouts',
                        text: 'Player can view the Table Payouts by clicking on Limits on the table and overlay with information will be displayed. Payouts are always the same. Each bet has the following payout: ',
                     },
                  ],
               },
               betsPayout: {
                  titles: ['Bet', 'Pays'],
                  list: [
                     { bet: 'Straight up', payout: '35:1' },
                     { bet: 'Split', payout: '17:1' },
                     { bet: 'Street', payout: '11:1' },
                     { bet: 'Corner/Basket', payout: '8:1' },
                     { bet: 'Line', payout: '5:1' },
                     { bet: 'Column/Dozen', payout: '2:1' },
                     { bet: 'Red/Black', payout: '1:1' },
                     { bet: 'Even/Odd', payout: '1:1' },
                     { bet: '1-18/19-36', payout: '1:1' },
                  ],
               },
               placingBets: {
                  title: 'Placing Bets',
                  content: [
                     {
                        text: 'Player must have sufficient balance to place a minimum bet to participate in the game.',
                     },
                     {
                        text: 'Chip tray displays available chip values and allows you to select the value of chips you want to bet',
                     },
                     {
                        text: 'Chips of thousand denomination are displayed as number of thousands and character K, for example, chip of value 1000 will be displayed as 1k.',
                     },
                     {
                        text: 'Once the chip size is selected, a bet can be placed by clicking/tapping the betting area on the game table.',
                     },
                     { text: 'More than one chip can be placed on a single betting area.' },
                     {
                        text: 'Total Bet will show the total bet amount on bets for the current game round',
                     },
                     {
                        text: 'Once the maximum bet limit is reached, no additional funds will be accepted for that bet.',
                     },
                  ],
               },
               betTypes: {
                  title: 'Bet types',
                  content: [
                     {
                        text: 'There are multiple ways to bet in Roulette game. Each bet type has its own payout rate. Bets can cover a single number or multiple numbers.',
                     },
                  ],
               },
               insideBets: {
                  title: 'Inside Bets',
                  content: [
                     {
                        text: 'Bets placed on the number bet spots or between the numbered bet spots within the betting grid are called Inside Bets.',
                     },
                     { text: 'Straight Up - bet placed on a single number.' },
                     {
                        text: 'Split - bet placed on the line between any two numbers, either on the vertical or horizontal.',
                     },
                     {
                        text: 'Street - bet placed at the end of any row of numbers. A Street Bet covers three numbers. Bets placed at the junctions 0/1/2 and 0/2/3 are also accepted as street bets.',
                     },
                     {
                        text: 'Corner - bet placed at corner (central intersection) where four numbers meet. All four numbers are covered. Basket bet placed at the junction of 0/1/2/3 is also accepted as corner bet.',
                     },
                     {
                        text: 'Line - bet at the end of two rows on the intersection between the two rows. A line bet covers all the numbers in both rows, a total of six numbers.',
                     },
                  ],
               },
               outsideBets: {
                  title: 'Outside Bets',
                  content: [
                     {
                        text: 'Bets that are made on the bet spots below and to the right of the numbered board are called outside bets. The zero is not covered by any of these bet spots.',
                     },
                     {
                        text: 'Column - box marked "2 to 1" at the end of the column covers all 12 numbers in that column.',
                     },
                     {
                        text: 'Dozen - box marked "1st12", "2n12", "3rd12" covers the 12 numbers alongside the box.',
                        list: [
                           '1-18/19-36 - covers the first or second bet set of 18 numbers.',
                           'Even/Odd - covers the 18 even or 18 odd numbers.',
                           'Red/Black - covers 18 red or 18 black numbers.',
                        ],
                     },
                     { text: 'Racetrack Bets' },
                     {
                        text: 'These bets are placed using Roulette racetrack, which represents the numbers as they appear on the Roulette wheel, rather than the Roulette table.',
                     },
                     {
                        text: 'Provides unique betting options: racetrack bets and neighbour bets. Racetrack bets includes: Voisins du Zero, Zero, Tiers, Orphelins.',
                     },
                  ],
               },
               racetrackBets: {
                  title: 'Racetrack Bets',
                  content: [
                     {
                        text: 'A single-zero Roulette wheel is split into 4 sectors that corresponds to areas on the table. The traditional names of these sectors are:',
                     },
                  ],
               },
               voisinsDuZero: {
                  title: 'Voisins du Zero',
                  content: [
                     {
                        text: 'Covers a total of 17 numbers that include 22,25 and the numbers that lie between them on the side of the Roulette wheel that contains zero.',
                     },
                     {
                        text: '9 chips are placed as follows:',
                        list: [
                           '2 chips on the 0/2/3 street',
                           '1 chip on the 4/7 split',
                           '1 chip on the 12/15 split',
                           '1 chip on the 18/21 split',
                           '1 chip on the 19/22 split',
                           '2 chips on the 25/26/28/29 corner',
                           '1 chip on the 32/35 split',
                        ],
                     },
                  ],
               },
               jeuZero: {
                  title: 'Jeu Zero',
                  content: [
                     {
                        text: 'Covers zero and the 6 numbers in close proximity to zero on the Roulette wheel 12, 35, 3, 26, 0, 32 and 15',
                     },
                     {
                        text: '4 chips are placed as follows:',
                        list: [
                           '1 chip on the 0/3 split',
                           '1 chip on the 12/15 split',
                           '1 chip on 26 (straight up)',
                           '1 chip on the 32/35 split',
                        ],
                     },
                  ],
               },
               tiers: {
                  title: 'Tiers du Cylindre',
                  content: [
                     {
                        text: 'Covers a total of 12 numbers that include 27, 33 and the numbers that lie between them on the side of the Roulette wheel opposite to zero',
                     },
                     {
                        text: '6 chips are placed as follows:',
                        list: [
                           '1 chip on the 5/8 split',
                           '1 chip on the 10/11 split',
                           '1 chip on the 13/16 split',
                           '1 chip on the 23/24 split',
                           '1 chip on the 27/30 split',
                           '1 chip on the 33/36 split',
                        ],
                     },
                  ],
               },
               orphelins: {
                  title: 'Orphelins',
                  content: [
                     {
                        text: 'Covers a total of 8 numbers on the two segments of the Roulette wheel not covered by Voisins du Zero and Tiers bet above.',
                     },
                     {
                        text: '5 chips are placed as follows:',
                        list: [
                           '1 chip on 1 (straight up)',
                           '1 chip on the 6/9 split',
                           '1 chip on the 14/17 split',
                           '1 chip on the 17/20 split',
                           '1 chip on the 31/34 split',
                        ],
                     },
                  ],
               },
               neighbourBets: {
                  title: 'Neighbour’s Bets',
                  content: [
                     {
                        text: 'Covers 1 straight up number and from 0 to 9 of its neighbours from left and right, based on the number location on the wheel:',
                        list: [
                           'Player places from 1 chip (1 number + 0 left/0 right neighbours) to 19 chips (1 number + 9 left/9 right neighbours).',
                           'Player can select number or neighbours from 0 to 9.',
                        ],
                     },
                     {
                        text: 'Neighbour’s Bets can be placed with click/tap on "-" or "+" button to increase or decrease chosen neighbour bets to the right and left.',
                     },
                  ],
               },
               myBets: {
                  title: 'My Bets',
                  content: [
                     {
                        text: 'My Bet feature provides an option to save current bet for fast betting in future rounds. Also you can create custom sets of bets and save them for later play.',
                     },
                     {
                        text: 'Straight-Up bets. By press on any of bet options in this block you will place bets on all relevant straight-up positions on the betting map.',
                     },
                     {
                        text: 'For example, pressing “Red” on Straight-Up Bets panel will put chips on all red numbers on the betting map.',
                     },
                     {
                        text: 'Special bets have three betting options:',
                        list: [
                           'The Final Bet is made on the last digit of a number. For instance Final Four means that you bet on the numbers 4, 14, 24 and 34. The bet on Final 1–6 requires 4 chips, while Final 6–9 gets along with just 3 chips.',
                           'Complete bets - take all inside bets and puts them on a particular number.',
                           'Try My Luck - puts chips on 3, 5 or 7 numbers randomly selected by server.',
                        ],
                     },
                     {
                        text: 'Bet Generator opens Betting map or Race track for manual creation of a set of custom bets.',
                     },
                  ],
               },
               bettingFromStatistics: {
                  title: 'Betting from Statistics panels',
                  content: [
                     {
                        text: 'You can place bets straight from Statistics, Last Results and Chart panels',
                     },
                     {
                        text: 'Hover over relevant bet type will highlight it on the Betting Map or Racetrack. It is possible to bet from Statistics during Betting time only.',
                     },
                  ],
               },
               rebet: {
                  title: 'Rebet',
                  content: [
                     {
                        text: 'Clicking/Tapping on Rebet button will place bets on the table from the previous game round.',
                     },
                     {
                        text: 'Button appears if the player has already participated in the game. ',
                     },
                  ],
               },
               double: {
                  title: 'Double',
                  content: [
                     { text: "Clicking/Tapping on Double button will double all player's bets." },
                     {
                        text: 'Player can press Double as many times as he wants until his balance is sufficient to double the bet or he reaches bet limit.',
                     },
                  ],
               },
               undo: {
                  title: 'Undo',
                  content: [
                     {
                        text: 'Clicking/Tapping on Undo button will revoke last placed chip, Rebet and Double action.',
                     },
                     {
                        text: 'On the first entry Undo button is disabled. As soon as bet is placed Undo button is enabled.',
                     },
                     { text: 'All bets can be cleared by holding the Undo button.' },
                  ],
               },
               dragAndDrop: {
                  title: 'Drag and Drop Chips',
                  content: [
                     {
                        text: 'Drag and Drop Chips functionality allows the player to freely move chips that are already placed on the betting grid. Moving chips functionality is not supported on Racetrack.',
                     },
                     {
                        text: 'To select the chip, player needs to click & hold on the chip and move it to a different bet spot while it is being held.',
                     },
                  ],
               },
               autoplay: {
                  title: 'Autoplay',
                  content: [
                     {
                        text: 'The Autoplay feature is designed to enhance the convenience and efficiency of playing live online roulette. It enables players to set predefined betting strategies and parameters, allowing the game to automatically place bets on their behalf.',
                     },
                     {
                        text: 'You can use this feature to place bets automatically during predefined number of rounds if it is enabled for your casino.',
                     },
                     {
                        text: 'You can open Autoplay Panel where you can set a number of the Autoplay Rounds and limit conditions to stop autoplay.',
                     },
                     {
                        text: 'Counter on the Autoplay will notify you how many rounds you have left.',
                     },
                     {
                        text: 'You can see current conditions and change them.  New conditions are applied from the next round.',
                     },
                     {
                        text: 'If Autoplay will stop reaching set conditions, you will get notification.',
                     },
                  ],
               },
               statistics: {
                  title: 'Statistics',
                  content: [
                     {
                        text: 'By click on Statistics Icon player will open overlay with Statistics for the last 100, 200, 500 or 1000 rounds presented in a different ways – statistics wheel, bars or Last results layout.',
                     },
                  ],
               },
               returnToPlayer: {
                  title: 'Return to Player',
                  content: [
                     { text: 'The theoretical percentage return to player  (RTP) is 97.30%.' },
                  ],
               },
               cancelledGames: {
                  title: 'Cancelled Games',
                  content: [
                     { text: 'In some cases of technical issues game session can be interrupted.' },
                     {
                        text: 'All the players who are currently at the table are notified of the cancellation and all bets are returned to their accounts.',
                     },
                     { text: 'Cancelled round does not stop Autoplay.' },
                     {
                        text: 'Round bets will be returned but Autoplay conditions will apply to continue from the next round.',
                     },
                     { text: 'Cancelled game is not deducted from the Autoplay rounds.' },
                  ],
               },
               gameHistory: {
                  title: 'Game History',
                  content: [
                     {
                        text: 'Game History must show your completed game rounds history as a list of dates, game rounds, placed bets and round outcomes.',
                     },
                     {
                        text: 'Most recent game round result is positioned at the top of the list.',
                     },
                     {
                        text: 'Clicking/Tapping on a particular game round entry in Game History list will open detailed history for this game round.',
                     },
                  ],
               },
               chat: {
                  title: 'Chat',
                  content: [
                     {
                        text: 'It is possible to send messages to Game Presenter and other players.',
                     },
                     {
                        text: 'Chat functionality will be disabled for players who use inappropriate and offensive language or abuse the Game Presenter or other players.',
                     },
                     { text: 'Player can move chat to place it in a more convenient way for him.' },
                  ],
               },
               roundId: {
                  title: 'Round ID',
                  content: [
                     { text: 'Every game round has its own unique Round ID.' },
                     {
                        text: 'Refer to this Round ID, when contacting customer support regarding particular game.',
                     },
                  ],
               },
               gameSettings: {
                  title: 'Game Settings',
                  content: [
                     {
                        text: 'Game Settings display a list of game settings, that can be changed by the player.',
                     },
                     {
                        text: "Once applied, game settings are stored to player's profile and launched automatically once player logs in again.",
                     },
                  ],
               },
               errorHandling: {
                  title: 'Error Handling and Disconnection Policy',
                  content: [
                     {
                        text: 'In case of game error, service error or game procedure error, game round will be paused.',
                     },
                     {
                        text: 'Players will be notified via chat message or a pop-up message, that the issue is being investigated.',
                     },
                     {
                        text: 'If the error can be resolved immediately, the game round will continue as normal.',
                     },
                     {
                        text: 'If the immediate resolution is not possible, the game round will be cancelled, and initial bets will be returned to all players who participated in the game round.',
                     },
                     {
                        text: 'In case of disconnection from the game server, a reconnecting message will be displayed on the screen. If the disconnection occurred after bets were accepted, then all bets remain valid.',
                     },
                     {
                        text: "On player's return, if the game round is completed, game round outcome will be displayed in Game History.",
                     },
                  ],
               },
            },
            autoRoulette: {
               gameOverview: {
                  title: 'Game Overview',
                  content: [
                     {
                        text: "Single Zero Auto Roulette is played via Automatic roulette wheel. The objective of the game is to predict the number of the wheel's pocket where the spun ball will eventually land. The roulette wheel consists of 37 pockets numbered 0-36 where 0 represents single zero.",
                     },
                  ],
               },
               gameRound: {
                  title: 'Game Round',
                  content: [],
               },
               bettingTime: {
                  title: 'Betting Time',
                  content: [
                     {
                        text: 'Bets should be placed during the betting time on the table. To place a bet, chip value must be selected and placed on one or more bet spots on the betting grid or racetrack. Any placed bets that are below the minimum bet limit for the table are not valid and will not be accepted. Any placed bets that are above the maximum bet limit for the table are automatically adjusted to match the maximum limit. If you have joined the game round in progress, please wait for the next round to place bets.',
                     },
                  ],
               },
               spinning: {
                  title: 'Spinning',
                  content: [
                     {
                        text: 'After betting time has ended, the Roulette ball will be automatically released into the ball track of the spinning wheel. Ball is spun within the Roulette wheel. After the status "NO MORE BETS" announced bets are not accepted any more. Placed bets can not be modified.',
                     },
                  ],
               },
               gameOutcome: {
                  title: 'Game Outcome',
                  content: [
                     {
                        text: 'The ball will eventually land in one of the numbered pockets within the wheel and winning number will be announced and appear on UI in subtitles. The player will win if has placed a bet that covers that particular number. A winning number is valid only when the spin was valid. Valid Spin is when: The Roulette ball is spun by the Auto Roulette in the direction opposite to the rotation of the wheel and completes at least three revolutions around the track of the wheel before dropping to constitute valid spin.',
                     },
                  ],
               },
               rules: {
                  title: 'Rules',
                  content: [],
               },
               limits: {
                  title: 'Limits',
                  content: [
                     {
                        text: 'The Bet Limits should be always displayed during the game play and show minimum and maximum allowed bet limits at the table.',
                     },
                     {
                        text: 'The Min and Max sign on the table indicate the limits for Strait Up bets. All the limits are displayed on the Limits and Payouts panel in Menu.',
                     },
                     {
                        text: 'In Roulette games the following bets have their own limits: Straight Up, Split, Street, Corner, Basket, Line, Column, Dozen, Red, Black, Even, Odd, Low 1-18, High 19-36',
                     },
                  ],
               },
               placingBets: {
                  title: 'Placing Bets',
                  content: [
                     {
                        text: 'Player must have sufficient balance to place a minimum bet to participate in the game.',
                     },
                     {
                        text: 'Chip tray displays available chip values and allows you to select the value of chips you want to bet.',
                     },
                     {
                        text: 'Chips of thousand denomination are displayed as number of thousands and character K, for example, chip of value 2000 will be displayed as 2k.',
                     },
                     {
                        text: 'Once the chip size is selected, a bet can be placed by clicking/tapping the betting area on the game table.',
                     },
                     {
                        text: 'More than one chip can be placed on a single betting area. Total Bet will show the total bet amount on bets for the current game round. Once the maximum bet limit is reached, no additional funds will be accepted for that bet.',
                     },
                  ],
               },
               betTypes: {
                  title: 'Bet types',
                  content: [
                     {
                        text: 'There are multiple ways to bet in Roulette game. Each bet type has its own payout rate. Bets can cover a single number or multiple numbers.',
                     },
                  ],
               },
               insideBets: {
                  title: 'Inside Bets',
                  content: [
                     {
                        text: 'Bets placed on the number bet spots or between the numbered bet spots within the betting grid are called Inside Bets.',
                        list: [
                           'Straight Up - bet placed on a single number.',
                           'Split - bet placed on the line between any two numbers, either on the vertical or horizontal.',
                           'Street - bet placed at the end of any row of numbers.A Street Bet covers three numbers.Bets placed at the junctions 0/1/2 and 0/2/3 are also accepted as street bets.',
                           'Corner - bet placed at corner (central intersection) where four numbers meet. All four numbers are covered. Basket bet placed at the junction of 0/1/2/3 is also accepted as corner bet.',
                           'Line - bet at the end of two rows on the intersection between the two rows. A line bet covers all the numbers in both rows, a total of six numbers.',
                        ],
                     },
                  ],
               },
               outsideBets: {
                  title: 'Outside Bets',
                  content: [
                     {
                        text: 'Bets that are made on the bet spots below and to the right of the numbered board are called outside bets. The zero is not covered by any of these bet spots.',
                        list: [
                           'Column - box marked "2 to 1" at the end of the column covers all 12 numbers in that column.',
                           'Dozen - box marked "1st12", "2n12", "3rd12" covers the 12 numbers alongside the box.',
                           '1-18/19-36 - covers the first or second bet set of 18 numbers.',
                           'Even/Odd - covers the 18 even or 18 odd numbers.',
                           'Red/Black - covers 18 red or 18 black numbers.',
                        ],
                     },
                  ],
               },
               racetrackAndNeighbourBets: {
                  title: 'Racetrack and Neighbour Bets',
                  content: [
                     {
                        text: 'These bets are placed using Roulette racetrack, which represents the numbers as they appear on the Roulette wheel, rather than the Roulette table. Provides unique betting options: racetrack bets and neighbour bets. Racetrack bets includes: Voisins du Zero, Zero, Tiers, Orphelins. A single-zero Roulette wheel is split into 4 sectors that corresponds to areas on the table. The traditional names of these sectors are:',
                     },
                     { text: 'Voisins du Zero' },
                     {
                        text: 'Covers a total of 17 numbers that include 22,25 and the numbers that lie between them on the side of the Roulette wheel that contains zero.',
                     },
                     {
                        text: '9 chips are placed as follows:',
                        list: [
                           '2 chips on the 0/2/3 street',
                           '1 chip on the 4/7 split',
                           '1 chip on the 12/15 split',
                           '1 chip on the 18/21 split',
                           '1 chip on the 19/22 split',
                           '2 chips on the 25/26/28/29 corner',
                           '1 chip on the 32/35 split',
                        ],
                     },
                     {
                        text: 'Jeu Zero',
                     },
                     {
                        text: ' Covers zero and the 6 numbers in close proximity to zero on the Roulette wheel 12, 35, 3, 26, 0, 32 and 15.',
                     },
                     {
                        text: '4 chips are placed as follows:',
                        list: [
                           '1 chip on the 0/3 split',
                           '1 chip on the 12/15 split',
                           '1 chip on 26 (straight up)',
                           '1 chip on the 32/35 split',
                        ],
                     },
                     {
                        text: 'Tiers du Cylindre',
                     },
                     {
                        text: 'Covers a total of 12 numbers that include 27, 33 and the numbers that lie between them on the side of the Roulette wheel opposite to zero.',
                     },
                     {
                        text: '6 chips are placed as follows:',
                        list: [
                           '1 chip on the 5/8 split',
                           '1 chip on the 10/11 split',
                           '1 chip on the 13/16 split',
                           '1 chip on the 23/24 split',
                           '1 chip on the 27/30 split',
                           '1 chip on the 33/36 split',
                        ],
                     },
                     { text: 'Orphelins' },
                     {
                        text: 'Covers a total of 8 numbers on the two segments of the Roulette wheel not covered by Voisins du Zero and Tiers bet above.',
                     },
                     {
                        text: '5 chips are placed as follows:',
                        list: [
                           '1 chip on 1 (straight up)',
                           '1 chip on the 6/9 split',
                           '1 chip on the 14/17 split',
                           '1 chip on the 17/20 split',
                           '1 chip on the 31/34 split',
                        ],
                     },
                     {
                        text: 'Neighbour Bets Covers 1 straight up number and from 0 to 9 of its neighbours from left and right, based on the number location on the wheel. Player places from 1 chip (1 number + 0 left/0 right neighbours) to 19 chips (1 number + 9 left/9 right neighbours). Player can select number or neighbours from 0 to 9. Neighbour Bets can be placed with click/tap on "-" or "+" button to increase or decrease chosen neighbour bets to the right and left.',
                     },
                  ],
               },
               myBets: {
                  title: 'My Bets',
                  content: [
                     {
                        text: ' My Bet feature provides an option to save current bet and for fast betting in future rounds. Also you can create custom sets of bets and save them for later play. Straight-Up bets. By press on any of bet options in this block you will place bets on all relevant straight-up positions on the betting map. For example, pressing “Red” on Straight-Up Bets panel will put chips on all red numbers on the betting map.',
                     },
                     {
                        text: 'Special bets have three betting options:',
                        list: [
                           'The Final Bet is made on the last digit of a number. For instance Final Four means that you bet on the numbers 4, 14, 24 and 34. The bet on Final 1–6 requires 4 chips, while Final 6–9 gets along with just 3 chips. Complete bets - take all inside bets and puts them on a particular number.',
                           'Try My Luck - puts chips on 3, 5 or 7 numbers randomly selected by server.',
                           'Bet Generator opens Betting map or Race track for manual creation of a set of custom bets.',
                        ],
                     },
                  ],
               },
               bettingFromStatisticsPanels: {
                  title: 'Betting from Statistics panels',
                  content: [
                     {
                        text: ' You can place bets straight from Statistics, Last Results and Chart panels. Hower over relevant bet type will highlight it on the Betting Map or Racetrack. It is possible to bet from Statistics during Betting time only.',
                     },
                  ],
               },
               rebet: {
                  title: 'Rebet',
                  content: [
                     {
                        text: ' Clicking/Tapping on Rebet button will place bets on the table from the previous game round. On the first entry Rebet button is disabled. If the player has already participated in game, on next game round Rebet button is enabled.',
                     },
                  ],
               },
               double: {
                  title: 'Double',
                  content: [
                     {
                        text: "Clicking/Tapping on Double button will double all player's bets. Player can press Double as many times as he wants until his balance is sufficient to double the bet or he reaches bet/table limit.",
                     },
                  ],
               },
               undo: {
                  title: 'Undo',
                  content: [
                     {
                        text: ' Clicking/Tapping on Undo button will revoke last placed chip, Rebet and Double action. On the first entry Undo button is disabled. As soon as bet is placed Undo button is enabled. The button remains enabled until there are no more actions to undo. All bets can be cleared by holding the Undo button.',
                     },
                  ],
               },
               dragAndDrop: {
                  title: 'Drag and Drop Chips',
                  content: [
                     {
                        text: 'Drag and Drop Chips functionality allows the player to freely move chips that are already placed on the betting grid. Moving chips functionality is not supported on Racetrack. To select the chip, player needs to click & hold on the chip and move it to a different bet spot while it is being held. With Moving Chips functionality player can combine different chips or remove them from the betting grid. Undo function will revoke the last move done.',
                     },
                  ],
               },
               autoPlay: {
                  title: 'Autoplay',
                  content: [
                     {
                        text: 'This feature should be enabled by your service provider. You can use this feature to place bets automatically during predefined number of rounds. After you place bet on the table, you can open Autoplay Panel where you can set a number of the Autoplay Rounds and limit conditions to stop autoplay. Autoplay always starts from the next round. Counter on the Autoplay will notify you how many rounds you have left. If you press the Autoplay button while the feature is active, Autoplay will stop and panel will open. You can see current conditions and change them. New conditions are applied from the next round. If Autoplay will stop reaching set conditions, you will be notified.',
                     },
                  ],
               },
               announcements: {
                  title: 'Announcements',
                  content: [
                     {
                        text: 'Prerecorded voice announcements will declare every game state of the game round as well as subtitles will be shown. You can disable game state announcements in the game menu',
                     },
                  ],
               },
               returnToPlayer: {
                  title: 'Return to Player',
                  content: [
                     { text: 'The theoretical percentage return to player (RTP) is 97.30%.' },
                  ],
               },
               cancelledGames: {
                  title: 'Cancelled Games',
                  content: [
                     {
                        text: 'In some cases of technical issues game session can be interrupted. All the players who are currently at the table are notified of the cancellation and all bets are returned to their accounts. Cancelled game rounds are marked with X. The spin counts to be valid if the ball makes three full revolutions inside the wheel. If the ball did not make 3 revolutions it is counted as a Auto-roulette mistake, the game will be stopped, round will be cancelled. Cancelled round does not stop Autoplay. Round bets will be returned but Autoplay conditions will apply to continue from the next round. Cancelled game is not deducted from the Autoplay rounds.',
                     },
                  ],
               },
               gameHistory: {
                  title: 'Game History',
                  content: [
                     {
                        text: ' Game History must show your completed game rounds history as a list of dates, game rounds, placed bets and round outcomes. Most recent game round result is positioned at the top of the list. Clicking/Tapping on a particular game round entry in Game History list detailed history for this game round.',
                     },
                  ],
               },
               chat: {
                  title: 'Chat',
                  content: [
                     { text: 'It is possible to send messages to other players.' },
                     {
                        text: 'Customer Support team use chat to inform players. Chat should be available during all game phases.',
                     },
                     {
                        text: 'Chat functionality will be disabled for players who use inappropriate and offensivelanguage or abuse the other players.',
                     },
                     { text: 'Player can move chat to place it in a more convenient way for him.' },
                  ],
               },
               liveSupport: {
                  title: 'Live Support',
                  content: [
                     {
                        text: 'If assistance is needed, Live support chat allows you to contact our Customer Support team.',
                     },
                  ],
               },
               gameNumber: {
                  title: 'Game Number',
                  content: [
                     { text: 'Every game round has its own unique Game Number.' },
                     { text: 'This number reflects the time when the game round began.' },
                     {
                        text: 'Refer to this Game Number, when contacting customer support regarding particular game round. history for this game round.',
                     },
                  ],
               },
               gameSettings: {
                  title: 'Game Settings',
                  content: [
                     {
                        text: 'Game Settings displays a list of game settings, that can be changed by the player.',
                     },
                     {
                        text: "Once applied, game settings are stored to player's profile and launched automatically once player logs in again.",
                     },
                     {
                        text: 'Player can change nickname, video, announcements and sound settings.',
                     },
                  ],
               },
               errorHandlingAndDisconnectionPolicy: {
                  title: 'Error Handling and Disconnection Policy',
                  content: [
                     {
                        text: 'In case of game error, service error or Auto-roulette error, the game round will be paused.',
                     },
                     {
                        text: 'Players will be notified via chat message or a pop-up message, that the issue is being investigated.',
                     },
                     {
                        text: 'If the error can be resolved immediately, the game round will continue as normal.',
                     },
                     {
                        text: 'If the immediate resolution is not possible, the game round will be cancelled, and initial bets will be returned to all players who participated in the game round.',
                     },
                     {
                        text: 'In case of disconnection from the game server, a reconnecting message will be displayed on the screen. If the disconnection occurred after bets were accepted, then all bets remain valid.',
                     },
                     {
                        text: "On player's return, if the game round is completed, game round outcome will be displayed in game History.",
                     },
                  ],
               },
            },
         },
         history: {
            title: 'History',
            roundBetsTable: {
               betType: 'Bet type',
               bet: 'Bet',
               netCash: 'Net Cash',
            },
         },
         autoplay: {
            title: 'Autoplay',
            autoplayRounds: 'Autoplay Rounds',
            lossLimit: 'Loss Limit',
            singleWinLimit: 'Single Win Limit',
            noLimit: 'No limit',
            stop: 'Stop',
            start: 'Start',
            placeBet: 'Place a bet to start Autoplay',
            emptyLossLimit: 'This field is mandatory',
            lessThanBets: 'Value can’t be lower than bet',
         },
         favourite: {
            title: 'My Bets',
            savedBets: 'Saved bets',
            addLastBet: 'ADD LAST BET',
            specialBets: 'Special Bets',
            finalBets: 'Final Bets',
            tryMyLuck: 'Try My Luck',
            completeBets: 'Complete Bets',
            specialLineBets: 'Straight-Up Bets',
            bets: 'Bets',
            betConstructor: {
               title: 'Bet Generator',
               placeBets: 'Place Bet',
            },
         },
         statistics: {
            title: 'Statistics',
            lastResults: 'Last Results',
         },
      },
      chat: {
         title: 'Chat',
         placeholder: 'Type here to chat',
         errorLoading: 'Something went wrong. Please reopen the chat',
      },
      youWin: 'YOU WIN',
      unmute: 'PRESS TO UNMUTE',
      waitForNextRound: 'Please wait for the next round',
      centeredMessagesBanner: {
         pleaseWaitForNextRound: 'Please wait for next round',
         takeSeat: 'Please take a seat',
         confirmedBets: 'bet confirmed',
         gamePaused: 'The game has been paused',
      },

      bettingMap: {
         even: 'EVEN',
         odd: 'ODD',
         red: 'RED',
         black: 'BLACK',
         column: {
            first: '1st Column',
            second: '2nd Column',
            third: '3rd Column',
         },
         dozen: {
            first: '1st Dozen',
            second: '2nd Dozen',
            third: '3rd Dozen',
         },
      },
      notifications: {
         betConfirmed: {
            error: {
               title: 'Caution',
               content: 'Sorry, your bets were not accepted',
            },
            success: {
               sendSomeBets: 'some of your bets were not accepted',
               sendAllBets: 'bet confirmed',
            },
            errorByBalance: {
               title: 'Caution',
               content:
                  'Sorry, your bets were not accepted. There is not enough money on your balance',
            },
            errorByBetMinLimit: {
               title: 'Caution',
               content: 'Some of your bets were not accepted',
            },
         },
         cancelRound: {
            title: 'Caution',
            content: 'Sorry, this round is cancelled. All bets will be returned to your balance',
         },
         cancelAutoplay: {
            title: 'Information',
            content: 'Autoplay stopped',
         },
         maintenancePending: {
            title: 'Caution',
            content: 'Sorry the table will be closed from the next round',
         },
      },
      timer: {
         bettingTime: {
            lastBets: 'last bets',
            placeBets: 'place your bets',
            noMoreBets: 'No more bets',
         },
         decisionTime: 'make your decision',
         insuranceTime: 'insurance',
      },
      swipe: {
         title: 'swipe up',
         subtitle: 'for full screen',
      },
      playersList: {
         title: 'Total WIN',
      },
      video: {
         toggleVideoMode: 'Video mode',
         pleaseWait: 'Please Wait',
      },
      connection: {
         waitForConnection: 'Waiting for connection',
      },
      sound: {
         unmute: 'Unmute sound',
      },
      gameHistory: {
         chooseDate: 'Choose date',
         emptyGameHistoryList: 'No results for this time period.',
         datePicker: {
            cancel: 'Cancel',
            save: 'Save',
         },
         navigation: {
            next: 'Next',
            previous: 'Previous',
         },
         bjGameBetType: {
            cancelled: 'Cancelled',
            betBehind: 'Bet Behind',
            perfectPair: 'Perfect Pair',
            split: 'Split',
            twentyOnePlusThreeBet: '21 + 3',
            insurance: 'Insurance',
            hand: 'Hand',
            top3: 'Top 3',
            mainBet: 'Main Bet',
         },
         gameInfo: {
            gameId: 'Game ID',
            totalBet: 'Total Bet',
            netCash: 'Net Cash',
            dealer: 'Dealer',
            seat: 'Seat',
            dealerCards: 'Dealer Cards',
         },
         bjRoundBetsTable: {
            netCash: 'Net Cash',
            bet: 'Bet',
            seat: 'Seat',
            score: 'Score',
            cards: 'Cards',
            betType: 'Bet Type',
         },
      },
      tableCapacity: {
         limits: {
            bets: 'Bets',
            pays: 'Pays',
            minLimit: 'Min limit',
            maxLimit: 'Max limit',
         },
      },
      errorOverlay: {
         title: 'Ups...',
         message: 'Something went wrong. But we are fixing it already!',
         action: 'Got It',
         sessionClosed: 'Session was closed server side and is no longer valid.',
      },
      sessionErrorOverlay: {
         title: 'Refresh the page to re-join the game on this device',
         action: 'Got it',
      },
      notifyInactivePlayerOverlay: {
         title: 'Soon you will be disconnected due to inactivity',
         action: 'Got it',
      },
      sessionClosedInactivePlayer: {
         title: 'You have been disconnected due to inactivity',
         action: 'Got it',
      },
      maintenanceOverlay: {
         title: 'Sorry, this table is currently unavailable. Please choose another one.',
         action: 'Got it',
      },
      inactiveGameTableOverlay: {
         title: 'Sorry, this table is currently unavailable. Please choose another one.',
         action: 'Got it',
      },
      lostConnectionOverlay: {
         title: 'Connection lost. Please, check your internet',
         action: 'Got It',
      },
      tooltip: {
         limit: {
            maxLimit: 'Your bets are set to maximum limit for this bet position',
            minLimit: 'Your bet is below limit',
         },
         notEnoughBalance: 'Not enough balance',
      },
      announcements: {
         male: 'Male',
         female: 'Female',
         subtitlesLabel: 'Subtitles',
         numberColors: {
            red: 'Red',
            black: 'Black',
         },
         bettingEndTime: {
            1: 'Ladies and gentlemen, the betting time is closed. Thank you, and good luck!',
            2: 'No more bets are accepted for this round. Good luck.',
            3: "Thank you for your bets. Let's play. Good luck!",
         },
         startRound: {
            1: 'Betting time is open, please place your bets!',
            2: 'You are more than welcome to place your bets!',
            3: 'Dear ladies and gentlemen, betting time is available!',
         },
         winNumber: {
            1: 'The winning number is {{{winNumberTitle}}}. Congratulations to the winners!',
            2: 'The number is {{{winNumber}}}. Good luck next round!',
            3: 'The lucky number is {{{winNumberTitle}}}! Well done to all the winners!',
         },
         lastBetsStartTime: {
            1: 'Ladies and gentlemen, it’s the final countdown - place your bets before the round begins!',
            2: 'The wheel is about to spin, and the ball is about to drop. This is your last chance to place your bets!',
            3: 'The final countdown has begun, players. Make your bets now before the ball drops.',
         },
         welcomeAnnouncement: {
            1: 'Welcome! The betting starts shortly. Good luck!',
            2: 'Welcome! The wheel is spinning, and the ball is ready to drop. Place your bets.',
            3: 'Step right up, the action is about to begin!',
         },
      },
      betControls: {
         double: 'Double',
         rebet: 'Rebet',
      },
      dealerRating: {
         buttonTitle: 'Rate a Dealer',
         modalTitle: 'Do you like our dealer?',
         feedbackCommentTitle: 'Leave your feedback',
         sendButtonTitle: 'Send',
         appreciationTitle: 'Thank you for your feedback!',
      },
      betLimits: {
         mainBets: 'Main Bets',
         betBehind: 'Bet Behind',
         blackjack: 'Blackjack',
         winningHands: 'Winning hands',
         insurance: 'Insurance',
         perfectPairs: 'Perfect Pairs',
         perfectPair: 'Perfect Pair',
         coloredPair: 'Colored Pair',
         mixedPair: 'Mixed Pair',
         plusThree: '21+3',
         suitedTrips: 'Suited Trips',
         straightFlush: 'Straight Flush',
         threeOfAKind: 'Three of a Kind',
         straight: 'Straight',
         flush: 'Flush',
         bets: 'Bets',
         combination: 'Combination',
         pays: 'Pays',
         min: 'Min',
         max: 'Max',
         minCurrency: 'Min ({{{currency}}})',
         maxCurrency: 'Max ({{{currency}}})',
      },
      rouletteBetLimits: {
         straight: 'Straight up',
         split: 'Split',
         street: 'Street',
         corner: 'Corner/Basket',
         line: 'Line',
         dozen: 'Column/Dozen',
         color: 'Red/Black',
         evenOdd: 'Even/Odd',
         lowHigh: '1-18/19-36',
      },
   },
};
