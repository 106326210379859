import { Director, View, BroadcastEvent, MediaStreamSource, LayerInfo } from '@millicast/sdk';
import { ReactEventHandler } from 'react';

import { TNullable } from '@/common/types';
import { isNull, logger } from '@/common/utils';
import { EStreamingQualities } from '@/common/modules/LiveStreamPlayer/constants';

import { QUALITY_LEVELS, QUALITY_SIZE } from './constants';

type TLayerInfo = LayerInfo & {
   width: number;
};

export class DolbyController {
   private streamId: string | undefined;
   private view: TNullable<View> | undefined;
   private streamAccountId: string | undefined;
   private videoElement: HTMLVideoElement | undefined;
   private audioElement: HTMLAudioElement | undefined;
   private onConnectionError?: ReactEventHandler<HTMLVideoElement>;
   private pinnedSourceId: string | undefined;
   public qualityLevels: Record<EStreamingQualities, string> = QUALITY_LEVELS;
   public qualitySizes: Record<number, EStreamingQualities> = QUALITY_SIZE;
   private isConnectionInitialized: boolean = false;

   initializeConnection({
      streamId,
      audioElement,
      streamAccountId,
      videoElement,
      onConnectionError,
   }: {
      streamId: string;
      audioElement: HTMLAudioElement;
      streamAccountId: string;
      videoElement: HTMLVideoElement;
      onConnectionError?: ReactEventHandler<HTMLVideoElement>;
   }): void {
      this.streamId = streamId;
      this.videoElement = videoElement;
      this.streamAccountId = streamAccountId;
      this.audioElement = audioElement;
      this.onConnectionError = onConnectionError;
   }

   private _tokenGenerator = () => {
      if (this.streamId && this.streamAccountId) {
         return Director.getSubscriber({
            streamName: this.streamId,
            streamAccountId: this.streamAccountId,
         });
      }
   };

   private _setStreamQualities = (event: BroadcastEvent): void => {
      // Workaround to move from hardcoded config to dynamically generated quality levels
      // As Dolby can provide IDs in different format that can't be hardcoded
      // We map through data that Dolby provides and sort them by sizes
      // @ts-expect-error
      const qualityLevels = event.data?.medias[0].layers
         .map((layer: TLayerInfo) => ({
            encodingId: layer.encodingId,
            width: layer.width,
         }))
         .toSorted(
            (prevLayer: TLayerInfo, nextLayer: TLayerInfo) => prevLayer.width - nextLayer.width,
         );

      // Dolby possibly can return number of layers that is different from 3
      // So we define quality levels as start, median and end of quality levels array
      // For example from 5 layers array we should take 1, 3 and 5th element
      const lowQuality = qualityLevels[0];
      const mediumQuality = qualityLevels[Math.ceil((qualityLevels.length - 1) / 2)];
      const hightQuality = qualityLevels[qualityLevels.length - 1];

      this.qualityLevels = {
         [EStreamingQualities.Low]: lowQuality.encodingId,
         [EStreamingQualities.Medium]: mediumQuality.encodingId,
         [EStreamingQualities.High]: hightQuality.encodingId,
      };

      this.qualitySizes = {
         [lowQuality.width]: EStreamingQualities.Low,
         [mediumQuality.width]: EStreamingQualities.Medium,
         [hightQuality.width]: EStreamingQualities.High,
      };
   };

   public subscribeToBroadcastEvents = (
      onQualityChange: (quality: EStreamingQualities) => void,
      onPlaying: () => void,
   ): (() => void) => {
      const handleBroadcastEvent = (event: BroadcastEvent) => {
         this.isConnectionInitialized = true;

         // if stream started or resumed we should trigger onPlaying event to remove loader
         if (event.name === 'active') {
            onPlaying();
         }

         if (event) {
            this.subscribeToSourceIdEvents(event);
         }

         // if we get layers event from Dolby it means that live stream server has changed the quality
         if (event.name !== 'layers') return;

         this._setStreamQualities(event);

         // determine the current quality setting based on the resolution width of the video element.
         const videoWidth = (this.videoElement as HTMLVideoElement).videoWidth as number;
         const quality: EStreamingQualities | undefined = this.qualitySizes[videoWidth];

         if (!isNull(quality)) {
            onQualityChange(quality);
         }
      };

      this.view?.on('broadcastEvent', handleBroadcastEvent);

      return () => {
         this.view?.off('broadcastEvent', handleBroadcastEvent);
      };
   };

   public connectToAudio = (): void => {
      this.view?.on('track', (event) => {
         if (event.track.kind === 'audio' && this.audioElement) {
            this.audioElement.srcObject = new MediaStream([event.track]);
            this.audioElement.play().catch((e) => {
               // We should ignore NotAllowedError because in our case it's expected.
               // We know that the browser can block autoplay, and we solved this issue with user interaction on the mute icon.
               if (e.name === 'NotAllowedError') {
                  return null;
               }
            });
         }
      });
   };

   public setVolume = (volume: number): void => {
      if (this.audioElement) {
         this.audioElement.volume = volume;
      }
   };

   public connectToVideo = (): void => {
      // Contract: streamName: string, tokenGenerator: TokenGeneratorCallback, mediaElement?: HTMLVideoElement, autoReconnect?: boolean
      // @ts-ignore
      this.view = new View(this.streamName, this._tokenGenerator, this.videoElement, true);

      this.view
         ?.connect({
            pinnedSourceId: this.pinnedSourceId ?? undefined,
            events: ['active', 'inactive', 'stopped', 'layers'],
         })
         .catch((reason) => {
            // handle connection error change on initial connection
            if (this.onConnectionError) {
               this.onConnectionError(reason);
            }

            logger.log('Stream connection error!');
         });
   };

   public subscribeToLiveStreamConnectionStateChange = (
      onConnectionError: ReactEventHandler<HTMLVideoElement>,
   ): void => {
      this.view?.on('connectionStateChange', (state) => {
         if (state === 'disconnected') {
            onConnectionError(state);
         }
      });
   };

   private subscribeToSourceIdEvents = (event: BroadcastEvent): void => {
      const pinnedSourceId = (event.data as MediaStreamSource)?.sourceId;
      const isPinnedSourceChanged = this.pinnedSourceId !== pinnedSourceId;
      if (isPinnedSourceChanged && pinnedSourceId) {
         this.pinnedSourceId = pinnedSourceId;

         this.destroyConnection();
         this.connectToVideo();
         this.connectToAudio();
      }
   };

   public setQuality = (quality: EStreamingQualities): void => {
      if (this.view) {
         // @ts-ignore
         this.view?.select({ encodingId: this.qualityLevels[quality] });
      }
   };

   public setAutoQuality = (): void => {
      // we can only set auto quality if connection is initialized
      // because view instance is not enough, and we need to receive layers event first from Dolby
      // to be sure that we have quality levels
      if (this.view && this.isConnectionInitialized) {
         // select method runs auto quality mode
         this.view?.select();
      }
   };

   public pause = (): void => {
      this.videoElement?.pause();
      this.audioElement?.pause();
   };

   public play = (): void => {
      this.videoElement?.play().catch();
      this.audioElement?.play();
   };

   public reconnect = (): void => {
      this.view?.replaceConnection();
   };

   public destroyConnection = (): void => {
      this.view?.stop();
   };
}

export const dolbyController = new DolbyController();
