import { useOfflineStatus } from '@/common/hooks';
import { EGameState, useGameStateActions } from '@/common/state/gameTypes/roulette/gameState';
import { useTimerActions } from '@/common/modules/CountDown/state';

export const useCheckOfflineGame = () => {
   const { handleSetWaitingForConnection, handleChangeGameState } = useGameStateActions();
   const { handleResetTimerState } = useTimerActions();

   useOfflineStatus(() => {
      handleChangeGameState(EGameState.WAIT_NEXT_ROUND);
      handleSetWaitingForConnection();
      handleResetTimerState();
   });
};
