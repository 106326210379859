import { TListenerApiDispatchType } from '@/common/types';
import { ETableStatus } from '@/common/constants';
import { isTableStatus } from '@/common/modules/TableStatusOverlay/type-guards';
import { tableStatusSlice } from '@/common/modules/TableStatusOverlay/state';

export const handleErrorBaseTableStatus = ({
   error,
   dispatch,
}: {
   error: unknown;
   dispatch: TListenerApiDispatchType;
}) => {
   if (error instanceof Error && isTableStatus(error.message)) {
      const { setMaintenanceInProgress, setInactiveTable, setErrorTable } =
         tableStatusSlice.actions;
      const actionCreatorByTableStatus = {
         [ETableStatus.MaintenanceInProgress]: setMaintenanceInProgress,
         [ETableStatus.InactiveTable]: setInactiveTable,
         [ETableStatus.Error]: setErrorTable,
      };

      const actionCreator = actionCreatorByTableStatus?.[error.message];
      if (actionCreator) {
         dispatch(actionCreator());
      }
   }
};
