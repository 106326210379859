// @ts-nocheck
import styled from 'styled-components/macro';

export const Wrapper = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: grid;
  place-items: center;
  /* todo: move to theme */
  z-index: 99;
`;

export const ModalWrapper = styled.div`
  scale: var(--scale, 1);
  position: relative;
  width: ${({ width }: { width?: number }) => `${width}px`};
  min-width: ${({ minWidth }: { minWidth: number }) => `${minWidth}px`};
  min-height: 263px;
  padding: 40px;
  background-color: #030303;
  border-radius: 4px;
  /* todo: move to theme */
  box-shadow:
    0 12px 24px #00000033,
    0 4px 8px #0000004c;

  display: flex;
  gap: 20px;

  & > :nth-child(1) {
    flex: 1;
    text-align: center;
  }
  & > :nth-child(2) {
    flex: 6;
  }
`;
