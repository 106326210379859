import { useDispatch } from 'react-redux';

import { EGameState, useGameState } from '@/common/state/gameTypes/roulette/gameState';

import { useBalanceActions } from '#/modules/Balance';
import { savePreviousRoundBetsAction } from '#/modules/Rebet/middleware';
import { useWin } from '#/modules/WinSum';
import { useBetsState } from '#/state/features/bets/selectors';

import { RoundResultEvent } from './schemes';

type UseRoundResultOutput = (socket: RoundResultEvent) => void;

export const useRoundResultEvent = (): UseRoundResultOutput => {
   const { handleSetWin, handleResetWin } = useWin();
   const { handleChangeGameState } = useGameState();
   const { handleUpdateInitialBalance, handleUpdateBalanceFromServer } = useBalanceActions();
   const { handleResetBets } = useBetsState();

   const dispatch = useDispatch();

   return ({ win, balance }) => {
      const isPlayerWon = win > 0;

      if (!isPlayerWon) {
         handleResetWin();
      }

      handleSetWin(win);
      dispatch(savePreviousRoundBetsAction());
      handleResetBets();
      handleUpdateBalanceFromServer(Number(balance));
      handleUpdateInitialBalance(Number(balance));
      handleChangeGameState(EGameState.ROUND_RESULT);
   };
};
