import { logger, pipe } from '@/common/utils';
import { TNullable } from '@/common/types';
import { ETypeBet } from '@/common/roulette/constants';
import { errors } from '@/common/roulette/errors';
import { ILimitTooltipActions } from '@/common/modules/LimitTooltip/state';

import { sumBetsById } from '#/services/api/betsApiSlice/helpers';
import { extractDoubleBet } from '#/services/api/betsApiSlice/helpers/extractDoubleBet';
import { extractSpecialBet } from '#/services/api/betsApiSlice/helpers/extractSpecialBet';
import { TLimits } from '#/modules/TableCapacity';
import {
   TBetType,
   TCallBet,
   TCommonBet,
   TTotalBets,
   calculateTotalBets,
} from '#/state/features/bets';
import { isCallBetType } from '#/state/features/bets/type-guards';

import { IBetLimits } from './types';

export class BetLimits implements IBetLimits {
   private readonly limitsActions: ILimitTooltipActions;
   private readonly limits: TLimits;

   constructor({
      limitsActions,
      limits,
   }: {
      limitsActions: ILimitTooltipActions;
      limits: TLimits;
   }) {
      this.limits = limits;
      this.limitsActions = limitsActions;
   }

   private getBetLimitsByType = ({ type, betLimits }: { type: ETypeBet; betLimits: TLimits }) => {
      try {
         return betLimits?.[type] ?? { min: 0, max: 0 };
      } catch (error) {
         logger.error(`${errors.bets.limits} ${error}`);
         return { min: 0, max: 0 };
      }
   };

   private validateBetWithinLimits = ({
      type,
      amount,
      betLimits,
   }: {
      type: ETypeBet;
      betLimits: TLimits;
      amount: number;
   }): {
      isBetBelowMinLimit: boolean;
      isBetAboveMaxLimit: boolean;
   } => {
      const { min, max } = this.getBetLimitsByType({ type, betLimits });

      return {
         isBetBelowMinLimit: amount < min,
         isBetAboveMaxLimit: amount >= max,
      };
   };

   private validateBetByLimits = ({
      bet,
      totalBets,
      betLimits,
   }: {
      bet: TCommonBet; // The bet that should be validated
      betLimits: TLimits; // The limits for the bet
      totalBets: TTotalBets; // Total bets (already placed)
   }): {
      bet: TNullable<TCommonBet>; // The validated bet
      isBetBelowMinLimit: boolean; // Indicator whether the bet is below the minimum limit
      isBetAboveMaxLimit: boolean; // Indicator whether the bet is above the maximum limit
   } => {
      // Get the current amount of the bet from total bets, default to 0 if not found.
      const currentBetAmount = totalBets?.[bet.id] ?? 0;

      // Get the amount of the current valid bet.
      const validBetAmount = bet.amount;

      // Validate the total bet (current + valid) within the bet limits.
      const totalBet = this.validateBetWithinLimits({
         type: bet.type,
         amount: currentBetAmount + validBetAmount,
         betLimits,
      });

      // If the total bet exceeds the maximum limit.
      if (totalBet.isBetAboveMaxLimit) {
         // Validate the current bet within limits.
         const currentBet = this.validateBetWithinLimits({
            type: bet.type,
            amount: currentBetAmount,
            betLimits,
         });

         // If the current bet also exceeds the maximum limit.
         if (currentBet.isBetAboveMaxLimit) {
            // Return null indicating the bet cannot be made, and it's above the maximum limit.
            return { bet: null, isBetBelowMinLimit: false, isBetAboveMaxLimit: true };
         }

         // Get the maximum bet limit for the specified bet type
         const { max: maxBetLimit } = this.getBetLimitsByType({
            type: bet.type,
            betLimits,
         });

         // Calculate the amount for a partially bet
         const partialBetAmount = maxBetLimit - currentBetAmount;
         // Define a chip count for the partially bet
         const CHIP_COUNT = 1;

         // Create a partially bet
         const partiallyBet = {
            ...bet,
            amount: partialBetAmount,
            chips: { [partialBetAmount]: CHIP_COUNT },
         };

         // Return the partially bet and flag indicating it's above the maximum limit
         return {
            bet: partiallyBet,
            isBetBelowMinLimit: false,
            isBetAboveMaxLimit: true,
         };
      }

      // If the total bet is below the minimum limit
      if (totalBet.isBetBelowMinLimit) {
         // Return the original bet and flag indicating it's below the minimum limit
         return { bet, isBetBelowMinLimit: true, isBetAboveMaxLimit: false };
      }

      // If the bet is within the limits, return the original bet and flags indicating it's not below the minimum or above the maximum limit
      return { bet, isBetBelowMinLimit: false, isBetAboveMaxLimit: false };
   };

   private validateDoubleCommonBet({ bet, totalBets }: { bet: TCommonBet; totalBets: TTotalBets }) {
      const updatedBets: TCommonBet[] = [];
      const updatedTotalBets = { ...totalBets };
      const currentBetAmount = updatedTotalBets?.[bet.id] ?? 0;
      const { max: maxBetLimit } = this.getBetLimitsByType({
         type: bet.type,
         betLimits: this.limits,
      });
      const totalBet = this.validateBetWithinLimits({
         type: bet.type,
         amount: currentBetAmount,
         betLimits: this.limits,
      });

      if (totalBet.isBetAboveMaxLimit) {
         this.showMaxLimitTooltip();

         return;
      }

      const doubledBet = this.validateBetWithinLimits({
         type: bet.type,
         amount: currentBetAmount * 2,
         betLimits: this.limits,
      });

      if (doubledBet.isBetBelowMinLimit) {
         this.showMinLimitTooltip();
      }

      if (doubledBet.isBetAboveMaxLimit) {
         this.showMaxLimitTooltip();
         const partialBetAmount = maxBetLimit - currentBetAmount;
         const CHIP_COUNT = 1;
         updatedBets.push({
            ...bet,
            amount: partialBetAmount,
            chips: {
               [partialBetAmount]: CHIP_COUNT,
            },
         });

         updatedTotalBets[bet.id] = (updatedTotalBets?.[bet.id] ?? 0) + partialBetAmount;
      } else {
         updatedBets.push(bet);
         updatedTotalBets[bet.id] = (updatedTotalBets?.[bet.id] ?? 0) + bet.amount;
      }

      return {
         updatedBets,
         updatedTotalBets,
      };
   }

   private validateDoubleCallBet({
      callBet,
      totalBets,
   }: {
      callBet: TCallBet;
      totalBets: TTotalBets;
   }) {
      const isCallBetValid = callBet.extractedCommand.every((bet) => {
         const doubledBet = this.validateBetWithinLimits({
            type: bet.type,
            amount: bet.amount * 2,
            betLimits: this.limits,
         });
         if (doubledBet.isBetBelowMinLimit) {
            this.showMinLimitTooltip();
         }

         if (doubledBet.isBetAboveMaxLimit) {
            this.showMaxLimitTooltip();
         }

         return !doubledBet.isBetAboveMaxLimit;
      });

      if (isCallBetValid) {
         return {
            updatedBets: [callBet],
            updatedTotalBets: { ...totalBets },
         };
      }

      return null;
   }

   public showMinLimitTooltip = (): void => {
      this.limitsActions.handleSetMinLimitTooltip();
      this.limitsActions.handleShowTooltip();
   };

   public showMaxLimitTooltip = (): void => {
      this.limitsActions.handleSetMaxLimitTooltip();
      this.limitsActions.handleShowTooltip();
   };

   public hideLimitTooltip = (): void => {
      this.limitsActions.handleHideTooltip();
   };

   public validateBetsByMinLimit = ({
      bets,
      totalBets,
   }: {
      bets: TBetType[];
      totalBets: TTotalBets;
   }): void => {
      const summedBets: TCommonBet[] = pipe(extractDoubleBet, extractSpecialBet, sumBetsById)(bets);

      summedBets.some((bet) => {
         const { id, type, amount } = bet;
         const { min } = this.limits[type];
         const betTotalAmount = totalBets?.[id] ?? 0;
         const amountIsLessThanMinLimit = amount + betTotalAmount < min;

         if (amountIsLessThanMinLimit) {
            this.showMinLimitTooltip();
         }
      });
   };

   public handleBetLimitTooltips = (
      validatedBets: {
         bet: TNullable<TCommonBet>;
         isBetBelowMinLimit: boolean;
         isBetAboveMaxLimit: boolean;
      }[],
   ): void => {
      // Hide any existing limit tooltip
      this.hideLimitTooltip();

      // Initialize indicators to track if tooltips need to be shown
      let isShowMinLimitTooltip = false;
      let isShowMaxLimitTooltip = false;

      validatedBets.forEach(({ isBetBelowMinLimit, isBetAboveMaxLimit }) => {
         if (isBetBelowMinLimit) {
            isShowMinLimitTooltip = true;
         }

         if (isBetAboveMaxLimit) {
            isShowMaxLimitTooltip = true;
         }
      });

      // Show the minimum limit tooltip if necessary
      if (isShowMinLimitTooltip) {
         this.showMinLimitTooltip();
      }

      // Show the maximum limit tooltip if necessary
      if (isShowMaxLimitTooltip) {
         this.showMaxLimitTooltip();
      }
   };

   public filterNonNullBets = (
      bets: {
         bet: TNullable<TCommonBet>; // The bet list
         isBetBelowMinLimit: boolean; // Indicator whether the bet is below the minimum limit
         isBetAboveMaxLimit: boolean; // Indicator whether the bet is above the maximum limit
      }[],
   ): TCommonBet[] => {
      // Removing nullable bets and return an array of non-nullable bets
      return bets
         .filter(({ bet }) => bet !== null) // Filter out nullable bets
         .map(({ bet }) => bet as TCommonBet); // Extract non-nullable bets
   };

   public validateBetsByLimits = ({
      bets,
      totalBets,
   }: {
      bets: TBetType[];
      totalBets: TTotalBets;
   }): {
      bet: TNullable<TCommonBet>;
      isBetBelowMinLimit: boolean;
      isBetAboveMaxLimit: boolean;
   }[] => {
      const transformBets = pipe(extractDoubleBet, extractSpecialBet, sumBetsById);
      const inputBets = transformBets(bets);
      return inputBets.map((bet: TCommonBet) =>
         this.validateBetByLimits({
            bet,
            totalBets,
            betLimits: this.limits,
         }),
      );
   };

   public validateCallBetByLimits = ({
      callBet,
      totalBets,
   }: {
      callBet: TCallBet;
      totalBets: TTotalBets;
   }) =>
      callBet.extractedCommand.map((bet: TCommonBet) =>
         this.validateBetByLimits({
            bet,
            totalBets,
            betLimits: this.limits,
         }),
      );

   public validateDoubleBets = ({
      bets,
      totalBets,
   }: {
      bets: (TCallBet | TCommonBet)[];
      totalBets: TTotalBets;
   }) => {
      let updatedTotalBets = { ...totalBets };
      const updatedBets: (TCallBet | TCommonBet)[] = [];

      bets?.forEach((bet) => {
         const validatedBet = isCallBetType(bet)
            ? this.validateDoubleCallBet({ callBet: bet, totalBets: updatedTotalBets })
            : this.validateDoubleCommonBet({ bet, totalBets: updatedTotalBets });

         const isHasValidateBet = validatedBet?.updatedBets.length;

         if (isHasValidateBet) {
            updatedTotalBets = validatedBet.updatedTotalBets;
            updatedBets.push(...validatedBet.updatedBets);
         }
      });

      return {
         updatedTotalBets: calculateTotalBets(updatedBets),
         updatedBets,
      };
   };
}
