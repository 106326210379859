import { PowerSavingModeDetector } from '@/common/modules/PowerSavingModeDetector/PowerSavingModeDetector';
import { isMobileDevice } from '@/common/utils/checkDevice';

import {
   AmbientVideo,
   RouletteLoadingScreen,
   VideoLoadingScreen,
   LiveStream,
   LimitTooltipContainer,
   TableOverlayContainer,
} from '#/modules';
import { LayoutOverlay } from '#/modules/LayoutOverlay/LayoutOverlay';
import { useSmallVideoMode } from '#/modules/LiveStream/state';
import { ConnectionLoadingScreen } from '#/modules/ConnectionLoadingScreen/ConnectionLoadingScreen';

import {
   useContextMenu,
   useInitSocketConnection,
   useInitConnection,
   useCheckOfflineGame,
   useToggleMuteOnMobile,
} from './hooks';
import { Grid } from './Grid';
import { MainContainer } from './containers/MainContainer';
import { Gradient } from './components/Gradient';

export const Layout = () => {
   useInitConnection();
   useInitSocketConnection();
   useCheckOfflineGame();
   useContextMenu();
   useToggleMuteOnMobile();
   const { isSmallVideo } = useSmallVideoMode();

   return (
      // TODO: after adding <LostConnectionOverlay/> to <TableOverlayContainer/> we should remove <LayoutOverlay />
      <LayoutOverlay>
         <TableOverlayContainer>
            {!isSmallVideo && <VideoLoadingScreen />}
            <ConnectionLoadingScreen />
            <AmbientVideo />
            <RouletteLoadingScreen />
            <div className={`main-container ${isMobileDevice() && 'overflow-visible'}`}>
               <MainContainer>
                  <PowerSavingModeDetector>
                     <LiveStream />
                  </PowerSavingModeDetector>
                  <Gradient />
                  <Grid />
                  <LimitTooltipContainer />
               </MainContainer>
            </div>
         </TableOverlayContainer>
      </LayoutOverlay>
   );
};
