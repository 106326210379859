import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useGameConfigStore } from 'services/api/gameConfigApiSlice/state';
import { userConnectionActionCreator } from 'services/api/middleware/userConnectionMiddleware';

import { handleGameLanguage } from '@/common/entities/Language/handleGameLanguage';

export const useInitConnection = () => {
   const { handleSetGameLanguage } = useGameConfigStore();
   const dispatch = useDispatch();

   useEffect(() => {
      handleGameLanguage({
         queryParamsKey: 'language',
         onSuccessCallback: (language) => {
            handleSetGameLanguage(language);
         },
      });

      dispatch(userConnectionActionCreator());
   }, []);
};
