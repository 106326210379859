import { FC } from 'react';

import * as S from './WinAmountView.styles';

interface IWinAmountView {
  animationDuration: number;
}

export const PortraitWinAmountView: FC<IWinAmountView> = ({ animationDuration }) => (
  <S.SVGLines
    viewBox="0 0 263 138"
    shapeRendering="geometricPrecision"
    textRendering="geometricPrecision"
    animationDuration={animationDuration}
  >
    <defs>
      <linearGradient
        id="esowayYjugi2-stroke"
        x1="250.991"
        y1="131.001"
        x2="26.3703"
        y2="131.001"
        spreadMethod="pad"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(0 0)"
      >
        <stop id="esowayYjugi2-stroke-0" offset="0%" stop-color="rgba(255,195,79,0)" />
        <stop id="esowayYjugi2-stroke-1" offset="51.0417%" stop-color="#faa200" />
        <stop id="esowayYjugi2-stroke-2" offset="100%" stop-color="rgba(255,195,79,0)" />
      </linearGradient>
      <linearGradient
        id="esowayYjugi3-fill"
        x1="263"
        y1="121"
        x2="0"
        y2="121"
        spreadMethod="pad"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(0 0)"
      >
        <stop id="esowayYjugi3-fill-0" offset="4.16667%" stop-color="rgba(0,0,0,0)" />
        <stop id="esowayYjugi3-fill-1" offset="28.6458%" stop-color="rgba(0,0,0,0.722222)" />
        <stop id="esowayYjugi3-fill-2" offset="51.0417%" stop-color="#000" />
        <stop id="esowayYjugi3-fill-3" offset="70.8333%" stop-color="rgba(0,0,0,0.634146)" />
        <stop id="esowayYjugi3-fill-4" offset="93.75%" stop-color="rgba(0,0,0,0)" />
      </linearGradient>
      <linearGradient
        id="esowayYjugi4-stroke"
        x1="250.39"
        y1="7.98519"
        x2="29.0353"
        y2="7.98322"
        spreadMethod="pad"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(0 0)"
      >
        <stop id="esowayYjugi4-stroke-0" offset="0%" stop-color="rgba(255,195,79,0)" />
        <stop id="esowayYjugi4-stroke-1" offset="51.0417%" stop-color="#faa200" />
        <stop id="esowayYjugi4-stroke-2" offset="100%" stop-color="rgba(255,195,79,0)" />
      </linearGradient>
      <filter id="esowayYjugi5-filter" x="-150%" width="400%" y="-150%" height="400%">
        <feGaussianBlur id="esowayYjugi5-filter-blur-0" stdDeviation="1.5,1.5" result="result" />
      </filter>
      <linearGradient
        id="esowayYjugi6-stroke"
        x1="237.948"
        y1="131.001"
        x2="32.7993"
        y2="131.001"
        spreadMethod="pad"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(0 0)"
      >
        <stop id="esowayYjugi6-stroke-0" offset="0%" stop-color="rgba(255,195,79,0)" />
        <stop id="esowayYjugi6-stroke-1" offset="51.0417%" stop-color="#faa200" />
        <stop id="esowayYjugi6-stroke-2" offset="100%" stop-color="rgba(255,195,79,0)" />
      </linearGradient>
      <filter id="esowayYjugi7-filter" x="-150%" width="400%" y="-150%" height="400%">
        <feGaussianBlur id="esowayYjugi7-filter-blur-0" stdDeviation="1.5,1.5" result="result" />
      </filter>
      <linearGradient
        id="esowayYjugi8-stroke"
        x1="237.78"
        y1="11.0012"
        x2="27.5958"
        y2="11.0012"
        spreadMethod="pad"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(0 0)"
      >
        <stop id="esowayYjugi8-stroke-0" offset="0%" stop-color="rgba(255,195,79,0)" />
        <stop id="esowayYjugi8-stroke-1" offset="51.0417%" stop-color="#faa200" />
        <stop id="esowayYjugi8-stroke-2" offset="100%" stop-color="rgba(255,195,79,0)" />
      </linearGradient>
      <filter id="esowayYjugi10-filter" x="-150%" width="400%" y="-150%" height="400%">
        <feGaussianBlur id="esowayYjugi10-filter-blur-0" stdDeviation="3.5,3.5" result="result" />
      </filter>
      <radialGradient
        id="esowayYjugi13-fill"
        cx="0"
        cy="0"
        r="1"
        spreadMethod="pad"
        gradientUnits="userSpaceOnUse"
        gradientTransform="matrix(7.87027 2.7 -1.65381 4.82071 -0.064 0)"
      >
        <stop id="esowayYjugi13-fill-0" offset="0%" stop-color="#e6faff" />
        <stop id="esowayYjugi13-fill-1" offset="100%" stop-color="rgba(79,223,255,0)" />
      </radialGradient>
      <radialGradient
        id="esowayYjugi14-fill"
        cx="0"
        cy="0"
        r="1"
        spreadMethod="pad"
        gradientUnits="userSpaceOnUse"
        gradientTransform="matrix(19.8271 0 0 0.798464 0.383 0.27391)"
      >
        <stop id="esowayYjugi14-fill-0" offset="46.875%" stop-color="#fff" />
        <stop id="esowayYjugi14-fill-1" offset="93.75%" stop-color="rgba(255,255,255,0)" />
      </radialGradient>
      <filter id="esowayYjugi16-filter" x="-150%" width="400%" y="-150%" height="400%">
        <feGaussianBlur id="esowayYjugi16-filter-blur-0" stdDeviation="3.5,3.5" result="result" />
      </filter>
      <radialGradient
        id="esowayYjugi20-fill"
        cx="0"
        cy="0"
        r="1"
        spreadMethod="pad"
        gradientUnits="userSpaceOnUse"
        gradientTransform="matrix(7.87027 2.7 -1.65381 4.82071 -0.0643 0)"
      >
        <stop id="esowayYjugi20-fill-0" offset="0%" stop-color="#e6faff" />
        <stop id="esowayYjugi20-fill-1" offset="100%" stop-color="rgba(79,223,255,0)" />
      </radialGradient>
      <radialGradient
        id="esowayYjugi21-fill"
        cx="0"
        cy="0"
        r="1"
        spreadMethod="pad"
        gradientUnits="userSpaceOnUse"
        gradientTransform="matrix(19.8271 0 0 0.798464 0.3833 0.274)"
      >
        <stop id="esowayYjugi21-fill-0" offset="46.875%" stop-color="#fff" />
        <stop id="esowayYjugi21-fill-1" offset="93.75%" stop-color="rgba(255,255,255,0)" />
      </radialGradient>
      <linearGradient
        id="esowayYjugi25-fill"
        x1="76.6301"
        y1="29"
        x2="76.6301"
        y2="73"
        spreadMethod="pad"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(0 0)"
      >
        <stop id="esowayYjugi25-fill-0" offset="0%" stop-color="rgba(255,206,79,0.49)" />
        <stop id="esowayYjugi25-fill-1" offset="29.1667%" stop-color="#faa200" />
      </linearGradient>
      <linearGradient
        id="esowayYjugi26-fill"
        x1="75.56833"
        y1="-43.905998"
        x2="75.56833"
        y2="0.094002"
        spreadMethod="pad"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(0 0)"
      >
        <stop id="esowayYjugi26-fill-0" offset="0%" stop-color="rgba(255,206,79,0.49)" />
        <stop id="esowayYjugi26-fill-1" offset="29.1667%" stop-color="#faa200" />
      </linearGradient>
      <linearGradient
        id="esowayYjugi27-fill"
        x1="0.5"
        y1="0"
        x2="0.5"
        y2="1"
        spreadMethod="pad"
        gradientUnits="objectBoundingBox"
        gradientTransform="translate(0 0)"
      >
        <stop id="esowayYjugi27-fill-0" offset="0%" stop-color="rgba(255,255,255,0)" />
        <stop id="esowayYjugi27-fill-1" offset="100%" stop-color="#010022" />
      </linearGradient>
    </defs>
    <g id="win-amount-background">
      <line
        x1="17.4131"
        y1="129"
        x2="250.991"
        y2="129"
        fill="none"
        stroke="url(#esowayYjugi2-stroke)"
        stroke-width="2"
        id="bottom-line"
      />
      <rect
        id="esowayYjugi3"
        width="263"
        height="121"
        rx="0"
        ry="0"
        transform="translate(0 9)"
        fill="url(#esowayYjugi3-fill)"
      />
      <path
        d="M19.2142,7.99995l229.9748.0001"
        fill="none"
        stroke="url(#esowayYjugi4-stroke)"
        stroke-width="2"
        id="top-line"
      />

      <g id="top-line-circle" transform="translate(210.229012,8.5)">
        <g id="top-line-circle-scale" transform="scale(1,1)">
          <g id="top-line-circle-inner" transform="translate(-200.229011,-8.5)" opacity="0.4">
            <g transform="translate(30 0)" filter="url(#esowayYjugi10-filter)">
              <ellipse rx="16.2123" ry="1.5" transform="translate(170.53 8.5)" fill="#faa200" />
            </g>
            <g transform="translate(30 0)">
              <ellipse
                rx="11.7089"
                ry="4.5"
                transform="translate(170.829 8.5)"
                fill="url(#esowayYjugi13-fill)"
              />
            </g>
            <ellipse
              rx="30.9235"
              ry="2"
              transform="translate(200.229 8)"
              fill="url(#esowayYjugi14-fill)"
            />
          </g>
        </g>
      </g>
      <g id="bottom-line-circle" transform="translate(42.354202,129.5)">
        <g id="bottom-line-circle-scale" transform="scale(1,1)">
          <g id="bottom-line-circle-inner" transform="translate(-42.354202,-129.5)" opacity="0.4">
            <g transform="translate(-30 0)" filter="url(#esowayYjugi16-filter)">
              <ellipse rx="16.2123" ry="1.5" transform="translate(72.6548 129.5)" fill="#faa200" />
            </g>
            <g transform="translate(-30 0)">
              <g>
                <ellipse
                  rx="11.7089"
                  ry="4.5"
                  transform="translate(72.9551 129.5)"
                  fill="url(#esowayYjugi20-fill)"
                />
              </g>
              <ellipse
                rx="30.9235"
                ry="2"
                transform="translate(72.3542 129)"
                fill="url(#esowayYjugi21-fill)"
              />
            </g>
          </g>
        </g>
      </g>

      <g id="esowayYjugi5" filter="url(#esowayYjugi5-filter)">
        <line
          x1="24.6185"
          y1="129"
          x2="237.948"
          y2="129"
          fill="none"
          stroke="url(#esowayYjugi6-stroke)"
          stroke-width="2"
        />
      </g>
      <g id="esowayYjugi7" filter="url(#esowayYjugi7-filter)">
        <line
          x1="19.2142"
          y1="9"
          x2="237.78"
          y2="8.99997"
          fill="none"
          stroke="url(#esowayYjugi8-stroke)"
          stroke-width="2"
        />
      </g>
    </g>
  </S.SVGLines>
);
