import { Suspense, memo, FC, ReactNode } from 'react';

import { autoKeys } from '@/common/utils';
import { useSidebar } from '@/common/modules/Sidebar/state';
import { Sidebar, SidebarContent } from '@/common/modules/Sidebar';
import { ERouletteSidebar, EBlackjackSidebar } from '@/common/entities/Sidebar';

import * as S from './Sidebars.styles';

interface ISidebarsProps {
   children: ReactNode;
   sidebarsConfig: {
      type: EBlackjackSidebar | ERouletteSidebar;
      icon?: ReactNode;
      content: ReactNode;
      isScrollableBody?: boolean;
      isCloseOnClickOutside: boolean;
      title?: string;
   }[];
}

export const Sidebars: FC<ISidebarsProps> = memo(({ sidebarsConfig, children }) => {
   const { sidebarType, handleCloseSidebar } = useSidebar();

   return (
      <S.SidebarsWrapper>
         {autoKeys(
            sidebarsConfig.map(
               ({ title, type, content, icon, isCloseOnClickOutside, isScrollableBody }) => (
                  <Sidebar
                     isOpen={sidebarType === type}
                     handleCloseOnClickOutside={() => {
                        if (!isCloseOnClickOutside) {
                           return null;
                        }

                        handleCloseSidebar();
                     }}
                  >
                     <SidebarContent
                        isScrollableBody={isScrollableBody}
                        handleCloseSidebar={handleCloseSidebar}
                        title={title ?? null}
                        icon={icon ?? null}
                     >
                        {content}
                     </SidebarContent>
                  </Sidebar>
               ),
            ),
         )}
         <Suspense fallback={null}>{children}</Suspense>
      </S.SidebarsWrapper>
   );
});

Sidebars.displayName = 'Sidebars';
