// @ts-nocheck
import styled from 'styled-components/macro';

import { orientation } from '@/common/ui/theme/mixins';

export const Button = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  width: 100%;
  border: ${({ theme }) => `2px solid ${theme.colors.grey}`};
  background-color: transparent;
  color: ${({ theme }) => theme.colors?.white};
  font-weight: 400;
  font-size: 16px;
  padding: 8px 3px;

  &:hover:not(:disabled) {
    cursor: pointer;
    border-color: ${({ theme }) => theme.colors?.main};
  }

  &:active:not(:disabled) {
    cursor: pointer;
    background-color: ${({ theme }) => theme.colors?.main};
    border-color: ${({ theme }) => theme.colors?.main};
  }

  &:disabled {
    opacity: 0.6;
  }

  ${orientation.mobileLandscape`
      font-size: 18px;
      padding: 8px 0;
   `}

  ${orientation.portrait`
      font-size: 14px;
   `}
`;
