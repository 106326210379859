import { TNullable } from '@/common/types';
import { FadeBox } from '@/common/ui';
import { winNumberDTO, getWinNumbers } from '@/common/helpers';

import { useWinNumber } from '#/modules/WinNumbers';
import { FADE_DURATION } from '#/constants';

import { useWinNumberAnimation } from './hooks';
import { WinNumbersView } from './components/WinNumbersView/WinNumbersView';
import { ANIMATION_DURATION } from './constants';

export const WinNumbers = (): TNullable<JSX.Element> => {
   const { winNumber } = useWinNumber();
   const formattedWinNumber: string = winNumberDTO(winNumber ?? '');
   const { isStartWinNumberAnimation } = useWinNumberAnimation();
   const { currentColor, leftColors, rightColors, previousNumber, nextNumber } = getWinNumbers(
      winNumber ?? '',
   );

   return (
      <FadeBox isVisible={isStartWinNumberAnimation} duration={FADE_DURATION}>
         <WinNumbersView
            nextNumber={nextNumber}
            leftColors={leftColors}
            rightColors={rightColors}
            currentColor={currentColor}
            winNumber={Number(formattedWinNumber)}
            previousNumber={previousNumber}
            animationDuration={ANIMATION_DURATION}
         />
      </FadeBox>
   );
};
