// @ts-nocheck
import { css } from 'styled-components';

import { Range } from '@/common/types';

import { isDesktopDevice, isMobileDevice } from '../../utils/checkDevice';

import { theme } from '.';

interface IAspectRatio {
  height: number;
  width: number;
}
export const aspectRationMixin = ({ width, height }: IAspectRatio): string => css`
  @supports (aspect-ratio: ${width} / ${height}) {
    aspect-ratio: ${width} / ${height};
  }
  @supports not (aspect-ratio: ${width} / ${height}) {
    position: relative;

    & > video {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }
  }
`;

export const orientation = {
  portrait: (...args) =>
    isMobileDevice() &&
    css`
      @media (orientation: portrait) {
        ${css(...args)}
      }
    `,
  mobileLandscape: (...args) =>
    isMobileDevice() &&
    css`
      @media (orientation: landscape) {
        ${css(...args)}
      }
    `,

  desktopLandscape: (...args) =>
    isDesktopDevice() &&
    css`
      @media (orientation: landscape) {
        ${css(...args)}
      }
    `,
};

// Added additional check for samsung S22 device
export const isHoverSupports = (...args) =>
  isDesktopDevice() &&
  css`
    @media (hover: hover) {
      ${css(...args)}
    }
  `;

type ZIndexValues = keyof typeof theme.zIndex;

export const getZIndex = (zIndexValue: ZIndexValues): number => {
  return theme.zIndex[zIndexValue];
};
export const getSeatZIndex = (seatPosition: Range<0, 7>) => {
  return theme.zIndex.seats[seatPosition];
};
export const applyZIndex = (zIndexValue: ZIndexValues) => {
  return css`
    z-index: ${getZIndex(zIndexValue)};
  `;
};

export const truncatedText = css`
  overflow: hidden;
  text-overflow: ellipsis;
  text-wrap: nowrap;
`;

export const blockCentering = (x: string = '50%', y: string = x) => `
  transform: translate(-${x}, -${y});
  top: ${y};
  left: ${x};
`;

/* for preventing blur inside an iframe */
export const preventBlurInsideIframe = css`
  will-change: transform;
`;
