import { usePowerSavingMode, usePowerSavingModal } from './hooks';
import { TestVideo } from './components/TestVideo/TestVideo';
import { PowerSavingModeModal } from './components/PowerSavingModeModal/PowerSavingModeModal';
import * as S from './PowerSavingModeDetector.styles';

export const PowerSavingModeDetector = ({ children }) => {
   const { isCheckedPowerSavingMode, handleSetCheckedPowerSavingMode } = usePowerSavingMode();
   const { isOpenModal, handleOpenModal, handleCloseModal } = usePowerSavingModal();

   return isCheckedPowerSavingMode ? (
      <>{children}</>
   ) : (
      <>
         <PowerSavingModeModal isShow={isOpenModal} onClose={handleCloseModal} />
         <TestVideo onSuccessPlay={handleSetCheckedPowerSavingMode} onErrorPlay={handleOpenModal} />
         <S.PowerSavingMode isHide={isOpenModal}>{children}</S.PowerSavingMode>
      </>
   );
};
