import dayjs from 'dayjs';

import { TNullable } from '@/common/types';

export const formatDate = ({
   date,
   options,
   locale = 'en-US',
}: {
   date: Date;
   options: Intl.DateTimeFormatOptions;
   locale?: string;
}): string => new Intl.DateTimeFormat(locale, options).format(new Date(date));

export const enum EDateTypeFormat {
   TIME = 'TIME',
   DATE = 'DATE',
   DATE2 = 'DATE2',
   DATE3 = 'DATE3',
   DATE4 = 'DATE4',
}

const dateFormats = {
   [EDateTypeFormat.DATE]: 'D/M/YYYY, HH:mm:ss',
   [EDateTypeFormat.DATE2]: 'ddd D MMM YYYY',
   [EDateTypeFormat.DATE3]: 'M/D/YYYY',
   [EDateTypeFormat.TIME]: 'HH:mm:ss',
   [EDateTypeFormat.DATE4]: 'YYYY-MM-DDTHH:mm:ss.000',
};

type FormatOption = {
   typeFormat?: TNullable<EDateTypeFormat>;
   format?: string;
};

export const formatDateWithDayJS = (
   date: TNullable<Date | string> = null,
   options?: FormatOption,
): string => {
   if (!date) return '-';

   const typeFormat = options?.typeFormat || null;
   const format = options?.format || dateFormats[EDateTypeFormat.DATE];

   const dateFormat = typeFormat ? dateFormats[typeFormat] : format;
   return dayjs(date).format(dateFormat);
};
