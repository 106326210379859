import { FC } from 'react';

import { Banner } from '../../../../ui/Banner/Banner';

import * as S from './DesktopBettingPanelView.styles';

interface IDesktopBettingPanelProps {
  ChipTray: JSX.Element;
  CountDown: JSX.Element;
  LeftControl: JSX.Element;
  RightControl: JSX.Element;
}

export const DesktopBettingPanelView: FC<IDesktopBettingPanelProps> = ({
  CountDown,
  ChipTray,
  LeftControl,
  RightControl,
}) => (
  <Banner>
    <S.DesktopBettingPanelGrid>
      <S.DesktopBettingPanelTimer>{CountDown}</S.DesktopBettingPanelTimer>
      <S.DesktopBettingPanelLeftControl>{LeftControl}</S.DesktopBettingPanelLeftControl>
      <S.DesktopBettingPanelGridChipTray>{ChipTray}</S.DesktopBettingPanelGridChipTray>
      <S.DesktopBettingPanelRightControl>{RightControl}</S.DesktopBettingPanelRightControl>
    </S.DesktopBettingPanelGrid>
  </Banner>
);
