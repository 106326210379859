// @ts-nocheck
import styled, { keyframes } from 'styled-components/macro';

export const backgroundAnimation = keyframes`
	0% {
		opacity: 1
	}

	66.666667% {
		opacity: 1
	}

	100% {
		opacity: 0
	}
`;

export const topCircleAnimation = keyframes`
	0% {
		transform: translate(210.229012px, 8.5px)
	}

	33.333333% {
		transform: translate(131.5px, 8.5px)
	}

	66.666667% {
		transform: translate(43.622299px, 8.5px)
	}

	100% {
		transform: translate(43.622299px, 8.5px)
	}
}`;

export const topCircleScaleAnimation = keyframes`
	0% {
		transform: scale(1, 1)
	}

	33.333333% {
		transform: scale(1.398747, 1.398747)
	}

	66.666667% {
		transform: scale(1, 1)
	}

	100% {
		transform: scale(1, 1)
	}
`;

export const bottomCircleAnimation = keyframes`
	0% {
		transform: translate(42.354202px, 129.5px)
	}

	33.333333% {
		transform: translate(131.5px, 129.5px)
	}

	66.666667% {
		transform: translate(220.0675px, 129.5px)
	}

	100% {
		transform: translate(220.0675px, 129.5px)
	}
`;

export const bottomCircleScaleAnimation = keyframes`
	0% {
		transform: scale(1, 1)
	}

	33.333333% {
		transform: scale(1.4, 1.4)
	}

	66.666667% {
		transform: scale(1, 1)
	}

	100% {
		transform: scale(1, 1)
	}
`;

export const titleTextAnimation = keyframes`
	0% {
		opacity: 0.4
	}

	33.333333% {
		opacity: 1
	}

	66.666667% {
		opacity: 0.4
	}

	100% {
		opacity: 0
	}
`;

export const descriptionTextAnimation = keyframes`
	0% {
		opacity: 1
	}

	66.666667% {
		opacity: 1
	}

	100% {
		opacity: 0
	}
`;

export const lineFadeAnimation = keyframes`
  0% {
    opacity: 0;
  }

  3.333333% {
    opacity: 0;
  }

  10% {
    opacity: 1;
  }

  90% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
`;

export const SVGLines = styled.svg`
  width: 100%;
  height: 100%;
  overflow: visible;
`;
