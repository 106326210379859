import { useGameLanguageSelector } from 'roulette-client-blackjack/src/state/features/gameConfig';

import { History, getTranslations } from '@/common/modules/History';
import { IPlayTapSound } from '@/common/entities';

import { useUserSelector } from '#/services';
import { useUserCurrencySelector } from '#/services/api/authApiSlice/authSlice';
import { useTranslation } from '#/translates';
import { withTapSound } from '#/hocs';

export const RouletteHistoryContainer = ({ playTapSound }: IPlayTapSound) => {
   const { id: userId } = useUserSelector();
   const { t } = useTranslation();
   const currency = useUserCurrencySelector();
   const currentLanguage = useGameLanguageSelector();

   const translation = getTranslations(t);
   return (
      <History
         playTapSound={playTapSound}
         currency={currency}
         userId={userId}
         translation={translation}
         locale={currentLanguage}
      />
   );
};

export const RouletteHistoryContainerWithSound = withTapSound(RouletteHistoryContainer);
