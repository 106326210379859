import { ELastPauseAction, EServerLastPauseAction } from '../../constants';

export const serverByClientPauseActionMap = (
   lastPauseServerAction: EServerLastPauseAction,
): ELastPauseAction => {
   const lastPauseAction = {
      [EServerLastPauseAction.PAUSE]: ELastPauseAction.Pause,
      [EServerLastPauseAction.RESUME]: ELastPauseAction.Resume,
      [EServerLastPauseAction.CANCEL_HAND]: ELastPauseAction.CancelHand,
      [EServerLastPauseAction.CANCEL_SEAT]: ELastPauseAction.CancelSeat,
   };

   return lastPauseAction[lastPauseServerAction];
};
