import { createAction, createListenerMiddleware } from '@reduxjs/toolkit';

import { gameStateSlice } from '@/common/state/gameTypes/roulette/gameState';
import { handleErrorBaseTableStatus } from '@/common/modules/TableStatusOverlay';

import { getAuthData, getTableConfig, getStatistics } from './helpers';

export const userConnectionMiddleware = createListenerMiddleware();
export const userConnectionActionCreator = createAction('auth');

userConnectionMiddleware.startListening({
   actionCreator: userConnectionActionCreator,
   effect: async (_, listenerApi) => {
      const { dispatch, getState } = listenerApi;
      const { setGameStatusAsLoading, setGameStatusAsSuccess } = gameStateSlice.actions;

      try {
         dispatch(setGameStatusAsLoading());

         await getAuthData(dispatch, getState);

         Promise.all([
            await getTableConfig(dispatch, getState),
            await getStatistics(dispatch, getState),
         ]);

         dispatch(setGameStatusAsSuccess());
      } catch (error) {
         handleErrorBaseTableStatus({ dispatch, error });
      }
   },
});
