import { LimitTooltipWrapper } from '@/common/modules';

import { WaitForTheNextStateTooltip } from '#/modules/Tooltips';

import * as S from './CenterStatisticsBar.styles';

export const CenterStatisticsBar = ({
   color,
   label,
   onClick,
   percentage,
   highlightCells,
   dataTestId,
}) => (
   <LimitTooltipWrapper>
      {({ handleShowLimitTooltip }) => (
         <WaitForTheNextStateTooltip>
            <S.CenterStatisticsBarWrapper
               data-testid={dataTestId}
               percentage={percentage}
               handleAction={(e) => {
                  onClick();
                  handleShowLimitTooltip(e[0]);
               }}
            >
               <S.Percentage direction="center">{percentage}%</S.Percentage>
               <S.CenterStatisticsBarContainer color={color} data-highlight-cells={highlightCells}>
                  <span data-highlight-cells={highlightCells}>{label}</span>
               </S.CenterStatisticsBarContainer>
            </S.CenterStatisticsBarWrapper>
         </WaitForTheNextStateTooltip>
      )}
   </LimitTooltipWrapper>
);
