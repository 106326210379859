import { useEffect } from 'react';

import { useGameState } from '@/common/state/gameTypes/roulette/gameState';

import { useTrackState } from '../../state';

export const useTrackCollapse = (): boolean => {
   const { isNoMoreBetsState, isBettingTimeState, isRoundCancelledState } = useGameState();
   const { isCollapsedTrack, handleCollapseTrack } = useTrackState();

   const showTrack = () => handleCollapseTrack(true);
   const hideTrack = () => handleCollapseTrack(false);

   useEffect((): void => {
      if (isNoMoreBetsState || isRoundCancelledState) {
         showTrack();
      }
   }, [isNoMoreBetsState, isRoundCancelledState]);

   useEffect((): void => {
      if (isBettingTimeState) {
         hideTrack();
      }
   }, [isBettingTimeState]);

   return isCollapsedTrack;
};
