import { configureStore } from '@reduxjs/toolkit';

import { chatSlice } from '@/common/modules/Chat';
import { clientApiSlice } from '@/common/services/clientAPI/clientApiSlice';
import { gameStateSlice } from '@/common/state/gameTypes/roulette/gameState';
import { userGeneralSettingsSlice } from '@/common/state/gameTypes/common/userGeneralSettings';
import { timerSlice } from '@/common/modules/CountDown';
import { sidebarSlice } from '@/common/modules/Sidebar/state';
import { chipTraySlice } from '@/common/modules/ChipTray/state';
import { dealerRateSlice } from '@/common/modules/DealerRating/state';
import { notificationsSlice } from '@/common/modules/Notifications';
import { tooltipPortalSlice } from '@/common/modules/TooltipPortal';
import { gameHistorySlice } from '@/common/modules/History';
import { tableStatusSlice } from '@/common/modules/TableStatusOverlay/state';
import { limitTooltipSlice } from '@/common/modules';

import { autoplaySlice } from '#/modules/Autoplay/state';
import { balanceSlice } from '#/modules/Balance';
import { betConfirmedSlice } from '#/modules/BetConfirmedNotification';
import { betConstructorSlice } from '#/modules/Favourite/components/BetConstructor';
import { lastBetsMiddleware, lastBetsSlice } from '#/modules/Favourite/components/SavedBets';
import { winnerListSlice } from '#/modules/PlayersList';
import { rebetSlice, savePreviousRoundBetsMiddleware } from '#/modules/Rebet';
import { statisticsSlice } from '#/modules/Statistics';
import { tableCapacitySlice } from '#/modules/TableCapacity';
import { trackTypeSlice } from '#/modules/Track';
import { raceTrackSlice } from '#/modules/Track/RaceTrack';
import { videoSlice } from '#/modules/LiveStream/state/video';
import { videoLoadingSlice } from '#/modules/LiveStream/components/VideoLoadingScreen/state';
import { winSumSlice } from '#/modules/WinSum';
import { winHistorySlice } from '#/modules/WinHistory';
import { winNumberSlice } from '#/modules/WinNumbers';
import { roundSlice } from '#/services';
import { userConnectionMiddleware } from '#/services/api/middleware/userConnectionMiddleware';
import { apiSlice } from '#/services/api/apiSlice';
import { authSlice } from '#/services/api/authApiSlice/authSlice';
import { sendBetsMiddleware } from '#/services/api/betsApiSlice/middleware';
import { gameConfigSlice } from '#/services/api/gameConfigApiSlice/state';
import { betsSlice } from '#/state/features/bets';
import {
   cancelAutoplayMiddleware,
   updateAutoplayLossLimitMiddleware,
   reconnectMiddleware,
} from '#/state/middlewares';
import { videoSizeSlice } from '#/modules/LiveStream/state';
import { videoQualitySlice } from '#/modules/LiveStream/state/videoQuality';
import { announcementsSlice } from '#/modules/Announcements/state';
import { dealerRatingEventMiddleware } from '#/services/api/middleware/dealerRatingMiddleware/dealerRatingEventMiddleware';
import { dealerFeedbackRequestEventMiddleware } from '#/services/api/middleware/dealerRatingMiddleware/dealerFeedbackRequestEventMiddleware';
import { dealerRatingUpdateEventMiddleware } from '#/services/api/middleware/dealerRatingMiddleware/dealerRatingUpdateEventMiddleware';
import { casinoGameTypeSettingsEventMiddleware } from '#/services/api/middleware/casinoGameTypeSettingsMiddleware';
import { userGeneralSettingsUpdatedEventMiddleware } from '#/services/api/middleware/userGeneralSettingsUpdatedMiddleware';
import { userGeneralSettingsEventMiddleware } from '#/services/api/middleware/userGeneralSettingsMiddleware';

import { playersCountSlice } from './features/playersCount';

const enableDevTools: boolean = import.meta.env.DEV;

export const store = configureStore({
   reducer: {
      winSum: winSumSlice.reducer,
      auth: authSlice.reducer,
      chat: chatSlice.reducer,
      bets: betsSlice.reducer,
      timer: timerSlice.reducer,
      video: videoSlice.reducer,
      balance: balanceSlice.reducer,
      sidebar: sidebarSlice.reducer,
      lastBets: lastBetsSlice.reducer,
      chipTray: chipTraySlice.reducer,
      videoSize: videoSizeSlice.reducer,
      videoQuality: videoQualitySlice.reducer,
      gameState: gameStateSlice.reducer,
      raceTrack: raceTrackSlice.reducer,
      winNumber: winNumberSlice.reducer,
      trackType: trackTypeSlice.reducer,
      winHistory: winHistorySlice.reducer,
      statistics: statisticsSlice.reducer,
      betConfirmed: betConfirmedSlice.reducer,
      videoLoading: videoLoadingSlice.reducer,
      playersCount: playersCountSlice.reducer,
      notifications: notificationsSlice.reducer,
      tableCapacity: tableCapacitySlice.reducer,
      tooltipPortal: tooltipPortalSlice.reducer,
      betConstructor: betConstructorSlice.reducer,
      limitTooltip: limitTooltipSlice.reducer,
      round: roundSlice.reducer,
      rebet: rebetSlice.reducer,
      history: gameHistorySlice.reducer,
      autoplay: autoplaySlice.reducer,
      winnerList: winnerListSlice.reducer,
      gameConfig: gameConfigSlice.reducer,
      [apiSlice.reducerPath]: apiSlice.reducer,
      [clientApiSlice.reducerPath]: clientApiSlice.reducer,
      announcements: announcementsSlice.reducer,
      dealerRate: dealerRateSlice.reducer,
      userGeneralSettings: userGeneralSettingsSlice.reducer,
      tableStatus: tableStatusSlice.reducer,
   },
   devTools: enableDevTools,
   middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware()
         .prepend([
            clientApiSlice.middleware,
            apiSlice.middleware,
            userConnectionMiddleware.middleware,
            cancelAutoplayMiddleware.middleware,
            updateAutoplayLossLimitMiddleware.middleware,
            sendBetsMiddleware.middleware,
            savePreviousRoundBetsMiddleware.middleware,
            dealerRatingEventMiddleware.middleware,
            dealerFeedbackRequestEventMiddleware.middleware,
            dealerRatingUpdateEventMiddleware.middleware,
            casinoGameTypeSettingsEventMiddleware.middleware,
            userGeneralSettingsUpdatedEventMiddleware.middleware,
            userGeneralSettingsEventMiddleware.middleware,
            reconnectMiddleware.middleware,
         ])
         .concat(lastBetsMiddleware),
});
