import { FC, ComponentProps } from 'react';

import { isMobileDevice } from '@/common/utils/checkDevice';

import * as S from './LimitsRange.styles';

export const LimitsRange: FC<
  ComponentProps<'div'> & {
    handleHideLimitsTable: () => void;
    handleShowLimitsTable: () => void;
    isShowLimitsTable: boolean;
  }
> = ({ children, isShowLimitsTable, handleShowLimitsTable, handleHideLimitsTable }) => {
  const handleOnClick = () =>
    isShowLimitsTable ? handleHideLimitsTable() : handleShowLimitsTable();

  return isMobileDevice() ? (
    <>
      {isShowLimitsTable && <S.MobileBackdrop onClick={handleHideLimitsTable} />}
      <S.LimitsRange data-testid="limit-value" onClick={handleOnClick}>
        {children}
      </S.LimitsRange>
    </>
  ) : (
    <S.LimitsRange
      data-testid="limit-value"
      onClick={handleOnClick}
      onMouseEnter={handleShowLimitsTable}
      onMouseLeave={handleHideLimitsTable}
    >
      {children}
    </S.LimitsRange>
  );
};
