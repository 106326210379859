import { useEffect } from 'react';
import { Socket } from 'socket.io-client';
import { useAuthSelector } from 'services/api/authApiSlice/authSlice';
import { useGameConfigStore } from 'services/api/gameConfigApiSlice/state';
import { useSocketEvents } from 'services/sockets';

import { eventsTracker, eventsToTrackController } from '@/common/dev/eventsTracker';

import { useConnectAnnouncementToSockets } from '#/modules/Announcements/services/hooks';

import { useReconnect } from '../useReconnect';
import { rouletteSocket } from '../SocketConnection';

export const useInitSocketConnection = (): void => {
   const events = useSocketEvents();
   const { gameConfig } = useGameConfigStore();
   const { user, session } = useAuthSelector();
   const { handleConnectError, handleReconnect, handleReachingReconnectAttempts } = useReconnect();
   const handleAddAnnouncementsEvents = useConnectAnnouncementToSockets();

   const handleSocketReconnect = (socket: Socket) => {
      if (socket) {
         socket.io.on('reconnect', async () => {
            handleReconnect();
         });
         socket.io.on('reconnect_failed', () => {
            handleReachingReconnectAttempts();
         });
      }
   };

   const handleTrackEvents = (socket: Socket) => {
      if (socket) {
         socket.onAny((event) => {
            const eventsToTrack = eventsToTrackController.get();
            eventsTracker(event, eventsToTrack);

            handleAddAnnouncementsEvents(event);
         });
      }
   };

   const hasRequiredParamsForConnection =
      user?.id && session?.id && gameConfig?.gameTableId && gameConfig?.wssUrl;

   useEffect(() => {
      if (!hasRequiredParamsForConnection) {
         return;
      }

      rouletteSocket.createConnection({
         hasRequiredParamsForConnection,
         baseUrl: gameConfig.wssUrl,
         queryParams: {
            table: gameConfig.gameTableId,
            userId: user.id,
            sessionId: session.id,
         },
         // @ts-ignore
         events,
         additionalAction: {
            reconnectHandlers: {
               // @ts-ignore

               handleSocketReconnect,
               handleConnectError,
            },
            // @ts-ignore

            trackEvents: { handleTrackEvents },
         },
         additionalConfig: {
            reconnectionAttempts: import.meta.env.VITE_SOCKET_RECONNECT_RETRY_ATTEMPTS,
         },
      });

      return () => rouletteSocket.disconnect();
   }, [hasRequiredParamsForConnection]);
};
