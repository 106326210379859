// @ts-nocheck
import styled, { css } from 'styled-components/macro';
import { bettingMapGridAreas } from 'theme/mixins';

import { defaultBettingMapColorsConfig } from '@/common/roulette/constants';
import { isHoverSupports, orientation } from '@/common/ui/theme/mixins';

const colors = {
   hot: defaultBettingMapColorsConfig.hotShadowColor,
   cold: defaultBettingMapColorsConfig.coldShadowColor,
};

export const Container = styled.div`
   --cell-size: 20px;
   --half-cell-width: calc(var(--cell-size) / 2);

   --box-shadow-size: 2px;
   --box-shadow: 0 var(--box-shadow-size) 0 0 var(--betting-map-border-color) inset,
      var(--box-shadow-size) 0 0 0 var(--betting-map-border-color) inset,
      0 var(--box-shadow-size) 0 0 var(--betting-map-border-color),
      var(--box-shadow-size) var(--box-shadow-size) 0 0 var(--betting-map-border-color),
      var(--box-shadow-size) 0 0 0 var(--betting-map-border-color);

   ${orientation.mobileLandscape`
      --cell-size: 15px;
   `}

   position: relative;
   display: grid;
   align-items: center;
   justify-content: center;
   padding-top: var(--box-shadow-size);
   padding-left: var(--box-shadow-size);
   box-shadow: var(--box-shadow);

   ${({ color }) =>
      color &&
      css`
         box-shadow:
            var(--box-shadow),
            var(--${color}-betting-map-shadow-color, ${colors[color]}) 0px 0px 10px 10px inset;
      `};

   background-color: ${({ fillBg }) => fillBg || 'var(--betting-map-bg-default)'};

   &.cell_base_sector_red {
      background-color: var(--betting-map-base-sector-bg-red);
   }
   &.cell_base_sector_black {
      background-color: var(--betting-map-base-sector-bg-black);
   }
   &.cell_zero {
      background-color: var(--betting-map-bg-zero);
   }

   &.cell_red {
      background-color: var(--betting-map-bg-red);
   }

   &.cell_black {
      background-color: var(--betting-map-bg-black);
   }

   ${bettingMapGridAreas()}

   cursor: pointer;
   user-select: none;

   &.highlight {
      ${isHoverSupports`
         --border-color: ${({ theme }) => theme.colors?.main};
         background-color: rgba(210, 210, 210, 0.5);
   `}
   }
`;
