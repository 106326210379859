import { Overlay } from '@/common/ui';
import { EModalNotificationType } from '@/common/ui/ModalNotification/constants';

export const MaintenanceOverlay = ({ onClick, buttonTitle, modalTitle }) => {
   return (
      <Overlay
         type={EModalNotificationType.Warning}
         title={buttonTitle}
         actionButton={{
            title: modalTitle,
            onClick,
         }}
      />
   );
};
