import { IBlackjackBetAmount } from '../../../seatBetsAdapter/types';
import { TGameState } from '../../types';

export interface IDealNowAdapter {
   adapt: (params: {
      uniqueTakenSeatsAmount: number;
      dealNowLimitValue: number;
      playerBetAmountByBetType: IBlackjackBetAmount;
      playerId: string;
      dealNowUsers: string[];
   }) => TGameState['dealNow'];
}

class DealAdapter implements IDealNowAdapter {
   public adapt = ({
      uniqueTakenSeatsAmount,
      playerBetAmountByBetType,
      playerId,
      dealNowUsers,
      dealNowLimitValue,
   }: {
      uniqueTakenSeatsAmount: number;
      dealNowLimitValue: number;
      playerBetAmountByBetType: IBlackjackBetAmount;
      playerId: string;
      dealNowUsers: string[];
   }): TGameState['dealNow'] => {
      const isPlayerMadeDealNow = dealNowUsers.includes(playerId);
      const isPlayerHasPlacedAnteBet = playerBetAmountByBetType.ante > 0;
      if (!isPlayerHasPlacedAnteBet) {
         return {
            canMakeDealNow: false,
            isWaitingOtherPlayers: false,
            dealNowUsers,
         };
      }

      if (isPlayerMadeDealNow) {
         return {
            canMakeDealNow: false,
            isWaitingOtherPlayers: true,
            dealNowUsers,
         };
      }

      const isDealNowAvailable = uniqueTakenSeatsAmount <= dealNowLimitValue;
      if (!isDealNowAvailable) {
         return {
            canMakeDealNow: false,
            isWaitingOtherPlayers: false,
            dealNowUsers,
         };
      }

      return {
         canMakeDealNow: !isPlayerMadeDealNow,
         isWaitingOtherPlayers: isPlayerMadeDealNow,
         dealNowUsers,
      };
   };
}

export const dealNowAdapter = new DealAdapter();
