import { z } from 'zod';

export const dealerRatingScheme = z.object({
   dealerId: z.string(),
   dealerRating: z.number(),
   dealerName: z.string(),
   isFeedbackEnabledOnCasinoGameTypeLevel: z.boolean(),
});

export type TDealerRatingEvent = z.infer<typeof dealerRatingScheme>;
