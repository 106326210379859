// @ts-nocheck
import styled, { css } from 'styled-components/macro';

import { orientation, isHoverSupports } from '../../ui/theme/mixins';
import { hexToRgba } from '../../utils/hexToRgba';

const isActiveControlStyles = css`
  box-shadow: var(--hover-box-shadow);
  background: var(--hover-bg), var(--control-bg-color);

  circle,
  path {
    stroke: var(--control-hover-color);
  }
`;

export const SvgWrapper = styled.div`
  display: flex;
  width: 100%;
  aspect-ratio: 1/1;
  pointer-events: none;

  > * {
    pointer-events: none;
  }
`;

export const ControlWrapper = styled.button`
  --control-hover-color: ${({ theme }) => theme.colors.main};
  --control-default-color: #ffffff;
  --control-bg-color: #030303;

  --control-size: ${({ size }) => size};

  --hover-transition-duration: 0.2s;
  --hover-transition-timing-function: ease-in-out;
  --hover-box-shadow: 0 0 6px 0 var(--control-hover-color);
  --hover-bg: ${({ theme }) =>
    `linear-gradient(180deg, ${hexToRgba(theme.colors.main, 0.2)} 0%, ${hexToRgba(
      theme.colors.main,
      0.16,
    )} 100%)`};

  position: relative;
  width: var(--control-size);
  height: var(--control-size);
  border-radius: 50px;
  background: var(--control-bg-color);
  transition: box-shadow var(--hover-transition-duration) var(--hover-transition-timing-function);
  cursor: pointer;

  svg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
  }

  circle,
  path {
    fill: none;
    stroke: var(--control-default-color);
    stroke-width: 1;
    transition:
      stroke var(--hover-transition-duration) var(--hover-transition-timing-function),
      fill var(--hover-transition-duration) var(--hover-transition-timing-function);
  }

  circle {
    stroke: ${({ isHasInitialBorder }) =>
      isHasInitialBorder ? 'var(--control-default-color)' : 'none'};
  }

  path {
    stroke-linejoin: round;
  }

  &:focus {
    outline: none;
  }

  &:disabled {
    cursor: auto;
    pointer-events: none;
    box-shadow: none;
    opacity: 0.4;
  }

  ${isHoverSupports`
      &:hover {
        --control-default-color: var(--control-hover-color);

        box-shadow: var(--hover-box-shadow);

        circle {
            stroke: var(--control-default-color);
        }
      }
  `};

  ${({ isActive }) =>
    isActive &&
    css`
      ${isActiveControlStyles}
    `}

  &:active {
    ${isActiveControlStyles}
  }

  ${orientation.portrait`
    width: 13vw;
    height: 13vw;

      & {
        margin: 6px 0;
      }
    `}

  ${orientation.mobileLandscape`
    width: 44px;
    height: 44px;
  `}
`;
