import { TNullable } from '@/common/types';

export const getWinNumberColorKey = (winNumber: TNullable<string>, t) => {
   if (!winNumber) {
      return;
   }

   const redNumbers: number[] = [1, 3, 5, 7, 9, 12, 14, 16, 18, 19, 21, 23, 25, 27, 30, 32, 34, 36];

   return redNumbers.includes(parseInt(winNumber))
      ? t('announcements.numberColors.red')
      : t('announcements.numberColors.black');
};
