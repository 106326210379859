import { FC } from 'react';

import { Overlay } from '@/common/ui';
import { EModalNotificationType } from '@/common/ui/ModalNotification/constants';

interface INotifyInactivePlayerProps {
   buttonTitle: string;
   modalTitle: string;
   onClick: () => void;
}

export const NotifyInactivePlayer: FC<INotifyInactivePlayerProps> = ({
   onClick,
   buttonTitle,
   modalTitle,
}) => (
   <Overlay
      withBackdrop={false}
      actionButton={{
         title: buttonTitle,
         onClick,
      }}
      type={EModalNotificationType.Warning}
      title={modalTitle}
   />
);
