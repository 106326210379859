import { ENotificationIcons, useNotificationsActions } from '@/common/modules/Notifications';

import { translation } from '#/translates';

import { MaintenancePendingEvent } from './schemes';

type MaintenanceEndEventOutput = (socket: MaintenancePendingEvent) => void;

export const useMaintenancePending = (): MaintenanceEndEventOutput => {
   const { handleAddNotification } = useNotificationsActions();

   return () => {
      handleAddNotification({
         title: translation('notifications.maintenancePending.title'),
         action: '',
         content: translation('notifications.maintenancePending.content'),
         type: ENotificationIcons.Warning,
      });
   };
};
