export const enum ETableStatus {
   Error = 'ERROR',
   MaintenanceIsPending = 'PENDING',
   MaintenanceInProgress = 'STARTED',
   MaintenanceIsEnded = 'ENDED',
   WaitForConnection = 'WAIT_FOR_CONNECTION',
   InactiveTable = 'INACTIVE_TABLE',
   SessionClosed = 'SESSION_CLOSED',
   LostConnection = 'LOST_CONNECTION',
   KickInactivePlayer = 'KICK_INACTIVE_PLAYER',
   LoginFromAnotherPlace = 'LOGIN_FROM_ANOTHER_PLACE',
}
