import { EGameState, useGameStateActions } from '@/common/state/gameTypes/roulette/gameState';

import { LastBetsStartTimeEvent } from './schemas';

type UseRoundResultOutput = (socket: LastBetsStartTimeEvent) => void;

export const useLastBetsStartTime = (): ((socket: UseRoundResultOutput) => void) => {
   const { handleChangeGameState } = useGameStateActions();

   return () => {
      handleChangeGameState(EGameState.LAST_BETS);
   };
};
