import { FC, ReactNode } from 'react';

import { useGameType } from '#/services/api/gameConfigApiSlice/state/gameConfig';

interface IDealerNameRuleProps {
   children: ReactNode;
   gameType: ReturnType<typeof useGameType>;
}

export const DealerNameRule: FC<IDealerNameRuleProps> = ({ gameType, children }) => {
   const isAutoRoulette = gameType.isAutoRoulette;

   // for autoroulette we don't show dealer name rule
   return isAutoRoulette ? null : <>{children}</>;
};
