import { TNullable } from '@/common/types';
import { logger } from '@/common/utils';

const TOOLTIP_TOP_GAP = 4;

export const positioningTooltip = ({
   target,
   selector,
   gap,
}: {
   target: HTMLElement;
   selector: string;
   gap?: number;
}): void => {
   try {
      const {
         width: targetWidth,
         top: targetTop,
         left: targetLeft,
      } = target.getBoundingClientRect();

      requestAnimationFrame(() => {
         const tooltipNode: TNullable<HTMLElement> = document.querySelector(selector);

         if (tooltipNode) {
            tooltipNode.style.position = 'absolute';
            const tooltipTop = Math.max(
               0,
               targetTop - tooltipNode.clientHeight - (gap ?? TOOLTIP_TOP_GAP),
            );
            const tooltipLeft = Math.max(
               0,
               targetLeft + targetWidth / 2 - tooltipNode.clientWidth / 2,
            );

            tooltipNode.style.top = `${tooltipTop}px`;
            tooltipNode.style.left = `${tooltipLeft}px`;

            const tooltipRect = tooltipNode.getBoundingClientRect();

            if (tooltipRect.right > window.innerWidth) {
               tooltipNode.style.left = 'unset';
               tooltipNode.style.right = '0';
            }
         }
      });
   } catch (error) {
      logger.log('Tooltip not found:', error);
   }
};
