export const enum EControl {
  Chat = 'Chat',
  Play = 'Play',
  Menu = 'Menu',
  Statistics = 'Statistics',
  Remove = 'Remove',
  Favourite = 'MyBets',
  BettingMap = 'BettingMap',
  Racetrack = 'Racetrack',
  MuteVolume = 'MuteVolume',
  ToggleVideo = 'ToggleVideo',
  UnmuteVolume = 'UnmuteVolume',
  Collapse = 'Collapse',
  CollapseExpand = 'CollapseExpand',
  Incognito = 'Incognito',
}
