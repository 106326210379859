import { straightConfig } from 'core/config';

import { ETypeBet } from '@/common/roulette/constants';

import { TCommonBet } from '#/state/features/bets';
import { FinalCommandParams } from '#/modules/Track/BettingMap/commands/special/types';

import { AbstractSpecialCommand } from './AbstractSpecialCommand';
import { ISpecialCommand } from './types';

export class FinalCommand extends AbstractSpecialCommand implements ISpecialCommand {
   constructor({ cell, amount }: FinalCommandParams) {
      super({ type: ETypeBet.FINAL, amount, cell });

      this.extractedCommand = this.extract();
   }

   extract = (): TCommonBet[] =>
      straightConfig
         .getConfig()
         .filter(({ id }) => {
            const [firstDigit = null, secondDigit = null] = String(id);
            const oneDigitNumber = !secondDigit && firstDigit === String(this.cell);
            const twoDigitNumber = secondDigit === String(this.cell);
            return oneDigitNumber || twoDigitNumber;
         })
         .map(({ id, type, numbers }) => ({
            id,
            amount: this.amount,
            type,
            numbers,
            chips: {
               [this.amount]: this.numberOfChips,
            },
         }));
}
