import { FC } from 'react';

import * as S from './WinAmountView.styles';

interface IWinAmountView {
  animationDuration: number;
}

export const DesktopWinAmountView: FC<IWinAmountView> = ({ animationDuration }) => (
  <S.SVGLines
    viewBox="0 0 438 124"
    shapeRendering="geometricPrecision"
    textRendering="geometricPrecision"
    animationDuration={animationDuration}
  >
    <defs xmlns="http://www.w3.org/2000/svg">
      <linearGradient
        id="ekfYmiLh4e23-stroke"
        x1="418"
        y1="120.001"
        x2="43.9174"
        y2="120.001"
        spreadMethod="pad"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(0 0)"
      >
        <stop id="ekfYmiLh4e23-stroke-0" offset="0%" stop-color="rgba(255,195,79,0)" />
        <stop id="ekfYmiLh4e23-stroke-1" offset="51.0417%" stop-color="#faa200" />
        <stop id="ekfYmiLh4e23-stroke-2" offset="100%" stop-color="rgba(255,195,79,0)" />
      </linearGradient>
      <linearGradient
        id="ekfYmiLh4e24-fill"
        x1="438"
        y1="114.00016"
        x2="0"
        y2="114.00016"
        spreadMethod="pad"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(0 0)"
      >
        <stop id="ekfYmiLh4e24-fill-0" offset="4.16667%" stop-color="rgba(0,0,0,0)" />
        <stop id="ekfYmiLh4e24-fill-1" offset="28.6458%" stop-color="rgba(0,0,0,0.722222)" />
        <stop id="ekfYmiLh4e24-fill-2" offset="51.0417%" stop-color="#000" />
        <stop id="ekfYmiLh4e24-fill-3" offset="70.8333%" stop-color="rgba(0,0,0,0.634146)" />
        <stop id="ekfYmiLh4e24-fill-4" offset="93.75%" stop-color="rgba(0,0,0,0)" />
      </linearGradient>
      <linearGradient
        id="ekfYmiLh4e25-stroke"
        x1="417"
        y1="3.98519"
        x2="48.356"
        y2="3.97973"
        spreadMethod="pad"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(0 0)"
      >
        <stop id="ekfYmiLh4e25-stroke-0" offset="0%" stop-color="rgba(255,195,79,0)" />
        <stop id="ekfYmiLh4e25-stroke-1" offset="51.0417%" stop-color="#faa200" />
        <stop id="ekfYmiLh4e25-stroke-2" offset="100%" stop-color="rgba(255,195,79,0)" />
      </linearGradient>
      <filter id="ekfYmiLh4e27-filter" x="-150%" width="400%" y="-150%" height="400%">
        <feGaussianBlur id="ekfYmiLh4e27-filter-blur-0" stdDeviation="3.5,3.5" result="result" />
      </filter>
      <radialGradient
        id="ekfYmiLh4e210-fill"
        cx="0"
        cy="0"
        r="1"
        spreadMethod="pad"
        gradientUnits="userSpaceOnUse"
        gradientTransform="matrix(13.1071 2.69999 -1.06473 5.16873 -0.107 0)"
      >
        <stop id="ekfYmiLh4e210-fill-0" offset="0%" stop-color="#e6faff" />
        <stop id="ekfYmiLh4e210-fill-1" offset="100%" stop-color="rgba(79,223,255,0)" />
      </radialGradient>
      <radialGradient
        id="ekfYmiLh4e211-fill"
        cx="0"
        cy="0"
        r="1"
        spreadMethod="pad"
        gradientUnits="userSpaceOnUse"
        gradientTransform="matrix(33.0201 0 0 0.798464 0.638 0.27391)"
      >
        <stop id="ekfYmiLh4e211-fill-0" offset="46.875%" stop-color="#fff" />
        <stop id="ekfYmiLh4e211-fill-1" offset="93.75%" stop-color="rgba(255,255,255,0)" />
      </radialGradient>
      <filter id="ekfYmiLh4e213-filter" x="-150%" width="400%" y="-150%" height="400%">
        <feGaussianBlur id="ekfYmiLh4e213-filter-blur-0" stdDeviation="3.5,3.5" result="result" />
      </filter>
      <radialGradient
        id="ekfYmiLh4e216-fill"
        cx="0"
        cy="0"
        r="1"
        spreadMethod="pad"
        gradientUnits="userSpaceOnUse"
        gradientTransform="matrix(13.1071 2.69999 -1.06473 5.16873 -0.1071 0)"
      >
        <stop id="ekfYmiLh4e216-fill-0" offset="0%" stop-color="#e6faff" />
        <stop id="ekfYmiLh4e216-fill-1" offset="100%" stop-color="rgba(79,223,255,0)" />
      </radialGradient>
      <radialGradient
        id="ekfYmiLh4e217-fill"
        cx="0"
        cy="0"
        r="1"
        spreadMethod="pad"
        gradientUnits="userSpaceOnUse"
        gradientTransform="matrix(33.0201 0 0 0.798464 0.6384 0.274)"
      >
        <stop id="ekfYmiLh4e217-fill-0" offset="46.875%" stop-color="#fff" />
        <stop id="ekfYmiLh4e217-fill-1" offset="93.75%" stop-color="rgba(255,255,255,0)" />
      </radialGradient>
      <filter id="ekfYmiLh4e218-filter" x="-150%" width="400%" y="-150%" height="400%">
        <feGaussianBlur id="ekfYmiLh4e218-filter-blur-0" stdDeviation="1.5,1.5" result="result" />
      </filter>
      <linearGradient
        id="ekfYmiLh4e219-stroke"
        x1="396.279"
        y1="121.001"
        x2="54.6243"
        y2="121.001"
        spreadMethod="pad"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(0 0)"
      >
        <stop id="ekfYmiLh4e219-stroke-0" offset="0%" stop-color="rgba(255,195,79,0)" />
        <stop id="ekfYmiLh4e219-stroke-1" offset="51.0417%" stop-color="#faa200" />
        <stop id="ekfYmiLh4e219-stroke-2" offset="100%" stop-color="rgba(255,195,79,0)" />
      </linearGradient>
      <filter id="ekfYmiLh4e220-filter" x="-150%" width="400%" y="-150%" height="400%">
        <feGaussianBlur id="ekfYmiLh4e220-filter-blur-0" stdDeviation="1.5,1.5" result="result" />
      </filter>
      <linearGradient
        id="ekfYmiLh4e221-stroke"
        x1="396"
        y1="7.00118"
        x2="45.9587"
        y2="7.00125"
        spreadMethod="pad"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(0 0)"
      >
        <stop id="ekfYmiLh4e221-stroke-0" offset="0%" stop-color="rgba(255,195,79,0)" />
        <stop id="ekfYmiLh4e221-stroke-1" offset="51.0417%" stop-color="#faa200" />
        <stop id="ekfYmiLh4e221-stroke-2" offset="100%" stop-color="rgba(255,195,79,0)" />
      </linearGradient>
      <linearGradient
        id="ekfYmiLh4e225-fill"
        x1="99"
        y1="27"
        x2="99"
        y2="84"
        spreadMethod="pad"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(0 0)"
      >
        <stop id="ekfYmiLh4e225-fill-0" offset="0%" stop-color="rgba(255,206,79,0.49)" />
        <stop id="ekfYmiLh4e225-fill-1" offset="29.1667%" stop-color="#faa200" />
      </linearGradient>
      <linearGradient
        id="ekfYmiLh4e226-fill"
        x1="98.658691"
        y1="-57.349503"
        x2="98.658691"
        y2="-0.349503"
        spreadMethod="pad"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(0 0)"
      >
        <stop id="ekfYmiLh4e226-fill-0" offset="0%" stop-color="rgba(255,206,79,0.49)" />
        <stop id="ekfYmiLh4e226-fill-1" offset="29.1667%" stop-color="#faa200" />
      </linearGradient>
      <linearGradient
        id="ekfYmiLh4e227-fill"
        x1="0.5"
        y1="0"
        x2="0.5"
        y2="1"
        spreadMethod="pad"
        gradientUnits="objectBoundingBox"
        gradientTransform="translate(0 0)"
      >
        <stop id="ekfYmiLh4e227-fill-0" offset="0%" stop-color="rgba(255,255,255,0)" />
        <stop id="ekfYmiLh4e227-fill-1" offset="100%" stop-color="#010022" />
      </linearGradient>
    </defs>

    <g id="win-amount-background">
      <line
        x1="29"
        y1="118"
        x2="418"
        y2="118"
        fill="none"
        stroke="url(#ekfYmiLh4e23-stroke)"
        stroke-width="2"
        id="bottom-line"
      />
      <rect
        width="438"
        height="114"
        rx="0"
        ry="0"
        transform="translate(0 4.83984)"
        fill="url(#ekfYmiLh4e24-fill)"
      />
      <path
        d="M32,3.99995l383,.0001"
        fill="none"
        stroke="url(#ekfYmiLh4e25-stroke)"
        stroke-width="2"
        id="top-line"
      />

      <g id="top-line-circle" transform="translate(336.5,4.5)">
        <g id="top-line-circle-scale" transform="scale(1,1)">
          <g id="top-line-circle-inner" transform="translate(-336.5,-4.5)" opacity="0.5">
            <g filter="url(#ekfYmiLh4e27-filter)">
              <ellipse rx="27" ry="1.5" transform="translate(337 4.5)" fill="#faa200" />
            </g>
            <g>
              <ellipse
                rx="19.5"
                ry="4.5"
                transform="matrix(2.38392 0 0 1 337.5 4.5)"
                fill="url(#ekfYmiLh4e210-fill)"
              />
            </g>
            <ellipse
              rx="51.5"
              ry="2"
              transform="translate(336.5 4)"
              fill="url(#ekfYmiLh4e211-fill)"
            />
          </g>
        </g>
      </g>
      <g id="bottom-line-circle" transform="translate(89.5,119.5)">
        <g id="bottom-line-circle-scale" transform="scale(1,1)">
          <g id="bottom-line-circle-inner" transform="translate(-89.5,-119.5)" opacity="0.5">
            <g filter="url(#ekfYmiLh4e213-filter)">
              <ellipse rx="27" ry="1.5" transform="translate(90 119.5)" fill="#faa200" />
            </g>
            <g>
              <ellipse
                rx="19.5"
                ry="4.5"
                transform="matrix(2.076925 0 0 0.333334 90.5 119.5)"
                fill="url(#ekfYmiLh4e216-fill)"
              />
            </g>
            <ellipse
              rx="51.5"
              ry="2"
              transform="translate(89.5 119)"
              fill="url(#ekfYmiLh4e217-fill)"
            />
          </g>
        </g>
      </g>

      <g filter="url(#ekfYmiLh4e218-filter)">
        <line
          x1="41"
          y1="119"
          x2="396.279"
          y2="119"
          fill="none"
          stroke="url(#ekfYmiLh4e219-stroke)"
          stroke-width="2"
        />
      </g>

      <g filter="url(#ekfYmiLh4e220-filter)">
        <line
          x1="32"
          y1="5"
          x2="396"
          y2="4.99997"
          fill="none"
          stroke="url(#ekfYmiLh4e221-stroke)"
          stroke-width="2"
        />
      </g>
    </g>
  </S.SVGLines>
);
