// @ts-nocheck
import styled from 'styled-components/macro';

import { applyZIndex, orientation } from '@/common/ui/theme/mixins';

export const WarningNotificationWrapper = styled.div`
  --padding: 10px;
  --fz: 14px;
  --icon-size: 24px;
  ${applyZIndex('biggest')};

  background: rgba(0, 0, 0, 0.9);
  border: 1px solid rgba(255, 187, 61, 0.3);
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 7px;
  position: absolute;
  padding: var(--padding);
  max-width: 280px;
  width: fit-content;
  top: -100%;
  left: -100%;
  color: #fff;
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: space-between;
  pointer-events: none;
  scale: var(--scale);
  transform-origin: bottom;
  font-size: var(--fz);
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
  opacity: ${({ shouldShowNotification }) => (shouldShowNotification ? '1' : '0')};

  ${orientation.mobileLandscape`
    --padding: 8px;
    --fz: 10px;
    --icon-size: 16px;
  `};

  ${orientation.portrait`
    --padding: 8px;
    --fz: 10px;
    --icon-size: 16px;
  `};
`;

export const Icon = styled.div`
  position: relative;
  width: var(--icon-size);
  height: var(--icon-size);
  display: flex;
  flex-shrink: 0;

  svg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`;

export const WarningNotificationContent = styled.div`
  width: 100%;
`;
