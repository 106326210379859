import { TNullable } from '@/common/types';
import { EBJBetType } from '@/common/blackjack/constants';

import { TSeat } from '../../../../types';
import { EBlackJackGameStep, EServerGameStep } from '../../constants';

export interface IGameStepAdapter {
   adaptServerGameStepToClient: (params: { serverGameStep: EServerGameStep }) => EBlackJackGameStep;
   isAwaitCard: (serverGameStatus: EServerGameStep) => boolean;
   isAwaitingBets: (serverGameStatus: EServerGameStep) => boolean;
   isBettingTime: (serverGameStatus: EServerGameStep) => boolean;
   isDealingTime: (serverGameStatus: EServerGameStep) => boolean;
   isEarlyDecisionGameStep: (serverGameStatus: EServerGameStep) => boolean;
   isInsuranceTime: (serverGameStatus: EServerGameStep) => boolean;
   isPlayerDecisionTime: (serverGameStatus: EServerGameStep) => boolean;
   isRoundFinished: (serverGameStatus: EServerGameStep) => boolean;
   serverByClientGameStepMap: (serverGameStatus: EServerGameStep) => EBlackJackGameStep;
   shouldWaitNextRound: (params: {
      seats: TSeat[];
      isPlayerHasTakenSeat: boolean;
      serverGameStep: EServerGameStep;
      bettingStartTime: TNullable<string>;
   }) => boolean;
}

class GameStepAdapter implements IGameStepAdapter {
   public serverByClientGameStepMap = (serverGameStatus: EServerGameStep): EBlackJackGameStep => {
      const gameStepsMap = {
         [EServerGameStep.AWAITING_BETS]: EBlackJackGameStep.AwaitingBets,
         [EServerGameStep.AWAIT_CARD]: EBlackJackGameStep.AwaitCard,
         [EServerGameStep.BETTING_TIME]: EBlackJackGameStep.BettingTime,
         [EServerGameStep.LAST_BETS]: EBlackJackGameStep.LastBets,
         [EServerGameStep.INIT]: EBlackJackGameStep.DealCards,
         [EServerGameStep.INSURANCE]: EBlackJackGameStep.Insurance,
         [EServerGameStep.PLAYER_INPUT]: EBlackJackGameStep.PlayerDecision,
         [EServerGameStep.FINISHED]: EBlackJackGameStep.RoundFinished,
         [EServerGameStep.CANCELED]: EBlackJackGameStep.RoundCanceled,
         [EServerGameStep.PAUSED]: EBlackJackGameStep.GamePaused,
         [EServerGameStep.BET_PROCESSING]: EBlackJackGameStep.BetProcessing,
      };

      return gameStepsMap[serverGameStatus];
   };

   private isBettingTimeFinished = ({
      serverGameStep,
      bettingStartTime,
   }: {
      serverGameStep: EServerGameStep;
      bettingStartTime: TNullable<string>;
   }) => {
      const currentGameStepIsNotBettingTime = ![
         EServerGameStep.BETTING_TIME,
         EServerGameStep.LAST_BETS,
      ].includes(serverGameStep);

      const bettingTimeStarted = bettingStartTime !== null;

      return bettingTimeStarted && currentGameStepIsNotBettingTime;
   };

   private isPlayerHasNoBetBehindBets = (seats: TSeat[]) => {
      return seats.every(
         (seat) => !seat.seatBets.some((seatBet) => seatBet.type === EBJBetType.BetBehind),
      );
   };

   private isPlayerHasNoOwnSeatBets = (seats: TSeat[]) => {
      return seats
         .filter((seat) => seat.isCurrentPlayerSeat)
         .every((seat) => seat.seatBets.length === 0);
   };

   private isPlayerHasNoBets = (seats: TSeat[]) => {
      return this.isPlayerHasNoOwnSeatBets(seats) && this.isPlayerHasNoBetBehindBets(seats);
   };

   public shouldWaitNextRound = ({
      serverGameStep,
      bettingStartTime,
      isPlayerHasTakenSeat,
      seats,
   }: {
      seats: TSeat[];
      isPlayerHasTakenSeat: boolean;
      serverGameStep: EServerGameStep;
      bettingStartTime: TNullable<string>;
   }): boolean => {
      // Check if the betting time has finished.
      const isBettingTimeFinished = this.isBettingTimeFinished({
         serverGameStep,
         bettingStartTime,
      });

      // Check if the player has not placed any bets.
      const isPlayerHasNoBets = this.isPlayerHasNoBets(seats);

      // Check if the round failed to start.
      const isFailedRoundStart = this.isFailedRoundStart(serverGameStep);

      // Check if it's currently the bet processing time.
      const isBetProcessingTime = this.isBetProcessingTime(serverGameStep);

      // Determine if the player should wait for the next round.
      const playerShouldWaitNextRound =
         isPlayerHasTakenSeat && isPlayerHasNoBets && isBettingTimeFinished;

      // Return true if the player should wait for the next round
      // and it's not a failed round start or bet processing time.
      return playerShouldWaitNextRound && !isFailedRoundStart && !isBetProcessingTime;
   };

   public isEarlyDecisionGameStep = (serverGameStatus: EServerGameStep) => {
      return [
         EServerGameStep.PLAYER_INPUT,
         EServerGameStep.AWAIT_CARD,
         EServerGameStep.INIT,
         EServerGameStep.INSURANCE,
      ].includes(serverGameStatus);
   };

   public isInsuranceTime = (serverGameStatus: EServerGameStep) => {
      return [EServerGameStep.INSURANCE].includes(serverGameStatus);
   };

   public isDealingTime = (serverGameStatus: EServerGameStep) => {
      return [EServerGameStep.INIT].includes(serverGameStatus);
   };

   public isBettingTime = (serverGameStatus: EServerGameStep) => {
      return [EServerGameStep.BETTING_TIME, EServerGameStep.LAST_BETS].includes(serverGameStatus);
   };

   public isAwaitCard = (serverGameStatus: EServerGameStep) => {
      return serverGameStatus === EServerGameStep.AWAIT_CARD;
   };

   public isAwaitingBets = (serverGameStatus: EServerGameStep) => {
      return serverGameStatus === EServerGameStep.AWAITING_BETS;
   };

   public isRoundFinished = (serverGameStatus: EServerGameStep) => {
      return serverGameStatus === EServerGameStep.FINISHED;
   };

   public isPlayerDecisionTime = (serverGameStatus: EServerGameStep) => {
      return serverGameStatus === EServerGameStep.PLAYER_INPUT;
   };

   public isFailedRoundStart = (serverGameStatus: EServerGameStep) => {
      return serverGameStatus === EServerGameStep.FAILED_TO_START;
   };

   public isBetProcessingTime = (serverGameStatus: EServerGameStep) => {
      return serverGameStatus === EServerGameStep.BET_PROCESSING;
   };

   public adaptServerGameStepToClient = ({
      serverGameStep,
   }: {
      serverGameStep: EServerGameStep;
   }): EBlackJackGameStep => this.serverByClientGameStepMap(serverGameStep);
}

export const gameStepAdapter = new GameStepAdapter();
