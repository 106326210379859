/* eslint-disable sonarjs/no-duplicate-string */

import { en } from '../en';

export const tr = {
   translation: {
      ...en.translation,
      track: {
         // Missing TR
         raceTrack: 'Parkur',
         bettingMap: 'Bahis haritası',
      },
      menu: {
         lobby: 'Lobi',
         history: 'Geçmiş',
         help: 'Nasıl oynanır?',
         settings: 'Ayarları',
      },
      gameId: 'ID',
      balance: 'Bakiye',
      bet: 'Bahis',
      sidebars: {
         menu: {
            title: 'Menü',
         },
         settings: {
            title: 'Ayarlar',
            volume: 'Ses Düzeyı',
            gameSounds: 'Oyun Sesleri',
            dealerSounds: 'Stüdyo Sesleri',
            dealerRate: 'Oyun Sunucusunu Değerlendirmeyi Etkinleştir',
            videoQuality: 'Video Kalitesi',
            currentQuality: 'Şu anki kalite',
            lowQuality: 'Düşük',
            mediumQuality: 'Orta',
            highQuality: 'Yüksek',
            autoQuality: 'Otomatik',
            announcementsSounds: 'Otomatik Ruley Sesli Duyuruları',
         },
         help: {
            title: 'Nasıl oynanır?',
            search: {
               placeholder: 'Metin girin',
            },
            singleZeroRoulette: {
               gameOverview: {
                  title: 'Oyuna Genel Bakış',
                  content: [
                     {
                        text: 'Canlı Online Tek Sıfırlı Rulet, klasik casino oyununun, oyunculara sürükleyici ve etkileşimli bir deneyim sunan, heyecan verici sanal adaptasyonudur. Gerçek zamanlı olarak profesyonel krupiyeler tarafından yönetilen bu oyun oyunculara, evlerinin konforundan rulet heyecanınıyaşamaimkanısağlar. Oyun, tek sıfırlı ruletin standart kuralları ile oynanır. Gerçekçilik artar ve heyecan verici bir oyun deneyimi sunulur.',
                     },
                  ],
               },
               objective: {
                  title: 'Amaç',
                  content: [
                     {
                        text: 'Canlı Online Tek Sıfırlı Rulet oyununda amaç, rulet tekerinin dönüşünün sonucunu doğru tahmin etmektir. Oyuncular tekil sayılar,sayıgrupları, renkler veya tek/çift gibi çeşitli mevcut bahis seçeneklerine bahis koyarlar ve tahminleri ile sonuç aynı olursa kazanırlar.',
                     },
                  ],
               },
               gamePlayFeatures: {
                  title: 'Oyun Özellikleri',
                  content: [
                     {
                        text: 'Canlı Krupiyeler: Deneyimli ve samimi krupiyeler, oyunu oynatırken gerçekçi ve etkileşimli bir atmosfer yaratırlar. Tekeri döndürüp sonuçları duyururlar ve çekici bir oyun deneyimi sağlarlar.',
                     },
                     {
                        text: 'Gerçek Zamanlıyayın: Oyun, yüksek kalitede video ve ses ile gerçek zamanlı olarak yayınlanarakoyuncuların, oyunu her yönüyle net bir şekilde görmeleri sağlanır. Oyuncular, canlı sohbet özelliğiüzerinden krupiye ve diğer oyuncular ile etkileşime girebilirler.',
                     },
                     {
                        text: "Tek Sıfırlı Teker:Canlı Online Tek Sıfırlı Rulet, 0'dan 36'ya sayıları içeren Avrupa tarzı rulet tekeri kullanır. Bu tekerde bir adet yeşilsıfırlı cep vardır ve böylece oyunculara çift sıfırlı rulete oranla daha yüksek şans verir.",
                     },
                     {
                        text: 'Bahis Seçenekleri:Oyuncuların iç bahisler (tekil sayılar veya küçük gruplara koyulan bahisler) ve dışbahisler (renkler veya tek/çift gibi büyük gruplara koyulan bahisler) dahil çeşitli bahis seçenekleri mevcuttur. Oyun arayüzü, bahis koymak için sezgisel bir platform sunar.',
                     },
                     {
                        text: 'Bahis Limitleri: Her Canlı Online Tek Sıfırlı Rulet masasında önceden belirlenmiş minimum ve maksimum bahis sınırlarıvardır. Böylece oyuncular tercih ettikleri bahis aralığındaki bir masayıseçebilirler.',
                     },
                     {
                        text: 'İstatistik ve Geçmiş: Oyun, geçmişdönüşlerindetaylı istatistiklerini ve kapsamlıgeçmişini sunar. Böylece oyuncular önceki sonuçlara bakarak koyacakları bahisleri seçebilirler.',
                     },
                  ],
               },
               howToPlay: {
                  title: 'Oynanış',
                  content: [
                     {
                        text: 'Masaya gelen oyuncular, arayüzü kullanarak istedikleri fiş değerini seçerler ve onları sanal rulet masasındaki ilgili bahis alanlarına yerleştirirler.',
                     },
                     {
                        text: 'Bahis süresi sona erdiğinde, krupiye tekeri döndürür ve topu atar. Oyuncular, canlı video yayını ile tekerin dönüşünü görürler.',
                     },
                     {
                        text: 'Top, numaralı ceplerden birine gelip durduğunda, krupiye kazanan sayıyı duyurur. ',
                     },
                     {
                        text: 'Kazanan bahisler otomatik olarak sonuçlandırılır ve karşılık gelen ödemeler oyuncuların hesaplarına yatırılır. Ardından oyuncular, sonraki oyun turu için tekrar bahis koyabilirler.',
                     },
                  ],
               },
               tipsAndStrategies: {
                  title: 'İpuçları ve Stratejiler ',
                  content: [
                     {
                        text: 'Doğru bahis kararları vermek için çeşitli bahis türleri ve bunların ödeme oranları hakkında bilgilenin.',
                     },
                     {
                        text: 'Bütçenizi doğru yönetin ve sorumlu bir şekilde oynadığınızdan emin olmak için bahislerinize limit koyun.',
                     },
                     {
                        text: 'Bahis stratejinizi geliştirmeye yardımcı olabilmesi için oyun istatistikleri ve geçmişini kullanarak olası örüntülerini veya trendleri görmeye çalışın.',
                     },
                     {
                        text: 'Genel oyun deneyiminizi daha iyi hale getirmek için krupiye ve diğer oyuncular ile etkileşime geçin.',
                     },
                  ],
               },
               gameRound: {
                  title: 'Oyun Turu',
                  content: [
                     {
                        title: 'Bahis Süresi',
                        text: 'Bahisler, bahis süresi içinde masaya koyulmalıdır. Bahis koymak için fiş değerinin seçilmesi ve ana bahis alanı veya parkur üzerinde bir ya da daha fazla bahis bölgesine yerleştirilmesi gerekir. Masanın minimum bahis limitinden düşük olan bahisler geçersizdir ve kabul edilmez. Masanın maksimum bahis limitinden yüksek olan bahisler otomatik olarak maksimum limite gelecek şekilde değiştirilir. Oyun turu devam ederken masaya geldiyseniz, bahis koymak için lütfen sonraki turun başlamasını bekleyin.',
                     },
                     {
                        title: 'Dönüş',
                        text: 'Bahis süresi sona erdikten sonra Oyun Sunucusu, Rulet topunu dönen tekerin üzerindeki top yoluna bırakır. Top, Rulet tekeri içinde döner. "BAHİSLER KAPANDI" yazısı göründükten sonra bahisler alınmaz. Koyulan bahisler değiştirilemez.',
                     },
                     {
                        title: 'Oyun Sonucu',
                        text: ' Top, bir noktada tekerin üzerindeki numaralı ceplerden birinin içine düşer. Kazanan sayı Oyun Sunucusu tarafından duyurulur ve arayüzde gösterilir.  Oyuncu o sayıyı kapsayan bir bahis koyduysa, kazanır. Kazanan sayı, sadece spin geçerliyse geçerlidir.   Spinin geçerli olması için:   Rulet Topunun Oyun Sunucusu tarafından tekerin dönüş yönünün aksinde döndürülmesi ve bir cebin içinde durmadan önce tekerin etrafında en az üç tur dönmesi gereklidir. ',
                     },
                  ],
               },
               rules: {
                  title: 'Kurallar',
                  content: [
                     {
                        title: 'Limitler',
                        text: 'Bahis Limitleri oyun sırasında sürekli olarak mevcut olmalı ve masada izin verilen minimum ve maksimum bahis düzeylerini göstermelidir. Masadaki Min ve Max sembolleri, Düz bahisler için limitleri gösterir. Tüm limitler Limitler ve Ödemeler panelinde gösterilir.Rulet oyunlarında, şu bahislerin kendi limitleri vardır: Düz, Ayrılmış, Sokak, Köşe, Sepet, Çizgi, Sütun Düzine, Kırmızı, Siyah, Çift, Tek, Düşük 1-18, Yüksek 19-36. Minimum ve Maksimum limitleri idare tarafından belirlenir ve sunucudan gönderilir.',
                     },
                     {
                        title: 'Ödemeler',
                        text: 'Oyuncu, masadaki Limitler üzerine tıklayarak açılan pencereden Masa Ödemelerini görebilir. Ödemeler her zaman aynıdır. Her bahis için ödemeler şöyledir:',
                     },
                  ],
               },
               betsPayout: {
                  titles: ['Bahis', 'Ödeme'],
                  list: [
                     { bet: 'Düz', payout: '35:1' },
                     { bet: 'Ayrılmış', payout: '17:1' },
                     { bet: 'Cadde', payout: '11:1' },
                     { bet: 'Köşe/Sepet', payout: '8:1' },
                     { bet: 'Çizgi', payout: '5:1' },
                     { bet: 'Sütun/Düzine', payout: '2:1' },
                     { bet: 'Kırmızı/Siyah', payout: '1:1' },
                     { bet: 'Tek/Çift', payout: '1:1' },
                     { bet: '1-18/19-36', payout: '1:1' },
                  ],
               },
               placingBets: {
                  title: 'Bahis Koyma',
                  content: [
                     {
                        text: 'Oyuna katılabilmek için oyuncunun en az minimum bahis tutarı kadar bakiyesi olmalıdır.',
                     },
                     {
                        text: 'Fiş tablası, mevcut fiş değerlerini gösterir ve bahis koymak istediğiniz fişlerin değerini seçmenize imkan tanır.',
                     },
                     {
                        text: 'Binlik değerdeki fişler K ile ifade edilir. Örneğin, 1000 değerindeki fişin üzerinde 1k yazar.',
                     },
                     {
                        text: 'Fiş değeri seçildikten sonra, oyun masasındaki bahis alanında tıklayarak/dokunarak bahis koyulabilir.',
                     },
                     { text: 'Bir bahis alanında birden fazla fiş yerleştirilebilir.' },
                     {
                        text: 'Toplam Bahis, mevcut oyun turu için bahislerin toplam tutarını gösterir.',
                     },
                     {
                        text: 'Maksimum bahis limitine erişildiğinde, o bahis için fazladan para kabul edilmez.',
                     },
                  ],
               },
               betTypes: {
                  title: 'Bahis türleri',
                  content: [
                     {
                        text: 'Rulet oyununda çeşitli bahis türleri mevcuttur. Her bahis türünün kendi ödeme oranı vardır. Bahisler bir veya birden çok sayıyı kapsayabilir.',
                     },
                  ],
               },
               insideBets: {
                  title: 'İç Bahisler',
                  content: [
                     {
                        text: 'Bahisler, İç Bahisler denen bahis panelindeki numaralı bahis yerlerine veya numaralı bahis yerlerinin aralarına koyulur.',
                     },
                     { text: 'Düz - Bahis, tek bir sayı üzerine koyulur.' },
                     {
                        text: 'Ayrılmış - Bahis, dikey veya yatay olarak iki sayı arasındaki çizgi üzerine koyulur.',
                     },
                     {
                        text: 'Sokak - Bahis, herhangi bir sayı satırının sonuna koyulur. Sokak Bahsi üç sayıyı kapsar. 0/1/2 ve 0/2/3 kesişimlerine koyulan bahisler de sokak bahsi olarak alınır.',
                     },
                     {
                        text: 'Köşe - Bahis, dört sayının kesiştiği (merkezi kesişim) köşeye koyulur. Dört sayının her biri kapsanır. 0/1/2/3 kesişimine koyulan sepet bahsi de köşe bahsi olarak alınır.',
                     },
                     {
                        text: 'Çizgi - Bahis, iki satırın sonundaki kesişimlerine koyulur. Çizgi bahsi, iki satırdaki tüm sayılar olmak üzere toplam altı sayıyı kapsar.',
                     },
                  ],
               },
               outsideBets: {
                  title: 'Dış Bahisler',
                  content: [
                     {
                        text: 'Sayılı panelin sağındaki veya altındaki bahis yerlerine koyulan bahislere dış bahisler denir. Sıfır, bu alanların hiçbirine dahil değildir.',
                     },
                     {
                        text: 'Sütun - Sütunun altındaki "2\'ye 1" yazılı kutu, o sütundaki 12 sayının tamamını kapsar.',
                     },
                     {
                        text: 'Düzine - "1\'inci 12", "2\'nci 12", "3\'üncü 12" yazılı kutu, kutunun yanındaki 12 sayıyı kapsar.',
                        list: [
                           '1-18/19-36 - 18 sayıdan oluşan ilk veya ikinci grubu kapsar.',
                           'Çift/Tek - 18 tek veya 18 çift sayıyı kapsar.',
                           'Kırmızı/Siyah - 18 kırmızı veya 18 siyah sayıyı kapsar.',
                        ],
                     },
                     { text: 'Parkur Bahisleri' },
                     {
                        text: 'Bu bahisler, sayıları Rulet tekerindeki sırasıyla gösteren Rulet parkuru kullanılarak yapılır.',
                     },
                     {
                        // Missing TR
                        text: 'Provides unique betting options: racetrack bets and neighbour bets. Racetrack bets includes: Voisins du Zero, Zero, Tiers, Orphelins.',
                     },
                  ],
               },
               racetrackBets: {
                  title: 'Parkur Bahisleri',
                  content: [
                     {
                        text: 'Tek sıfırlı Rulet tekeri, masadaki ilgili bölgelere karşılık gelen 4 bölüme ayrılmıştır. Bu bölgelerin geleneksel adları:',
                     },
                  ],
               },
               voisinsDuZero: {
                  title: 'Voisins du Zero',
                  content: [
                     {
                        text: 'Rulet tekerinde sıfırın olduğu tarafta 22 ve 25 dahil olmak üzere, bunların arasında kalan 17 sayıyı kapsar.',
                     },
                     {
                        text: '9 fiş şu şekilde yerleştirilir:',
                        list: [
                           '2 fiş 0/2/3 sokağına',
                           '1 fiş 4/7 arasına',
                           '1 fiş 12/15 arasına',
                           '1 fiş 18/21 arasına',
                           '1 fiş 19/22 arasına',
                           '2 fiş 25/26/28/29 köşesine',
                           '1 fiş 32/35 arasına',
                        ],
                     },
                  ],
               },
               jeuZero: {
                  title: 'Jeu Zero',
                  content: [
                     {
                        text: 'Sıfırı ve rulet tekerinde sıfırın yakınındaki 6 sayıyı (12, 35, 3, 26, 0, 32 ve 15) kapsar',
                     },
                     {
                        text: '4 fiş şu şekilde yerleştirilir:',
                        list: [
                           '1 fiş 0/3 arasına',
                           '1 fiş 12/15 arasına',
                           '1 fiş 26 üzerine (düz)',
                           '1 fiş 32/35 arasına',
                        ],
                     },
                  ],
               },
               tiers: {
                  title: 'Tiers du Cylindre',
                  content: [
                     {
                        text: ' Rulet tekerinde sıfırın karşı tarafında 27 ve 33 dahil olmak üzere, bunların arasında kalan 12 sayıyı kapsar.',
                     },
                     {
                        text: '6 fiş şu şekilde yerleştirilir:',
                        list: [
                           '1 fiş 5/8 arasına',
                           '1 fiş 10/11 arasına',
                           '1 fiş 13/16 arasına',
                           '1 fiş 23/24 arasına',
                           '1 fiş 27/30 arasına',
                           '1 fiş 33/36 arasına',
                        ],
                     },
                  ],
               },
               orphelins: {
                  title: 'Orphelins',
                  content: [
                     {
                        text: 'Yukarıdaki Voisins du Zero ve Tiers bahislerine dahil olmayan, Rulet tekerinde iki ayrı bölümde bulunan 8 sayıyı kapsar.',
                     },
                     {
                        text: '5 fiş şu şekilde yerleştirilir:',
                        list: [
                           '1 fiş 1 üzerine (düz)',
                           '1 fiş 6/9 arasına',
                           '1 fiş 14/17 arasına',
                           '1 fiş 17/20 arasına',
                           '1 fiş 31/34 arasına',
                        ],
                     },
                  ],
               },
               neighbourBets: {
                  title: 'Komşu Bahisleri',
                  content: [
                     {
                        text: '1 tekil sayıyı ve sayının tekerdeki konumuna bağlı olarak soldan ve sağdan onun 0 ila 9 komşusunu kapsar:',
                        list: [
                           'Oyuncu 1 fiş (1 sayı + 0 sol/0 sağ komşusu) ila 19 fiş (1 sayı + 9 sol/9 sağ komşusu) yerleştirir.',
                           'Oyuncu, 0 ila 9 olarak komşu sayısını seçebilir.',
                        ],
                     },
                     {
                        // Missing TR
                        text: 'Neighbour’s Bets can be placed with click/tap on "-" or "+" button to increase or decrease chosen neighbour bets to the right and left.',
                     },
                  ],
               },
               myBets: {
                  title: 'Bahislerim',
                  content: [
                     {
                        text: 'Bahislerim özelliği, mevcut bahsi kaydederek sonraki turlarda hızlıca bahis koymayı sağlar. Ayrıca özel bahis seçkilerinizi oluşturup sonraki oyunlar için kaydedebilirsiniz.',
                     },
                     {
                        text: 'Düz bahisler. Bu bloktaki bahis seçeneklerinin herhangi birine tıklayarak, bahis panelindeki ilgili tüm düz bahis konumlarına bahis koyabilirsiniz.',
                     },
                     {
                        text: 'Örneğin, Düz Bahisler panelindeki "Kırmızı" üzerine tıklamak, bahis panelindeki tüm kırmızı sayılara fiş yerleştirir.',
                     },
                     {
                        text: 'Özel bahislerin üç bahis seçeneği vardır:',
                        list: [
                           'Son bahis, sayıların son basamağına yapılır. Örneğin Son Dört 4, 14, 24 ve 34 sayılarına bahis koymak anlamına gelir. Son 1-6 için 4 fiş gerekirken, Son 6-9 için sadece 3 fiş gerekir.',
                           'Tam bahisler - Tüm iç bahisleri alır ve belirli bir sayıya koyar.',
                           'Şansımı Göreyim - Sunucu tarafından rastgele olarak seçilen 3, 5 veya 7 sayıya bahis koyar. ',
                        ],
                     },
                     {
                        text: 'Bahis Üreteci kendiniz özel bahis seçkisi oluşturabilmeniz için Bahis Panelini veya Parkuru açar.',
                     },
                  ],
               },
               bettingFromStatistics: {
                  title: 'İstatistik panelinden bahis koyma',
                  content: [
                     {
                        text: 'Doğrudan İstatistik, Son Sonuçlar ve Tablo panellerinden bahis koyabilirsiniz.',
                     },
                     {
                        text: 'İlgili bahis türü üzerine geldiğinizde Bahis Paneli veya Parkur üzerinde vurgulanacaktır. İstatistikler penceresinden sadece Bahis süresi içinde bahis koyabilirsiniz.',
                     },
                  ],
               },
               rebet: {
                  title: 'Bahsi tekrarla',
                  content: [
                     {
                        text: 'Bahsi tekrarla butonuna tıklamak/dokunmak, önceki oyun turundaki bahisleri tekrar koyar.',
                     },
                     { text: 'Buton, sadece oyuncu daha önce oynadıysa mevcuttur.' },
                  ],
               },
               double: {
                  title: 'İkiye katla',
                  content: [
                     {
                        text: 'İkiye katla butonuna tıklamak/dokunmak, oyuncunun tüm bahislerini ikiye katlamasını sağlar.',
                     },
                     {
                        text: 'Oyuncu, bahsi tekrarlamak için bakiyesi yeterli olduğu sürece veya bahis limitine ulaşana kadar istediği kadar kez İkiye katla butonuna basabilir.',
                     },
                  ],
               },
               undo: {
                  title: 'Geri al',
                  content: [
                     {
                        text: 'Geri al butonuna tıklamak/dokunmak en son fiş koyma, Bahsi tekrarla veya İkiye katla eylemini geri alır.',
                     },
                     {
                        text: 'İlk bahis koyulana kadar Geri al butonu devre dışıdır. Bahis koyulduktan sonra Geri al butonu etkinleşir.',
                     },
                     { text: 'Geri al butonuna basılı tutarak tüm bahisler kaldırılabilir' },
                  ],
               },
               dragAndDrop: {
                  title: 'Fişleri Sürükleyip Bırakma',
                  content: [
                     {
                        text: 'Fişleri Sürükleyip Bırakma özelliği oyuncunun, halihazırda bahis alanında bulunan fişleri kolayca hareket ettirmesini sağlar. Fişleri hareket ettirme özelliği Parkur bölümünde mevcut değildir.',
                     },
                     {
                        text: 'Fişi seçmek için oyuncunun fişin üzerine tıklaması ve baslı tutarken onu farklı bir yere taşıması gereklidir.',
                     },
                  ],
               },
               autoplay: {
                  title: 'Otomatik oyun',
                  content: [
                     {
                        text: 'Otomatik oyun özelliği online rulet oynamayı daha kolay ve verimli bir hale getirmek için tasarlanmıştır. Oyuncuların önceden belirlenmiş bahis stratejileri ayarlayarak oyunun, onların yerine bahis koymasını sağlar.',
                     },
                     {
                        text: 'Oynadığınız casinoda mevcutsa, bu özelliği kullanarak belirli bir sayıda tur boyunca otomatik olarak bahis koyabilirsiniz.',
                     },
                     {
                        text: 'Otomatik Oyun Panelinden Otomatik Oyun Turu sayısını seçebilir, otomatik oyunun durması için limit şartlarını belirleyebilirsiniz.',
                     },
                     { text: 'Otomatik Oyun üzerindeki sayaç, kaç turunuz kaldığını gösterir.' },
                     {
                        text: 'Mevcut koşulları görebilir ve değiştirebilirsiniz.  Sonraki turdan itibaren yeni şartlar geçerli olur.',
                     },
                     {
                        text: 'Otomatik oyun ayarlanan şartlara ulaşıldığı için durursa bir bildirim alırsınız.',
                     },
                  ],
               },
               statistics: {
                  title: 'İstatistikler',
                  content: [
                     {
                        text: 'Oyuncu, İstatistik simgesine tıklayarak son 100, 200, 500 veya 1000 tur için İstatistikleri daire, çubuk veya son sonuçlar düzeni gibi farklı şekillerde görebilir.',
                     },
                  ],
               },
               returnToPlayer: {
                  title: 'Oyuncuya Dönüş Oranı',
                  content: [{ text: "Teorik olarak oyuncuya dönüş oranı   (ODO) %97,30'dur." }],
               },
               cancelledGames: {
                  title: 'İptal Edilen Oyunlar',
                  content: [
                     {
                        text: 'Bazı durumlarda teknik sorunlar nedeniyle oyun oturumu iptal edilebilir.',
                     },
                     {
                        text: 'Bu durumda masadaki tüm oyunculara bildirim gider ve tüm bahisler hesaplarına geri yatırılır.',
                     },
                     { text: 'İptal edilen tur Otomatik Oyunu durdurmaz' },
                     {
                        text: 'O turdaki bahisler iade edilir, ancak Otomatik Oyun koşulları sonraki turda geçerliliğini korur.',
                     },
                     { text: 'İptal edilen oyun, Otomatik Oyun turlarından düşülmez.' },
                  ],
               },
               gameHistory: {
                  title: 'Oyun Geçmişi',
                  content: [
                     {
                        text: 'Oyun Geçmişi, tamamladığınız oyun turları geçmişini tarih, oyun turu, koyulan bahis ve tur sonuçlarının listeleri şeklinde gösterir.',
                     },
                     { text: 'En son oyun turu sonucu listenin en üstünde yer alır.' },
                     {
                        text: 'Oyun Geçmişi listesindeki belirli bir oyun turu üzerine dokunmak/tıklamak, o tur için detaylı geçmişi açar.',
                     },
                  ],
               },
               chat: {
                  title: 'Sohbet',
                  content: [
                     { text: 'Oyun Sunucusuna ve diğer oyunculara mesaj gönderilebilir.' },
                     {
                        text: 'Sohbet özelliği, Oyun Sunucusuna veya diğer oyunculara karşı uygunsuz veya kaba mesajlar gönderen oyuncular için devre dışı bırakılacaktır.',
                     },
                     {
                        text: 'Oyuncular, sohbet penceresini kendileri için kullanışlı olacak bir yere taşıyabilirler.',
                     },
                  ],
               },
               roundId: {
                  title: 'Tur Kimliği',
                  content: [
                     { text: 'Her oyun turunun kendine ait eşsiz bir Tur Kimliği vardır.' },
                     {
                        text: 'Belirli bir oyun ile ilgili müşteri hizmetlerine eriştiğinizde, bu Tur Kimliğini belirtin.',
                     },
                  ],
               },
               gameSettings: {
                  title: 'Oyun Ayarları',
                  content: [
                     {
                        text: 'Oyun Ayarları, oyuncu tarafından değiştirilebilen oyun ayarlarının listesini gösterir.',
                     },
                     {
                        text: 'Uygulanan oyun ayarları oyuncunun profiline kaydedilir ve oyuncu tekrar giriş yaptığında otomatik olarak yüklenir.',
                     },
                  ],
               },
               errorHandling: {
                  title: 'Arıza ve Bağlantı Kesintisi Politikası',
                  content: [
                     {
                        text: 'Oyunda, hizmette veya oyun prosedüründe herhangi bir hata olması durumunda oyun turu duraklatılır.',
                     },
                     {
                        text: 'Durumun incelendiği oyunculara sohbet mesajı veya açılır mesaj ile bildirilir.',
                     },
                     {
                        text: 'Hata anında giderilebiliyorsa, oyun turu normal şekilde devam eder.',
                     },
                     {
                        text: 'Anında bir çözüm bulunamazsa, oyun turu iptal edilir ve koyulan bahisler oyun turuna katılan oyunculara iade edilir.',
                     },
                     {
                        text: 'Oyun sunucusu ile bağlantının kesilmesi halinde, ekranda yeniden bağlanıldığında dair bir mesaj belirir. Bahisler kabul edildikten sonra bağlantı kesilirse, tüm bahisler geçerliliğini korur.',
                     },
                     {
                        text: 'Oyun turu tamamlandıysa, oyuncu döndüğünde sonuç Oyun Geçmişi bölümünde gösterilir.',
                     },
                  ],
               },
            },
            autoRoulette: {
               gameOverview: {
                  title: 'Oyuna Genel Bakış',
                  content: [
                     {
                        text: 'Canlı Online Tek Sıfırlı Rulet, klasik casino oyununun, oyunculara sürükleyici ve etkileşimli bir deneyim sunan, heyecan verici sanal adaptasyonudur. Gerçek zamanlı olarak profesyonel krupiyeler tarafından yönetilen bu oyun oyunculara, evlerinin konforundan rulet heyecanınıyaşamaimkanısağlar. Oyun, tek sıfırlı ruletin standart kuralları ile oynanır. Gerçekçilik artar ve heyecan verici bir oyun deneyimi sunulur.',
                     },
                  ],
               },
               gameRound: {
                  title: 'Oyun Turu',
                  content: [],
               },
               bettingTime: {
                  // Missing TR
                  title: 'Betting Time',
                  content: [
                     {
                        // Missing TR
                        text: 'Bets should be placed during the betting time on the table. To place a bet, chip value must be selected and placed on one or more bet spots on the betting grid or racetrack. Any placed bets that are below the minimum bet limit for the table are not valid and will not be accepted. Any placed bets that are above the maximum bet limit for the table are automatically adjusted to match the maximum limit. If you have joined the game round in progress, please wait for the next round to place bets.',
                     },
                  ],
               },
               spinning: {
                  // Missing TR
                  title: 'Spinning',
                  content: [
                     {
                        // Missing TR
                        text: 'After betting time has ended, the Roulette ball will be automatically released into the ball track of the spinning wheel. Ball is spun within the Roulette wheel. After the status "NO MORE BETS" announced bets are not accepted any more. Placed bets can not be modified.',
                     },
                  ],
               },
               gameOutcome: {
                  // Missing TR
                  title: 'Game Outcome',
                  content: [
                     {
                        // Missing TR
                        text: 'The ball will eventually land in one of the numbered pockets within the wheel and winning number will be announced and appear on UI in subtitles. The player will win if has placed a bet that covers that particular number. A winning number is valid only when the spin was valid. Valid Spin is when: The Roulette ball is spun by the Auto Roulette in the direction opposite to the rotation of the wheel and completes at least three revolutions around the track of the wheel before dropping to constitute valid spin.',
                     },
                  ],
               },
               rules: {
                  title: 'Kurallar',
                  content: [],
               },
               limits: {
                  // Missing TR
                  title: 'Limits',
                  content: [
                     {
                        // Missing TR
                        text: 'The Bet Limits should be always displayed during the game play and show minimum and maximum allowed bet limits at the table.',
                     },
                     {
                        // Missing TR
                        text: 'The Min and Max sign on the table indicate the limits for Strait Up bets. All the limits are displayed on the Limits and Payouts panel in Menu.',
                     },
                     {
                        text: 'Rulet oyunlarında, şu bahislerin kendi limitleri vardır: Düz, Ayrılmış, Sokak, Köşe, Sepet, Çizgi, Sütun Düzine, Kırmızı, Siyah, Çift, Tek, Düşük 1-18, Yüksek 19-36.',
                     },
                  ],
               },
               placingBets: {
                  title: 'Bahis Koyma',
                  content: [
                     {
                        text: 'Oyuna katılabilmek için oyuncunun en az minimum bahis tutarı kadar bakiyesi olmalıdır.',
                     },
                     {
                        text: 'Fiş tablası, mevcut fiş değerlerini gösterir ve bahis koymak istediğiniz fişlerin değerini seçmenize imkan tanır.',
                     },
                     {
                        text: 'Binlik değerdeki fişler K ile ifade edilir. Örneğin, 1000 değerindeki fişin üzerinde 1k yazar.',
                     },
                     {
                        text: 'Fiş değeri seçildikten sonra, oyun masasındaki bahis alanında tıklayarak/dokunarak bahis koyulabilir.',
                     },

                     {
                        text: 'Bir bahis alanında birden fazla fiş yerleştirilebilir.',
                     },
                  ],
               },
               betTypes: {
                  title: 'Bahis türleri',
                  content: [
                     {
                        text: 'Rulet oyununda çeşitli bahis türleri mevcuttur. Her bahis türünün kendi ödeme oranı vardır. Bahisler bir veya birden çok sayıyı kapsayabilir.',
                     },
                  ],
               },
               insideBets: {
                  title: 'İç Bahisler',
                  content: [
                     {
                        text: 'Bahisler, İç Bahisler denen bahis panelindeki numaralı bahis yerlerine veya numaralı bahis yerlerinin aralarına koyulur.',
                        list: [
                           'Düz - Bahis, tek bir sayı üzerine koyulur.',
                           'Ayrılmış - Bahis, dikey veya yatay olarak iki sayı arasındaki çizgi üzerine koyulur.',
                           'Sokak - Bahis, herhangi bir sayı satırının sonuna koyulur. Sokak Bahsi üç sayıyı kapsar. 0/1/2 ve 0/2/3 kesişimlerine koyulan bahisler de sokak bahsi olarak alınır.',
                           'Köşe - Bahis, dört sayının kesiştiği (merkezi kesişim) köşeye koyulur. Dört sayının her biri kapsanır. 0/1/2/3 kesişimine koyulan sepet bahsi de köşe bahsi olarak alınır.',
                           'Çizgi - Bahis, iki satırın sonundaki kesişimlerine koyulur. Çizgi bahsi, iki satırdaki tüm sayılar olmak üzere toplam altı sayıyı kapsar.',
                        ],
                     },
                  ],
               },
               outsideBets: {
                  title: 'Dış Bahisler',
                  content: [
                     {
                        text: 'Sayılı panelin sağındaki veya altındaki bahis yerlerine koyulan bahislere dış bahisler denir. Sıfır, bu alanların hiçbirine dahil değildir.',
                        list: [
                           'Sütun - Sütunun altındaki "2\'ye 1" yazılı kutu, o sütundaki 12 sayının tamamını kapsar.',
                           'Düzine - "1\'inci 12", "2\'nci 12", "3\'üncü 12" yazılı kutu, kutunun yanındaki 12 sayıyı kapsar.',
                           '1-18/19-36 - 18 sayıdan oluşan ilk veya ikinci grubu kapsar.',
                           'Çift/Tek - 18 tek veya 18 çift sayıyı kapsar.',
                           'Kırmızı/Siyah - 18 kırmızı veya 18 siyah sayıyı kapsar.',
                        ],
                     },
                  ],
               },
               racetrackAndNeighbourBets: {
                  // Missing TR
                  title: 'Racetrack and Neighbour Bets',
                  content: [
                     {
                        // Missing TR
                        text: 'These bets are placed using Roulette racetrack, which represents the numbers as they appear on the Roulette wheel, rather than the Roulette table. Provides unique betting options: racetrack bets and neighbour bets. Racetrack bets includes: Voisins du Zero, Zero, Tiers, Orphelins. A single-zero Roulette wheel is split into 4 sectors that corresponds to areas on the table. The traditional names of these sectors are:',
                     },
                     { text: 'Voisins du Zero' },
                     {
                        text: 'Rulet tekerinde sıfırın olduğu tarafta 22 ve 25 dahil olmak üzere, bunların arasında kalan 17 sayıyı kapsar.',
                     },
                     {
                        text: '9 fiş şu şekilde yerleştirilir:',
                        list: [
                           '2 fiş 0/2/3 sokağına',
                           '1 fiş 4/7 arasına',
                           '1 fiş 12/15 arasına',
                           '1 fiş 18/21 arasına',
                           '1 fiş 19/22 arasına',
                           '2 fiş 25/26/28/29 köşesine',
                           '1 fiş 32/35 arasına',
                        ],
                     },
                     {
                        text: 'Jeu Zero',
                     },
                     {
                        text: 'Sıfırı ve rulet tekerinde sıfırın yakınındaki 6 sayıyı (12, 35, 3, 26, 0, 32 ve 15) kapsar',
                     },
                     {
                        text: '4 fiş şu şekilde yerleştirilir:',
                        list: [
                           '1 fiş 0/3 arasına',
                           '1 fiş 12/15 arasına',
                           '1 fiş 26 üzerine (düz)',
                           '1 fiş 32/35 arasına',
                        ],
                     },
                     {
                        text: 'Tiers du Cylindre',
                     },
                     {
                        text: ' Rulet tekerinde sıfırın karşı tarafında 27 ve 33 dahil olmak üzere, bunların arasında kalan 12 sayıyı kapsar.',
                     },
                     {
                        text: '6 fiş şu şekilde yerleştirilir:',
                        list: [
                           '1 fiş 5/8 arasına',
                           '1 fiş 10/11 arasına',
                           '1 fiş 13/16 arasına',
                           '1 fiş 23/24 arasına',
                           '1 fiş 27/30 arasına',
                           '1 fiş 33/36 arasına',
                        ],
                     },
                     { text: 'Orphelins' },
                     {
                        text: 'Yukarıdaki Voisins du Zero ve Tiers bahislerine dahil olmayan, Rulet tekerinde iki ayrı bölümde bulunan 8 sayıyı kapsar.',
                     },
                     {
                        text: '5 fiş şu şekilde yerleştirilir:',
                        list: [
                           '1 fiş 1 üzerine (düz)',
                           '1 fiş 6/9 arasına',
                           '1 fiş 14/17 arasına',
                           '1 fiş 17/20 arasına',
                           '1 fiş 31/34 arasına',
                        ],
                     },
                     {
                        // Missing TR
                        text: 'Neighbour Bets Covers 1 straight up number and from 0 to 9 of its neighbours from left and right, based on the number location on the wheel. Player places from 1 chip (1 number + 0 left/0 right neighbours) to 19 chips (1 number + 9 left/9 right neighbours). Player can select number or neighbours from 0 to 9. Neighbour Bets can be placed with click/tap on "-" or "+" button to increase or decrease chosen neighbour bets to the right and left.',
                     },
                  ],
               },
               myBets: {
                  title: 'Bahislerim',
                  content: [
                     {
                        text: 'Bahislerim özelliği, mevcut bahsi kaydederek sonraki turlarda hızlıca bahis koymayı sağlar. Ayrıca özel bahis seçkilerinizi oluşturup sonraki oyunlar için kaydedebilirsiniz. Düz bahisler. Bu bloktaki bahis seçeneklerinin herhangi birine tıklayarak, bahis panelindeki ilgili tüm düz bahis konumlarına bahis koyabilirsiniz. Örneğin, Düz Bahisler panelindeki "Kırmızı" üzerine tıklamak, bahis panelindeki tüm kırmızı sayılara fiş yerleştirir.',
                     },
                     {
                        text: 'Özel bahislerin üç bahis seçeneği vardır:',
                        list: [
                           'Son bahis, sayıların son basamağına yapılır. Örneğin Son Dört 4, 14, 24 ve 34 sayılarına bahis koymak anlamına gelir. Son 1-6 için 4 fiş gerekirken, Son 6-9 için sadece 3 fiş gerekir. Tam bahisler - Tüm iç bahisleri alır ve belirli bir sayıya koyar.',
                           'Şansımı Göreyim - Sunucu tarafından rastgele olarak seçilen 3, 5 veya 7 sayıya bahis koyar. ',
                           'Bahis Üreteci kendiniz özel bahis seçkisi oluşturabilmeniz için Bahis Panelini veya Parkuru açar.',
                        ],
                     },
                  ],
               },
               bettingFromStatisticsPanels: {
                  title: 'İstatistik panelinden bahis koyma',
                  content: [
                     {
                        text: 'Doğrudan İstatistik, Son Sonuçlar ve Tablo panellerinden bahis koyabilirsiniz. İlgili bahis türü üzerine geldiğinizde Bahis Paneli veya Parkur üzerinde vurgulanacaktır. İstatistikler penceresinden sadece Bahis süresi içinde bahis koyabilirsiniz.',
                     },
                  ],
               },
               rebet: {
                  title: 'Bahsi tekrarla',
                  content: [
                     {
                        // Missing TR
                        text: ' Clicking/Tapping on Rebet button will place bets on the table from the previous game round. On the first entry Rebet button is disabled. If the player has already participated in game, on next game round Rebet button is enabled.',
                     },
                  ],
               },
               double: {
                  title: 'İkiye katla',
                  content: [
                     {
                        text: 'İkiye katla butonuna tıklamak/dokunmak, oyuncunun tüm bahislerini ikiye katlamasını sağlar. Oyuncu, bahsi tekrarlamak için bakiyesi yeterli olduğu sürece veya bahis limitine ulaşana kadar istediği kadar kez İkiye katla butonuna basabilir.',
                     },
                  ],
               },
               undo: {
                  title: 'Geri al',
                  content: [
                     {
                        // Missing TR
                        text: ' Clicking/Tapping on Undo button will revoke last placed chip, Rebet and Double action. On the first entry Undo button is disabled. As soon as bet is placed Undo button is enabled. The button remains enabled until there are no more actions to undo. All bets can be cleared by holding the Undo button.',
                     },
                  ],
               },
               dragAndDrop: {
                  title: 'Fişleri Sürükleyip Bırakma',
                  content: [
                     {
                        // Missing TR
                        text: 'Drag and Drop Chips functionality allows the player to freely move chips that are already placed on the betting grid. Moving chips functionality is not supported on Racetrack. To select the chip, player needs to click & hold on the chip and move it to a different bet spot while it is being held. With Moving Chips functionality player can combine different chips or remove them from the betting grid. Undo function will revoke the last move done.',
                     },
                  ],
               },
               autoPlay: {
                  title: 'Otomatik oyun',
                  content: [
                     {
                        // Missing TR
                        text: 'This feature should be enabled by your service provider. You can use this feature to place bets automatically during predefined number of rounds. After you place bet on the table, you can open Autoplay Panel where you can set a number of the Autoplay Rounds and limit conditions to stop autoplay. Autoplay always starts from the next round. Counter on the Autoplay will notify you how many rounds you have left. If you press the Autoplay button while the feature is active, Autoplay will stop and panel will open. You can see current conditions and change them. New conditions are applied from the next round. If Autoplay will stop reaching set conditions, you will be notified.',
                     },
                  ],
               },
               announcements: {
                  // Missing TR
                  title: 'Announcements',
                  content: [
                     {
                        // Missing TR
                        text: 'Prerecorded voice announcements will declare every game state of the game round as well as subtitles will be shown. You can disable game state announcements in the game menu',
                     },
                  ],
               },
               returnToPlayer: {
                  title: 'Oyuncuya Dönüş Oranı',
                  content: [{ text: "Teorik olarak oyuncuya dönüş oranı   (ODO) %97,30'dur." }],
               },
               cancelledGames: {
                  title: 'İptal Edilen Oyunlar',
                  content: [
                     {
                        // Missing TR
                        text: 'In some cases of technical issues game session can be interrupted. All the players who are currently at the table are notified of the cancellation and all bets are returned to their accounts. Cancelled game rounds are marked with X. The spin counts to be valid if the ball makes three full revolutions inside the wheel. If the ball did not make 3 revolutions it is counted as a Auto-roulette mistake, the game will be stopped, round will be cancelled. Cancelled round does not stop Autoplay. Round bets will be returned but Autoplay conditions will apply to continue from the next round. Cancelled game is not deducted from the Autoplay rounds.',
                     },
                  ],
               },
               gameHistory: {
                  title: 'Oyun Geçmişi',
                  content: [
                     {
                        text: 'Oyun Geçmişi, tamamladığınız oyun turları geçmişini tarih, oyun turu, koyulan bahis ve tur sonuçlarının listeleri şeklinde gösterir. En son oyun turu sonucu listenin en üstünde yer alır. Oyun Geçmişi listesindeki belirli bir oyun turu üzerine dokunmak/tıklamak, o tur için detaylı geçmişi açar.',
                     },
                  ],
               },
               chat: {
                  title: 'Sohbet',
                  content: [
                     { text: 'Oyun Sunucusuna ve diğer oyunculara mesaj gönderilebilir.' },
                     {
                        // Missing TR
                        text: 'Customer Support team use chat to inform players. Chat should be available during all game phases.',
                     },
                     {
                        // Missing TR
                        text: 'Chat functionality will be disabled for players who use inappropriate and offensivelanguage or abuse the other players.',
                     },
                     {
                        text: 'Oyuncular, sohbet penceresini kendileri için kullanışlı olacak bir yere taşıyabilirler.',
                     },
                  ],
               },
               liveSupport: {
                  // Missing TR
                  title: 'Live Support',
                  content: [
                     {
                        // Missing TR
                        text: 'If assistance is needed, Live support chat allows you to contact our Customer Support team.',
                     },
                  ],
               },
               gameNumber: {
                  // Missing TR
                  title: 'Game Number',
                  content: [
                     // Missing TR
                     { text: 'Every game round has its own unique Game Number.' },
                     // Missing TR
                     { text: 'This number reflects the time when the game round began.' },
                     {
                        // Missing TR
                        text: 'Refer to this Game Number, when contacting customer support regarding particular game round. history for this game round.',
                     },
                  ],
               },
               gameSettings: {
                  title: 'Oyun Ayarları',
                  content: [
                     {
                        text: 'Oyun Ayarları, oyuncu tarafından değiştirilebilen oyun ayarlarının listesini gösterir.',
                     },
                     {
                        text: 'Uygulanan oyun ayarları oyuncunun profiline kaydedilir ve oyuncu tekrar giriş yaptığında otomatik olarak yüklenir.',
                     },
                     {
                        // Missing TR
                        text: 'Player can change nickname, video, announcements and sound settings.',
                     },
                  ],
               },
               errorHandlingAndDisconnectionPolicy: {
                  title: 'Arıza ve Bağlantı Kesintisi Politikası',
                  content: [
                     {
                        // Missing TR
                        text: 'In case of game error, service error or Auto-roulette error, the game round will be paused.',
                     },
                     {
                        text: 'Durumun incelendiği oyunculara sohbet mesajı veya açılır mesaj ile bildirilir.',
                     },
                     {
                        text: 'Hata anında giderilebiliyorsa, oyun turu normal şekilde devam eder.',
                     },
                     {
                        text: 'Anında bir çözüm bulunamazsa, oyun turu iptal edilir ve koyulan bahisler oyun turuna katılan oyunculara iade edilir.',
                     },
                     {
                        text: 'Oyun sunucusu ile bağlantının kesilmesi halinde, ekranda yeniden bağlanıldığında dair bir mesaj belirir. Bahisler kabul edildikten sonra bağlantı kesilirse, tüm bahisler geçerliliğini korur.',
                     },
                     {
                        text: 'Oyun turu tamamlandıysa, oyuncu döndüğünde sonuç Oyun Geçmişi bölümünde gösterilir.',
                     },
                  ],
               },
            },
         },
         history: {
            title: 'Geçmiş',
            roundBetsTable: {
               betType: 'Bahis Türü',
               bet: 'Bahis',
               netCash: 'net nakit',
            },
         },
         autoplay: {
            title: 'Otomatik Oyun',
            autoplayRounds: 'Otomatik oyun turları',
            lossLimit: 'Kayıp Limiti',
            singleWinLimit: 'Tek Kazanma Sınırı',
            noLimit: 'limit yok',
            stop: 'Durmak',
            start: 'Başlangıç',
            placeBet: 'Otomatik oynatmayı başlatmak için bir bahis koyun',
            emptyLossLimit: 'Bu alan zorunludur',
            lessThanBets: 'Değer bahisten düşük olamaz',
         },
         favourite: {
            title: 'Bahislerim',
            savedBets: 'Kayıtlı bahisler',
            addLastBet: 'Son bahsi ekle',
            specialBets: 'Özel bahisler',
            finalBets: 'Son Bahisler',
            tryMyLuck: 'Şansımı dene',
            completeBets: 'Bahisleri Tamamla',
            specialLineBets: 'Düz bahisler',
            bets: 'Bahis',
            betConstructor: {
               title: 'Bahis Oluşturucu',
               placeBets: 'Bahislerinizi koyun',
            },
         },
         statistics: {
            title: 'İstatistikler',
            lastResults: 'Son sonuçlar',
         },
      },
      chat: {
         title: 'Sohbet',
         placeholder: 'Sohbet için tıklayın',
         errorLoading: 'Bir şeyler ters gitti Lütfen sohbeti yeniden açın',
      },
      youWin: 'Kazandınız',
      unmute: 'Seşı açmak ıçın basın',
      waitForNextRound: 'Sonraki oyunu bekleyin',
      centeredMessagesBanner: {
         pleaseWaitForNextRound: 'Sonraki oyunu bekleyin',
         takeSeat: 'Please take a seat',
         confirmedBets: 'Bahis Onaylandı',
         confirmedSomeBets: '',
         gamePaused: 'Oyun durakladı',
      },
      bettingMap: {
         even: 'Çift',
         odd: 'Tek',
         red: 'Kırmızı',
         black: 'Siyah',
         column: {
            first: '1. sütun',
            second: '2. sütun',
            third: '3. sütun',
         },
         dozen: {
            first: '1st Düzine',
            second: '2nd Düzine',
            third: '3rd Düzine',
         },
      },
      notifications: {
         betConfirmed: {
            error: {
               title: 'Dikkat',
               content: 'Üzgünüz, bahisleriniz kabul edilmedi.',
            },
            errorByBalance: {
               title: 'Uyarı',
               content: 'Üzgünüz, bahisleriniz kabul edilmedi. Bakiyenizde yeterli para yok',
            },
            errorByBetMinLimit: {
               title: 'Dikkat',
               content: 'bahislerinizden bazıları kabul edilmedi',
            },
            success: {
               sendSomeBets: 'bahislerinizden bazıları kabul edilmedi',
               sendAllBets: 'Bahis Onaylandı',
            },
         },
         errorByBalance: {
            title: 'Uyarı',
            content: 'Üzgünüz, bahisleriniz kabul edilmedi. Bakiyenizde yeterli para yok',
         },
         errorByBetMinLimit: {
            title: 'Dikkat',
            content: 'bahislerinizden bazıları kabul edilmedi',
         },
         cancelRound: {
            title: 'Uyarı',
            content: 'Üzgünüz, bu tur iptal edildi. Tüm bahisler bakiyenize iade edilecektir',
         },
         cancelAutoplay: {
            title: 'Bilgi',
            content: 'Otomatik oynatma durduruldu',
         },

         maintenancePending: {
            title: 'Uyarı',
            content: 'Üzgünüz, bu masa sonraki turdan itibaren kapanacaktır',
         },
      },
      timer: {
         bettingTime: {
            lastBets: 'Son bahisler',
            placeBets: 'Bahislerinizi koyun',
            noMoreBets: 'Bahisler kapandı',
         },
         decisionTime: 'kararınızı verin',
         insuranceTime: 'insurance',
      },
      swipe: {
         title: 'yukarı kaydırın',
         subtitle: 'tam ekran için',
      },
      playersList: {
         title: 'Toplam Galibiyet',
      },
      video: {
         toggleVideoMode: 'Video modu',
         pleaseWait: 'Lütfen Bekleyin',
      },
      connection: {
         waitForConnection: 'Lütfen yeniden bağlanmayı bekleyin',
      },
      sound: {
         unmute: 'Sesi aç',
      },
      gameHistory: {
         chooseDate: 'Tarih seçin',
         emptyGameHistoryList: 'Bu dönem için sonuç yok.',
         datePicker: {
            cancel: 'İptal etmek',
            save: 'Kaydetmek',
         },
         navigation: {
            next: 'Sonraki',
            previous: 'Öncesi',
         },
         bjGameBetType: {
            // Missing TR
            cancelled: 'Cancelled',
            betBehind: 'Arka bahis',
            perfectPair: 'Mükemmel Çift',
            split: 'Ayrılmış',
            twentyOnePlusThreeBet: '21 + 3',
            insurance: 'Sigorta',
            // Missing TR
            hand: 'Hand',
            // Missing TR
            top3: 'Top 3',
            mainBet: 'Ana Bahis',
         },
         gameInfo: {
            gameId: 'Oyun Kimliği',
            totalBet: 'Toplam Bahis',
            netCash: 'Net nakit',
            // Missing TR
            dealer: 'Dealer',
            seat: 'Koltuk',
            // Missing TR
            dealerCards: 'Dealer Cards',
         },
         bjRoundBetsTable: {
            netCash: 'Net nakit',
            bet: 'Bahis',
            seat: 'Koltuk',
            // Missing TR
            score: 'Score',
            // Missing TR
            cards: 'Cards',
            betType: 'Bahis Türü',
         },
      },
      tableCapacity: {
         limits: {
            bets: 'Bahisler',
            pays: 'Öder',
            minLimit: 'Minimum sınır',
            maxLimit: 'Maksimum sınır',
         },
      },
      errorOverlay: {
         title: 'Hop...',
         message: 'Bir şeyler ters gitti ama biz bunu zaten düzeltiyoruz',
         action: 'Anladım',
         // Missing TR
         sessionClosed: 'Session was closed server side and is no longer valid.',
      },
      sessionErrorOverlay: {
         title: 'Bu cihazda oyuna yeniden katılmak için sayfayı yenileyin',
         action: 'Anladım',
      },
      notifyInactivePlayerOverlay: {
         title: 'Etkin olmadığınız için yakında bağlantınız kesilecek',
         action: 'Anladım',
      },
      sessionClosedInactivePlayer: {
         title: 'Etkin olmadığınız için bağlantınız kesildi',
         action: 'Anladım',
      },
      maintenanceOverlay: {
         title: 'Üzgünüz bu masa şu anda aktif değil. Lütfen başka bir masa deneyin.',
         action: 'Anladım',
      },
      inactiveGameTableOverlay: {
         title: 'Üzgünüz ama şu anda bu masa kapalı.',
         action: 'Anladım',
      },
      lostConnectionOverlay: {
         title: 'Bağlantı kesildi. Lütfen internet bağlantınızı kontrol edin',
         action: 'Anladım',
      },
      tooltip: {
         limit: {
            maxLimit: 'Bahisleriniz bu bahis pozisyonu için maksimum limite ayarlandı',
            minLimit: 'Bahsiniz limitin altında',
         },
         notEnoughBalance: 'Yetersiz bakiye',
      },
      announcements: {
         male: 'Erkek',
         female: 'Kadın',
         subtitlesLabel: 'Altyazılar',
         numberColors: {
            red: 'Kırmızı',
            black: 'Siyah',
         },
         bettingEndTime: {
            1: 'Hanımlar ve beyler, bahisler kapandı. Teşekkürler ve bol şanslar!',
            2: 'Bu tur için daha fazla bahis kabul edilmeyecektir. Bol şanslar',
            3: 'Bahisler için teşekkürler. Hadi oynayalım, Başarılar!',
         },
         startRound: {
            1: 'Bahisler açık, lütfen bahislerinizi yatırın!',
            2: 'Büyük memnuniyetle bahislerinizi yatira bilirsiniz!',
            3: 'Deyerli hanımlar ve beyler, bahis süresi başladi!',
         },
         winNumber: {
            1: 'Kazanan sayımız {{{winNumberTitle}}}. Tebrikler tüm kazananlara!',
            2: 'Kazanan sayımız {{{winNumber}}}. Bir sonraki turda bol şans!',
            3: 'Şanslı sayımız {{{winNumberTitle}}}! Kazananlar, güzel iş çıkardınız!',
         },
         lastBetsStartTime: {
            1: 'Hanımlar ve beyler, son saniyeler artık, yeni tur başlamadan önce bahislerinizi yatırın!',
            2: 'Çarki cevirmek ve spin yapmak üzereyiz, bahislerinizi yatırmanız için son şansınız!',
            3: 'Son saniyeler başladı deyerli oyuncular. Spinden önce bahislerinizi yatırın.',
         },
         welcomeAnnouncement: {
            1: 'Hoş geldiniz! Kısa sürede bahis yatırımı başlıyor. Bol şans!',
            2: 'Hoş geldiniz! Çarkımız dönüyor ve top düşmek üzere. Bahis yapmaya hazırlanın.',
            3: 'Buyurun, aksiyon başlamak üzere!',
         },
      },
      betControls: {
         double: 'Bahis katla',
         rebet: 'Bahis tekrarla',
      },
      dealerRating: {
         buttonTitle: 'Krupiyeyi Derecelendirin',
         modalTitle: 'Kurpiyemizi beğendiniz mi?',
         feedbackCommentTitle: 'Geri bildiriminizi bırakın',
         sendButtonTitle: 'Göndermek',
         appreciationTitle: 'Geri bildiriminiz için teşekkür ederiz',
      },
      betLimits: {
         mainBets: 'Ana Bahisler',
         betBehind: 'Arka bahis',
         blackjack: 'Blackjack',
         winningHands: 'Kazanan eller',
         insurance: 'Sigorta',
         // Missing TR
         perfectPairs: 'Perfect Pairs',
         perfectPair: 'Mükemmel Çift',
         coloredPair: 'Renkli Çift',
         mixedPair: 'Karışık Çift',
         plusThree: '21+3',
         suitedTrips: 'Yeterli Üçlü',
         straightFlush: 'Sıralı Floş',
         threeOfAKind: 'Üçlü',
         straight: 'Kent',
         flush: 'Floş',
         bets: 'Bahis',
         combination: 'Kombinasyon',
         pays: 'Ödeme',
         min: 'Minimum',
         max: 'Maksimum',
         minCurrency: 'Minimum ({{{currency}}})',
         maxCurrency: 'Maksimum ({{{currency}}})',
      },
      rouletteBetLimits: {
         straight: 'Düz',
         split: 'Ayrılmış',
         street: 'Cadde',
         corner: 'Köşe/Sepet',
         line: 'Çizgi',
         dozen: 'Sütun/Düzine',
         color: 'Kırmızı/Siyah',
         evenOdd: 'Tek/Çift',
         lowHigh: '1-18/19-36',
      },
   },
};
