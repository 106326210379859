// @ts-nocheck
import styled, { css } from 'styled-components/macro';

import { orientation, preventBlurInsideIframe } from '@/common/ui/theme/mixins';

import * as Desktop from './components/desktopWinAmountView/WinAmountView.styles';
import * as MobilePortrait from './components/portraitWinAmountView/WinAmountView.styles';

export const WinAmountTitle = styled.span`
  --fz: 30px;
  font-size: var(--fz);
  font-weight: 500;
  color: ${({ theme }) => theme.colors.white};

  ${orientation.portrait`
    --fz: 24px;
  `};
`;

export const WinAmountValue = styled.span`
  --fz: 47px;
  font-size: var(--fz);
  font-weight: 600;
  color: ${({ theme }) => theme.colors.main};

  ${orientation.portrait`
    --fz: 36px;
  `}
`;

export const WinSumContainer = styled.div`
  ${preventBlurInsideIframe};
  position: relative;
  display: flex;
  margin: 0 auto;
  --width: 438px;
  --height: 114px;

  ${orientation.portrait`
      --width: 238px;
      --height: 121px;
  `};

  width: var(--width);
  height: var(--height);

  ${({ animationDuration, isPortrait }) =>
    animationDuration &&
    css`
      --dark-color: ${({ theme }) => theme.colors?.main};
      /* add alpha channel as 0 */
      --light-color: ${({ theme }) => `${theme.colors?.main}00`};

      animation: ${(isPortrait ? MobilePortrait : Desktop).backgroundAnimation}
        ${animationDuration}ms linear 1 normal forwards;

      #top-line,
      #bottom-line,
      #top-line-circle-inner,
      x #bottom-line-circle-inner {
        animation: ${(isPortrait ? MobilePortrait : Desktop)['lineFadeAnimation']}
          ${animationDuration}ms linear 1 normal forwards;
      }

      #win-amount-background {
        animation: ${(isPortrait ? MobilePortrait : Desktop)['backgroundAnimation']}
          ${animationDuration}ms linear 1 normal forwards;
      }

      #top-line-circle {
        animation: ${(isPortrait ? MobilePortrait : Desktop)['topCircleAnimation']}
          ${animationDuration}ms linear 1 normal forwards;
      }

      #top-line-circle-scale {
        animation: ${(isPortrait ? MobilePortrait : Desktop)['topCircleScaleAnimation']}
          ${animationDuration}ms linear 1 normal forwards;
      }

      #bottom-line-circle {
        animation: ${(isPortrait ? MobilePortrait : Desktop)['bottomCircleAnimation']}
          ${animationDuration}ms linear 1 normal forwards;
      }

      #bottom-line-circle-scale {
        animation: ${(isPortrait ? MobilePortrait : Desktop)['bottomCircleScaleAnimation']}
          ${animationDuration}ms linear 1 normal forwards;
      }

      ${WinAmountTitle} {
        animation: ${(isPortrait ? MobilePortrait : Desktop)['titleTextAnimation']}
          ${animationDuration}ms linear 1 normal forwards;
      }

      ${WinAmountValue} {
        animation: ${(isPortrait ? MobilePortrait : Desktop)['descriptionTextAnimation']}
          ${animationDuration}ms linear 1 normal forwards;
      }
    `}
`;

export const SVGLines = styled.svg`
  width: 100%;
  height: 100%;
  overflow: visible;
`;

export const WinContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  position: absolute;
`;
