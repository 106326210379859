import { getNestedProperty } from '../../utils/getNestedProperty';
import {
   resolveInterpolationOptions,
   TResolveInterpolationOptions,
} from '../../utils/resolveInterpolationOptions';

export const useTranslationQuery = (translations, useGameLanguageSelector) => {
   const gameLanguage = useGameLanguageSelector();

   const t = (key: string, options?: TResolveInterpolationOptions): string => {
      const translation = getNestedProperty(translations[gameLanguage]?.translation, key) || key;

      return options ? resolveInterpolationOptions(translation, options) : translation;
   };

   return { t };
};
