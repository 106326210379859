import { LimitTooltipWrapper } from '@/common/modules';

import { WaitForTheNextStateTooltip } from '#/modules/Tooltips';

import * as S from './StatisticsBar.styles';

export const StatisticsBar = ({
   color,
   label,
   onClick,
   direction,
   percentage,
   highlightCells,
   dataTestId,
}) => (
   <LimitTooltipWrapper>
      {({ handleShowLimitTooltip }) => (
         <WaitForTheNextStateTooltip>
            <S.StatisticsBarWrapper
               data-testid={dataTestId}
               percentage={percentage}
               handleAction={(e) => {
                  onClick();
                  handleShowLimitTooltip(e[0]);
               }}
            >
               <S.Percentage direction={direction}>{percentage}%</S.Percentage>
               <S.Progress
                  color={color}
                  direction={direction}
                  data-highlight-cells={highlightCells}
               >
                  <S.Label data-highlight-cells={highlightCells} direction={direction}>
                     {label}
                  </S.Label>
               </S.Progress>
            </S.StatisticsBarWrapper>
         </WaitForTheNextStateTooltip>
      )}
   </LimitTooltipWrapper>
);
