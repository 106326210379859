import { createSlice } from '@reduxjs/toolkit';
import { useDispatch, useSelector } from 'react-redux';

import { TNullable } from '@/common/types';
import { ETableStatus } from '@/common/constants';

export enum EGameState {
   MAKE_BET = 'MAKE_BET',
   NO_MORE_BETS = 'NO_MORE_BETS',
   LAST_BETS = 'LAST_BETS',
   WIN_NUMBER = 'WIN_NUMBER',
   ROUND_RESULT = 'ROUND_RESULT',
   WAIT_NEXT_ROUND = 'WAIT_NEXT_ROUND',
   ROUND_CANCELLED = 'CANCELLED',
   IN_PROGRESS = 'IN_PROGRESS',
   ROUND_WINNERS = 'ROUND_WINNERS',
}

export const enum EGameStatus {
   INITIAL = 'INITIAL',
   LOADING = 'LOADING',
   SUCCESS = 'SUCCESS',
}

export const enum ESessionClosedReason {
   KickInactiveGameTablePlayer = 'KickInactiveGameTablePlayer',
   LoginFromAnotherPlace = 'LoginFromAnotherPlace',
}

interface IGameState {
   gameState: EGameState;
   gameStatus: EGameStatus;
   tableStatus: TNullable<ETableStatus>;
}

const initialState: IGameState = {
   gameStatus: EGameStatus.INITIAL,
   tableStatus: null,
   gameState: EGameState.WAIT_NEXT_ROUND,
};

export const gameStateSlice = createSlice({
   name: 'gameState',
   initialState,
   reducers: {
      changeGameState: (state, action) => {
         state.gameState = action.payload;
      },

      setGameStatusAsLoading: (state) => {
         state.gameStatus = EGameStatus.LOADING;
      },

      setGameStatusAsSuccess: (state) => {
         state.gameStatus = EGameStatus.SUCCESS;
      },

      setWaitingForConnection: (state) => {
         state.tableStatus = ETableStatus.WaitForConnection;
      },

      setLostConnectionMode: (state) => {
         state.tableStatus = ETableStatus.LostConnection;
      },
   },
   selectors: {
      getGameState: (state) => state.gameState,
      getGameStatus: (state) => state.gameStatus,
      getTableStatus: (state) => state.tableStatus,
   },
});

export const useGameStateActions = () => {
   const dispatch = useDispatch();
   const {
      changeGameState,
      setWaitingForConnection,
      setGameStatusAsSuccess,
      setLostConnectionMode,
   } = gameStateSlice.actions;

   const handleChangeGameState = (digitalTableState: string): void => {
      dispatch(changeGameState(digitalTableState));
   };

   const handleSetGameStatusAsSuccess = (): void => {
      dispatch(setGameStatusAsSuccess());
   };

   const handleSetWaitingForConnection = (): void => {
      dispatch(setWaitingForConnection());
   };

   const handleSetLostConnectionMode = () => {
      dispatch(setLostConnectionMode());
   };

   return {
      handleChangeGameState,
      handleSetWaitingForConnection,
      handleSetGameStatusAsSuccess,
      handleSetLostConnectionMode,
   };
};

export const useGameStateSelector = () => useSelector(gameStateSlice.selectors.getGameState);
export const useGameStatusSelector = () => useSelector(gameStateSlice.selectors.getGameStatus);
export const useTableStatusSelector = () => useSelector(gameStateSlice.selectors.getTableStatus);

export const useGameState = () => {
   const gameState = useGameStateSelector() ?? {};
   const gameStatus = useGameStatusSelector();
   const tableStatus = useTableStatusSelector();

   const { handleChangeGameState } = useGameStateActions();

   const startRound = () => handleChangeGameState(EGameState.MAKE_BET);
   const toggleToWaitForTheNextRound = () => handleChangeGameState(EGameState.WAIT_NEXT_ROUND);

   const isBettingTimeState = gameState === EGameState.MAKE_BET;
   const isNoMoreBetsState = gameState === EGameState.NO_MORE_BETS;
   const isLastBetsState = gameState === EGameState.LAST_BETS;
   const isRoundResultState = gameState === EGameState.ROUND_RESULT;
   const isWaitForTheNextRoundState = gameState === EGameState.WAIT_NEXT_ROUND;
   const isRoundCancelledState = gameState === EGameState.ROUND_CANCELLED;
   const isWinNumberState = gameState === EGameState.WIN_NUMBER;
   const isRoundWinnersState = gameState === EGameState.ROUND_WINNERS;

   const isLoadingGameStatus = gameStatus === EGameStatus.LOADING;
   const isSuccessGameStatus = gameStatus === EGameStatus.SUCCESS;

   const isSessionClosedStatus = tableStatus === ETableStatus.SessionClosed;
   const isWaitingForConnectionGameStatus = tableStatus === ETableStatus.WaitForConnection;
   const isConnectionLostMode = tableStatus === ETableStatus.LostConnection;

   const isGameInProgress = gameState === EGameState.IN_PROGRESS;

   return {
      gameState,
      gameStatus,
      startRound,
      isNoMoreBetsState,
      isLastBetsState,
      isBettingTimeState,
      isRoundResultState,
      isLoadingGameStatus,
      isSuccessGameStatus,
      isConnectionLostMode,
      handleChangeGameState,
      isRoundCancelledState,
      isWinNumberState,
      isSessionClosedStatus,
      isWaitForTheNextRoundState,
      isRoundWinnersState,
      toggleToWaitForTheNextRound,
      isWaitingForConnectionGameStatus,
      isGameInProgress,
      tableStatus,
   };
};
