// @ts-nocheck
import styled, { keyframes } from 'styled-components/macro';

export const backgroundAnimation = keyframes`
	0% {
		opacity: 1
	}

	66.666667% {
		opacity: 1
	}

	100% {
		opacity: 0
	}
`;

export const topCircleAnimation = keyframes`
	0% {
		transform: translate(336.5px, 4.5px);
		animation-timing-function: cubic-bezier(0.635, 0, 0.355, 0.98);
	}

	66.666667% {
		transform: translate(82.5px, 4.5px);
	}

	100% {
		transform: translate(82.5px, 4.5px);
	}
}`;

export const topCircleScaleAnimation = keyframes`
	0% {
		transform: scale(1, 1)
	}

	33.333333% {
		transform: scale(1, 1.5)
	}

	66.666667% {
		transform: scale(1, 1)
	}

	100% {
		transform: scale(1, 1)
	}
`;

export const D_Animation = keyframes`
	0% {
		opacity: 0.5
	}

	33.333333% {
		opacity: 1
	}

	66.666667% {
		opacity: 0.5
	}

	100% {
		opacity: 0.5
	}
`;

export const bottomCircleAnimation = keyframes`
	0% {
		transform: translate(89.5px, 119.5px);
		animation-timing-function: cubic-bezier(0.61, 0.005076, 0.805, 0.5)
	}

	33.333333% {
		transform: translate(214.7725px, 119.5px);
		animation-timing-function: cubic-bezier(0.195, 0.485222, 0.39, 0.975369)
	}

	66.666667% {
		transform: translate(386.5px, 119.5px)
	}

	100% {
		transform: translate(386.5px, 119.5px)
	}
`;

export const bottomCircleScaleAnimation = keyframes`
	0% {
		transform: scale(1, 1)
	}

	33.333333% {
		transform: scale(1, 3.25872)
	}

	66.666667% {
		transform: scale(1, 2.245162)
	}

	100% {
		transform: scale(1, 2.245162)
	}
`;

export const titleTextAnimation = keyframes`
	0% {
		opacity: 0.5
	}

	33.333333% {
		opacity: 1
	}

	66.666667% {
		opacity: 0.5
	}

	100% {
		opacity: 0.5
	}
`;

export const descriptionTextAnimation = keyframes`
	0% {
		opacity: 1
	}

	66.666667% {
		opacity: 1
	}

	100% {
		opacity: 0
	}
`;

export const lineFadeAnimation = keyframes`
  0% {
    opacity: 0;
  }

  3.333333% {
    opacity: 0;
  }

  10% {
    opacity: 1;
  }

  90% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
`;

export const SVGLines = styled.svg`
  width: 100%;
  height: 100%;
  overflow: visible;
`;
