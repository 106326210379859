import { Suspense, lazy, memo } from 'react';

import { Sidebars } from '@/common/modules/Sidebars';
import { ERouletteSidebar } from '@/common/entities/Sidebar';
import * as Icons from '@/common/ui/Icons';

import { useTranslation } from '#/translates';

import { Statistics } from '../Statistics';
import { Autoplay } from '../Autoplay';

import { RouletteHistoryContainer } from './components';
import * as S from './RouletteSidebars.styles';

const Favourite = lazy(() => import('#/modules/Favourite'));
const Settings = lazy(() => import('#/modules/Settings'));
const Help = lazy(() => import('#/modules/Help'));
const MenuContainer = lazy(() => import('#/modules/Menu'));

export const RouletteSidebars = memo(() => {
   const { t } = useTranslation();
   const sidebarsConfig = [
      {
         type: ERouletteSidebar.Statistics,
         title: t('sidebars.statistics.title'),
         icon: <Icons.Statistics />,
         content: <Statistics />,
         isCloseOnClickOutside: true,
      },

      {
         type: ERouletteSidebar.Favourite,
         title: t('sidebars.favourite.title'),
         icon: <Icons.Favourite />,
         content: (
            <Suspense fallback={null}>
               <Favourite />
            </Suspense>
         ),
         isCloseOnClickOutside: true,
      },
      {
         type: ERouletteSidebar.Autoplay,
         title: t('sidebars.autoplay.title'),
         icon: <Icons.Autoplay />,
         content: <Autoplay />,
         isCloseOnClickOutside: false,
      },
      {
         type: ERouletteSidebar.Settings,
         title: t('sidebars.settings.title'),
         icon: <Icons.Settings />,
         content: (
            <Suspense fallback={null}>
               <Settings />
            </Suspense>
         ),
         isCloseOnClickOutside: true,
      },
      {
         type: ERouletteSidebar.Help,
         content: (
            <Suspense fallback={null}>
               <Help />
            </Suspense>
         ),
         isCloseOnClickOutside: true,
         isScrollableBody: false,
      },
      {
         type: ERouletteSidebar.History,
         title: t('sidebars.history.title'),
         icon: <Icons.History />,
         content: <RouletteHistoryContainer />,
         isCloseOnClickOutside: true,
      },
   ];

   return (
      <S.SidebarsWrapper>
         <Sidebars sidebarsConfig={sidebarsConfig}>
            <MenuContainer />
         </Sidebars>
      </S.SidebarsWrapper>
   );
});

RouletteSidebars.displayName = 'RouletteSidebars';
