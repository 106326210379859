// @ts-nocheck
import styled from 'styled-components';

import { getBackgroundColorByTimerTick } from '../../../../utils';
import { orientation } from '../../../../../../ui/theme/mixins';

export const CircularCountDownProgressBar = styled.circle`
   --circular-countdown-stroke-width: 10;
   fill: transparent;
   stroke-width: var(--circular-countdown-stroke-width);
   stroke-linecap: round;
   transform: rotate(-90deg) translate3d(0, 0px, 0px) !important;
   transform-origin: center;
   stroke: ${({ tick }) => (tick ? getBackgroundColorByTimerTick(tick) : 'unset')};
   transition: stroke 1s linear;

   ${orientation.portrait`
      --circular-countdown-stroke-width: 8;
   `}
`;

export const CircularCountDownProgressBarContainer = styled.svg`
   position: absolute;
   overflow: visible;
   left: 0;
   top: 0;
   width: var(--circle-timer-size);
   height: var(--circle-timer-size);
`;
