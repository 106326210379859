import { useDocumentEvent } from '@/common/hooks';
import { isMobileDevice } from '@/common/utils/checkDevice';
import { useTableStatusSelector } from '@/common/modules/TableStatusOverlay/state';

import { DolbyController } from '../DolbyController';

// Handle stream visibility when the app is hidden or the tab is changed
export const useStreamVisibility = (liveStreamPlayer: DolbyController): void => {
   const { isSessionClosedDueToInactivity } = useTableStatusSelector();

   const handleStreamVisibility = (): void => {
      if (isSessionClosedDueToInactivity) {
         // we need to return early if session was closed due to inactivity to prevent restarting the video unnecessarily after changing tabs
         return;
      }

      if (!isMobileDevice()) {
         return;
      }

      const isTabInvisible = document.hidden;
      if (isTabInvisible) {
         liveStreamPlayer?.pause();
      } else {
         liveStreamPlayer?.play();
      }
   };

   useDocumentEvent('visibilitychange', handleStreamVisibility);
};
