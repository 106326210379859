// @ts-nocheck
import styled, { css } from 'styled-components/macro';

import { orientation } from '@/common/ui/theme/mixins';

export const HelpLayout = styled.div`
   --padding-top: 55px;
   --padding-right: 0px;
   --width: 665px;
   --height: 100%;
   --search-height: 40px;
   --search-mb: 18px;

   position: relative;
   display: flex;
   flex-direction: column;
   width: var(--width);
   height: var(--height);
   padding-right: var(--padding-right);
   padding-top: var(--padding-top);

   ${orientation.portrait`
      --width: 100%;
      --padding-top: 30px;
      --padding-right: 0px;
      --height: calc(100% - 58px);
   `};

   ${orientation.mobileLandscape`
      --width: 100%;
      --padding-top: 15px;
      --padding-right: 0;
      --height: calc(100% - 58px);
   `};
`;

export const TabsWithSections = styled.div`
   --height: 100%;
   --gap: 25px;
   --tabs-width: 196px;
   --tabs-transition-duration: 0.2s;
   --tabs-toggle-button-width: 36px;
   --sections-width: calc(100% - var(--tabs-width) - var(--gap));
   --sections-height: calc(100% - var(--search-height) - var(--search-mb));
   --padding-left: 0;

   display: flex;
   gap: var(--gap);
   height: 100%;
   width: 100%;
   padding-left: var(--padding-left);
   /* we need this property for correctly rendering on safari browsers */
   transform: translateY(0px);

   .marked-text {
      background: ${({ theme }) => theme.colors.main};
      color: ${({ theme }) => theme.colors.white};
   }

   ${orientation.mobileLandscape`
      --gap: 10px;
      --sections-width: calc(100% - var(--gap));
      --padding-left: calc(var(--tabs-toggle-button-width) + 5px);
      --height: calc(100% - 30px);
   `};

   ${orientation.portrait`
      --sections-width: 100%;
      --tabs-toggle-button-width: 26px;
      --padding-left: calc(var(--tabs-toggle-button-width) + 5px);
      --height: calc(100% - 30px);
   `};
`;

export const Search = styled.div`
   width: 100%;
   margin-bottom: 18px;
   position: relative;
   height: var(--search-height);
   display: flex;
   align-items: center;
   justify-content: space-between;
   gap: 10px;
   padding-left: var(--search-title-pl);
   font-family: 'Montserrat', sans-serif;

   ${orientation.portrait`
      --search-input-right-position: 28px;
      --search-title-pl: 20px;
   `}

   ${orientation.mobileLandscape`
      --search-input-right-position: 28px;
      --search-title-pl: 35px;
   `}
`;

export const Tabs = styled.div`
   width: var(--tabs-width);

   ${({ canHide, isTabsOpen }) =>
      canHide &&
      css`
         position: absolute;
         height: 100%;
         top: 0;
         left: 0;
         transform: ${isTabsOpen ? 'translateX(0)' : 'translateX(-100%)'};
         transition: transform var(--tabs-transition-duration) linear;
      `}
`;

export const Sections = styled.div`
   width: var(--sections-width);
   height: var(--sections-height);
`;

export const ToggleTabs = styled.div`
   position: absolute;
   top: 18px;
   right: calc(0px - var(--tabs-toggle-button-width));
   width: var(--tabs-toggle-button-width);
   height: calc(var(--tabs-toggle-button-width) + 10px);
   display: flex;
   justify-content: center;
   align-items: center;
   background: linear-gradient(0deg, #141414 0%, #141414 100%), #000;
   border-top-right-radius: 0;
   border-bottom-right-radius: 0;
   border-top-left-radius: 3px;
   border-bottom-left-radius: 3px;
   transform: rotate(180deg);

   &:hover {
      cursor: pointer;
   }

   ${({ isTabsOpen }) =>
      isTabsOpen &&
      css`
         transform: none;
         border-top-right-radius: 3px;
         border-bottom-right-radius: 3px;
         border-top-left-radius: 0;
         border-bottom-left-radius: 0;
      `}
`;
