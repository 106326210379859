// @ts-nocheck
import styled from 'styled-components';

import { orientation } from '@/common/ui/theme/mixins';

export const CircularCountDownContainer = styled.div`
   --circle-timer-size: 150px;

   ${orientation.mobileLandscape`
      --circle-timer-size: 50px;
   `};

   ${orientation.portrait`
      --circle-timer-size: 92px;
   `};

   position: relative;
   display: inline-block;
   width: var(--circle-timer-size);
   height: var(--circle-timer-size);
   border-radius: 50%;
`;
