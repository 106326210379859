// @ts-nocheck
import styled from 'styled-components/macro';

import { EModalNotificationType } from '#/ui/ModalNotification/constants';

import { orientation } from '../theme/mixins';

export const ModalWrapper = styled.div`
  --width: 460px;
  --icon-size: 36px;

  z-index: 9999;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 30px 20px;
  background: rgba(3, 3, 3, 0.8);
  box-shadow:
    0 4px 8px rgba(0, 0, 0, 0.3),
    0 12px 24px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  width: var(--width);

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 8px;
    background: ${({ type, theme }) => {
      const colors = {
        [EModalNotificationType.Warning]: theme.colors.main,
        [EModalNotificationType.Error]: theme.colors.red,
      };

      return colors[type];
    }};
    border-radius: 4px 4px 0 0;
  }

  ${orientation.portrait`
      --width: 90vw;
  `}

  ${orientation.mobileLandscape`
      --width: 55vw;
  `}
`;

export const Title = styled.h4`
  display: grid;
  grid-area: title;
  align-items: center;
  text-align: left;
  font-weight: 600;
  font-size: 14px;
  line-height: 140%;
  margin: 0;
  color: #fff;
`;

export const Message = styled.p`
  grid-area: message;
  margin: 0;
  text-align: left;
  font-size: 14px;
  font-family: Montserrat;
  font-weight: 600;
  line-height: 140%;
  color: #fff;
`;

export const ModalContent = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: auto 1fr auto;
  grid-template-rows: 1fr auto;
  grid-template-areas:
    'icon title   close'
    'icon message close';
`;

export const Button = styled.button`
  --btn-padding: 8px 40px;

  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
  padding: var(--btn-padding);
  background: none;
  font-size: 14px;
  font-family: Montserrat;
  font-weight: 600;
  color: #fff;
  border-width: 2px;
  border-style: solid;
  border-color: ${({ theme, type }) => {
    const colors = {
      [EModalNotificationType.Warning]: theme.colors.main,
      [EModalNotificationType.Error]: theme.colors.grey,
    };
    return colors[type];
  }};

  font-family: 'Montserrat', sans-serif;
  font-weight: 700;

  &:hover {
    opacity: 0.8;
    cursor: pointer;
  }
`;

export const ActionIcon = styled.span`
  --arrow-width: 15px;
  --arrow-height: 2.5px;

  grid-area: close;
  position: relative;
  width: var(--arrow-width);
  height: var(--arrow-height);
  display: inline-block;
  top: 10px;

  &:before,
  &:after {
    content: '';
    position: absolute;
    width: var(--arrow-width);
    height: var(--arrow-height);
    top: calc(50% - (var(--arrow-height) / 2));
    left: calc(50% - (var(--arrow-width) / 2));
    background-color: #c4c4c4;
    border-radius: 10px;
  }

  &:before {
    transform: rotate(-45deg);
  }

  &:after {
    transform: rotate(45deg);
  }

  &:hover {
    cursor: pointer;
    opacity: 0.8;
  }
`;
