import { TLaunchToken, TGameTableId } from '@/common/entities/Session';

import { RTKQueryApi, SuccessResponse } from '../types';

import { TClientAuth } from './types';

export const authAPI = (apiSlice: RTKQueryApi) =>
   apiSlice.injectEndpoints({
      endpoints: (build) => ({
         clientAuth: build.mutation<
            SuccessResponse<TClientAuth>,
            { launchToken: TLaunchToken; gameTableId: TGameTableId; requestScope: string }
         >({
            query: ({ launchToken, gameTableId, requestScope }) => ({
               url: '/authenticate',
               method: 'POST',
               body: {
                  launchToken,
                  gameTableId,
                  requestScope,
               },
            }),
         }),
      }),
   });
