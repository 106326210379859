import { useCallback } from 'react';

import { gameSettings } from '@/common/helpers';
import { handleGoToLobby } from '@/common/helpers/handleGoToLobby';

import { useGameConfigStore } from '#/services/api/gameConfigApiSlice/state';

export const useLobbyRedirect = () => {
   const { gameConfig } = useGameConfigStore();
   const mainLobbyRedirectUrl = gameConfig.lobbyRedirectUrl;
   const fallbackLobbyRedirectUrl = gameSettings.lobbyRedirectUrl;

   return useCallback(() => {
      handleGoToLobby(mainLobbyRedirectUrl ?? fallbackLobbyRedirectUrl);
   }, [mainLobbyRedirectUrl, handleGoToLobby, fallbackLobbyRedirectUrl]);
};
