import { useEffect } from 'react';

import {
   EStreamingQualities,
   EStreamingUpdateMode,
} from '@/common/modules/LiveStreamPlayer/constants';
import { isNull } from '@/common/utils';

import { dolbyController } from '../DolbyController';

interface LiveStreamQualitiesProps {
   isPlaying: boolean;
   onPlaying: () => void;
   onQualityChange: (quality: EStreamingQualities) => void;
   quality: EStreamingQualities;
   qualityMode: EStreamingUpdateMode;
   videoElement: HTMLVideoElement | null;
}

export const useLiveStreamEvents = ({
   quality,
   qualityMode,
   videoElement,
   onPlaying,
   onQualityChange,
   isPlaying,
}: LiveStreamQualitiesProps) => {
   useEffect(() => {
      if (!dolbyController || !videoElement) return;

      let unsubscribe: (() => void) | undefined;

      if (qualityMode === EStreamingUpdateMode.Auto && !isNull(quality)) {
         dolbyController.setAutoQuality();

         unsubscribe = dolbyController.subscribeToBroadcastEvents(onQualityChange, onPlaying);
      }

      // is we enter manual mode we should unsubscribe from auto quality updates
      if (qualityMode === EStreamingUpdateMode.Manual && !isNull(quality) && isPlaying) {
         unsubscribe?.();
         dolbyController.setQuality(quality);
      }

      return () => {
         unsubscribe?.();
      };
   }, [quality, qualityMode, videoElement, onQualityChange]);
};
