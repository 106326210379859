export const enum ERouletteSidebar {
   Menu = 'Menu',
   Help = 'Help',
   Lobby = 'Lobby',
   History = 'History',
   Settings = 'Settings',
   Autoplay = 'Autoplay',
   Favourite = 'Favourite',
   Statistics = 'Stats',
}

export const enum EBlackjackSidebar {
   Menu = 'Menu',
   Help = 'Help',
   Lobby = 'Lobby',
   History = 'History',
   Settings = 'Settings',
   BetBehind = 'BetBehind',
   GamePresenter = 'GamePresenter',
   IncognitoMode = 'IncognitoMode',
   VideoQuality = 'VideoQuality',
}
