import { EStreamingQualities } from '@/common/modules/LiveStreamPlayer/constants';

// you can get streams quality from here - https://streaming.dolby.io/
export const QUALITY_LEVELS: Record<EStreamingQualities, string> = {
   [EStreamingQualities.Low]: '2',
   [EStreamingQualities.Medium]: '1',
   [EStreamingQualities.High]: '0',
} as const;

export const QUALITY_SIZE: Record<string, EStreamingQualities> = {
   960: EStreamingQualities.Low,
   1280: EStreamingQualities.Medium,
   1920: EStreamingQualities.High,
} as const;
