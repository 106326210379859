import { useState, useCallback } from 'react';

export const usePowerSavingMode = () => {
   const [isCheckedPowerSavingMode, setIsCheckedPowerSavingMode] = useState(false);

   const handleSetCheckedPowerSavingMode = useCallback(() => setIsCheckedPowerSavingMode(true), []);

   return {
      isCheckedPowerSavingMode,
      handleSetCheckedPowerSavingMode,
   };
};
