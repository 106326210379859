import { ETypeBet } from '@/common/roulette/constants';

import { BetConfig, type IConfigWithTypeBet } from '../BetConfig';

class SplitBetsConfig extends BetConfig {
   protected readonly type: ETypeBet = ETypeBet.SPLIT;
   protected readonly multiplier = 2;
   readonly config = [
      [
         { id: 97, numbers: [0, 3] },
         { id: 98, numbers: [3, 6] },
         { id: 99, numbers: [6, 9] },
         { id: 100, numbers: [9, 12] },
         { id: 101, numbers: [12, 15] },
         { id: 102, numbers: [15, 18] },
         { id: 103, numbers: [18, 21] },
         { id: 104, numbers: [21, 24] },
         { id: 105, numbers: [24, 27] },
         { id: 106, numbers: [27, 30] },
         { id: 107, numbers: [30, 33] },
         { id: 108, numbers: [33, 36] },
      ],
      [
         { id: 109, numbers: [2, 3] },
         { id: 110, numbers: [5, 6] },
         { id: 111, numbers: [8, 9] },
         { id: 112, numbers: [11, 12] },
         { id: 113, numbers: [14, 15] },
         { id: 114, numbers: [17, 18] },
         { id: 115, numbers: [20, 21] },
         { id: 116, numbers: [23, 24] },
         { id: 117, numbers: [26, 27] },
         { id: 118, numbers: [29, 30] },
         { id: 119, numbers: [32, 33] },
         { id: 120, numbers: [35, 36] },
      ],
      [
         { id: 121, numbers: [0, 2] },
         { id: 122, numbers: [2, 5] },
         { id: 123, numbers: [5, 8] },
         { id: 124, numbers: [8, 11] },
         { id: 125, numbers: [11, 14] },
         { id: 126, numbers: [14, 17] },
         { id: 127, numbers: [17, 20] },
         { id: 128, numbers: [20, 23] },
         { id: 129, numbers: [23, 26] },
         { id: 130, numbers: [26, 29] },
         { id: 131, numbers: [29, 32] },
         { id: 132, numbers: [32, 35] },
      ],
      [
         { id: 133, numbers: [1, 2] },
         { id: 134, numbers: [4, 5] },
         { id: 135, numbers: [7, 8] },
         { id: 136, numbers: [10, 11] },
         { id: 137, numbers: [13, 14] },
         { id: 138, numbers: [16, 17] },
         { id: 139, numbers: [19, 20] },
         { id: 140, numbers: [22, 23] },
         { id: 141, numbers: [25, 26] },
         { id: 142, numbers: [28, 29] },
         { id: 143, numbers: [31, 32] },
         { id: 144, numbers: [34, 35] },
      ],
      [
         { id: 145, numbers: [0, 1] },
         { id: 146, numbers: [1, 4] },
         { id: 147, numbers: [4, 7] },
         { id: 148, numbers: [7, 10] },
         { id: 149, numbers: [10, 13] },
         { id: 150, numbers: [13, 16] },
         { id: 151, numbers: [16, 19] },
         { id: 152, numbers: [19, 22] },
         { id: 153, numbers: [22, 25] },
         { id: 154, numbers: [25, 28] },
         { id: 155, numbers: [28, 31] },
         { id: 156, numbers: [31, 34] },
      ],
   ];

   override getConfig = (): IConfigWithTypeBet[] => {
      return this.config.flat().map((configItem) => ({ ...configItem, type: this.type }));
   };

   public getIds = (): number[][] => {
      return [
         [...this.config[0].map(({ id }) => id)],
         [...this.config[1].map(({ id }) => id)],
         [...this.config[2].map(({ id }) => id)],
         [...this.config[3].map(({ id }) => id)],
         [...this.config[4].map(({ id }) => id)],
      ];
   };
}

export const splitConfig = new SplitBetsConfig();
