export type AnnouncementId = 1 | 2 | 3;

// currently we support only one variation of announcements
// which means we need disable randomizer by limiting it to 1 result
export const MAX_ANNOUNCEMENT_ID = 1;

// We add multiple variation values as currently there are
// Different amount of audio and subtitles variations count
// Subtitles are not exact transcription of voice
export const MAX_ANNOUNCEMENT_SUBTITLE_ID = 3;

export const getRandomAnnouncementId = (): AnnouncementId =>
   (Math.floor(Math.random() * MAX_ANNOUNCEMENT_ID) + 1) as AnnouncementId;

export const getRandomAnnouncementSubtitleId = (): AnnouncementId =>
   (Math.floor(Math.random() * MAX_ANNOUNCEMENT_SUBTITLE_ID) + 1) as AnnouncementId;
