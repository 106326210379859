import { FC, useRef } from 'react';

import { useLimitTooltipActions } from '../../state';
import { THandleShowLimitTooltip } from '../../types';
import { TSetTimeout, TNullable } from '../../../../types';
import { SHOW_LIMIT_TOOLTIP_IN_SEC } from '../../constants';
import { handlePositioningLimitTooltip } from '../../utils/handlePositioningLimitTooltip';

interface ILimitTooltipWrapperProps {
   children: (params: { handleShowLimitTooltip: THandleShowLimitTooltip }) => JSX.Element;
}

export const LimitTooltipWrapper: FC<ILimitTooltipWrapperProps> = ({ children }) => {
   const timeoutRef = useRef<TNullable<TSetTimeout>>(null);
   const { handleHideTooltip } = useLimitTooltipActions();

   const handleShowLimitTooltip: THandleShowLimitTooltip = (event) => {
      if (event.target) {
         handlePositioningLimitTooltip(event);
      }
      // reset timeout on the new click to prevent hiding tooltip while usr is clicking over it
      if (timeoutRef.current) {
         clearTimeout(timeoutRef.current);
      }

      timeoutRef.current = setTimeout(() => {
         handleHideTooltip();
      }, SHOW_LIMIT_TOOLTIP_IN_SEC);
   };

   return <>{children({ handleShowLimitTooltip })}</>;
};
