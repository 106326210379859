import { Spinner } from '@/common/ui';

import * as S from './Loader.styles';

interface ILoader {
   isCentered?: boolean;
   title: string;
}
export const Loader = ({ title, isCentered = false }: ILoader) => (
   <>
      <S.Progress isCentered={isCentered}>
         <Spinner />
         <S.ProgressMessage>{title}</S.ProgressMessage>
      </S.Progress>
   </>
);
