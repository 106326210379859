import { FC } from 'react';

import { useSelectedChipSelector } from '@/common/modules/ChipTray';
import { IPlayChipSound } from '@/common/entities';
import { LimitTooltipWrapper } from '@/common/modules';

import { mapConfigCommonBets } from '#/core';
import { betsCreator } from '#/core/betsCreator';
import { useSectorPointerEvents } from '#/modules/Statistics/components/StatisticsComponents/StatisticsWheel/components/Section/hooks/useSectorPoints';
import { WaitForTheNextStateTooltip } from '#/modules/Tooltips';
import { withChipSound } from '#/hocs';

import { Components } from './components';
import { ISection } from './types';
import * as S from './Section.styles';

export interface ISectionProps extends ISection {
   fillColor: string;
   number: number;
   path: string;
   scale: number;
}
export const Section: FC<ISectionProps & IPlayChipSound> = ({
   fillColor,
   scale,
   path,
   number,
   playChipSound,
}) => {
   const { value: chipValue } = useSelectedChipSelector();

   const handleSectionClick = (number: number): void => {
      const { id, type, numbers } = mapConfigCommonBets[number];
      betsCreator.makeCommonBet({ id, type, numbers, amount: chipValue });
      playChipSound();
   };

   const sectionPointerEvents = useSectorPointerEvents();
   return (
      <LimitTooltipWrapper>
         {({ handleShowLimitTooltip }) => (
            <WaitForTheNextStateTooltip>
               <S.G
                  data-testid={`statistic-wheel-number-${number}`}
                  handleAction={(event) => {
                     handleShowLimitTooltip(event[0]);
                     handleSectionClick(number);
                  }}
                  {...sectionPointerEvents}
               >
                  <Components.HoverPlace path={path} number={number} />
                  <Components.FillPlace path={path} scale={scale} fillColor={fillColor} />
               </S.G>
            </WaitForTheNextStateTooltip>
         )}
      </LimitTooltipWrapper>
   );
};

export const SectionWithChipSound = withChipSound(Section);
