import { Rank } from '@/common/cardGames/ui/Card/types';
import { TUnstableServerType } from '@/common/blackjack/types';

export class CardRankAdapter {
   static adapt(cardRank: TUnstableServerType): Rank {
      const ranks = {
         '2': Rank.TWO,
         '3': Rank.THREE,
         '4': Rank.FOUR,
         '5': Rank.FIVE,
         '6': Rank.SIX,
         '7': Rank.SEVEN,
         '8': Rank.EIGHT,
         '9': Rank.NINE,
         '10': Rank.TEN,
         J: Rank.JACK,
         Q: Rank.QUEEN,
         K: Rank.KING,
         A: Rank.ACE,
      };

      return ranks[cardRank];
   }
}
