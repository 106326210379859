// @ts-nocheck
import styled from 'styled-components/macro';

import { isMobileDevice } from '@/common/utils/checkDevice';
import { orientation } from '@/common/ui/theme/mixins';

export const TableWrapper = styled.div`
  overflow-x: auto;
  max-width: 100%;
  height: 100%;

  ::-webkit-scrollbar {
    height: 5px;
  }
`;

export const Close = styled.div`
  position: absolute;
  right: -5px;
  top: 8px;
  display: ${isMobileDevice() ? 'block' : 'none'};

  ${orientation.portrait`
      top: 0;
      right: 0;
      z-index: 1;
   `}

  ${orientation.mobileLandscape`
      right: 10px;
      z-index: 1;
   `}
`;

export const Table = styled.table`
  width: 100%;
  border-spacing: 0 3px;

  ${orientation.mobileLandscape`
        padding-right: 20px;
    `}
`;

export const Thead = styled.thead``;

export const Tr = styled.tr`
  white-space: nowrap;
`;

export const Th = styled.th`
  padding: 0 8px;
  color: ${({ theme }) => theme.colors.yellowHue[100]};
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.55);
  font-size: 16px;
  line-height: 20px;
  text-align: left;

  ${orientation.mobileLandscape`
    max-width: 10ch;
    overflow: hidden;
    text-overflow: ellipsis;
    position: sticky;
    top: -1px;
    background-color: rgba(3, 3, 3, 0.8);
  `}

  ${orientation.portrait`
        font-size: 12px;
        position: sticky;
        top: -1px;
        background-color: rgba(3, 3, 3, 0.8);
    `}
`;

export const Tbody = styled.tbody``;

export const Td = styled.td`
  padding: 0 8px;
  line-height: 27px;
  color: ${({ theme }) => theme.colors?.white};
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.45);
  font-size: 16px;
  font-family: 'Montserrat', sans-serif;
  font-weight: 400;

  ${orientation.portrait`
        font-size: 12px;
        line-height: 20px;
    `}
`;
