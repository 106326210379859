import { useState } from 'react';

export const useShowSlider = () => {
   const [showSlider, setShowSlider] = useState(false);

   const handleCloseSlider = (event: Event): void => {
      // todo: add navigation module to Slider component and remove this check
      const isClickedToChipTrayControl = (event.target as Element).role === 'chiptray-control';

      if (!isClickedToChipTrayControl) {
         setShowSlider(false);
      }
   };

   const handleToggleSlider = (): void => {
      setShowSlider((prev) => !prev);
   };

   return {
      showSlider,
      handleCloseSlider,
      handleToggleSlider,
   };
};
