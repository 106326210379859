import { RefObject, useEffect } from 'react';

import { useGameState } from '@/common/state/gameTypes/roulette/gameState';
import { useSelectedChipSelector } from '@/common/modules/ChipTray';
import { IPlayChipSound } from '@/common/entities';
import { ETypeBet } from '@/common/roulette/constants';

import { betsCreatorByPlaceTrack } from '#/core/betsCreator';
import { getAllCallBets } from '#/core/config/call-bets/getCallBetIdByType';
import { isNil } from '#/helpers';
import { useBalanceSelector } from '#/modules/Balance';
import { ITrackComponentState } from '#/state/features/bets';

import { RACETRACK_CELL_CLASSNAME } from '../../constants';
import { getActiveElementsIndexes } from '../../helpers';
import { IRaceTrackState } from '../../state';

export const useClickRaceTrack = ({
   trackStore,
   raceTrackContainerRef,
   playChipSound,
}: {
   trackStore: () => ITrackComponentState;
   raceTrackContainerRef: RefObject<SVGSVGElement>;
} & IPlayChipSound): void => {
   const balance = useBalanceSelector();
   const { gameState } = useGameState();
   const { value: chipValue } = useSelectedChipSelector();
   const { useRaceTrackSelector, placeTrack } = trackStore();
   const betsCreator = betsCreatorByPlaceTrack(placeTrack);
   const { rangeLevel }: IRaceTrackState = useRaceTrackSelector();

   useEffect(() => {
      const raceTrackContainer = raceTrackContainerRef.current;

      const handleOnClick = (event: MouseEvent): void => {
         const target = event.target as Element;
         const cellClassList = target.parentElement?.classList;

         if (!cellClassList || cellClassList.length === 0) {
            return;
         }

         const isRaceTrackCell = cellClassList[0].startsWith(RACETRACK_CELL_CLASSNAME);

         if (isRaceTrackCell) {
            const allSideBets = getAllCallBets().map(String);
            const cellClassListToArray = [...cellClassList.values()];
            const findSideBetType = cellClassListToArray.find((cls) => allSideBets.includes(cls));

            playChipSound();

            if (findSideBetType && !isNil(findSideBetType)) {
               betsCreator.makeCallBet({
                  type: ETypeBet[findSideBetType],
                  amount: chipValue,
               });
               return;
            }

            betsCreator.makeRangeStraightBets({
               numbers: getActiveElementsIndexes({ classList: cellClassList, rangeLevel }),
               amount: chipValue,
            });
         }
      };

      if (raceTrackContainer) {
         raceTrackContainer.addEventListener('click', handleOnClick);

         return () => {
            raceTrackContainer.removeEventListener('click', handleOnClick);
         };
      }
   }, [rangeLevel, chipValue, balance, gameState]);
};
