import { ETypeBet } from '@/common/roulette/constants';

import {
   TCallBet,
   TBetType,
   TCompleteBet,
   TDoubleBet,
   TFinalBet,
   TLuckyBet,
   TRangeStraightBet,
} from './types';

const isBetType = <T extends TBetType, U extends T['type']>(
   obj: T,
   type: U,
): obj is Extract<T, { type: U }> => {
   return obj.type === type;
};

export const isDoubleBetType = (bet: TBetType): bet is TDoubleBet => {
   return isBetType(bet, ETypeBet.DOUBLE);
};

export const isRangeStraightBetType = (obj: TBetType): obj is TRangeStraightBet => {
   return obj.type === ETypeBet.STRAIGHT && 'extractedCommand' in obj;
};

export const isSpecialBetType = (obj: TBetType): obj is TCompleteBet | TFinalBet | TLuckyBet => {
   const isCompleteBet = isBetType(obj, ETypeBet.COMPLETE);
   const isFinalBet = isBetType(obj, ETypeBet.FINAL);
   const isLuckyBet = isBetType(obj, ETypeBet.LUCKY);

   return isCompleteBet || isFinalBet || isLuckyBet;
};

export const isCallBetType = (bet: TBetType): bet is TCallBet => {
   return [ETypeBet.JEU_ZERO, ETypeBet.VOISIN, ETypeBet.ORPHELINS, ETypeBet.TIERS].includes(
      bet.type,
   );
};
