import { TWinNumberEvent } from '@/common/services/sockets/roulette/useWinNumber/types';

import { TWinResults } from '../../services/schemes/winResultsSchema';
import { checkIsCancelRound } from '../checkIsCancelRound';

export const mapWinResult = (winResults: TWinResults): string[] => {
   return winResults
      .map((winResult) => winResult.result)
      .filter((winNumber) => !checkIsCancelRound(winNumber));
};

export const winHistoryDTO = ({ winNumber, winResults }: TWinNumberEvent) => {
   return [winNumber, ...mapWinResult(winResults)];
};
