import { FC, memo } from 'react';

import { isPortraitMode } from '@/common/hooks';
import { ECellColor } from '@/common/roulette/constants';

import { useBetConstructorOrientationContext } from '#/modules/Favourite/components/BetConstructor/context';
import { useTranslation } from '#/translates';

import * as S from './Title.styles';

interface ITitle {
   cellNumber: number;
   numberColor?: ECellColor;
   title: string;
}
export const Title: FC<ITitle> = memo(({ title, numberColor = ECellColor.Black, cellNumber }) => {
   const { t } = useTranslation();
   const betConstructorOrientation = useBetConstructorOrientationContext();
   const RED_CELL_ID = 42;
   const BLACK_CELL_ID = 43;
   const whenNotRenderTitle = [RED_CELL_ID, BLACK_CELL_ID].includes(cellNumber);

   if (whenNotRenderTitle) {
      return null;
   }

   const isPortrait = betConstructorOrientation ? isPortraitMode(betConstructorOrientation) : false;

   return (
      <S.Title numberColor={numberColor} $cellId={cellNumber} $isPortrait={isPortrait}>
         {t(title)}
      </S.Title>
   );
});

Title.displayName = 'Title';
