import { EGameState, useGameState } from '@/common/state/gameTypes/roulette/gameState';
import { getEndTime } from '@/common/utils';
import { useTimerState } from '@/common/modules/CountDown';

import { roundStateApiAdapter } from '#/services/api/roundStateApiSlice/adapter';
import { useRoundState } from '#/services/api/roundStateApiSlice';
import { calculateTimerDuration } from '#/services/api/roundStateApiSlice/helpers';
import { useWinHistory } from '#/modules/WinHistory';

export const useRoundStateEvent = () => {
   const {
      handleSetRoundInfo,
      handleSetRoundPhysicalTableId,
      handleSetDealerInfo,
      handleSetPreviousRoundInfo,
   } = useRoundState();
   const { handleAddWinNumberToWinHistory } = useWinHistory();
   const { handleChangeGameState } = useGameState();
   const { handleSetEndTime } = useTimerState();

   return async (data) => {
      const {
         startBettingTime,
         uuid,
         roundNumber,
         bettingTime,
         dealerName,
         winResults = [],
         physicalTableId,
      } = roundStateApiAdapter(data);

      const winNumbersList = winResults?.map?.((win) => win?.result);

      handleSetRoundInfo({ id: uuid, number: roundNumber });
      handleSetRoundPhysicalTableId(physicalTableId);
      handleSetDealerInfo({ name: dealerName });
      handleSetPreviousRoundInfo({ id: uuid, number: roundNumber });
      handleAddWinNumberToWinHistory(winNumbersList);

      const leftTimerTime = await calculateTimerDuration({
         startTime: startBettingTime,
         bettingTime,
      });
      const isBettingTimeFinished = leftTimerTime <= 0;

      if (isBettingTimeFinished) {
         handleChangeGameState(EGameState.WAIT_NEXT_ROUND);
      } else {
         handleChangeGameState(EGameState.MAKE_BET);
         const endTime: number = getEndTime({
            startBettingTime,
            bettingTime,
         });
         handleSetEndTime(endTime);
      }
   };
};
