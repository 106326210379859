import { THandleShowLimitTooltip } from '../types';
import { LIMIT_TOOLTIP_CLASSNAME } from '../constants';

import { positioningTooltip } from './positioningTooltip';

export const handlePositioningLimitTooltip: THandleShowLimitTooltip = (event) => {
   if (event.target) {
      positioningTooltip({
         target: event.target as HTMLElement,
         selector: `.${LIMIT_TOOLTIP_CLASSNAME}`,
      });
   }
};
