import { cornerConfig, lineConfig, splitConfig, straightConfig, streetConfig } from 'core/config';
import { IConfigByCellNumber } from 'core/config/BetConfig';

import { ETypeBet } from '@/common/roulette/constants';

import { TCommonBet } from '#/state/features/bets';

import { CompleteCommandParams, ISpecialCommand } from './types';
import { AbstractSpecialCommand } from './AbstractSpecialCommand';

export class CompleteCommand extends AbstractSpecialCommand implements ISpecialCommand {
   constructor({ cell, amount }: CompleteCommandParams) {
      super({ type: ETypeBet.COMPLETE, amount, cell });

      this.extractedCommand = this.extract();
   }

   extract = (): TCommonBet[] => {
      return [lineConfig, cornerConfig, straightConfig, streetConfig, splitConfig]
         .reduce((total: IConfigByCellNumber[], config) => {
            total.push(...config.findConfigByCellNumber(this.cell));
            return total;
         }, [])
         .map(({ id, type, numbers = [], multiplier = 1 }) => {
            const amount = this.amount * multiplier;

            const chips = {
               [amount]: this.numberOfChips,
            };
            return { id, type, numbers, amount: this.amount * multiplier, chips };
         });
   };
}
