import { BetConfig } from 'core/config/BetConfig';

import { rangeNumbers } from '@/common/utils';
import { ETypeBet } from '@/common/roulette/constants';

class LowBetConfig extends BetConfig {
   protected readonly type: ETypeBet = ETypeBet.LOW;
   readonly config = [
      {
         id: 40,
         value: '1 to 18',
         numbers: rangeNumbers({ start: 1, end: 18 }),
      },
   ];
}

export const lowConfig = new LowBetConfig();
