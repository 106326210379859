import { dolbyController } from '@/common/modules/LiveStreamPlayer/Dolby/DolbyController';
import { useTableStatusActions } from '@/common/modules/TableStatusOverlay/state';
import { ESessionClosedReason } from '@/common/constants';

export const useSessionClosedEvent = () => {
   const { handleSetSessionClosed, handleUnsetNotifyInactivePlayer } = useTableStatusActions();

   return (event: { reason: ESessionClosedReason }) => {
      handleSetSessionClosed(event.reason);
      handleUnsetNotifyInactivePlayer();
      dolbyController.pause();
      dolbyController.destroyConnection();
   };
};
