import { ETypeBet } from '@/common/roulette/constants';

import { CompleteCommand } from './CompleteCommand';
import { FinalCommand } from './FinalCommand';
import { LuckyCommand } from './LuckyCommand';
import { ISpecialCommand, SpecialCommandOptions } from './types';

export class SpecialCommand {
   createCommand = (options: SpecialCommandOptions) => {
      const specialCommandsMapper: Record<
         typeof options.type,
         new (data: Omit<SpecialCommandOptions, 'type'>) => ISpecialCommand
      > = {
         [ETypeBet.COMPLETE]: CompleteCommand,
         [ETypeBet.LUCKY]: LuckyCommand,
         [ETypeBet.FINAL]: FinalCommand,
      };

      const Command = specialCommandsMapper[options.type];

      return new Command(options);
   };
}
