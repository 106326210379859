import { FC } from 'react';

import { DesktopWinAmountView } from './components/desktopWinAmountView';
import { PortraitWinAmountView } from './components/portraitWinAmountView';
import * as S from './WinAmountView.styles';

interface IWinAmountView {
  animationDuration: number;
  content: string;
  count: string;
  isPortrait: boolean;
}

export const WinAmountView: FC<IWinAmountView> = ({
  count,
  content,
  animationDuration,
  isPortrait,
}) => {
  return (
    <S.WinSumContainer animationDuration={animationDuration} isPortrait={isPortrait}>
      {isPortrait ? (
        <PortraitWinAmountView animationDuration={animationDuration} />
      ) : (
        <DesktopWinAmountView animationDuration={animationDuration} />
      )}
      <S.WinContent>
        <S.WinAmountTitle>{content}</S.WinAmountTitle>
        <S.WinAmountValue>{count}</S.WinAmountValue>
      </S.WinContent>
    </S.WinSumContainer>
  );
};
