import { FC, ReactNode } from 'react';

import { useTableStatusActions, useTableStatusSelector } from './state';
import {
   ErrorOverlay,
   InactiveGameTableOverlay,
   MaintenanceOverlay,
   NotifyInactivePlayer,
   KickInactivePlayerOverlay,
   LoginFromAnotherPlaceOverlay,
} from './components';

interface ITableStatusOverlayProps {
   children: ReactNode;
   handleLobbyRedirect: () => void;
   translation: (key: string) => string;
}

export const TableStatusOverlay: FC<ITableStatusOverlayProps> = ({
   translation,
   handleLobbyRedirect,
   children,
}) => {
   const { handleUnsetNotifyInactivePlayer } = useTableStatusActions();
   const {
      isError,
      isInactiveTable,
      isNotifyInactivePlayer,
      isSessionClosedDueToInactivity,
      isLoginFromAnotherPlace,
      isMaintenanceInProgress,
   } = useTableStatusSelector();

   if (isError) {
      return (
         <ErrorOverlay
            buttonTitle={translation('errorOverlay.action')}
            modalTitle={translation('errorOverlay.title')}
            modalMessage={translation('errorOverlay.message')}
            onClick={handleLobbyRedirect}
         />
      );
   }

   if (isInactiveTable) {
      return (
         <InactiveGameTableOverlay
            buttonTitle={translation('inactiveGameTableOverlay.action')}
            modalTitle={translation('inactiveGameTableOverlay.title')}
            onClick={handleLobbyRedirect}
         />
      );
   }

   if (isMaintenanceInProgress) {
      return (
         <MaintenanceOverlay
            buttonTitle={translation('maintenanceOverlay.title')}
            modalTitle={translation('maintenanceOverlay.action')}
            onClick={handleLobbyRedirect}
         />
      );
   }

   if (isSessionClosedDueToInactivity) {
      return (
         <KickInactivePlayerOverlay
            buttonTitle={translation('sessionClosedInactivePlayer.action')}
            modalTitle={translation('sessionClosedInactivePlayer.title')}
            onClick={handleLobbyRedirect}
         />
      );
   }

   if (isLoginFromAnotherPlace) {
      return (
         <LoginFromAnotherPlaceOverlay
            buttonTitle={translation('sessionErrorOverlay.action')}
            modalTitle={translation('sessionErrorOverlay.title')}
            onClick={handleLobbyRedirect}
         />
      );
   }

   return (
      <>
         {isNotifyInactivePlayer && (
            <NotifyInactivePlayer
               buttonTitle={translation('notifyInactivePlayerOverlay.action')}
               modalTitle={translation('notifyInactivePlayerOverlay.title')}
               onClick={handleUnsetNotifyInactivePlayer}
            />
         )}
         {children}
      </>
   );
};
