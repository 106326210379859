import { logger } from '../../../../utils/logger';

export type TResolveInterpolationOptions = Record<string, number | string>;
export const resolveInterpolationOptions = (
   translationString: string,
   interpolationOptions: TResolveInterpolationOptions,
) =>
   translationString.replace(/\{\{\{(.*?)\}\}\}/g, (_, key) => {
      try {
         const trimmedKey = key.trim();
         const result = interpolationOptions?.[trimmedKey] ?? `{{{${key}}}}`;

         return String(result);
      } catch (error) {
         logger.log('Something went wront', error);
         return translationString;
      }
   });
