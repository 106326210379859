// @ts-nocheck
import styled from 'styled-components/macro';

import { orientation } from '../theme/mixins';

export const GameInfoViewContainer = styled.div`
  --game-info-fz: 16px;
  --game-info-gap: 10px 1px;
  --game-info-lh: 19.5px;

  width: max-content;
  height: var(--game-info-fz);
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: var(--game-info-gap);
  font-size: var(--game-info-fz);
  font-weight: 400;
  line-height: var(--game-info-lh);
  color: ${({ theme }) => theme.colors?.mediumGrey};
  white-space: nowrap;

  ${orientation.portrait`
    --game-info-fz: 12px;
    --game-info-gap: 1px;
    --game-info-lh: 10.8px;
    justify-content: flex-start;
  `}

  ${orientation.mobileLandscape`
      --game-info-fz: 12px;
      --game-info-gap: 7px 1px;
      --game-info-lh: 10.8px;
   `}
`;

export const PlayersCountContainer = styled.div`
  flex-basis: 100%;
`;

export const StyledText = styled.span`
  font-family: 'Montserrat', sans-serif;
  font-size: inherit;
`;

export const DealerName = styled(StyledText)`
  --dealer-name-ml: 5px;
  color: ${({ theme }) => theme.colors?.yellow};
  font-weight: 500;
  margin-left: var(--dealer-name-ml);

  ${orientation.mobileLandscape`
    --dealer-name-ml: 0;
  `}

  ${orientation.portrait`
    --dealer-name-ml: 0;
  `}
`;

export const VerticalSeparator = styled.span`
  position: relative;
  width: ${({ width }) => width};
  height: 100%;

  &:before {
    content: '';
    position: absolute;
    width: 1px;
    border-radius: 1px;
    height: 100%;
    top: 0;
    left: 50%;
    background-color: ${({ theme }) => theme.colors?.mediumGrey};
  }

  ${orientation.mobileLandscape`
    width: ${({ width }) => `calc(${width} - 5px)`};
  `};

  ${orientation.portrait`
    width: ${({ width }) => `calc(${width} - 5px)`};
  `};
`;
