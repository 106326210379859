import { FC } from 'react';

import { WarningTooltip } from '../../../../ui/Tooltip';
import { LIMIT_TOOLTIP_CLASSNAME } from '../../constants';
import { useLimitTooltipSelector, useShowLimitTooltipSelector } from '../../state';

interface ILimitTooltipProps {
   maxLimitMessage: string;
   minLimitMessage: string;
   // TODO: after adding noEnoughBalanceMessage in the roulette we should change noEnoughBalanceMessage to required (not optional)
   noEnoughBalanceMessage?: string;
}

export const LimitTooltipView: FC<ILimitTooltipProps> = ({
   maxLimitMessage,
   minLimitMessage,
   noEnoughBalanceMessage = '',
}) => {
   const isShowTooltip = useShowLimitTooltipSelector();
   const { isMaxLimitTooltip, isMinLimitTooltip, isNotEnoughBalance } = useLimitTooltipSelector();

   const getTooltipMessage = () => {
      if (isMaxLimitTooltip) {
         return maxLimitMessage;
      }

      if (isMinLimitTooltip) {
         return minLimitMessage;
      }

      if (isNotEnoughBalance) {
         return noEnoughBalanceMessage;
      }
   };

   const tooltipMessage = getTooltipMessage() ?? '';

   return (
      <WarningTooltip
         shouldShowNotification={isShowTooltip}
         message={tooltipMessage}
         selector={LIMIT_TOOLTIP_CLASSNAME}
      />
   );
};
