import { createAction, createListenerMiddleware } from '@reduxjs/toolkit';

import { dealerRateSlice } from '@/common/modules/DealerRating/state';
import { userGeneralSettingsSlice } from '@/common/state/gameTypes/common/userGeneralSettings';

import { TDealerFeedbackRequestEvent } from '#/services/sockets/hooks/event-hooks/useSocketEvents/useDealerFeedbackRequestEvent/schemes';

export const dealerFeedbackRequestEventMiddleware = createListenerMiddleware();
export const dealerFeedbackRequestEventAction =
   createAction<TDealerFeedbackRequestEvent>('dealerFeedbackRequest');

dealerFeedbackRequestEventMiddleware.startListening({
   actionCreator: dealerFeedbackRequestEventAction,
   effect: ({ payload }, listenerApi) => {
      const { dispatch } = listenerApi;
      const { setIsLeavingFeedbackAvailable, setIsLeavingFeedbackButtonShown, setFeedbackId } =
         dealerRateSlice.actions;
      const isDealerRatingEnabled = userGeneralSettingsSlice.selectors.getDealerRatingEnabled;

      if (!isDealerRatingEnabled) {
         return;
      }

      const { feedbackId } = payload;

      dispatch(setIsLeavingFeedbackAvailable(true));
      dispatch(setIsLeavingFeedbackButtonShown(true));
      dispatch(setFeedbackId(feedbackId));
   },
});
