// @ts-nocheck
import styled from 'styled-components/macro';

import { getCardHeightByCardWidth } from '../../../blackjack/utils';

export const Card = styled.div`
   display: flex;
   position: relative;
   z-index: ${({ cardZIndex }) => cardZIndex};
   align-items: ${({ isBottom }) => (isBottom ? 'bottom' : 'top')};
   justify-content: space-between;
   flex-direction: column;
   flex-grow: 1;
   padding: 0;
   background: ${({ isFaceDown, theme }) => !isFaceDown && theme.colors?.white};
   max-width: ${({ size }) => size};
   width: ${({ size }) => size};
   height: ${({ size }) => `${getCardHeightByCardWidth(parseInt(size))}px`};
   border-radius: 3px;
   box-sizing: border-box;
   box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.25);
`;
