import { FC, useRef, ReactEventHandler } from 'react';

import { TNullable } from '@/common/types';
import { dolbyController } from '@/common/modules/LiveStreamPlayer/Dolby/DolbyController';
import { useConnectVideoElement } from '@/common/modules/LiveStreamPlayer/hooks';
import { LiveStreamElement } from '@/common/modules/LiveStreamPlayer/components';
import {
   EStreamingQualities,
   EStreamingUpdateMode,
} from '@/common/modules/LiveStreamPlayer/constants';

import { useStreamVisibility, useConnectLiveStreamToVideo, useLiveStreamEvents } from '../hooks';

interface IDolbyProps {
   isPlaying: boolean;
   muted?: boolean;
   onConnectionError: ReactEventHandler<HTMLVideoElement>;
   onPause: ReactEventHandler<HTMLVideoElement>;
   onPlaying: () => void;
   onQualityChange: (quality: EStreamingQualities) => void;
   quality: number;
   qualityMode: EStreamingUpdateMode;
   streamId: string;
}

export const DolbyPlayer: FC<IDolbyProps> = ({
   muted = false,
   streamId,
   onPause,
   onPlaying,
   quality,
   qualityMode,
   isPlaying,
   onQualityChange,
   onConnectionError,
}) => {
   const audioElement = useRef<TNullable<HTMLAudioElement>>(null);
   const { videoElement, onVideoElementChange } = useConnectVideoElement();

   useConnectLiveStreamToVideo({
      streamId,
      videoElement,
      onConnectionError,
      audioElement: audioElement.current,
   });

   useStreamVisibility(dolbyController);

   useLiveStreamEvents({
      quality,
      qualityMode,
      videoElement,
      onQualityChange,
      onPlaying,
      isPlaying,
   });

   return (
      <>
         <LiveStreamElement
            ref={onVideoElementChange}
            onAbort={onConnectionError}
            onPause={onPause}
            onPlaying={onPlaying}
         />
         <audio ref={audioElement} muted={muted} id="audio" />
      </>
   );
};

DolbyPlayer.displayName = 'DolbyPlayer';
