import { createAction, createListenerMiddleware } from '@reduxjs/toolkit';

import { userGeneralSettingsSlice } from '@/common/state/gameTypes/common/userGeneralSettings';

import { TUserGeneralSettingsUpdateEvent } from '#/services/sockets/hooks/event-hooks/useSocketEvents/useUserGeneralSettingsUpdateEvent/schemes';
import { announcementsSlice } from '#/modules/Announcements/state';

export const userGeneralSettingsUpdatedEventMiddleware = createListenerMiddleware();
export const userGeneralSettingsUpdatedEventAction = createAction<TUserGeneralSettingsUpdateEvent>(
   'userGeneralSettingsUpdated',
);

userGeneralSettingsUpdatedEventMiddleware.startListening({
   actionCreator: userGeneralSettingsUpdatedEventAction,
   effect: async ({ payload }, listenerApi) => {
      const { dispatch } = listenerApi;
      const { setIsDealerRatingEnabled } = userGeneralSettingsSlice.actions;
      const { setShowAnnouncementsSubtitles } = announcementsSlice.actions;
      const { is_feedback_enabled, subtitles } = payload;

      dispatch(setIsDealerRatingEnabled(is_feedback_enabled));
      dispatch(setShowAnnouncementsSubtitles(Boolean(subtitles)));
   },
});
