import { TGetArrayItem } from '@/common/types';
import { TRoundWinners } from '@/common/services/sockets/roulette/useRoundWinners/schemes';

type Amount = TGetArrayItem<TRoundWinners>['amount'];
type Username = TGetArrayItem<TRoundWinners>['username'];

export const splitWinnerList = (winnerList: TRoundWinners) => {
   return winnerList.reduce<{
      listOfAmounts: Amount[];
      listOfUsernames: Username[];
   }>(
      (total, item) => {
         total.listOfAmounts.push(item.amount);
         total.listOfUsernames.push(item.username);
         return total;
      },
      { listOfAmounts: [], listOfUsernames: [] },
   );
};
