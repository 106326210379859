import { TUserId } from '@/common/entities';
import { EBJBetType, EDoubleBetTypes, EBetResult } from '@/common/blackjack/constants';
import {
   TBjGameStateEvent,
   TServerSeatBets,
} from '@/common/blackjack/services/schemas/socket/gameStateSchema';

import { TSeatBet } from '../../types';

import { IBlackjackBetAmount, ISeatBetsAdapter, TMapChipValueById } from './types';

class BetAmount implements ISeatBetsAdapter {
   private isAnteBet = (bet: TSeatBet) => {
      return bet.type === EBJBetType.Ante;
   };

   private isInsuranceBet = (bet: TSeatBet) => {
      return bet.type === EBJBetType.Insurance;
   };

   private isTwentyOnePlusThreeBet = (bet: TSeatBet) => {
      return bet.type === EBJBetType.TwentyOnePlusThreeBet;
   };

   private isTopThreeBet = (bet: TSeatBet) => {
      return bet.type === EBJBetType.TopThreeBet;
   };

   private isPerfectPairsBet = (bet: TSeatBet) => {
      return bet.type === EBJBetType.PerfectPairsBet;
   };

   private isBetBehindBet = (bet: TSeatBet, currentPlayerId?: string) => {
      if (!currentPlayerId) {
         return bet.type === EBJBetType.BetBehind;
      }
      return bet.type === `${EBJBetType.BetBehind}-${currentPlayerId}`;
   };

   private isSplitBet = (bet: TSeatBet) => {
      return bet.type === EBJBetType.Split;
   };

   private isDoubleFirstHandBet = (bet: TSeatBet) => {
      return bet.type === EDoubleBetTypes.DoubleZero;
   };

   private isDoubleSecondHandBet = (bet: TSeatBet) => {
      return bet.type === EDoubleBetTypes.DoubleOne;
   };

   private adaptSeatBet = ({
      serverSeatBet,
      mapChipValueById,
   }: {
      serverSeatBet: TServerSeatBets[number];
      mapChipValueById: TMapChipValueById;
   }): TSeatBet => {
      const { amount, amountPayed, uuid, chips, type: betType, result } = serverSeatBet;

      return {
         type: betType as TSeatBet['type'],
         uuid,
         // In the current implementation of async api, when the status is BETTING_TIME and LAST_BETS, we get the amount field in the seat bets as null :
         // {
         //   "type": "ante",
         //   "amount": null,
         //   "uuid": null,
         //   "chips": [
         //       "8f2afc7e-1a20-4393-9841-277f0c235150"
         //   ]
         // }
         // Therefore we calculate bet amount by chips id when amount is null
         amount:
            amount ||
            chips.reduce((total, chip) => {
               const betAmount = mapChipValueById?.[chip] ?? 0;
               return total + betAmount;
            }, 0),
         amountPayed,
         chips,
         result,
      };
   };

   private filterLostSideBets = (bets: TSeatBet[]) => {
      return bets.filter((bet) => {
         const isSideBet =
            this.isPerfectPairsBet(bet) ||
            this.isTwentyOnePlusThreeBet(bet) ||
            this.isTopThreeBet(bet);
         const isBetLost = bet.result === EBetResult.LOSE;

         return !(isSideBet && isBetLost);
      });
   };

   private getBetBehindTypesPool = (playerId: TUserId) => {
      return [
         `betBehind-${playerId}`,
         `split-${playerId}`,
         `double0-${playerId}`,
         `double1-${playerId}`,
         `insurance-${playerId}`,
      ];
   };

   private filterOwnBets(bets: TServerSeatBets): TServerSeatBets {
      return bets.filter((bet) => !bet.type.includes('-'));
   }

   private filterBetBehindBets(
      serverBets: TServerSeatBets,
      betBehindTypes: string[],
   ): TServerSeatBets {
      return serverBets.filter((bet) => betBehindTypes.includes(bet.type));
   }

   private getAllPlayersBets = ({
      serverSeats,
      playerId,
   }: {
      serverSeats: TBjGameStateEvent['seats'];
      playerId: TUserId;
   }): TServerSeatBets => {
      const betBehindTypes = this.getBetBehindTypesPool(playerId);

      const allPlayersBets = serverSeats.flatMap((seat) => {
         const { userId, bets } = seat;

         const isCurrentPlayerSeat = userId === playerId;
         // for current player seat we should filter all bet behinds for another players
         if (isCurrentPlayerSeat) {
            return this.filterOwnBets(bets);
         }
         // for another player seat we should get all bet behinds current player
         return this.filterBetBehindBets(bets, betBehindTypes);
      });

      return allPlayersBets;
   };

   private getUnconfirmedBets = (serverBets: TServerSeatBets): TServerSeatBets => {
      return serverBets.filter((bet) => !bet.uuid);
   };

   private calculateTotalAmountBets = (bets: TSeatBet[]) => {
      return bets.reduce((sum, bet) => sum + bet.amount, 0);
   };

   // calculate all current player bets total amount (plus lost sidebets)
   public calculateCurrentPlayerBetsTotalAmount = ({
      serverSeats,
      playerId,
      mapChipValueById,
   }: {
      serverSeats: TBjGameStateEvent['seats'];
      mapChipValueById: TMapChipValueById;
      playerId: TUserId;
   }) => {
      const allPlayerBets = this.getAllPlayersBets({ serverSeats, playerId });

      const adaptedBets = allPlayerBets.map((serverSeatBet) =>
         this.adaptSeatBet({ serverSeatBet, mapChipValueById }),
      );

      const currentPlayerTotalBetsAmount = this.calculateTotalAmountBets(adaptedBets);

      return currentPlayerTotalBetsAmount;
   };

   // calculate all current player UNCONFIRMED (when bet uuid is null) bets total amount
   public calculateCurrentPlayerUnconfirmedBetsTotalAmount = ({
      serverSeats,
      playerId,
      mapChipValueById,
   }: {
      serverSeats: TBjGameStateEvent['seats'];
      mapChipValueById: TMapChipValueById;
      playerId: TUserId;
   }) => {
      const allPlayerBets = this.getAllPlayersBets({ serverSeats, playerId });

      const unconfirmedBets = this.getUnconfirmedBets(allPlayerBets);

      const adaptedBets = unconfirmedBets.map((serverSeatBet) =>
         this.adaptSeatBet({ serverSeatBet, mapChipValueById }),
      );

      const calculateCurrentPlayerUnconfirmedBetsTotalAmount =
         this.calculateTotalAmountBets(adaptedBets);

      return calculateCurrentPlayerUnconfirmedBetsTotalAmount;
   };

   public getCurrentPlayerBetAmountByBetType = ({
      serverSeats,
      mapChipValueById,
      playerId,
   }: {
      serverSeats: TBjGameStateEvent['seats'];
      mapChipValueById: TMapChipValueById;
      playerId: TUserId;
   }) => {
      const allPlayerBets = this.getAllPlayersBets({ serverSeats, playerId });
      const adaptedBets = allPlayerBets.map((serverSeatBet) =>
         this.adaptSeatBet({ serverSeatBet, mapChipValueById }),
      );
      const adaptedBetsWithTransformedBetBehind = this.betBehindCleanupAdapter({
         bets: adaptedBets,
         playerId,
      });

      const betAmountByBetType = this.calculateBetAmountByBetType(
         adaptedBetsWithTransformedBetBehind,
      );

      return betAmountByBetType;
   };

   public adaptSeatBets = ({
      serverSeatBets,
      mapChipValueById,
      playerId,
   }: {
      playerId: TUserId;
      serverSeatBets: TServerSeatBets;
      mapChipValueById: TMapChipValueById;
   }) => {
      // Adapt server seat bets to client seat bets
      const adaptedBets = serverSeatBets.map((serverSeatBet) =>
         this.adaptSeatBet({ serverSeatBet, mapChipValueById }),
      );

      // Filter out lost side bets
      const validBets = this.filterLostSideBets(adaptedBets);

      // Cleanup other players betBehind bets and rename current player
      // betBehind bet to appropriate convention (betBehind-{playerId} to batBehind)
      const finalBets = this.betBehindCleanupAdapter({
         bets: validBets,
         playerId,
      });

      return finalBets;
   };

   public betBehindCleanupAdapter = ({
      bets,
      playerId,
   }: {
      bets: TSeatBet[];
      playerId: TUserId;
   }) => {
      return bets?.reduce<TSeatBet[]>((totalBets, bet) => {
         if (this.isBetBehindBet(bet, playerId)) {
            const betBehindBetsPool = bets.filter((bet) =>
               this.getBetBehindTypesPool(playerId).some((type) => type === bet.type),
            );

            const betBehind = betBehindBetsPool.reduce(
               (result, bet) => {
                  return {
                     chips: result.chips.concat(bet.chips),
                     amount: result.amount + bet.amount,
                     type: EBJBetType.BetBehind,
                     result: bet.result,
                  };
               },
               { chips: [], amount: 0, type: EBJBetType.BetBehind, result: null } satisfies {
                  chips: TSeatBet['chips'];
                  amount: TSeatBet['amount'];
                  type: EBJBetType.BetBehind;
                  result: TSeatBet['result'];
               },
            );
            totalBets.push(betBehind);
         }

         if (
            this.isAnteBet(bet) ||
            this.isInsuranceBet(bet) ||
            this.isTwentyOnePlusThreeBet(bet) ||
            this.isTopThreeBet(bet) ||
            this.isPerfectPairsBet(bet) ||
            this.isSplitBet(bet) ||
            this.isDoubleFirstHandBet(bet) ||
            this.isDoubleSecondHandBet(bet)
         ) {
            totalBets.push(bet);
         }

         return totalBets;
      }, []);
   };

   public calculateBetAmountByBetType = (bets: TSeatBet[]): IBlackjackBetAmount => {
      const updateBetAmount = ({
         totalBetAmount,
         betType,
         bet,
      }: {
         totalBetAmount: IBlackjackBetAmount;
         betType: EBJBetType;
         bet: TSeatBet;
      }) => {
         totalBetAmount[betType] = totalBetAmount[betType] + (bet.amount ?? 0);
      };

      return bets.reduce(
         (totalBetAmount, bet) => {
            if (bet.result === EBetResult.LOSE) {
               return totalBetAmount;
            }
            // we sum all double bets (first split hand double and second split hand double), split bet and insurance to ante bet
            if (
               this.isAnteBet(bet) ||
               this.isDoubleFirstHandBet(bet) ||
               this.isDoubleSecondHandBet(bet) ||
               this.isSplitBet(bet) ||
               this.isInsuranceBet(bet)
            ) {
               updateBetAmount({
                  totalBetAmount,
                  betType: EBJBetType.Ante,
                  bet,
               });
            }

            if (this.isTwentyOnePlusThreeBet(bet)) {
               updateBetAmount({
                  totalBetAmount,
                  betType: EBJBetType.TwentyOnePlusThreeBet,
                  bet,
               });
            }

            if (this.isPerfectPairsBet(bet)) {
               updateBetAmount({
                  totalBetAmount,
                  betType: EBJBetType.PerfectPairsBet,
                  bet,
               });
            }

            if (this.isTopThreeBet(bet)) {
               updateBetAmount({
                  totalBetAmount,
                  betType: EBJBetType.TopThreeBet,
                  bet,
               });
            }

            if (this.isBetBehindBet(bet)) {
               updateBetAmount({
                  totalBetAmount,
                  betType: EBJBetType.BetBehind,
                  bet,
               });
            }

            return totalBetAmount;
         },
         {
            [EBJBetType.Ante]: 0,
            [EBJBetType.TopThreeBet]: 0,
            [EBJBetType.PerfectPairsBet]: 0,
            [EBJBetType.TwentyOnePlusThreeBet]: 0,
            [EBJBetType.BetBehind]: 0,
         },
      );
   };

   public calculateTotalBetAmount = (bets: TSeatBet[]): number => {
      if (!bets.length) {
         return 0;
      }

      const betAmountByBetType = this.calculateBetAmountByBetType(bets);
      const betTotalAmount = Object.values(betAmountByBetType).reduce(
         (total, betAmount) => total + betAmount,
         0,
      );

      return betTotalAmount;
   };
}

export const seatBetsAdapter = new BetAmount();
